import * as Icons from "components/Icons";
import * as S from "./styled";

interface Props {
  imageFiles: FileList;
  removeImage: () => void;
}
export const FileUploadImageNameList: React.FC<Props> = ({
  imageFiles,
  removeImage,
}) => {
  const images = Array.from(imageFiles!);
  return (
    <>
      {images.map((image) => (
        <S.FileContainer key={image.name}>
          <S.FileName>{image.name}</S.FileName>
          <S.Close onClick={removeImage}>
            <Icons.CloseRoundFilled />
          </S.Close>
        </S.FileContainer>
      ))}
    </>
  );
};
