import styled from "styled-components";

interface ICell {
  title: string;
  content: string;
}
interface IProps {
  cellList: ICell[];
  boldFont?: boolean;
  noLine?: boolean;
  valueLeftAlign?: boolean;
}

const TemplateCellList = ({
  cellList,
  boldFont,
  noLine,
  valueLeftAlign,
}: IProps) => {
  return (
    <Container
      boldFont={boldFont}
      noLine={noLine}
      valueLeftAlign={valueLeftAlign}
    >
      {
        cellList.map((cell, index)=>(
          <dl key={`cell-${index}`}>
            <dt>{cell.title ? cell.title : "셀 제목"}</dt>
            <dd>{cell.content ? cell.content : "내용"}</dd>
          </dl>
        ))
      }
    </Container>
  );
};

export default TemplateCellList;

const Container = styled.div<{
  boldFont;
  noLine;
  valueLeftAlign;
}>`
  box-sizing: border-box;
  ${props=>
    !props.noLine ? `
      margin: 8px 0;
      padding: 8px 0;
      border-top: 1px solid #999;
      border-bottom: 1px solid #999;
    `
      : `
      padding: 12px 0;
    `}
  dl {
    display: flex;
    margin-bottom: 4px;
    dt {
      margin-right: 6px;
      white-space: nowrap;
      font-weight: ${(props) => (props.boldFont ? "bold" : "normal")};
    }
    dd {
      flex: 1;
      text-align: ${(props) => (props.valueLeftAlign ? "left" : "right")};
      white-space: nowrap;
    }
  }
  dl:last-child {
    margin-bottom: 0;
  }
`;
