import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { isMobile as isMobileDevice } from "react-device-detect";
import * as session from "utils/session";

import { PATH } from "properties/menu";
import { MEDIA_QUERY } from "properties/constant";
import { ImgLogo } from "assets";

import WalltetStore from "stores/WalltetStore";
import UserStore from "stores/UserStore";

import { useCustomNavigate } from "hooks/useCustomNavigate";
import useResponsive from "hooks/useResponsive";
import { ModalLayout } from "components/Modals/ModalLayout";
import { Content, Button as ModalButton } from "components/Modals/LayoutStyled";
import { RoundedButton } from "pages/point/Pricing/styled";

export const TopNav: React.FC = observer(() => {
  const [chargedPoint, setChargedPoint] = useState<number>();
  const navigate = useCustomNavigate();
  const walletStore = useContext(WalltetStore);
  const userStore = useContext(UserStore);
  const { isMobile } = useResponsive();
  const [visibleMobileViewAlert, setVisibleMobileViewAlert] = useState(false);

  useEffect(() => {
    // 모바일로 메시지 페이지 접근하면 경고 창 노출
    if (isMobileDevice && navigate.pathname.includes("/message")) {
      const isMobileWarningOpened = session.getMobileViewWarning();
      if (!isMobileWarningOpened) {
        setVisibleMobileViewAlert(true);
        session.setMobileViewWarning(true);
      }
    }
  }, [navigate.pathname]);

  useEffect(() => {
    const fetchWallet = async () => {
      await walletStore.fetchWallet();

      setChargedPoint(walletStore.wallet.amount + walletStore.wallet.point);
    };

    if (userStore.user) {
      fetchWallet();
    }
  }, [walletStore, userStore.user]);

  return (
    <>
      <EmptyBox />
      <Container>
        <Inner>
          <Nav>
            <div className="navLeft">
              <div>
                <Link onClick={() => navigate.moveTo(PATH.HOME)}>
                  <Logo src={ImgLogo} alt="회사 로고" />
                </Link>
              </div>

              {!isMobile && (
                <Links>
                  <Link onClick={() => navigate.moveTo(PATH.HOME)}>
                    <NavLink active={navigate.pathname === PATH.HOME}>
                      <div>서비스소개</div>
                    </NavLink>
                  </Link>

                  <Link onClick={() => navigate.moveTo(PATH.DASHBOARD)}>
                    <NavLink
                      active={
                        navigate.pathname.includes("/message") ||
                        navigate.pathname.includes("/template") ||
                        navigate.pathname.includes("/addressbook") ||
                        navigate.pathname.includes("/point/history")
                      }
                    >
                      <div>문자서비스</div>
                    </NavLink>
                  </Link>

                  <Link onClick={() => navigate.moveTo(PATH.PRICING)}>
                    <NavLink active={navigate.pathname === PATH.PRICING}>
                      <div>요금제</div>
                    </NavLink>
                  </Link>

                  <Link onClick={() => navigate.moveTo(PATH.POINT_CHARGE)}>
                    <NavLink active={navigate.pathname === PATH.POINT_CHARGE}>
                      <div>
                        충전하기&nbsp;
                        {chargedPoint > 0 && (
                          <span>
                            (<b>{chargedPoint.toLocaleString()}&nbsp;</b>p)
                          </span>
                        )}
                      </div>
                    </NavLink>
                  </Link>
                </Links>
              )}
            </div>

            <div className="navRight">
              {!isMobile && (
                <UserLinks>
                  <RoundedButton
                    href={"https://forms.gle/zbg8HVnbSfn2GZf78"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    무료 상담받기
                  </RoundedButton>
                </UserLinks>
              )}
              {userStore.user ? (
                <Button onClick={userStore.logout}>로그아웃</Button>
              ) : (
                <>
                  <Button onClick={() => navigate.moveTo(PATH.SIGN_IN)}>
                    로그인
                  </Button>
                  {!isMobile && (
                    <Button onClick={() => navigate.moveTo(PATH.SIGN_UP)}>
                      회원가입
                    </Button>
                  )}
                </>
              )}
            </div>
          </Nav>
          {isMobile && (
            <MobileContainer>
              <Link onClick={() => navigate.moveTo(PATH.HOME)}>
                <NavLink active={navigate.pathname === PATH.HOME}>
                  <div>서비스소개</div>
                </NavLink>
              </Link>

              <Link onClick={() => navigate.moveTo(PATH.DASHBOARD)}>
                <NavLink
                  active={
                    navigate.pathname.includes("/message") ||
                    navigate.pathname.includes("/template") ||
                    navigate.pathname.includes("/addressbook") ||
                    navigate.pathname.includes("/point/history")
                  }
                >
                  <div>문자서비스</div>
                </NavLink>
              </Link>

              <Link onClick={() => navigate.moveTo(PATH.PRICING)}>
                <NavLink active={navigate.pathname === PATH.PRICING}>
                  <div>요금제</div>
                </NavLink>
              </Link>

              <Link onClick={() => navigate.moveTo(PATH.POINT_CHARGE)}>
                <NavLink active={navigate.pathname === PATH.POINT_CHARGE}>
                  <div>충전하기</div>
                </NavLink>
              </Link>
            </MobileContainer>
          )}
        </Inner>
        {visibleMobileViewAlert && (
          <ModalLayout
            width={340}
            title="화면 최적화 안내"
            onClose={() => setVisibleMobileViewAlert(false)}
          >
            <>
              <ModalContent>
                비즈포스트는 데스크탑에 최적화 되어있습니다. <br />
                데스크탑으로 이용을 권장드립니다
              </ModalContent>
              <ModalButton onClick={() => setVisibleMobileViewAlert(false)}>
                확인
              </ModalButton>
            </>
          </ModalLayout>
        )}
      </Container>
    </>
  );
});

const Container = styled.div`
  width: 100%;
  text-align: center;
  position: fixed;
  z-index: 10;
  top: 0px;
`;
const EmptyBox = styled.div`
  width: 100%;
  height: 65px;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    height: 100px;
  }
`;
const Logo = styled.img`
  width: 85px;
  height: 22px;
`;

const Inner = styled.div`
  width: 100%;
  background-color: white;
  border-bottom: solid 1px #e5e5e5;
  padding: 0 40px;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    padding: 24px 24px 8px;
  }
`;

const Nav = styled.div`
  background-color: white;
  max-width: 980px;
  min-width: 860px;
  margin: 0 auto;
  height: 64px;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  .navLeft {
    display: flex;
  }
  .navRight {
    display: flex;
    gap: 32px;
  }

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    min-width: 290px;
    max-width: 420px;
    width: 100%;
    height: auto;
    gap: 12px;
  }
`;

const Links = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-left: 72px;
  gap: 32px;
`;

const MobileContainer = styled.div`
  display: flex;
  justify-content: space-between;

  padding-top: 20px;
  flex-wrap: no-wrap;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    max-width: 420px;
    margin: 0 auto;
    min-width: 340px;
  }
`;

const NavLink = styled.div<{ active: boolean }>`
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -1px;
  color: ${(props) => (props.active ? "#1b58f1" : "#333333")};
  font-weight: ${(props) => (props.active ? "700" : "normal")};
  cursor: pointer;
  p {
    padding: 0 4px;
  }
`;

const Button = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: -1px;

  color: #333333;
  cursor: pointer;
`;

const UserLinks = styled.div`
  display: flex;
  gap: 28px;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    flex-direction: row-reverse;
  }
`;

const Link = styled.div``;

const ModalContent = styled(Content)`
  text-align: left;
`;
