import { SVGProps } from "react";

export const Template = ({
  width = 22,
  height = 22,
  fill = "#666666",
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.125 19.25C3.75833 19.25 3.4375 19.1125 3.1625 18.8375C2.8875 18.5625 2.75 18.2417 2.75 17.875V4.125C2.75 3.75833 2.8875 3.4375 3.1625 3.1625C3.4375 2.8875 3.75833 2.75 4.125 2.75H11V4.125H4.125V17.875H17.875V11H19.25V17.875C19.25 18.2417 19.1125 18.5625 18.8375 18.8375C18.5625 19.1125 18.2417 19.25 17.875 19.25H4.125ZM15.125 9.625V6.875H12.375V5.5H15.125V2.75H16.5V5.5H19.25V6.875H16.5V9.625H15.125Z"
        fill={fill}
        stroke={fill}
        strokeWidth="0.7"
      />
    </svg>
  );
};
