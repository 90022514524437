import { AddressBookRequestType, AddressBookType } from "types/types";

/**
 * 변경된 수소록만 필터링
 * @param origin 기존 주소록
 * @param modified 변경된 주소록
 */
export const filterModfiedAddressBooks = (
  origin: AddressBookType[],
  modified: AddressBookType[]
) => {
  const filtered = modified.filter((book) => {
    const originBook = origin.find((item) => item.id === book.id);
    if (originBook) {
      if (
        originBook.name !== book.name ||
        originBook.phone_number !== book.phone_number ||
        originBook.memo !== book.memo
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  });
  return filtered;
};

/**
 * 주소록 서버 요청 타입으로 변환
 * @param addressBooks 주소록
 * @param groupId 그룹 아이디
 * @returns
 */
export const convertToAddressBookRequestType = (
  addressBooks,
  groupId: number
) => {
  const books: AddressBookRequestType[] = addressBooks.map((book) => {
    return {
      id: book.id,
      group_id: groupId,
      phone_number: book.phone_number,
      name: book.name,
      memo: book.memo,
    };
  });
  return books;
};
