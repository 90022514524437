import { SVGProps } from "react";

export const TooltipBg = ({
  width = 160,
  height = 67,
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_1600_5" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29 0C24.5817 0 21 3.58172 21 8V32.5624L0 41.8L21 51.0376V59C21 63.4183 24.5817 67 29 67H151.786C156.204 67 159.786 63.4183 159.786 59V8C159.786 3.58172 156.204 0 151.786 0H29Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29 0C24.5817 0 21 3.58172 21 8V32.5624L0 41.8L21 51.0376V59C21 63.4183 24.5817 67 29 67H151.786C156.204 67 159.786 63.4183 159.786 59V8C159.786 3.58172 156.204 0 151.786 0H29Z"
        fill="white"
      />
      <path
        d="M21 32.5624L21.4027 33.4778L22 33.215V32.5624H21ZM0 41.8L-0.402651 40.8846L-2.48354 41.8L-0.402651 42.7154L0 41.8ZM21 51.0376H22V50.385L21.4027 50.1223L21 51.0376ZM22 8C22 4.13401 25.134 1 29 1V-1C24.0294 -1 20 3.02944 20 8H22ZM22 32.5624V8H20V32.5624H22ZM0.402651 42.7154L21.4027 33.4778L20.5973 31.647L-0.402651 40.8846L0.402651 42.7154ZM21.4027 50.1223L0.402651 40.8846L-0.402651 42.7154L20.5973 51.953L21.4027 50.1223ZM22 59V51.0376H20V59H22ZM29 66C25.134 66 22 62.866 22 59H20C20 63.9706 24.0294 68 29 68V66ZM151.786 66H29V68H151.786V66ZM158.786 59C158.786 62.866 155.652 66 151.786 66V68C156.756 68 160.786 63.9706 160.786 59H158.786ZM158.786 8V59H160.786V8H158.786ZM151.786 1C155.652 1 158.786 4.13401 158.786 8H160.786C160.786 3.02944 156.756 -1 151.786 -1V1ZM29 1H151.786V-1H29V1Z"
        fill="black"
        mask="url(#path-1-inside-1_1600_5)"
      />
    </svg>
  );
};
