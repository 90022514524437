import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import OutsideClickHandler from "react-outside-click-handler";

import { MessageUppercaseType } from "types/types";
import { ButtonPrimary, Flex, Pad } from "styles/styled";
import {
  AlertModal,
  ConfirmModal,
  InputButtonWithBottomArrow,
  InputDate,
  MessageLayout,
} from "components";
import {
  DATE_FORMAT_2,
  addDays,
  addMonth,
  getCurrentTime,
} from "utils/datetime";
import { smsTypeOptions } from "properties/content";
import { Pagination } from "components/Pagination";
import { MessageReservedGroup } from "./MessageReservedGroup";
import { useCustomNavigate } from "hooks/useCustomNavigate";
import { PATH } from "properties/menu";
import { Option } from "styles/styled";
import { observer } from "mobx-react-lite";
import MessageStore from "stores/MessageStore";

export const MessageReserveResult = observer(() => {
  const messageStore = useContext(MessageStore);
  const navigate = useCustomNavigate();
  const { start, end } = navigate.query;

  const today = getCurrentTime(DATE_FORMAT_2);
  const MIN_DATE = addMonth(today, DATE_FORMAT_2, -1);
  const MAX_DATE = addDays(today, DATE_FORMAT_2, 365);

  const [startDate, setStartDate] = useState<string>(
    start ? String(start) : MIN_DATE
  );
  const [endDate, setEndDate] = useState<string>(start ? String(end) : today);
  const [visibleCancelReservationModal, setVisibleCancelReservationModal] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const [visibleMessageTypeSelection, setVisibleMessageTypeSelection] =
    useState(false);
  const [visibleAlertModal, setVisibleAlertModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");

  useEffect(() => {
    if (messageStore.currentPage) {
      messageStore.setTargetMessageType(null);
      fetchMessageReservedResults();
    }
  }, []);

  const onClickSearchButton = async () => {
    messageStore.setCurrentPage(1);
    await fetchMessageReservedResults();
  };

  const fetchMessageReservedResults = async () => {
    if (isLoading) {
      return;
    } else {
      setIsLoading(true);
      await messageStore.fetchMessageResult(startDate, endDate, true);
      const url = messageStore.generateUrlWithParams(
        PATH.MESSAGE_RESERVED_RESULT,
        startDate,
        endDate
      );
      navigate.replace(url);
      setIsLoading(false);
    }
  };

  const onClickMessageType = (type: MessageUppercaseType | null) => {
    messageStore.setTargetMessageType(type);
    setVisibleMessageTypeSelection(!visibleMessageTypeSelection);
  };

  const MessageTypeText = messageStore.targetMessageType || "메시지 타입";

  const onHandleCancelReservation = async () => {
    const result = await messageStore.cancelReservedMessage(
      messageStore.targetGroup?.group_id
    );
    if (result.canceled) {
      setVisibleCancelReservationModal(false);
      openAlertModal("예약이 취소되었습니다");
      messageStore.fetchMessageResult(startDate, endDate, true);
    } else {
      openAlertModal("예약 취소에 실패하였습니다.");
    }
  };

  const openAlertModal = (message: string) => {
    setAlertMessage(message);
    setVisibleAlertModal(true);
  };

  const closeAlertModal = () => {
    setAlertMessage(null);
    setVisibleAlertModal(false);
  };

  return (
    <MessageLayout>
      <Container>
        <Inner>
          <Header>
            <SubTitle>예약 내역</SubTitle>
            <Descriptions>
              <Li>‘예약 삭제’는 전송 10분 전까지 취소할 수 있습니다.</Li>
              <Li>삭제된 예약은 복구가 불가합니다.</Li>
            </Descriptions>
          </Header>
          <Pad px={24} py={16}>
            <Flex gap={8} justifyContent="flex-end">
              <InputDate
                date={startDate}
                minDate={MIN_DATE}
                maxDate={MAX_DATE}
                setDate={setStartDate}
                height="46px"
                width="140px"
              />
              <span>~</span>
              <InputDate
                date={endDate}
                minDate={MIN_DATE}
                maxDate={MAX_DATE}
                setDate={setEndDate}
                height="46px"
                width="140px"
              />

              <OutsideClickHandler
                onOutsideClick={() => {
                  setVisibleMessageTypeSelection(false);
                }}
              >
                <MessageTypeContainer>
                  <InputButtonWithBottomArrow
                    onClick={() => setVisibleMessageTypeSelection(true)}
                    text={MessageTypeText}
                    width="160px"
                    height="46px"
                  />

                  {visibleMessageTypeSelection && (
                    <Selection>
                      <Option
                        width={160}
                        height={44}
                        onClick={() => onClickMessageType(null)}
                      >
                        메시지 타입
                      </Option>
                      {smsTypeOptions.map((smsType) => (
                        <Option
                          width={160}
                          height={44}
                          key={smsType.key}
                          onClick={() =>
                            onClickMessageType(smsType.uppercaseKey)
                          }
                        >
                          {smsType.uppercaseKey}
                        </Option>
                      ))}
                    </Selection>
                  )}
                </MessageTypeContainer>
              </OutsideClickHandler>
              <ButtonPrimaryStyled
                radius={4}
                padding="10px 16px"
                onClick={onClickSearchButton}
              >
                검색
              </ButtonPrimaryStyled>
            </Flex>
          </Pad>
          <section>
            <MessageReservedGroup
              groups={messageStore.resultGroups}
              setTargetGroup={messageStore.setTargetGroup}
              setVisibleCancelReservationModal={
                setVisibleCancelReservationModal
              }
            />
          </section>
          <Pad pb={36} />
          <Pagination
            setCurrentPage={messageStore.setCurrentPage}
            size={messageStore.resultTotalCount}
            defaultPage={messageStore.currentPage}
          />
        </Inner>

        {visibleCancelReservationModal && (
          <ConfirmModal
            title="예약 취소"
            confirmButtonText="예약 취소하기"
            confirmButtonColor="#EC1C24"
            closeModal={() => setVisibleCancelReservationModal(false)}
            onConfirm={onHandleCancelReservation}
          >
            <div>
              선택한 예약을 취소하시겠습니까?
              <br />
              취소된 예약 건은 복구할 수 없습니다.
            </div>
          </ConfirmModal>
        )}

        {visibleAlertModal && (
          <AlertModal title="알림" closeModal={closeAlertModal}>
            <div>{alertMessage}</div>
          </AlertModal>
        )}
      </Container>
    </MessageLayout>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubTitle = styled.h4`
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  letter-spacing: -1px;
  color: #333333;
  padding-bottom: 16px;
`;

const Inner = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding-bottom: 40px;
`;

const Header = styled.div`
  padding: 24px;
`;

const Descriptions = styled.ul``;

const Li = styled.li`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -1px;
  color: #666666;

  &::before {
    content: "• ";
    padding-right: 4px;
  }
`;

const MessageTypeContainer = styled.div`
  position: relative;
`;

const Selection = styled.div`
  position: absolute;
`;

const ButtonPrimaryStyled = styled(ButtonPrimary)``;
