import styled from "styled-components";

export const Notice: React.FC = () => {
  return (
    <Container>
      <h3>유의사항</h3>
      <ul>
        <li>
          서비스 이용 단가는 비즈포스트 내에서 제공하는 기본 단가(Starter)로
          우선 반영됩니다.
        </li>
        <li>충전을 통한 충전포인트는 소멸되지 않습니다.</li>
        <li>
          충전을 통한 추가 적립포인트는 환불이 불가하고 유효기간은 1년입니다.
        </li>
      </ul>
    </Container>
  );
};

const Container = styled.div`
  padding-bottom: 40px;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -1px;
  border-bottom: 0.5px solid #999999;
  li {
    margin-left: 20px;
    list-style-type: disc;

    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: -1px;

    color: #333333;
  }
`;
