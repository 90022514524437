import { ImgArrowBottomBlack, ImgPricingMockSet1 } from "assets";
import { Checkbox } from "components/Icons";
import { MEDIA_QUERY } from "properties/constant";
import styled from "styled-components";
import { PricingSectionBottom, SectionTitle } from "./styled";
import { Pad } from "styles/styled";

export const SMSPricing = () => {
  return (
    <Container>
      <Inner>
        <Pad pb={40}>
          <SectionTitle>일반문자 이용 요금</SectionTitle>
        </Pad>
        <CheckListContainer>
          <CheckList>
            <Checkbox active={true} />
            <div>
              실패 건수 <span>100% 재충전</span>
            </div>
          </CheckList>
          <CheckList>
            <Checkbox active={true} />
            <div>
              사용기한 <span>제한 없음</span>
            </div>
          </CheckList>
          <CheckList>
            <Checkbox active={true} />
            <div>
              무통장, 가상계좌, 신용카드 <span>동일 금액</span>
            </div>
          </CheckList>
        </CheckListContainer>
        <MockUp src={ImgPricingMockSet1} />
      </Inner>
      <BoxShadow>
        <PricingSectionBottom>
          <MessageCard>
            <div>
              <Badge color={"#333333"}>SMS</Badge>
              <LetterLimit>45자 이내</LetterLimit>
              <Desc>
                짧은 알림이나
                <br />
                공지 전달 시 추천해요
              </Desc>
            </div>
            <BottomArrow src={ImgArrowBottomBlack} alt="bottom arrow" />
            <Pricing>
              <Origin>9.5원</Origin>
              <Discounted color={"#333333"}>
                <span>8</span>원
              </Discounted>
            </Pricing>
          </MessageCard>
          <MessageCard>
            <div>
              <Badge color={"#1B58F1"}>LMS</Badge>
              <LetterLimit>1,000자 이내</LetterLimit>
              <Desc>
                긴 내용의 광고/홍보, 정보
                <br />
                링크 첨부 시 추천해요
              </Desc>
            </div>
            <BottomArrow src={ImgArrowBottomBlack} alt="bottom arrow" />
            <Pricing>
              <Origin>29원</Origin>
              <Discounted color={"#1B58F1"}>
                <span>28</span>원
              </Discounted>
            </Pricing>
          </MessageCard>
          <MessageCard>
            <div>
              <Badge color={"#652D90"}>MMS</Badge>
              <LetterLimit>이미지 포함, 1000자 이내</LetterLimit>
              <Desc>
                광고/홍보, 상품 소개,
                <br />
                기프티콘 전달 시 추천해요
              </Desc>
            </div>
            <BottomArrow src={ImgArrowBottomBlack} alt="bottom arrow" />
            <Pricing>
              <Origin>65원</Origin>
              <Discounted color={"#652D90"}>
                <span>60</span>원
              </Discounted>
            </Pricing>
          </MessageCard>
        </PricingSectionBottom>
      </BoxShadow>
    </Container>
  );
};

const Container = styled.div`
  @media (max-width: ${MEDIA_QUERY.MOBILE_S.MAX_WIDTH}px) {
  }
`;
const BoxShadow = styled.div`
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
`;

const MockUp = styled.img`
  margin-top: -120px;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    margin-top: -60px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE_S.MAX_WIDTH}px) {
    display: none;
  }
`;
const Inner = styled.div`
  padding: 80px 20px 0px;
  background: #f6f8ff;
  position: relative;
  z-index: 1;
  @media (max-width: ${MEDIA_QUERY.MOBILE_S.MAX_WIDTH}px) {
    padding-bottom: 40px;
  }
`;
const CheckListContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 40px;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -1px;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    flex-direction: column;
    font-size: 16px;
    width: 280px;
    margin: 0 auto;
    gap: 12px;
  }
`;
const CheckList = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  div {
    padding-top: 3px;
  }
  span {
    color: #1b58f1;
    font-weight: 700;
  }
`;

const MessageCard = styled.div`
  width: 240px;
  text-align: center;

  @media (max-width: ${MEDIA_QUERY.MOBILE_S.MAX_WIDTH}px) {
    width: 300px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const Badge = styled.div<{ color: string }>`
  background: ${(props) => props.color};
  border-radius: 100px;
  margin: 0 auto;
  color: white;
  width: fit-content;
  padding: 8px 30px 6px;
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -1px;
  color: #ffffff;
  @media (max-width: ${MEDIA_QUERY.MOBILE_S.MAX_WIDTH}px) {
    margin-left: 0px;
  }
`;
const LetterLimit = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  letter-spacing: -1px;
  padding-bottom: 8px;

  color: #333333;
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 16px;
  }
`;
const Desc = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -1px;

  color: #666666;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 14px;
    line-height: 20px;
  }
`;
const BottomArrow = styled.img`
  width: 11px;
  margin: 20px;
  @media (max-width: ${MEDIA_QUERY.MOBILE_S.MAX_WIDTH}px) {
    transform: rotate(-90deg);
  }
`;
const Pricing = styled.div`
  display: flex;
  gap: 8px;
  align-items: baseline;
  justify-content: center;
`;
const Origin = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 39px;
  letter-spacing: -1px;
  text-decoration-line: line-through;
  color: #666666;
`;
const Discounted = styled.div<{ color: string }>`
  font-weight: 400;
  font-size: 20px;
  line-height: 39px;
  display: flex;
  justify-content: center;
  align-items: baseline;

  color: #333333;
  span {
    color: ${(props) => props.color};
    font-weight: 700;
    font-size: 40px;
    line-height: 39px;
  }

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 16px;
    line-height: 24px;
    span {
      font-size: 28px;
      line-height: 24px;
    }
  }
`;
