import { useState } from "react";
import * as S from "./styled";
import { FileUploadOption } from "./FileUploadOption";
import { FileUploadImageNameList } from "./FileUploadImageNameList";
import { Pad } from "styles/styled";

interface Props {
  imageFiles: FileList | null;
  setImageFiles: (images: FileList | null) => void;
}
export const FileUpload: React.FC<Props> = ({ imageFiles, setImageFiles }) => {
  const [selectedOption, setSelectedOption] = useState<"none" | "image">(
    "none"
  );
  const MAX_SIZE = { width: 1440, height: 1440 };

  /**
   이미지 업로드 후 조건 체크하여 ImageFiles에 반영
   */

  const onUploadImages = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files ? e.target.files : null;
    if (!files) {
      return;
    }
    const fileList: FileList = await getSizeInImageFileList(files);
    setImageFiles(fileList);
  };

  /**
   * 이미지 파일 리스트를 받아서, 이미지 사이즈를 체크하고, 조건에 맞는 이미지만 리턴
   * @param files
   * @returns
   */
  const getSizeInImageFileList = async (files: FileList): Promise<FileList> => {
    const filteredIndexs: number[] = [];
    for (let i = 0; i < files.length; i++) {
      // 이미지 크기 계산
      let img = new Image();
      img.src = window.URL?.createObjectURL(files[i]);
      await img.decode();
      if (img.width < MAX_SIZE.width && img.height < MAX_SIZE.height) {
        filteredIndexs.push(i);
      } else {
        alert(
          `[${files[i].name}] 이미지를 확인해주세요. \n용량 300KB 이하, 크기 1440*1440px 이하 파일만 첨부 가능합니다.`
        );
      }
    }

    // FileList로 변환해 리턴
    const dt = new DataTransfer();

    for (let i = 0; i < files.length; i++) {
      if (filteredIndexs.includes(i)) {
        dt.items.add(files[i]);
      }
    }

    return dt.files;
  };

  function removeImage(): void {
    setImageFiles(null);
  }
  const isImageOptionSelected = selectedOption === "image";

  return (
    <S.Container>
      <Pad pt={20}>
        <S.Header>
          <S.Title>파일첨부</S.Title>

          <S.Options>
            <FileUploadOption
              optionLabel="없음"
              optionValue="none"
              selectedOptionValue={selectedOption}
              setSelectedOption={setSelectedOption}
            />
            <FileUploadOption
              optionLabel="이미지"
              optionValue="image"
              selectedOptionValue={selectedOption}
              setSelectedOption={setSelectedOption}
            />
          </S.Options>
        </S.Header>
      </Pad>

      {isImageOptionSelected && (
        <S.ImageOptionContainer>
          <S.Descriptions>
            <S.Description>
              최대 크기 : 1440*1440px 이하 파일만 첨부 가능합니다.
            </S.Description>
            <S.Description>
              지원 규격 : 각 300KB (최대 3장), jpg/jpeg 파일만 첨부 가능합니다.
            </S.Description>
          </S.Descriptions>

          {imageFiles && (
            <FileUploadImageNameList
              imageFiles={imageFiles}
              removeImage={removeImage}
            />
          )}
          <S.ButtonOutline htmlFor="imageInput">
            이미지 불러오기
          </S.ButtonOutline>
          <S.ImageInput
            type="file"
            multiple
            id="imageInput"
            name="image"
            accept="image/jpg, image/jpeg"
            onChange={onUploadImages}
          />
        </S.ImageOptionContainer>
      )}
    </S.Container>
  );
};
