import { fetchStatistics } from "apis/messageApi";
import { fetchBilingWallet } from "apis/pointApi";
import { observable, makeObservable, runInAction } from "mobx";
import { createContext } from "react";
import { StatisticsType, WalletType } from "types/types";

class WalletStore {
  @observable wallet: WalletType | null = null;
  @observable stats: StatisticsType | null = null;

  constructor() {
    makeObservable(this);
  }

  fetchWallet = async (isReload?: boolean) => {
    if (!this.wallet || isReload) {
      const wallet = await fetchBilingWallet();

      runInAction(() => {
        this.wallet = wallet;
      });
    }
  };

  fetchStatistics = async (isReload?: boolean) => {
    if (!this.stats || isReload) {
      const stats = await fetchStatistics();
      runInAction(() => {
        this.stats = stats;
      });
    }
  };
}

export default createContext(new WalletStore());
