import { useContext, useEffect, useState } from "react";

import { InputWithLabel, ModalLayout } from "components";
import { Loading } from "components/Icons/Loading";

import { validateEmail } from "utils/email";
import { validatePhoneNumberOnly } from "utils/phone";
import { importCeriticatePhone } from "utils/import";

import { useCustomNavigate } from "hooks/useCustomNavigate";
import styled, { css } from "styled-components";
import UserStore from "stores/UserStore";
import { observer } from "mobx-react-lite";
import {
  PrimaryButton,
  ButtonContainer,
  Content,
  Button,
} from "components/Modals/LayoutStyled";
import { PATH } from "properties/menu";
import { MEDIA_QUERY } from "properties/constant";

export const ResetPassword = observer(() => {
  const userStore = useContext(UserStore);
  const [username, setUsername] = useState("");
  const [phone, setPhone] = useState("");
  const [submitValidated, setSubmitValidate] = useState(false);
  const [validatedPhone, setValidatedPhone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userCertificated, setUserCeritificated] = useState(false);
  const [impUid, setImpUid] = useState("");
  const [email, setEmail] = useState("");
  const [visibleNewPasswordModal, setVisibleNewPasswordModal] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [visibleAlertModal, setVisibleAlertModal] = useState(false);

  const navigate = useCustomNavigate();

  useEffect(() => {
    /** 회원가입 완료 유효성 검사 */
    const validateSubmit = () => {
      const isValidated =
        username.length > 0 &&
        validateEmail(email) &&
        validatedPhone &&
        userCertificated;
      setSubmitValidate(isValidated);
    };
    validateSubmit();
  }, [username, phone, email, userCertificated, validatedPhone]);

  useEffect(() => {
    setUserCeritificated(false);
  }, [phone]);

  const onHandleChangePhone = (e: any) => {
    const phone = e.target.value;
    const validated = validatePhoneNumberOnly(phone);
    setValidatedPhone(validated);
    setPhone(phone);
  };
  const onClickVerifyPhone = async () => {
    if (!validatedPhone) {
      alert("전화번호 형식을 확인해주세요.");
      return;
    }

    // 이미 등록된 번호인지 확인
    const result = await userStore.checkUniquePhone(phone);

    if (result.isUnique) {
      alert("회원 가입하지 않은 번호입니다.");
      return;
    }

    // 인증번호 요청
    importCeriticatePhone(
      phone,
      callbackCertificatePhoneSuccess,
      callbackCertificatePhoneFail
    );
  };

  const callbackCertificatePhoneSuccess = async (impUid: string) => {
    setImpUid(impUid);
    setUserCeritificated(true);
  };

  const callbackCertificatePhoneFail = (errorMesasge) => {
    setUserCeritificated(false);
  };

  /** 비밀번호 재설정하기 */
  const onClickResetPassword = async () => {
    if (loading) {
      return;
    } else if (newPassword !== newPassword2) {
      alert("동일한 비밀번호를 입력해주세요.");
    } else if (!validatedPhone || username.length === 0) {
      alert("정보를 모두 입력해주세요.");
    }

    setLoading(true);
    const response = await userStore.resetPassword(
      username,
      impUid,
      phone,
      email,
      newPassword
    );
    setLoading(false);
    if (response.updated) {
      setVisibleNewPasswordModal(false);
      setVisibleAlertModal(true);
    } else {
      alert(`비밀번호 재설정에 문제가 발생했습니다. 다시 시도해주세요.`);
    }
  };
  const onMoveSignIn = () => {
    setVisibleAlertModal(false);
    navigate.moveTo(PATH.SIGN_IN);
  };
  const onClickOpenNewPasswordModal = () => {
    if (submitValidated) {
      setVisibleNewPasswordModal(true);
    }
  };

  return (
    <div>
      <Container>
        <Inner>
          <Title>비밀번호 초기화하기 </Title>

          <InputWithLabel
            label="이메일"
            placeholder="이메일을 입력해주세요"
            onInputChange={setEmail}
            value={email}
          />
          <InputWithLabel
            label="이름"
            placeholder="이름을 입력해주세요"
            onInputChange={setUsername}
            value={username}
          />
          {/* 핸드폰 본인 인증 로직 */}
          <InputContainer>
            <Label>핸드폰 번호</Label>
            <InputButtonContainer>
              <Input
                type="phone"
                placeholder="-를 제외하고 입력"
                onChange={onHandleChangePhone}
              />

              <SideButton active={validatedPhone} onClick={onClickVerifyPhone}>
                {userCertificated ? "인증완료" : "본인인증"}
              </SideButton>
            </InputButtonContainer>
          </InputContainer>

          <SubmitButton
            active={submitValidated}
            onClick={onClickOpenNewPasswordModal}
          >
            <div>확인</div>
            {loading && <Loading />}
          </SubmitButton>
        </Inner>

        {visibleNewPasswordModal && (
          <ModalLayout
            title="비밀번호 변경하기"
            onClose={() => setVisibleNewPasswordModal(false)}
          >
            <div>
              <Content>{`변경할 비밀번호를 입력해주세요`}</Content>

              <InputWithLabel
                type="password"
                label="새 비밀번호"
                placeholder="비밀번호를 입력해주세요"
                onInputChange={setNewPassword}
                value={newPassword}
              />
              <InputWithLabel
                type="password"
                label="새 비밀번호 확인"
                placeholder="비밀번호를 다시 입력해주세요"
                onInputChange={setNewPassword2}
                value={newPassword2}
                onEnter={onClickResetPassword}
              />
              <ButtonContainer>
                <PrimaryButton onClick={onClickResetPassword}>
                  확인
                </PrimaryButton>
              </ButtonContainer>
            </div>
          </ModalLayout>
        )}
        {visibleAlertModal && (
          <ModalLayout
            title={"변경 완료"}
            onClose={() => setVisibleAlertModal(false)}
          >
            <>
              <Content>{`비밀번호 변경이 완료되었습니다. \n로그인을 진행해주세요.`}</Content>
              <Button onClick={onMoveSignIn}>확인</Button>
            </>
          </ModalLayout>
        )}
      </Container>
    </div>
  );
});

const Container = styled.div`
  min-height: 100vh;
`;

const Inner = styled.div`
  max-width: 420px;
  background-color: white;
  margin-top: 32px;
  padding: 32px;
  margin: 16px auto 0px;
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    margin-top: 80px;
  }
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 500;

  letter-spacing: -1px;
  padding-bottom: 40px;
`;

const InputButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  button {
    width: 95px;
  }
  input {
    flex: 1;
  }
`;

const SubmitButton = styled.button<{ active: boolean }>`
  background: #e1e1e1;
  border-radius: 100px;
  padding: 16px;
  text-align: center;
  align-items: center;
  width: 100%;

  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -1px;
  display: flex;

  justify-content: center;
  gap: 4px;
  color: #ffffff;

  .icon {
    margin: 0px;
  }

  ${(props) =>
    props.active &&
    css`
      background: #1b58f1;
      cursor: pointer;
    `}
`;
const SideButton = styled.button<{ active: boolean }>`
  display: block;
  height: auto;
  border-radius: 4px;
  color: white;
  background: #e1e1e1;

  ${(props) =>
    props.active &&
    css`
      background: #1b58f1;
      cursor: pointer;
    `}
`;

const Label = styled.label`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -1px;
  color: #666666;
  padding-bottom: 8px;
  display: block;
`;

const InputContainer = styled.div`
  margin-bottom: 28px;
`;

const Input = styled.input<{ active?: boolean }>`
  background: #ffffff;
  border: 1px solid #d7dbe2;
  border-radius: 4px;
  padding: 16px;
  width: 100%;

  &::placeholder {
    color: #999999;
  }

  ${(props) =>
    props.active &&
    css`
      border-color: #333333;
    `}
`;
