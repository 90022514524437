import { SVGProps } from "react";

interface Props {
  active: boolean;
  width?: number;
  height?: number;
}
export const Checkbox = ({ active, width = 24, height = 24 }: Props) => {
  if (active) {
    return <CheckboxChecked width={width} height={height} />;
  } else {
    return <CheckboxUnchecked width={width} height={height} />;
  }
};

export const CheckboxChecked = ({
  width = 24,
  height = 24,
  fill = "#1B58F1",
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill={fill} />
      <path
        d="M6.16596 10.4343C6.45885 10.1414 6.93373 10.1414 7.22662 10.4343L10.9389 14.1466L16.7726 8.31293C17.0655 8.02004 17.5403 8.02004 17.8332 8.31293C18.1261 8.60583 18.1261 9.0807 17.8332 9.37359L11.5556 15.6512C11.215 15.9918 10.6628 15.9918 10.3223 15.6512L6.16596 11.4949C5.87307 11.202 5.87307 10.7271 6.16596 10.4343Z"
        fill="white"
      />
    </svg>
  );
};

export const CheckboxUnchecked = ({
  width = 24,
  height = 24,
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#C4C9D1"
      />
      <path
        d="M6.21967 9.84099C6.51256 9.5481 6.98744 9.5481 7.28033 9.84099L10.9926 13.5533L16.8263 7.71967C17.1192 7.42678 17.594 7.42678 17.8869 7.71967C18.1798 8.01256 18.1798 8.48744 17.8869 8.78033L11.6093 15.058C11.2687 15.3985 10.7166 15.3985 10.376 15.058L6.21967 10.9017C5.92678 10.6088 5.92678 10.1339 6.21967 9.84099Z"
        fill="#C4C9D1"
      />
    </svg>
  );
};
