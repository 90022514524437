import { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Loading } from "components/Icons";
import { InputContainer, Label, Title, Button, LoadingIcon } from ".";
import { FlexBetween, FlexEnd, InnerContainer, Pad } from "styles/styled";

import { importCeriticatePhone } from "utils/import";
import { validatePhoneNumberOnly } from "utils/phone";
import { UserType } from "types/types";
import { PATH } from "properties/menu";
import { useCustomNavigate } from "hooks/useCustomNavigate";

interface Props {
  loading: boolean;
  user: UserType;
}
export const ChangeProfileInfo = ({ loading, user }: Props) => {
  const navigate = useCustomNavigate();
  const [phone, setPhone] = useState(user.phone_number);
  const [address, setAddress] = useState("");
  const [addressDetail, setAddressDetail] = useState("");
  const [validatedPhone, setValidatedPhone] = useState(false);
  const [userCertificated, setUserCeritificated] = useState(false);
  const [validateErrorReason, setValidateErrorReason] = useState("");

  useEffect(() => {
    const validate = () => {
      const phoneValid = validatePhoneNumberOnly(phone);
      setValidatedPhone(phoneValid);

      if (!phoneValid) {
        setValidateErrorReason("전화번호 형식을 확인해주세요.");
      } else if (user.phone_number !== phone && !userCertificated) {
        setValidateErrorReason("전화번호 인증을 해주세요");
      }
    };
    validate();
  }, [user.phone_number, phone, userCertificated]);

  const onClickVerifyPhone = async () => {
    if (!validatedPhone) {
      alert("전화번호를 입력해주세요.");
      return;
    }

    // 인증번호 요청
    importCeriticatePhone(
      phone,
      callbackCertificatePhoneSuccess,
      callbackCertificatePhoneFail
    );
  };

  const callbackCertificatePhoneSuccess = async () => {
    setUserCeritificated(true);
  };

  const callbackCertificatePhoneFail = () => {
    setUserCeritificated(false);
  };

  const onHandleChangePhone = (e: any) => {
    const phone = e.target.value;
    const validated = validatePhoneNumberOnly(phone);
    setValidatedPhone(validated);
    setPhone(phone);
  };
  const onClickAddressInput = () => {
    let daum = window["daum"];
    new daum.Postcode({
      oncomplete: function (data) {
        setAddress(data.address);
        // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분입니다.
        // 예제를 참고하여 다양한 활용법을 확인해 보세요.
      },
    }).open();
  };

  const onClickSaveButton = () => {
    // TODO: 구현
    alert(
      "서비스에 문제가 생겼습니다. 불편을 끼쳐드려 죄송합니다.\n고객센터를 통해서 정보를 변경해주세요🙏🏻"
    );
    if (loading) {
      return;
    }
    if (validateErrorReason) {
      alert(validateErrorReason);
      return;
    }
  };

  const getCompanySignUpStatus = () => {
    let status = null;

    if (user.company_info && user.company_info.length > 0) {
      status = user.company_info[0]?.status;
    } else if (user.company_info && typeof user.company_info === "object") {
      const companyInfo: any = user.company_info;
      status = companyInfo.status;
    }
    return status;
  };

  const getStatusText = () => {
    const statusNumber = getCompanySignUpStatus();

    switch (statusNumber) {
      case -1:
        return "기업회원 승인거절";
      case 0:
        return "기업회원 전환 요청됨";
      case 1:
        return "기업 회원 전환 승인됨";
      default:
        return "기업회원 전환";
    }
  };

  return (
    <Container>
      <InnerContainer>
        <Pad pb={180}>
          <Pad pb={40}>
            <FlexBetween>
              <Title>회원 정보</Title>

              {loading ? (
                <FlexEnd>
                  <Pad pr={60}>
                    <Loading />
                  </Pad>
                </FlexEnd>
              ) : (
                <RoundedButton
                  status={getCompanySignUpStatus()}
                  onClick={() => {
                    navigate.moveTo(PATH.CHANGE_COMPANY_PROFILE);
                  }}
                >
                  {getStatusText()}
                </RoundedButton>
              )}
            </FlexBetween>
          </Pad>
          <InputContainer>
            <Label>이메일</Label>
            <Input type="text" value={user.email} readOnly />
          </InputContainer>

          <InputContainer>
            <Label>이름</Label>
            <Input type="text" value={user.username} readOnly />
          </InputContainer>

          {/* 핸드폰 본인 인증 로직 */}
          <InputContainer>
            <Label>핸드폰 번호</Label>
            <InputButtonContainer>
              <Input
                type="phone"
                placeholder="-를 제외하고 입력"
                onChange={onHandleChangePhone}
                value={phone}
              />

              <SideButton
                active={validatedPhone}
                onClick={onClickVerifyPhone}
                bgPrimary={userCertificated}
              >
                {userCertificated ? <span>인증완료</span> : <span>변경</span>}
              </SideButton>
            </InputButtonContainer>
          </InputContainer>

          <InputContainer>
            <Label>주소지</Label>
            <AddressInput
              type="text"
              readOnly
              placeholder="주소지 입력"
              value={address}
              onClick={onClickAddressInput}
            />
            <AddressInput
              type="text"
              placeholder="상세 주소 입력"
              value={addressDetail}
              onChange={(e) => setAddressDetail(e.target.value)}
            />

            <Pad pt={40}>
              <Button active={!validateErrorReason} onClick={onClickSaveButton}>
                <span>확인</span>
                {loading && (
                  <LoadingIcon>
                    <Loading />
                  </LoadingIcon>
                )}
              </Button>
            </Pad>
          </InputContainer>
        </Pad>
      </InnerContainer>
    </Container>
  );
};

const Container = styled.div``;

const InputButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  button {
    width: 95px;
  }
  input {
    flex: 1;
  }
`;

const SideButton = styled.button<{ active: boolean; bgPrimary: boolean }>`
  display: block;
  height: auto;
  border-radius: 4px;
  color: white;
  background: ${(props) => (props.bgPrimary ? "#1b58f1" : "#333333")};

  ${(props) =>
    !props.active &&
    css`
      background: #e1e1e1;
      cursor: pointer;
    `}
`;
export const Input = styled.input`
  background: #ffffff;
  border: 1px solid #d7dbe2;
  border-radius: 4px;
  padding: 16px;
  width: 100%;
  height: 52px;

  &::placeholder {
    color: #999999;
  }
`;

export const AddressInput = styled(Input)`
  margin-bottom: 4px;
  cursor: pointer;
`;

const RoundedButton = styled.div<{ status: number }>`
  padding: 8px 24px;
  background-color: #1b58f1;
  border-radius: 24px;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: white;
  cursor: pointer;

  ${(props) =>
    props.status === -1 &&
    css`
      color: #ec1c24;
      border: 1px solid #ec1c24;
      background-color: #ffffff;
    `};
  ${(props) =>
    (props.status === 0 || props.status === 1) &&
    css`
      color: #1b58f1;
      background-color: #ffffff;
      border: 1px solid #1b58f1;
    `};
`;
