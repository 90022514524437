import { SVGProps } from "react";

export const Person = ({
  width = 22,
  height = 22,
  fill = "#666666",
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 7C14 8.65685 12.6569 10 11 10C9.34315 10 8 8.65685 8 7C8 5.34315 9.34315 4 11 4C12.6569 4 14 5.34315 14 7ZM16 7C16 9.76142 13.7614 12 11 12C8.23858 12 6 9.76142 6 7C6 4.23858 8.23858 2 11 2C13.7614 2 16 4.23858 16 7ZM5.14934 16.3747C4.19048 17.2622 3.96489 18.285 4.00414 18.8766C4.04071 19.4277 3.62362 19.9041 3.07255 19.9406C2.52148 19.9772 2.0451 19.5601 2.00853 19.009C1.93093 17.8395 2.37145 16.2207 3.79073 14.907C5.15532 13.6438 7.33512 12.7408 10.6166 12.6648C10.6503 12.6614 10.6845 12.6596 10.7191 12.6596C10.7779 12.6596 10.8363 12.6599 10.8944 12.6604C10.9526 12.6599 11.011 12.6596 11.0698 12.6596C11.1044 12.6596 11.1386 12.6614 11.1723 12.6648C14.4538 12.7408 16.6336 13.6438 17.9982 14.907C19.4174 16.2207 19.858 17.8395 19.7804 19.009C19.7438 19.5601 19.2674 19.9772 18.7163 19.9406C18.1653 19.9041 17.7482 19.4277 17.7848 18.8766C17.824 18.285 17.5984 17.2622 16.6396 16.3747C15.7054 15.51 13.9801 14.6904 10.8944 14.6604C7.80878 14.6904 6.08346 15.51 5.14934 16.3747Z"
        fill={fill}
      />
    </svg>
  );
};
