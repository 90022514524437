import styled from "styled-components";
import { ModalLayout } from "components";

interface Props {
  title: string;
  children: JSX.Element;
  confirmButtonText: string;
  confirmButtonColor?: string;
  closeButtonText?: string;
  closeModal: () => void;
  onConfirm: () => void;
}
export const ConfirmModal: React.FC<Props> = ({
  children,
  title,
  confirmButtonText,
  confirmButtonColor = "#1B58F1",
  closeButtonText = "취소",
  onConfirm,
  closeModal,
}) => {
  return (
    <ModalLayout onClose={closeModal} title={title}>
      <Container>
        {children}
        <ButtonContainer>
          <ButtonOutline onClick={closeModal}>{closeButtonText}</ButtonOutline>
          <ButtonPrimary bgColor={confirmButtonColor} onClick={onConfirm}>
            {confirmButtonText}
          </ButtonPrimary>
        </ButtonContainer>
      </Container>
    </ModalLayout>
  );
};

const Container = styled.div`
  padding-top: 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -1px;
  white-space: pre-line;

  color: #666666;
`;
const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  padding-top: 40px;
`;
const Button = styled.div`
  flex: 1;
  border-radius: 100px;
  padding: 14px 14px 10px;
  text-align: center;

  font-size: 18px;
  line-height: 26px;
  letter-spacing: -1px;
  cursor: pointer;
`;

const ButtonOutline = styled(Button)`
  border: 1px solid #333333;
`;
const ButtonPrimary = styled(Button)<{ bgColor: string }>`
  background: ${(props) => props.bgColor};
  color: white;
  font-weight: 700;
`;

export const Selection = styled.div`
  display: flex;
  border: 1px solid #d7dbe2;
  border-radius: 4px;
  flex: 1;
  align-items: center;
  padding: 0 8px;
  justify-content: space-between;
  position: relative;
`;

export const Options = styled.div`
  background: white;
  position: absolute;
  top: 45px;
  left: 0px;
  width: 100%;
  border: 0.5px solid #d7dbe2;
  border-radius: 3px;
  overflow-y: auto;
  max-height: 380px;
`;

export const Option = styled.div`
  padding: 8px;
  border: 0.5px solid #d7dbe2;
  &:hover {
    background: #eef3ff;
  }
`;
export const SelectionContainer = styled.div`
  padding-top: 16px;
  div:first-child {
    padding: 12px 16px;
  }
`;
