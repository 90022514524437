import { ModalLayout } from 'components';
import ButtonSquare from 'components/ButtonSquare';
import { FlexWrapper } from 'components/Flex';
import MessageTemplateLayout, { IMessageTemplateProps } from 'components/template/MessageTemplateLayout';
import TemplateCellList from 'components/template/TemplateCellList';
import React from 'react';
import styled from 'styled-components';
import { AutoCenterCoverSizeImage, Caption, Small } from 'styles/styled';


// Image List
import TemplateSampleImageList1 from "assets/images/template/template-sample-1.jpg";
import TemplateSampleImageList2 from "assets/images/template/template-sample-2.jpg";
import TemplateSampleImageList3 from "assets/images/template/template-sample-3.jpg";
import TemplateSampleImageList4 from "assets/images/template/template-sample-4.jpg";
import TemplateSampleImageList5 from "assets/images/template/template-sample-5.jpg";
import TemplateSampleImageList6 from "assets/images/template/template-sample-6.jpg";

import TemplateThumbnail1 from "assets/images/template/template-thumbnail-1.jpg";
import TemplateThumbnail2 from "assets/images/template/template-thumbnail-2.jpg";
import TemplateThumbnail3 from "assets/images/template/template-thumbnail-3.jpg";
import TemplateThumbnail4 from "assets/images/template/template-thumbnail-4.jpg";
import TemplateThumbnail5 from "assets/images/template/template-thumbnail-5.jpg";

const ThumbnailHorizontal = [
  TemplateThumbnail1,
  TemplateThumbnail2,
  TemplateThumbnail3
];

const Title = styled.div`
  padding-top: 40px;
  border-top: 1px solid #D9D9D9;
  font-weight: 700;
  font-size: 24px;
  line-height: 39px;
  letter-spacing: -1px;
`;
const TemplateThumbnailVertical = styled.div`
  margin-top: 10px;
  li{
    display: flex;
    margin-bottom: 6px;
    .image{
      width: 46px;
      height: 48px;
      margin-right: 12px;
      border-radius: 2.65px;
      overflow: hidden;
    }
  }
  li:last-child{
    margin-bottom: 0;
  }
`;

const templates:ITemplate = {
  selectableTitleText:[
    {
      messageProps: {
        height: "250px",
        title: "주문",
        boldTitle: true,
        children: <div>
          {`김** 고객님 
          주문내역입니다. 
          주문금액 : 50,000원 
          주문번호 : 2023-07-5625 
          배송주소 : 서울 영등포구 여의도동 
          12-34
          상품명 : 오늘의 북 외 5권 

          배송이 시작되면 안내메시지가 발송
          됩니다.
          `}
        </div>,
        buttonList: [
          {
            text: "상세 주문내역 보기"
          }
        ]
      },
      templateType:"서술 (Description)",
      onClickCreationButton: ()=>{}
    },
    {
      messageProps: {
        height: "250px",
        title: "예약",
        boldTitle: true,
        children: <>
          <div>설치예약 안내</div>
          <TemplateCellList
            cellList={
              [
                {
                  title:"상품명",
                  content: "김치냉장고 K3424S2"
                },
                {
                  title:"결제 금액",
                  content: "1,500,600원"
                },
                {
                  title:"설치 비용",
                  content: "50,000원"
                },
                {
                  title:"설치 일자",
                  content: "9월5일 오후 2시"
                },
              ]
            }
            boldFont
          />
          <div>해당일에 설치예약이 완료되었습니다.</div>
        </>,
        buttonList: [
          {
            text: "설치예약 확인"
          }
        ]
      },
      templateType:"서술 (Cell)",
      onClickCreationButton: ()=>{}
    },
    {
      messageProps: {
        height: "250px",
        title: "알림",
        boldTitle: true,
        children: <div>
          {`김** 고객님의 9월 사용금액
            168,200원이 신한은행에서 금일 출
            금 예정입니다.
            출금 계좌의 잔액을 확인해주세요.
            출금계좌 : 신한 123-4567-88899
            예금주 : 김**
            출금 예정일 : 2023년 9월 5일

            문의사항은 고객센터(1234-56780
            )로 문의주세요.
          `}
        </div>
      },
      templateType:"서술 (Cell)",
      onClickCreationButton: ()=>{}
    },
  ],
  freeTitleText: [
    {
      messageProps: {
        title: "주문",
        priceTag:"50,000원",
        titleUnderline: true,
        boldTitle: true,
        children: <div>
          {`김** 고객님
            주문내역입니다.
            주문금액 : 50,000원
            주문번호 : 2023-07-5625
            배송주소 : 서울 영등포구 여의도동 
            12-34
            상품명 : 오늘의 북 외 5권
            
            배송이 시작되면 안내메시지가 발송
            됩니다.`}
        </div>,
        buttonList: [
          {
            text: "상세 주문내역 보기",
            hasBackgroundColor: true
          }
        ],
      },
      templateType:"서술 (Description)",
      onClickCreationButton: ()=>{}
    },
    {
      messageProps: {
        title: "설치예약",
        priceTag:"50,000원",
        titleUnderline: true,
        boldTitle: true,
        children: <div>
          <Small
            css={{
              color: "#0A98FE",
              fontWeight: "bold"
            }}
          >
            내일 7/9(토) 배송 예정
          </Small>
          <TemplateCellList
            cellList={
              [
                {
                  title:"상품명",
                  content: "김치냉장고 K3424S2"
                },
                {
                  title:"결제 금액",
                  content: "1,500,600원"
                },
                {
                  title:"설치 비용",
                  content: "50,000원"
                }
              ]
            }
          />
          <FlexWrapper
            css={{
              justifyContent: "space-between"
            }}
          >
            <Small
              css={{
                fontWeight: "bold"
              }}
            >
              배송료
            </Small>
            <Small
              css={{
                fontWeight: "bold",
                color: "#0A98FE",
                textAlign: "right"
              }}
            >
              50,000원
            </Small>
          </FlexWrapper>
        </div>,
        buttonList: [
          {
            text: "설치 예약 확인",
            hasBackgroundColor: true
          }
        ],
      },
      templateType:"서술 (Cell)",
      onClickCreationButton: ()=>{}
    },
    {
      messageProps: {
        title: "알림",
        titleUnderline: true,
        boldTitle: true,
        children: <div>
          {`김** 고객님의 9월 사용금액
            168,200원이 신한은행에서 금일 출
            금 예정입니다.
            출금 계좌의 잔액을 확인해주세요.
            출금계좌 : 신한 123-4567-88899
            
            예금주 : 김**
            출금 예정일 : 2023년 9월 5일
            
            문의사항은 고객센터(1234-56780
            )로 문의주세요.
          `}
        </div>,
        buttonList: [],
      },
      templateType:"서술 (Cell)",
      onClickCreationButton: ()=>{}
    }
  ],
  image: [
    {
      messageProps: {
        height: "364px",
        children: <div>
          <AutoCenterCoverSizeImage
            width="100%"
            height="195px"
          >
            <img 
              src={TemplateSampleImageList1} 
              alt="" 
            />
          </AutoCenterCoverSizeImage>

          <FlexWrapper
            css={{
              flexDirection: "column",
              padding: "16px",
              boxSizing: "border-box"
            }}
          >
            <Small
              css={{
                fontWeight: "bold"
              }}
            >
              2주간 즐기는 프리미엄 회원 특별혜택!
            </Small>
            <TemplateCellList
              noLine
              boldFont
              cellList={
                [
                  {
                    title: "행사기간",
                    content: "2023.02.01 ~ 2023.02.28"
                  },
                  {
                    title: "참여대상",
                    content: "프리미엄 등급 고객"
                  },
                  {
                    title: "제공혜택",
                    content: "22% 추가 적립 + 무료배송"
                  },
                ]
              }
              valueLeftAlign
            />
          </FlexWrapper>
        </div>,
        buttonList: [
          {
            text: "자세히 보러가기",
            hasBackgroundColor: true
          }
        ],
      },
      templateType:"이미지 & 타이틀 강조형",
      onClickCreationButton: ()=>{}
    },
    {
      messageProps: {
        height: "364px",
        children: <div>
          <AutoCenterCoverSizeImage
            width="100%"
            height="224px"
          >
            <img 
              src={TemplateSampleImageList2} 
              alt="" 
            />
          </AutoCenterCoverSizeImage>

          <FlexWrapper
            css={{
              flexDirection: "column",
              padding: "16px",
              boxSizing: "border-box"
            }}
          >
            <Small
              css={{
                fontWeight: "bold",
                marginBottom: "8px"
              }}
            >
              2023 한강 불꽃축제 소식
            </Small>
            <Small>
              아름다운 한강에서 2023년 불꽃축제가 시작됩니다. 
            </Small>
          </FlexWrapper>
        </div>,
        buttonList: [
          {
            text: "자세히 보러가기",
            hasBackgroundColor: true
          }
        ],
      },
      templateType:"이미지 강조형",
      onClickCreationButton: ()=>{}
    },
    {
      messageProps: {
        height: "364px",
        children: <div>
          <AutoCenterCoverSizeImage
            width="100%"
            height="125px"
          >
            <img 
              src={TemplateSampleImageList3} 
              alt="" 
            />
          </AutoCenterCoverSizeImage>

          <FlexWrapper
            css={{
              flexDirection: "column",
              padding: "16px 16px 6px",
              boxSizing: "border-box"
            }}
          >
            <Small
              css={{
                fontWeight: "bold",
                marginBottom: "8px"
              }}
            >
              미리 만나는 프리썸머 페스타
            </Small>
            <Small>
              쿨~한 여름을 준비하는 아이템을 소개
              합니다.
            </Small>
            <TemplateThumbnailVertical>
              <li>
                <AutoCenterCoverSizeImage
                  width="46px"
                  height="48px"
                  className="image"
                >
                  <img src={TemplateThumbnail4} alt="" />
                </AutoCenterCoverSizeImage>
                <Small
                  className="text"
                >
                  달콤한 컬러의 시원한 미니 선풍기, 달달함은 덤!
                </Small>
              </li>
              <li>
                <AutoCenterCoverSizeImage
                  width="46px"
                  height="48px"
                  className="image"
                >
                  <img src={TemplateThumbnail5} alt="" />
                </AutoCenterCoverSizeImage>
                <Small
                  className="text"
                >
                  쿨링 키보드로 무더운 여름 손가락까지 시원하게
                </Small>
              </li>
            </TemplateThumbnailVertical>
          </FlexWrapper>
        </div>,
        buttonList: [
          {
            text: "자세히 보러가기",
            hasBackgroundColor: true
          }
        ],
      },
      templateType:"썸네일형 (세로)",
      onClickCreationButton: ()=>{}
    },
    {
      messageProps: {
        height: "356px",
        children: <div>
          <AutoCenterCoverSizeImage
            width="100%"
            height="141px"
          >
            <img 
              src={TemplateSampleImageList6} 
              alt="" 
            />
          </AutoCenterCoverSizeImage>
          <FlexWrapper
            css={{
              flexDirection: "column",
              padding: "16px 16px 6px",
              boxSizing: "border-box"
            }}
          >
            <Small
              css={{
                fontWeight: "bold",
                marginBottom: "8px"
              }}
            >
              요가하는 선생님
            </Small>
            <Small>
              {`선생님과 함께 하체부종 타파 요가
                자세, 지금 도전해보세요.
                매주 수요일 오후 2시 업데이트!
              `}
            </Small>
            <FlexWrapper
              css={{
                justifyContent: "space-between",
                marginTop: "16px"
              }}
            >
              {
                ThumbnailHorizontal.map((img, index)=>(
                  <AutoCenterCoverSizeImage
                    width="54px"
                    height="54px"
                    key={`template-thumbnail-${index}`}
                  >
                    <img 
                      src={TemplateThumbnail1} 
                      alt="" 
                    />
                  </AutoCenterCoverSizeImage>
                ))
              }
            </FlexWrapper>
          </FlexWrapper>
        </div>,
        buttonList: [
          {
            text: "자세히 보러가기",
            hasBackgroundColor: true
          }
        ],
      },
      templateType:"썸네일형 (가로)",
      onClickCreationButton: ()=>{}
    },
    {
      messageProps: {
        height: "356px",
        children: <FlexWrapper
          css={{
            flexDirection: "column",
            padding: "16px",
            
          }}
        >
          <Small
            css={{
              fontWeight: "bold",
              marginBottom: "8px"
            }}
          >
            {`멋진 오늘의 건축물
            역사와 현대가 함께 흐르는 공간`}
          </Small>
          <Small
            css={{
              marginBottom: "8px"
            }}
          >
            {`19세기 말에 지어진 영국 Clerk-
            enwell에 있는 건물이다. 역사적인 
            스토리를 그대로 담아 현대식 주택으
            로 변화시켰다.`}
          </Small>
          <AutoCenterCoverSizeImage
            width="100%"
            height="159px"
            css={{
              borderRadius: "3.98px"
            }}
          >
            <img 
              src={TemplateSampleImageList4} 
              alt="" 
            />
          </AutoCenterCoverSizeImage>
        </FlexWrapper>,
        buttonList: [
          {
            text: "자세히 보러가기",
            hasBackgroundColor: true
          }
        ],
      },
      templateType:"SNS형 (하단버튼)",
      onClickCreationButton: ()=>{}
    },
    {
      messageProps: {
        height: "356px",
        children: <div>
          <FlexWrapper
            css={{
              flexDirection: "column",
              padding: "16px", 
            }}
          >
            <Small
              css={{
                fontWeight: "bold",
                marginBottom: "16px"
              }}
            >
              {`ISSUE 2023
              스마트폰과 함께하는 시간`}
            </Small>
            <Small
              css={{
                marginBottom: "23px"
              }}
            >
              {`한국인의 하루 평균 스마트폰 사용시
              간은, 3시간 48분으로, 20대 하루 4
              시간이 넘게 스마트폰을 사용하고 있
              었습니다.`}
            </Small>
            <ButtonSquare
              css={{
                height: "32px",
                minHeight: "32px",
                borderColor: "transparent",
                backgroundColor: "#E4E4E4",
                color: "#0A98FE",
                fontSize: "12px",
                fontWeight: "bold",
                marginBottom: "4px"
              }}
            >
              자세히 보러가기
            </ButtonSquare>
          </FlexWrapper>
          <AutoCenterCoverSizeImage
            width="100%"
            height="158px"
          >
            <img 
              src={TemplateSampleImageList5} 
              alt="" 
            />
          </AutoCenterCoverSizeImage>
        </div>
      },
      templateType:"SNS형 (중간버튼)",
      onClickCreationButton: ()=>{}
    }
  ],
};

interface IMessageTemplate{
  messageProps: IMessageTemplateProps;
  templateType: string;
  onClickCreationButton?: ()=>void;
}
interface ITemplate{
  selectableTitleText: IMessageTemplate[];
  freeTitleText: IMessageTemplate[];
  image: IMessageTemplate[];
}

interface IProps{
  onClose:()=>void;
}

const TemplateModal = ({
  onClose
}:IProps) => {
  return (
    <ModalLayout 
      onClose={onClose}
      width={739}
      title="텍스트 템플릿 (타이틀 선택형)"
      css={{
        borderRadius: "10px",
        padding: "24px 40px"
      }}
    >
      <FlexWrapper
        css={{
          justifyContent: "space-between",
          marginTop: "48px",
          marginBottom: "60px"
        }}
      >
        {
          templates.selectableTitleText.map(el=>(
            <FlexWrapper
              css={{
                width: "auto",
                height: "345px",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              <MessageTemplateLayout
                {...el.messageProps}
              />
              <Caption
                css={{
                  color: "#666666"
                }}
              >
                {el.templateType}
              </Caption>
              <ButtonSquare
                onClick={el.onClickCreationButton}
                bgColor="#1B58F1"
                fontColor="white"
                borderColor="transparent"
                css={{
                  width: "120px",
                  height: "46px",
                  minHeight: "46px",
                  maxHeight: "46px",
                  fontWeight: "bold"
                }}
              >
                템플릿 만들기
              </ButtonSquare>
            </FlexWrapper>
          ))
        }
      </FlexWrapper>

      <Title>텍스트 템플릿 (타이틀 자유형)</Title>
      <FlexWrapper
        css={{
          justifyContent: "space-between",
          paddingTop: "32px",
          marginBottom: "60px"
        }}
      >
        {
          templates.freeTitleText.map(el=>(
            <FlexWrapper
              css={{
                width: "auto",
                height: "345px",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <MessageTemplateLayout
                {...el.messageProps}
              />
              <Caption
                css={{
                  color: "#666666"
                }}
              >
                {el.templateType}
              </Caption>
              <ButtonSquare
                onClick={el.onClickCreationButton}
                bgColor="#1B58F1"
                fontColor="white"
                borderColor="transparent"
                css={{
                  width: "120px",
                  height: "46px",
                  minHeight: "46px",
                  maxHeight: "46px",
                  fontWeight: "bold"
                }}
              >
                템플릿 만들기
              </ButtonSquare>
            </FlexWrapper>
          ))
        }
      </FlexWrapper>

      <Title>이미지 템플릿</Title>
      <FlexWrapper
        css={{
          justifyContent: "space-between",
          paddingTop: "32px",
          marginBottom: "30px",
          flexWrap: "wrap"
        }}
      >
        {
          templates.image.map(el=>(
            <FlexWrapper
              css={{
                width: "auto",
                height: "459px",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "60px"
              }}
            >
              <MessageTemplateLayout
                {...el.messageProps}
              />
              <Caption
                css={{
                  color: "#666666"
                }}
              >
                {el.templateType}
              </Caption>
              <ButtonSquare
                onClick={el.onClickCreationButton}
                bgColor="#1B58F1"
                fontColor="white"
                borderColor="transparent"
                css={{
                  width: "120px",
                  height: "46px",
                  minHeight: "46px",
                  maxHeight: "46px",
                  fontWeight: "bold"
                }}
              >
                템플릿 만들기
              </ButtonSquare>
            </FlexWrapper>
          ))
        }
      </FlexWrapper>
    </ModalLayout>
  );
};

export default TemplateModal;


