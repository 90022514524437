import AWS from "aws-sdk";
import dayjs from "dayjs";

const REGION = process.env.REACT_APP_S3_REGION;
const ACCESS_KEY_ID = process.env.REACT_APP_S3_ACCESS_KEY_ID;
const SECRET_ACCESS_KEY_ID = process.env.REACT_APP_S3_SECRET_ACCESS_KEY_ID;
const BUCKER_NAME = process.env.REACT_APP_S3_BUCKER_NAME;

/**
 * bf: business file(사업자 등록증)
 * ef: employee file(재직증명서))
 * cc: correspondence certificate (통신서비스 이용 증명원)
 * mms: MMS 이미지 파일
 * tms: Template MMS 이미지 파일
 * */
export const uploadS3File = async (
  type: "bf" | "ef" | "cc" | "mms" | "tms",
  file: File,
  fileName: string
) => {
  if (!file) {
    alert("이미지를 등록해 주세요.");
    return;
  }

  const date = dayjs().format("YYYYMMDD");
  const fileExtension = file.name.split(".").pop();
  const formData = new FormData();
  const fileKey = `${type}/${date}/${fileName}.${fileExtension}`;

  formData.append("file", file);
  formData.append("name", `${fileName}`);

  AWS.config.update({
    region: REGION,
    accessKeyId: ACCESS_KEY_ID,
    secretAccessKey: SECRET_ACCESS_KEY_ID,
  });

  const upload = new AWS.S3.ManagedUpload({
    params: {
      ACL: "public-read",
      Bucket: BUCKER_NAME,
      Key: fileKey,
      Body: file,
    },
  });

  return await upload.promise();
};
