import { Checkbox } from "components/Icons";
import { useState } from "react";
import styled from "styled-components";
import { Center, FlexEnd } from "styles/styled";
import { ResultGroupType } from "types/types";

interface Props {
  group: ResultGroupType;
}
export const ResultReason: React.FC<Props> = ({ group }) => {
  const [selectedGroupStatus, setSelectedGroupStatus] = useState<string[]>([]);

  const isActiveGroup = (status: string) => {
    return selectedGroupStatus.includes(status);
  };
  const onClickGroupCheckbox = (status: string) => {
    if (isActiveGroup(status)) {
      setSelectedGroupStatus(selectedGroupStatus.filter((id) => id !== status));
    } else {
      setSelectedGroupStatus([...selectedGroupStatus, status]);
    }
  };

  return (
    <Container>
      <FlexEnd gap={8} pb={16}>
        {/* TODO: */}
        {/* <ButtonPrimary active={hasSelectedGroup()}>주소록만들기</ButtonPrimary> */}
        {/* <ButtonPrimary active={hasSelectedGroup()}>엑셀다운로드</ButtonPrimary> */}
      </FlexEnd>

      <TableContainer>
        <THead>
          <TR>
            <TD>선택</TD>
            <TD>결과</TD>
            <TD>상세사유</TD>
            <TD>건수</TD>
          </TR>
        </THead>
        <TBody>
          {group.status_info.success_count > 0 && (
            <TR>
              <TD>
                <Center onClick={() => onClickGroupCheckbox("success")}>
                  <Checkbox active={isActiveGroup("success")} />
                </Center>
              </TD>
              <TD>
                <StatusText color="#1B58F1">성공</StatusText>
              </TD>
              <TD>전송 성공</TD>
              <TD>{group.status_info.success_count}</TD>
            </TR>
          )}
          {group.status_info.failure_count > 0 && (
            <TR>
              <TD>
                <Center onClick={() => onClickGroupCheckbox("fail")}>
                  <Checkbox active={isActiveGroup("fail")} />
                </Center>
              </TD>
              <TD>
                <StatusText color="#EC1C24">실패</StatusText>
              </TD>

              <TD>-</TD>
              <TD>{group.status_info.failure_count}</TD>
            </TR>
          )}
          {group.status_info.wait_count > 0 && (
            <TR>
              <TD>
                <Center onClick={() => onClickGroupCheckbox("wait")}>
                  <Checkbox active={isActiveGroup("wait")} />
                </Center>
              </TD>
              <TD>
                <StatusText color="#FAAF40">대기</StatusText>
              </TD>

              <TD>발송 대기중 </TD>
              <TD>{group.status_info.wait_count}</TD>
            </TR>
          )}
        </TBody>
      </TableContainer>
    </Container>
  );
};

const Container = styled.div``;

const TableContainer = styled.div``;

const TR = styled.div`
  padding: 0 24px;
  display: flex;
  width: 100%;
`;

const TD = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -1px;
  color: #333333;
  padding: 12px;
  text-align: center;

  &:nth-child(1) {
    width: 120px;
  }
  &:nth-child(2) {
    width: 120px;
  }
  &:nth-child(3) {
    flex: 1;
    text-align: left;
  }
  &:nth-child(4) {
    width: 60px;
  }
`;

const THead = styled.div`
  display: block;
  background: #fafafa;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -1px;
  color: #333333;
  margin: 0 -24px;
`;
const TBody = styled.div`
  display: block;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -1px;
  color: #333333;
  margin: 0 -24px;
`;

const StatusText = styled.div<{ color: string }>`
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -1px;
  color: ${(props) => props.color};
`;
