import styled from "styled-components";

import { ImgPricingHeaderSwiper3 } from "assets";
import { MEDIA_QUERY } from "properties/constant";
import { RoundedButton, SectionTitle } from "../styled";
import { Flex, Pad } from "styles/styled";
import { ArrowRight } from "components/Icons";

export const HeaderCard3 = () => {
  return (
    <Container>
      <Inner>
        <div>
          <Pad pb={4}>
            <Text>비즈포스트 스마트메시지를 아시나요?</Text>
          </Pad>
          <Pad pb={40}>
            <Title color="#ffffff">
              문자 하나 바꿨을 뿐인데 <br />
              <b>클릭율 580%</b> 증가
            </Title>
          </Pad>
          <ButtonContainer>
            <RoundedButton
              bgColor="#ffffff"
              color="#08173D"
              href={"https://forms.gle/zbg8HVnbSfn2GZf78"}
              target="_blank"
              rel="noreferrer"
            >
              <Flex gap={38}>
                <span>무료 상담받기</span> <ArrowRight fill={"#08173D"} />
              </Flex>
            </RoundedButton>
          </ButtonContainer>
        </div>
        <div>
          <Image src={ImgPricingHeaderSwiper3} alt="mockup" />
        </div>
      </Inner>
    </Container>
  );
};

const Container = styled.section`
  color: #ffffff;
  padding: 0 40px;
  height: 100%;

  @media (max-width: ${MEDIA_QUERY.MOBILE_S.MAX_WIDTH}px) {
    padding: 0 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;
const Inner = styled.div`
  max-width: 960px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${MEDIA_QUERY.MOBILE_S.MAX_WIDTH}px) {
    display: block;
  }
`;

const ButtonContainer = styled.div`
  @media (max-width: ${MEDIA_QUERY.MOBILE_S.MAX_WIDTH}px) {
    display: flex;
    justify-content: center;
  }
`;
const Title = styled(SectionTitle)`
  text-align: left;
  @media (max-width: ${MEDIA_QUERY.MOBILE_S.MAX_WIDTH}px) {
    text-align: center;
  }
`;
const Text = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -1px;
  color: #ffffff;
  text-align: left;

  @media (max-width: ${MEDIA_QUERY.MOBILE_S.MAX_WIDTH}px) {
    text-align: center;
  }
`;
const Image = styled.img`
  height: 540px;
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    display: none;
  }
`;
