import styled from "styled-components";

interface Props {
  label: string;
  onClick: () => void;
}

/**
 *
 * @param label
 * @param onClick
 * @returns
 */
export const ButtonPrimary = ({ label, onClick }: Props) => {
  return <Container onClick={onClick}>{label}</Container>;
};
const Container = styled.div`
  cursor: pointer;

  padding: 8px 16px;
  background-color: #1b58f1;
  width: fit-content;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;

  letter-spacing: -1px;
  color: #ffffff;
  border-radius: 100px;
  height: 36px;
`;
