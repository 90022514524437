import React from "react";
import styled, { css } from "styled-components";
import { ArrowBottom } from "components/Icons";
import SlideToggle from "react-slide-toggle";
import { MEDIA_QUERY } from "properties/constant";

interface Props {
  question: string;
  answer: JSX.Element;
}
const QnaListItem: React.FC<Props> = ({ question, answer }) => {
  return (
    <Container>
      <SlideToggle
        collapsed
        duration={300}
        render={({ toggle, setCollapsibleElement, toggleState }) => {
          const isOpen =
            toggleState === "EXPANDING" || toggleState === "EXPANDED";

          return (
            <SlideWrapper>
              <TitleContainer onClick={toggle}>
                <Text isOpen={isOpen}>
                  <b>Q.</b>
                  <span>{question}</span>
                </Text>
                <ArrowBottom />
              </TitleContainer>

              <Line />
              <AnswerContainer ref={setCollapsibleElement}>
                <div>
                  <b>A. </b>
                  <p>{answer}</p>
                </div>
              </AnswerContainer>
            </SlideWrapper>
          );
        }}
      />
    </Container>
  );
};

export default QnaListItem;
const Container = styled.div``;

const SlideWrapper = styled.div`
  overflow: hidden;
  width: 852px;
  margin: 0 auto;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 100%;
    padding: 0px 20px 20px;
  }
`;

const TitleContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  height: 75px;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    align-items: flex-start;
    height: auto;
    padding-bottom: 20px;
  }
`;
const Text = styled.div<{ isOpen: boolean }>`
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -1px;
  color: #333333;
  transition: all 0.1s ease-in-out;
  b {
    font-weight: 700;
    padding-right: 10px;
  }
  ${(props) =>
    props.isOpen &&
    css`
      font-weight: 700;
      color: #2984ff;
    `}
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 14px;
    text-align: left;
  }
`;

const AnswerContainer = styled.div`
  background: #f6f8ff;
  text-align: left;
  div {
    padding: 24px;
    display: flex;
  }

  b {
    font-weight: 700;
    padding-top: 4px;
    width: 20px;
  }

  p {
    flex: 1;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -1px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    p {
      font-size: 14px;
      line-height: 22px;
    }
  }
`;

const Line = styled.div`
  height: 1px;
  width: 100%;
  background-color: #999999;
`;
