import { MenuBar } from 'components/MenuBar';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Descriptions, Inner, Li, PaddingHorizontal, PageTitle, PageTitleSmall, TemplateLayout } from '../TemplateListSmart';
import { Padding } from 'components';
import { FlexWrapper } from 'components/Flex';
import { BodyBold, ButtonRound } from 'styles/styled';
import MessageTemplateLayout from 'components/template/MessageTemplateLayout';
import MockupBasic from 'components/MockupBasic';
import NavTopBack from 'components/Modals/NavTopBack';
import { PATH } from 'properties/menu';
import { useCustomNavigate } from 'hooks/useCustomNavigate';
import TemplateStore from 'stores/TemplateStore';
import TemplateCreationForm from 'components/template/TemplateCreationForm';
import TemplateButtonList from 'components/template/TemplateButtonList';

const messageProps = {
  height: "250px",
  title: "주문",
  boldTitle: true,
  children: <div>
    {`김** 고객님 
    주문내역입니다. 
    주문금액 : 50,000원 
    주문번호 : 2023-07-5625 
    배송주소 : 서울 영등포구 여의도동 
    12-34
    상품명 : 오늘의 북 외 5권 

    배송이 시작되면 안내메시지가 발송
    됩니다.
    `}
  </div>,
  buttonList: [
    {
      text: "상세 주문내역 보기"
    }
  ]
};

export const TemplateSmartSelectCell = () => {
  const templateStore = useContext(TemplateStore);
  const templateConfiguration = templateStore.templateConfiguration;
  const navigation = useCustomNavigate();
  const [messageTitle, setMessageTitle] = useState("");
  const [messageContent, setMessageContent] = useState("");
  const [isPassValidation, setIsPassValidation] = useState(false);

  const [messageInfo, setMessageInfo] = useState({
    messageProps: {
      height: "136px",
      title: "제목",
      boldTitle: true,
      children: <div>
        내용
      </div>,
      buttonList: [
        {
          text: "버튼",
          value: "",
          url:""
        }
      ]
    }
  });

  const [button1Details, setButton1Details] = useState({
    value: "",
    text: "",
    url: ""
  });

  const [button2Details, setButton2Details] = useState({
    value: "",
    text: "",
    url: ""
  });

  const moveToTemplateList = useCallback(() => {
    navigation.moveTo(PATH.TEMPLATE_LIST_SMART);
  },[navigation]);

  const validateTemplateInput = useCallback(() => {
    const result =  templateConfiguration.brandName !== "" &&
      templateConfiguration.templateFormat !== "" &&
      templateConfiguration.templateName !== "" &&
      messageContent !== "" &&
      ( 
        button1Details.value !== "" || 
        button2Details.value !== "" 
      ) &&
      (
        button1Details.value !== "" ? 
        button1Details.text !== "" &&
        button1Details.url !== "" 
        : true
      ) &&
      (
        button2Details.value !== "" ? 
        button2Details.text !== "" &&
        button2Details.url !== ""
        : true
      );
    setIsPassValidation(result);
  },[
    templateConfiguration.brandName,
    templateConfiguration.templateName,
    templateConfiguration.templateFormat,
    messageContent,
    button1Details,
    button2Details,
    setIsPassValidation
  ]);

  useEffect(() => {
    setMessageInfo((messageInfo)=>({
      ...messageInfo,
      messageProps:{
        ...messageInfo.messageProps,
        children: <div>
          {messageContent? messageContent : "내용"}
        </div>,
        title: messageTitle ? messageTitle : "제목",
        buttonList: 
          button1Details.value === "" && 
          button2Details.value === "" ? 
            [
              {
                text: "버튼",
                value: "",
                url:""
              }
            ]
            : [
              button1Details.value !== ""  && button1Details,
              button2Details.value !== ""  && button2Details
            ]
      }
    }));
    validateTemplateInput();
  }, [
    messageContent, 
    button1Details, 
    button2Details,
    messageTitle,
    validateTemplateInput
  ]);

  
  
  return (
    <TemplateLayout>
      <MenuBar/>
      <Container>
        <PageTitle>텍스트 템플릿 (타이틀 선택형) 추가</PageTitle>
        <Inner>
          <PaddingHorizontal>
            <PageTitleSmall>서술형 템플릿</PageTitleSmall>
            <Descriptions>
              <Li>서술형 템플릿 양식입니다.</Li>
            </Descriptions>
            <Padding height="26px"/>

            <FlexWrapper
              css={{
                paddingBottom: "40px",
                marginBottom: "40px",
                borderBottom: "1px solid #D9D9D9",
              }}
            >
              <FlexWrapper
                css={{
                  width: "495px",
                  minWidth: "495px"
                }}
              >
              <TemplateCreationForm/>
              </FlexWrapper>
              <FlexWrapper
                css={{
                  flex: "1",
                  marginLeft: "24px",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <MessageTemplateLayout
                  {...messageProps}
                  css={{
                    backgroundColor: "white",
                    border: "none"
                  }}
                />
              </FlexWrapper>
            </FlexWrapper>

            <FlexWrapper>
              <FlexWrapper
                css={{
                  width: "495px",
                  flexDirection: "column",
                  paddingBottom: "40px"
                }}
              >
                <BodyBold>
                  내용 입력
                </BodyBold>
                <Li>
                  {`변수로 설정하고자 하는 애용을 {{ }} 표시로 작성해주세요.
                  { 변수명은 영문, 숫자 가능 }
                  예) 이름 출금일 금액을 변수설정 : {{ name }}`}
                </Li>
                <Li>
                  {`메시지 전송 시 변수 값의 총합의 90자 이내로 전송해주세요. 
                  예) name date, amount 변수의 글자 갯수 종합이 90자 이내`}
                </Li>
                <Padding height="16px"/>
                
                <div>셀 추가 영역</div>
                
                <Padding height="40px"/>

                <BodyBold>
                  버튼 추가
                </BodyBold>
                <TemplateButtonList
                  button1Details={button1Details}
                  button2Details={button2Details}
                  setButton1Details={setButton1Details}
                  setButton2Details={setButton2Details}
                />
                <FlexWrapper
                  css={{
                    flex: "1",
                    marginTop: "24px",
                    paddingTop: "40px",
                    borderTop: "1px solid #D9D9D9",
                    height: "96px"
                  }}
                >
                  <ButtonRound
                    css={{
                      width: "158px",
                      fontWeight: "normal",
                      border: "1px solid #333",
                      marginRight: "10px"
                    }}
                  >
                    임시저장
                  </ButtonRound>
                  <ButtonRound
                    css={{
                      width: "158px",
                      fontWeight: "normal",
                      backgroundColor: "#333",
                      color: "white",
                      marginRight: "10px"
                    }}
                    onClick={moveToTemplateList}
                  >
                    템플릿 목록
                  </ButtonRound>
                  <ButtonRound
                    css={{
                      width: "158px",
                      fontWeight: "bold",
                      backgroundColor: isPassValidation ?
                        "#1B58F1" 
                        :"#E1E1E1",
                      color: "white"
                    }}
                  >
                    승인요청
                  </ButtonRound>
                </FlexWrapper>
              </FlexWrapper>
              <FlexWrapper
                css={{
                  flexDirection: "column",
                  alignItems: "flex-end",
                  paddingTop: "10px"
                }}
              >
                <MockupBasic>
                  <NavTopBack 
                    messageType="SMS"
                  />
                  <MockupContent>
                    <MessageTemplateLayout
                      {...messageInfo.messageProps}
                      css={{
                        width: "100%"
                      }}
                    />
                  </MockupContent>
                </MockupBasic>
              </FlexWrapper>
            </FlexWrapper>
          </PaddingHorizontal>
        </Inner>
      </Container>
    </TemplateLayout>
  );
};

export const Container = styled.div`
  padding: 48px 40px;
  width: fit-content;
  min-width: 976px;
`;

const MockupContent = styled.div`
  padding: 12px;
  box-sizing: border-box;
`;