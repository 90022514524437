import { ModalLayout } from "components";
import { ButtonNormal } from "components/ButtonNormal";
import {
  Container,
  Content,
  SubTitle,
  InputContainer,
  Input,
  ButtonContainer,
} from "../LayoutStyled";

interface Props {
  stepText: any;
  phone: string;
  phoneVerified: boolean;
  documentUploaded: boolean;
  onClose: () => void;
  onClickNext: () => void;
  onClickPrev: () => void;
  cetificatePhone: () => void;
}

export const ChooseNextStepModal = ({
  phone,
  phoneVerified,
  stepText,
  documentUploaded,
  onClose,
  onClickNext,
  onClickPrev,
  cetificatePhone,
}: Props) => {
  return (
    <ModalLayout title={stepText.title} onClose={onClose} width={700}>
      <Container>
        <Content>{stepText.content}</Content>
        <SubTitle>{stepText.subTitle} </SubTitle>

        {/* input box */}
        <InputContainer>
          <Input
            type="text"
            placeholder={stepText.inputPlaceholder}
            readOnly
            defaultValue={phone}
          />
        </InputContainer>

        {/* 하단 버튼 */}
        <ButtonContainer>
          <ButtonNormal label="이전" onClick={onClickPrev} />
          <ButtonNormal
            width={178}
            label={phoneVerified ? "인증 성공" : "휴대폰 번호인증"}
            onClick={cetificatePhone}
            disabled={phoneVerified}
            isPrimary={true}
          />
          {phoneVerified}
          <ButtonNormal
            width={298}
            label="통신서비스 이용증명원 서류인증"
            onClick={onClickNext}
            isOutline={true}
          />
        </ButtonContainer>
      </Container>
    </ModalLayout>
  );
};
