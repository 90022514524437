import OutsideClickHandler from "react-outside-click-handler";
import { useContext, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { observer } from "mobx-react-lite";
import { ConfirmModal, Icons, Pagination } from "components";
import { InputWithSearch } from "components/InputWithSearch";
import { AddressBooks } from "./AddressBooks";
import { AddressBookType, AddressGroupType } from "types/types";
import { convertPhoneOnlyNumber, validateAndConvertPhone } from "utils/phone";
import {
  convertToAddressBookRequestType,
  filterModfiedAddressBooks,
} from "utils/addressBook";
import { FlexBetween, Input, Option } from "styles/styled";
import { UploadExcelModal } from "./UploadExcelModal";
import AddressBookStore from "stores/AddressBookStore";
import { MoveAddressBookModal } from "../modals/MoveAddressBookModal";
import { CopyAddressBookModal } from "../modals/CopyAddressBookModal";

export const ManageAddressList: React.FC = observer(() => {
  const store = useContext(AddressBookStore);
  const [visibleAddressGroup, setVisibleAddressGroup] = useState(false);
  const [searchKeyword, setsearchKeyword] = useState<string>("");
  const [targetPhone, setTargetPhone] = useState<string>("");
  const [targetName, setTargetName] = useState<string>("");
  const [targetGroup, setTagerGroup] = useState<AddressGroupType | null>(
    store.activeGroup
  );
  const [targetAddressBooks, setTargetAddressBooks] = useState<
    AddressBookType[]
  >([]);
  const [originAddressBooks, setOriginAddressBookss] =
    useState<AddressBookType[]>();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const [validateAddAddress, setValidateAddAddress] = useState<boolean>(false);
  const [selectedAddressBooks, setSelectedAddressBooks] = useState<
    AddressBookType[]
  >([]);
  // Modal

  const [visibleRemoveAddressBookModal, setVisibleRemoveAddressBookModal] =
    useState<boolean>();
  const [visibleMoveAddressBookModal, setVisibleMoveAddressBookModal] =
    useState<boolean>();
  const [visibleCopyAddressBookModal, setVisibleCopyAddressBookModal] =
    useState<boolean>();
  const [visibleExcelUploaadModal, setVisibleExcelUploaadModal] =
    useState<boolean>(false);
  const [isSearchActive, setIsSearchActive] = useState<boolean>(false);

  useEffect(() => {
    const errorMessage = getErrorMessageCreateAddressBook();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setValidateAddAddress(!errorMessage);
  }, [targetPhone, targetName, store.addressBooks]);

  useEffect(() => {
    if (store.addressBooks && store.activeGroup) {
      setTargetAddressBooks([...store.addressBooks]);
      setOriginAddressBookss([...store.addressBooks]);
    }
    setSelectedAddressBooks([]);
    setIsEditMode(false);
    setTagerGroup(store.activeGroup);
  }, [store.activeGroup, store.currentPage, store.addressBooks]);

  /** 검색버튼을 눌렀을 때  */
  const onClickSearchButton = async () => {
    let books: AddressBookType[] = [];
    books = await store.searchAddressBooksByKeyword(
      store.activeGroup?.id,
      searchKeyword
    );
    setTargetAddressBooks(books);
    setIsSearchActive(true);
  };

  const getErrorMessageCreateAddressBook = (): string | null => {
    const validatedPhone = validateAndConvertPhone(targetPhone);
    const validatedUsername = targetName.length > 0;
    if (!validatedPhone) {
      return "전화번호를 확인해주세요";
    }
    if (!validatedUsername) {
      return "이름을 입력해주세요";
    }
    return null;
  };

  const onClickCreateAddressBookButton = async () => {
    const errorMessage = getErrorMessageCreateAddressBook();
    if (errorMessage) {
      alert(errorMessage);
      return;
    }

    const phone = convertPhoneOnlyNumber(targetPhone);
    const result = await store.onCreateAddressBook(
      phone,
      targetName,
      targetGroup ? targetGroup.id : null
    );
    if (result) {
      setTargetPhone("");
      setTargetName("");
      setTagerGroup(store.activeGroup);
    }
  };

  const onClickAddressBook = (addressBook: AddressBookType) => {
    if (selectedAddressBooks.includes(addressBook)) {
      const newSelectedAddressBooks = selectedAddressBooks.filter(
        (book) => book.id !== addressBook.id
      );
      setSelectedAddressBooks(newSelectedAddressBooks);
    } else {
      setSelectedAddressBooks([...selectedAddressBooks, addressBook]);
    }
  };

  const onRemoveAddressBook = async () => {
    const bookIds = selectedAddressBooks.map((book) => book.id);
    const response = await store.onDeleteAddressBooks(bookIds);
    if (response) {
      setVisibleRemoveAddressBookModal(false);
      setSelectedAddressBooks([]);
    }
  };

  const onClickDeleteButton = () => {
    if (selectedAddressBooks.length > 0) {
      setVisibleRemoveAddressBookModal(true);
    }
  };

  const onClickMoveButton = () => {
    if (selectedAddressBooks.length > 0) {
      setVisibleMoveAddressBookModal(true);
    }
  };
  const onClickCopyButton = () => {
    if (selectedAddressBooks.length > 0) {
      setVisibleCopyAddressBookModal(true);
    }
  };

  const onMoveAddressBooks = async (targetGroup: AddressGroupType) => {
    if (store.activeGroup!.group_name === targetGroup.group_name) {
      alert("같은 그룹으로는 이동할 수 없습니다");
      return;
    }
    const books = selectedAddressBooks.map((book) => {
      return {
        id: book.id,
        group_id: targetGroup!.id,
        phone_number: book.phone_number,
        name: book.name,
        memo: book.memo,
      };
    });
    const response = await store.onMoveGroupAddressBooks(books, targetGroup);
    if (response) {
      setVisibleMoveAddressBookModal(false);
      setSelectedAddressBooks([]);
    }
  };

  const onCopyAddressBooks = async (targetGroup: AddressGroupType) => {
    if (store.activeGroup!.group_name === targetGroup.group_name) {
      alert("같은 그룹으로는 복사할 수 없습니다");
      return;
    }
    const books = selectedAddressBooks.map((book) => {
      return {
        group_id: targetGroup.id,
        phone_number: book.phone_number,
        name: book.name,
        memo: book.memo,
      };
    });

    const response = await store.onCopyGroupAddressBooks(books);
    if (response) {
      setVisibleCopyAddressBookModal(false);
      setSelectedAddressBooks([]);
    }
  };

  const saveModifedAddressBook = async () => {
    let isNotValidated = false;

    const validatedBooks = targetAddressBooks.map((book) => {
      const isValidatePhone = validateAndConvertPhone(book.phone_number);
      book.phone_number = convertPhoneOnlyNumber(book.phone_number);
      const isValidateUsername = book.name.length > 1;
      if (!isValidatePhone && !isValidateUsername) {
        book.invalid = "all";
        isNotValidated = true;
      } else if (!isValidatePhone) {
        book.invalid = "phone";
        isNotValidated = true;
      } else if (!isValidateUsername) {
        book.invalid = "username";
        isNotValidated = true;
      } else {
        book.invalid = "";
      }
      return book;
    });

    //하나라도 유효하지 않으면 저장하지 않음
    if (isNotValidated) {
      alert("올바른 포맷으로 입력해주세요");
      setTargetAddressBooks(validatedBooks);
      return;
    } else {
      const books = filterModfiedAddressBooks(
        originAddressBooks!,
        targetAddressBooks
      );
      const bookRequests = convertToAddressBookRequestType(
        books,
        store.activeGroup!.id
      );
      await store.onSaveModifiedAddressBooks(bookRequests);
    }
  };

  const onChangeAddressBook = (
    index: number,
    type: "phone" | "username",
    value: string
  ) => {
    const books = [...targetAddressBooks];
    const book = { ...targetAddressBooks[index] };
    if (type === "phone") {
      book.phone_number = value;
    } else if (type === "username") {
      book.name = value;
    }
    books[index] = book;
    setTargetAddressBooks(books);
  };

  const onClickPage = async (page: number) => {
    await store.setCurrentPageAndReload(page, isSearchActive);
  };

  return (
    <Container>
      <FlexBetween>
        <h4>주소록 관리</h4>
        <ButtonPrimary onClick={() => setVisibleExcelUploaadModal(true)}>
          엑셀로 등록
        </ButtonPrimary>
      </FlexBetween>
      {/* 주소록 검색*/}
      <SearchEditContainer>
        <InputContainer>
          <InputWithSearch
            placeholder="이름 또는 휴대폰번호 검색"
            onInputChange={setsearchKeyword}
            onClickSearchButton={onClickSearchButton}
            value={searchKeyword}
          />
        </InputContainer>
        <ButtonContainer>
          {isEditMode ? (
            <ButtonPrimary active onClick={saveModifedAddressBook}>
              저장
            </ButtonPrimary>
          ) : (
            <Button active onClick={() => setIsEditMode(true)}>
              편집
            </Button>
          )}

          <Button
            active={selectedAddressBooks.length > 0}
            onClick={onClickDeleteButton}
          >
            삭제
          </Button>
          <Button
            active={selectedAddressBooks.length > 0}
            onClick={onClickMoveButton}
          >
            이동
          </Button>
          <Button
            active={selectedAddressBooks.length > 0}
            onClick={onClickCopyButton}
          >
            복사
          </Button>
        </ButtonContainer>
      </SearchEditContainer>
      <Line />
      {/* ------- 주소록 추가---------*/}
      <GroupSearchContainer>
        <Input
          placeholder="휴대폰 번호"
          width={194}
          onChange={(e) => setTargetPhone(e.target.value)}
          value={targetPhone}
          // onKeyDown={onEnterCreateAddressBook}
        />
        <Input
          placeholder="이름"
          width={132}
          onChange={(e) => setTargetName(e.target.value)}
          value={targetName}
          // onKeyDown={onEnterCreateAddressBook}
        />

        {/* ------- 타켓 그룹 선택 모달---------*/}
        <GroupSelectionContainer>
          <OutsideClickHandler
            onOutsideClick={() => setVisibleAddressGroup(false)}
          >
            <GroupSelection
              className="pointer activeItem"
              onClick={() => setVisibleAddressGroup(!visibleAddressGroup)}
            >
              <label>
                <b>{targetGroup ? targetGroup.group_name : "그룹 미지정"}</b>
              </label>
              <Icons.ArrowBottom color="#121212" />
              {visibleAddressGroup && store.addressBookGroups && (
                <GroupOptions>
                  {store.addressBookGroups.map((group) => (
                    <Option
                      height={36}
                      key={group.id}
                      onClick={() => setTagerGroup(group)}
                    >
                      {group.group_name}
                    </Option>
                  ))}
                </GroupOptions>
              )}
            </GroupSelection>
          </OutsideClickHandler>
        </GroupSelectionContainer>

        <PrimaryButton
          disabled={!validateAddAddress}
          onClick={onClickCreateAddressBookButton}
        >
          추가
        </PrimaryButton>
      </GroupSearchContainer>

      {/* ------- 주소록 리스트--------*/}
      <AddressBooks
        addressBooks={targetAddressBooks}
        selectedAddressBooks={selectedAddressBooks}
        isEditMode={isEditMode}
        onClickAddressBook={onClickAddressBook}
        onChangeAddressBook={onChangeAddressBook}
      />
      <PaginationContainer>
        <Pagination
          size={store.addressBookTotalCount}
          defaultPage={store.currentPage}
          setCurrentPage={onClickPage}
        />
      </PaginationContainer>
      {/* 번호 삭제 혹인 모달 */}
      {visibleRemoveAddressBookModal && (
        <ConfirmModal
          title="번호 삭제"
          confirmButtonText="번호 삭제"
          confirmButtonColor="#EC1C24"
          closeModal={() => setVisibleRemoveAddressBookModal(false)}
          onConfirm={onRemoveAddressBook}
        >
          <div>
            선택한 {selectedAddressBooks.length}개의 주소를 삭제하시겠습니까?
            <br /> 삭제된 주소는 복구할 수 없습니다.
          </div>
        </ConfirmModal>
      )}
      {/* 번호 이동 확인 모달 */}
      {visibleMoveAddressBookModal && (
        <MoveAddressBookModal
          groups={store.addressBookGroups}
          activeGroup={store.activeGroup}
          selectedAddressBooks={selectedAddressBooks}
          onClose={() => setVisibleMoveAddressBookModal(false)}
          onMoveAddressBooks={onMoveAddressBooks}
        />
      )}

      {/* 번호 복사 확인 모달 */}
      {visibleCopyAddressBookModal && (
        <CopyAddressBookModal
          groups={store.addressBookGroups}
          activeGroup={store.activeGroup}
          selectedAddressBooks={selectedAddressBooks}
          onClose={() => setVisibleCopyAddressBookModal(false)}
          onCopyAddressBooks={onCopyAddressBooks}
        />
      )}

      {/* 주소록 수정 모달 */}
      {visibleExcelUploaadModal && (
        <UploadExcelModal onClose={() => setVisibleExcelUploaadModal(false)} />
      )}
    </Container>
  );
});
const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-height: 600px;
  h4 {
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: -1px;
    color: #333333;
  }
  label {
    cursor: pointer;
  }
`;

const Line = styled.div`
  background-color: #999999;
  height: 1px;
  width: 100%;
`;

const GroupSearchContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const InputContainer = styled.div`
  flex: 1;
`;
const PrimaryButton = styled.div<{ disabled?: boolean }>`
  cursor: pointer;
  width: 74px;
  background: #1b58f1;
  border-radius: 6px;
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -1px;
  padding: 12px 22px;
  ${(props) =>
    props.disabled &&
    css`
      background: #d7dbe2;
      cursor: not-allowed;
    `}
`;

const SearchEditContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const Button = styled.div<{ active?: boolean }>`
  width: fit-content;
  border: 1px solid #d7dbe2;
  color: #d7dbe2;
  border-radius: 4px;
  padding: 8px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;

  ${(props) =>
    props.active &&
    css`
      border-color: #333333;
      color: #333333;
    `}
`;

const ButtonPrimary = styled(Button)`
  background-color: #1b58f1;
  color: #ffffff;
  border: none;
`;
const GroupSelectionContainer = styled.div`
  border: 1px solid #d7dbe2;
  border-radius: 4px;
  padding: 8px 12px;
  position: relative;
  flex: 1;
`;
const GroupSelection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const GroupOptions = styled.div`
  padding-bottom: 1px;
  position: absolute;
  top: 43px;
  left: 0px;
  width: 100%;
  border-radius: 3px;
  overflow-y: auto;
  max-height: 380px;
`;
const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0 36px;
`;
