import { InputWithSearch, Pagination } from "components";
import { ModalLayout } from "components";
import { Loading } from "components/Icons";
import { useContext, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { AddressBookType, AddressGroupType } from "types/types";
import { AddressBookRow } from "./AddressBookRow";
import AddressBookStore from "stores/AddressBookStore";
import { observer } from "mobx-react-lite";
import {
  Pad,
  Center,
  TextGray6,
  ButtonRoundPrimary,
  ButtonRoundOutline,
} from "styles/styled";
export * as Icons from "components/Icons";

interface Props {
  selectedAddressBooks: AddressBookType[];
  addGroupToReceivers: (group: AddressGroupType) => void;
  addAddressesToReceiver: () => void;
  onClickAddressBookName: (addressBook: AddressBookType) => void;
  onClose: () => void;
}
export const AddressBookModal: React.FC<Props> = observer(
  ({
    selectedAddressBooks,
    onClickAddressBookName,
    addGroupToReceivers,
    addAddressesToReceiver,
    onClose,
  }) => {
    const store = useContext(AddressBookStore);
    const [targetGroupName, setTargetGroupName] = useState<string>("");
    const [searchKeyword, setSearchKeyword] = useState<string>("");
    const [isSearchActive, setIsSearchActive] = useState<boolean>(false);
    const [filteredAddressGroups, setFilteredAddressGroups] = useState<
      AddressGroupType[] | null
    >(store.addressBookGroups);
    const [filteredAddressBooks, setFilteredAddressBooks] = useState<
      AddressBookType[] | null
    >(null);

    useEffect(() => {
      fetchAddressBookGroups();
      return () => {
        if (store.addressBookGroups?.length > 0) {
          store.setActiveGroup(store.addressBookGroups[0]);
        }
      };
    }, []);

    const fetchAddressBookGroups = async () => {
      await store.fetchAddressBookGroups();
      await store.fetchAddressBooks(true);

      if (store.addressBookGroups) {
        setFilteredAddressGroups(store.addressBookGroups);
        if (store.addressBookGroups.length > 0) {
          store.setActiveGroup(store.addressBookGroups[0]);
        }
      }
      if (store.addressBooks) {
        setFilteredAddressBooks(store.addressBooks);
      }
    };

    const onClickGroup = async (group: AddressGroupType) => {
      store.setActiveGroup(group);
      await store.fetchAddressBooks(true);
      setFilteredAddressBooks([...store.addressBooks]);
    };

    const isActiveCheckbox = (adressBook: AddressBookType) => {
      return selectedAddressBooks.includes(adressBook);
    };

    /** 그룹명을 검색한 결과 */
    const onSearchGroupName = () => {
      if (store.addressBookGroups) {
        const filteredGroups = store.addressBookGroups.filter((group) =>
          group.group_name.includes(targetGroupName)
        );
        setFilteredAddressGroups(filteredGroups);
      }
      setIsSearchActive(true);
    };

    /** 주소록 검색한 결과 */
    const onSearchAddressBook = async () => {
      if (store.addressBooks) {
        const filteredBooks = await store.searchAddressBooksByKeyword(
          store.activeGroup?.id,
          searchKeyword
        );

        setFilteredAddressBooks(filteredBooks);
      }
      setIsSearchActive(false);
    };

    const onClickPage = async (page: number) => {
      await store.setCurrentPageAndReload(page, isSearchActive);
      setFilteredAddressBooks(store.addressBooks);
    };

    return (
      <ModalLayout title="주소록 불러오기" onClose={onClose} width={844}>
        <Container>
          {/* 로딩 */}
          {!store.addressBooks && (
            <LoadingContainer>
              <Loading />
            </LoadingContainer>
          )}

          {store.addressBooks && filteredAddressGroups && (
            <Container>
              <div className="groupContainer">
                <div className="subTitle">그룹</div>

                <InputWithSearch
                  placeholder={"그룹명 검색"}
                  onInputChange={setTargetGroupName}
                  onClickSearchButton={onSearchGroupName}
                  value={""}
                />
                <Pad pb={16} />
                <div className="groupListContainer">
                  {filteredAddressGroups!.map((group) => (
                    <GroupItem
                      key={group.id}
                      active={store.activeGroup?.id === group.id}
                      onClick={() => onClickGroup(group)}
                    >
                      {`${group.group_name} (${group.total_count})`}
                    </GroupItem>
                  ))}
                </div>
                <ButtonRoundPrimary
                  onClick={() => addGroupToReceivers(store.activeGroup)}
                >
                  그룹 전체 추가
                </ButtonRoundPrimary>
              </div>

              <AddressBookContainer>
                <div className="subTitle">주소록</div>
                <InputWithSearch
                  width="260px"
                  placeholder={"이름 또는 휴대폰번호 검색"}
                  onInputChange={setSearchKeyword}
                  onClickSearchButton={onSearchAddressBook}
                  value={""}
                />
                <Pad pb={16} />
                <Table>
                  <TableTbody />
                  {filteredAddressBooks &&
                    filteredAddressBooks.map((book) => (
                      <AddressBookRow
                        key={book.id}
                        book={book}
                        onClickAddressBook={() => onClickAddressBookName(book)}
                        isActiveCheckbox={() => isActiveCheckbox(book)}
                      />
                    ))}
                  {filteredAddressBooks &&
                    filteredAddressBooks.length === 0 && (
                      <Pad pt={40} pb={16}>
                        <Center>
                          <TextGray6>등록된 주소록이 없습니다.</TextGray6>
                        </Center>
                      </Pad>
                    )}
                </Table>

                <Pagination
                  defaultPage={store.currentPage}
                  size={store.addressBookTotalCount}
                  setCurrentPage={onClickPage}
                />

                <ButtonContainer>
                  <ButtonRoundOutline width={274} onClick={onClose}>
                    취소
                  </ButtonRoundOutline>
                  <ButtonRoundPrimary
                    disabled={selectedAddressBooks.length === 0}
                    width={274}
                    onClick={addAddressesToReceiver}
                  >
                    선택한 번호 추가
                  </ButtonRoundPrimary>
                </ButtonContainer>
              </AddressBookContainer>
            </Container>
          )}
        </Container>
      </ModalLayout>
    );
  }
);

const TableTbody = () => {
  return (
    <TBody>
      <TH>선택</TH>
      <TH>휴대폰번호</TH>
      <TH>이름</TH>
      <TH>그룹명</TH>
      <TH>수신거부</TH>
    </TBody>
  );
};

const Container = styled.div`
  display: flex;
  gap: 20px;
  min-height: 300px;

  .groupContainer {
    width: 210px;
  }

  .groupListContainer {
    border: 1px solid #d7dbe2;
    border-radius: 6px;
    padding: 12px 10px;
    margin-bottom: 24px;
    min-height: 300px;
    overflow-y: auto;
  }

  .subTitle {
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    padding: 16px 0px;
    letter-spacing: -1px;
    color: #333333;
  }
  .rodal-dialog {
    overflow-y: auto;
    transform: scale(0.95);
    min-width: 844px !important;
    width: 80% !important;
    max-width: 1000px;
  }
`;
const TR = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 4px;
  div {
    text-align: center;
  }
  div:nth-child(1) {
    width: 60px;
  }
  div:nth-child(2) {
    width: 120px;
  }
  div:nth-child(3),
  div:nth-child(4) {
    flex: 1;
  }
  div:nth-child(5) {
    width: 70px;
  }
`;

const TBody = styled(TR)`
  background: #fafafa;
  margin-bottom: 8px;
`;

const TD = styled.div<{ active?: boolean; isEditable?: boolean }>`
  padding: 4px 10px;
  color: #333333;
  text-align: center;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    display: inline-block;
  }

  ${(props) =>
    props.active &&
    css`
      color: #ec1c24;
      font-weight: 700;
    `}

  ${(props) =>
    props.isEditable &&
    css`
      border: 1px solid #d7dbe2;
      border-radius: 6px;
    `}
`;

const TH = styled(TD)``;

const AddressBookContainer = styled.div`
  flex: 1;
`;

const GroupItem = styled.div<{ active?: boolean }>`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: flex-start;
  letter-spacing: -1px;
  color: #333333;
  padding-bottom: 10px;
  cursor: pointer;
  &::before {
    content: "• ";
    padding: 0 10px;
  }

  ${(props) =>
    props.active &&
    css`
      font-weight: 700;
      color: #1b58f1;
    `}
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const Table = styled.div`
  min-height: 380px;
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;
