import styled, { css } from "styled-components";
import { MenuItemType } from "properties/menu";
import { useCustomNavigate } from "hooks/useCustomNavigate";

interface Props {
  menu: MenuItemType;
  active: boolean;
}

export const MainMenuItem = ({ menu, active }: Props) => {
  const navigate = useCustomNavigate();
  return (
    <ListItem active={active} onClick={() => navigate.moveTo(menu.path)}>
      <LinkItem disabled={menu.disabled}>
        {active ? menu.iconActive : menu.icon}
        <div>{menu.label}</div>
        {!menu.isSubMenu && active && <ActiveBar />}
      </LinkItem>
    </ListItem>
  );
};

const ListItem = styled.div<{ active: boolean }>`
  height: 100%;
  padding: 14px 0px;
  position: relative;
  cursor: pointer;
  ${(props) =>
    props.active &&
    css`
      font-weight: 700;
      color: #1b58f1;
    `}
`;

const LinkItem = styled.div<{ disabled?: boolean }>`
  display: flex;
  gap: 10px;
  align-items: center;

  ${(props) =>
    props.disabled &&
    css`
      color: #e1e1e1;
      cursor: not-allowed;
    `}
`;

const ActiveBar = styled.div`
  height: 32px;
  width: 4px;

  background-color: #1b58f1;
  position: absolute;
  right: 0px;
  top: 11px;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
`;
