import { useEffect, useState } from "react";
import styled from "styled-components";

import { MessageLayout } from "components";
import { DateSelection } from "./DateSelection";
import { BllingList } from "./BllingList";

import {
  DATE_FORMAT_2,
  changeDateFormat,
  getCurrentTime,
  subtractDate,
} from "utils/datetime";
import * as pointApi from "apis/pointApi";
import { BillingType, PeriodOptionType } from "types/types";
import { ReceiptModal } from "./ReceiptModal";
import { ButtonPrimary, Flex } from "styles/styled";
import { useCustomNavigate } from "hooks/useCustomNavigate";
import { PATH } from "properties/menu";
import { addParameterToURL } from "utils/addParameterToURL";
import { periodOptions } from "properties/content";

const DEFAULT_DAYS_BEFORE = 7;

export const BillingHistory = () => {
  const navigate = useCustomNavigate();
  const { start, end, page } = navigate.query;

  const [startDate, setStartDate] = useState<string>(
    start
      ? String(start)
      : subtractDate(
          getCurrentTime(DATE_FORMAT_2),
          DATE_FORMAT_2,
          DEFAULT_DAYS_BEFORE
        )
  );
  const [activePeriod, setActivePeriod] = useState<PeriodOptionType | null>(
    periodOptions[1]
  );
  const [endDate, setEndDate] = useState<string>(
    end ? String(end) : getCurrentTime(DATE_FORMAT_2)
  );
  const [billings, setBillings] = useState<BillingType[] | null>(null);
  const [targetBilling, setTargetBilling] = useState<BillingType | null>();
  const [currentPage, setCurrentPage] = useState<number>(
    page ? Number(page) : 1
  );
  const [billingCount, setBillingCount] = useState<number>(0);

  useEffect(() => {
    if (activePeriod) {
      fetchBillings();
      navigate.replace(generateUrlWithParams());
    }
  }, [activePeriod]);

  useEffect(() => {
    fetchBillings();
  }, [currentPage]);
  const fetchBillings = async () => {
    const body = {
      startDate: changeDateFormat(startDate, DATE_FORMAT_2),
      endDate: changeDateFormat(endDate, DATE_FORMAT_2),
      page: currentPage,
      size: 10,
    };
    const response = await pointApi.fetchBillings(body);
    setBillings(response.results);
    setBillingCount(response.count);
  };

  const onClickSearchButton = () => {
    const { start, end, page } = navigate.query;
    if (start) setStartDate(String(start));
    if (end) setEndDate(String(end));
    if (page) setCurrentPage(Number(page));
    fetchBillings();
    navigate.replace(generateUrlWithParams());
  };

  const generateUrlWithParams = () => {
    let url = PATH.CHARGE_HISTORY;

    if (startDate) url = addParameterToURL(url, "start", startDate);
    if (endDate) url = addParameterToURL(url, "end", endDate);
    if (currentPage) url = addParameterToURL(url, "page", currentPage);
    return url;
  };

  return (
    <MessageLayout>
      <>
        <Container>
          <Inner>
            <Header>
              <SubTitle>충전내역</SubTitle>
              <Description>
                <Li>최근 충전 내역을 확인할 수 있습니다</Li>
              </Description>

              <Flex gap={8}>
                <DateSelection
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  activePeriod={activePeriod}
                  setActivePeriod={setActivePeriod}
                />

                <ButtonPrimaryStyled
                  radius={4}
                  padding="10px 16px"
                  onClick={onClickSearchButton}
                >
                  검색
                </ButtonPrimaryStyled>
              </Flex>
            </Header>

            <BllingList
              billings={billings}
              onClickBilling={setTargetBilling}
              billingCount={billingCount}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </Inner>
        </Container>

        {targetBilling && (
          <ReceiptModal
            billing={targetBilling!}
            onClose={() => setTargetBilling(null)}
          />
        )}
      </>
    </MessageLayout>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 800px;
`;

const Inner = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  min-width: 820px;
`;
const Header = styled.div`
  padding: 24px;
`;

const Li = styled.li`
  padding-bottom: 8px;
  color: rgb(85, 85, 85);
  &::before {
    content: "• ";
    list-style: none;
    padding-right: 4px;
  }
`;

const SubTitle = styled.h3`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  letter-spacing: -1px;
  color: #333333;
  padding-bottom: 16px;
`;

const Description = styled.ul`
  list-style: none;
  padding-bottom: 40px;
`;

const ButtonPrimaryStyled = styled(ButtonPrimary)``;
