import * as S from "./styled";
import { NoData, TBody, TD, TR, TRow } from "styles/styled";
import { Loading } from "components/Icons";

import { TemplateType } from "types/types";
import { PATH } from "properties/menu";
import { useCustomNavigate } from "hooks/useCustomNavigate";
import { changeDateFormat, DATE_FORMAT_1 } from "utils/datetime";

interface Props {
  templates: TemplateType[] | null;
}

export const TemplateTable = ({ templates }: Props) => {
  const navigate = useCustomNavigate();

  const onClickItem = (item: TemplateType) => {
    navigate.moveTo(
      PATH.TEMPLATE_DETAIL_PLAIN.replace(":templateId", item.id.toString())
    );
  };

  const getCreatedDate = (group: TemplateType) => {
    return changeDateFormat(group.created, DATE_FORMAT_1);
  };

  const getMessageTypeLabel = (typeNumber: number) => {
    switch (typeNumber) {
      case 1:
        return "SMS";
      case 2:
        return "LMS";
      case 3:
        return "MMS";
      default:
        return "-";
    }
  };
  return (
    <S.Container>
      <TBody>
        <TR>번호</TR>
        <TR>메시지 타입</TR>
        <TR>템플릿 이름</TR>
        <TR>상태</TR>
        <TR>최종수정일</TR>
      </TBody>

      {templates && templates.length > 0 ? (
        templates.map((template, index) => {
          return (
            <TRow key={`m-template-${template.id}`}>
              <TD>{index + 1}</TD>
              <TD>{getMessageTypeLabel(template.msg_type)}</TD>
              <TD
                hasLink={true}
                onClick={() => onClickItem(template)}
                title={template.template_name}
              >
                {template.template_name}
              </TD>
              <TD>저장</TD>

              <TD>{getCreatedDate(template)}</TD>
            </TRow>
          );
        })
      ) : (
        <>
          {templates ? (
            <NoData>등록된 템플릿이 없습니다.</NoData>
          ) : (
            <S.LoadingContainer>
              <Loading width={50} height={50} />
            </S.LoadingContainer>
          )}
        </>
      )}
    </S.Container>
  );
};
