import { Routes, Route } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { observer } from "mobx-react-lite";

import * as Page from "./pages";
import { PATH } from "properties/menu";

import { TopNav } from "components";

import GlobalStyled from "styles/global";

const App = () => {
  return (
    <BrowserRouter>
      <GlobalStyled />
      <TopNav />
      <Routes>
        <Route index element={<Page.Home />} />
        <Route path={PATH.HOME} element={<Page.Home />} />
        <Route path={PATH.SIGN_UP} element={<Page.SignUp />} />
        <Route path={PATH.SIGN_UP_REGULAR} element={<Page.SignUpRegular />} />
        <Route path={PATH.SIGN_UP_COMPANY} element={<Page.SignUpCompany />} />
        <Route path={PATH.SIGN_IN} element={<Page.SignIn />} />
        <Route path={PATH.FIND_ID} element={<Page.FindEmail />} />
        <Route path={PATH.CHANGE_PROFILE} element={<Page.ChangeProfile />} />
        <Route
          path={PATH.CHANGE_COMPANY_PROFILE}
          element={<Page.ChangeCompanyProfile />}
        />
        <Route path={PATH.RESET_PASSWORD} element={<Page.ResetPassword />} />
        <Route path={PATH.DASHBOARD} element={<Page.Dashaboard />} />
        <Route path={PATH.MESSAGE_SEND} element={<Page.MessageSend />} />
        {/* <Route path={PATH.MESSAGE_SEND_SMART} element={} /> */}
        <Route
          path={PATH.MESSAGE_SEND_RESULT}
          element={<Page.MessageSendResult />}
        />
        <Route
          path={PATH.MESSAGE_SEND_RESULT_DETAIL}
          element={<Page.MessageSendResultDetail type="SEND" />}
        />
        <Route
          path={PATH.MESSAGE_RESERVED_RESULT}
          element={<Page.MessageReserveResult />}
        />
        <Route
          path={PATH.MESSAGE_RESERVED_RESULT_DETAIL}
          element={<Page.MessageSendResultDetail type="RESERVED" />}
        />
        <Route
          path={PATH.MESSAGE_BLOCK_RESULT}
          element={<Page.BlockedNumber />}
        />
        <Route path={PATH.CALLERS} element={<Page.Senders />} />
        <Route path={PATH.PRICING} element={<Page.Pricing />} />
        <Route path={PATH.POINT_CHARGE} element={<Page.PointCharge />} />
        <Route path={PATH.CHARGE_HISTORY} element={<Page.BillingHistory />} />
        <Route path={PATH.ADDRESS_BOOK} element={<Page.AddressBook />} />

        <Route
          path={PATH.TEMPLATE_LIST_PLAIN}
          element={<Page.TemplateListPlain />}
        />
        <Route
          path={PATH.TEMPLATE_DETAIL_PLAIN}
          element={<Page.TemplateDetailPlain isUpdate={true} />}
        />
        <Route
          path={PATH.TEMPLATE_NEW_PLAIN}
          element={<Page.TemplateDetailPlain isUpdate={false} />}
        />
        <Route
          path={PATH.TEMPLATE_LIST_SMART}
          element={<Page.TemplateListSmart />}
        />
        <Route
          path={PATH.TEMPLATE_SMART_SELECT_DESCRIPTION}
          element={<Page.TemplateSmartSelectDescription />}
        />
        <Route
          path={PATH.TEMPLATE_SMART_IMAGE_TITLE}
          element={<Page.TemplateSmartImageTitle />}
        />
        <Route
          path={PATH.TEMPLATE_SMART_SELECT_CELL}
          element={<Page.TemplateSmartSelectCell />}
        />
        <Route path={PATH.BRAND} element={<Page.BrandHome />} />
        <Route path={PATH.BRAND_CREATION} element={<Page.BrandCreation />} />

        <Route path={PATH.KAKAO_PROFILE} element={<Page.KakaoProfile />} />
      </Routes>
    </BrowserRouter>
  );
};

export default observer(App);
