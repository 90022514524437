import styled, { css } from "styled-components";

interface ButtonProps {
  label: JSX.Element | string;
  isPrimary?: boolean;
  isOutline?: boolean;
  disabled?: boolean;
  width?: number;
  onClick: () => void;
}
export const ButtonNormal = ({
  label,
  isPrimary,
  isOutline,
  disabled,
  onClick,
  width,
}: ButtonProps) => {
  return (
    <Button
      isPrimary={isPrimary}
      isOutline={isOutline}
      disabled={disabled}
      flexInherit={width ? true : false}
      style={{ width: width ? `${width}px` : "auto" }}
      onClick={onClick}
    >
      {label}
    </Button>
  );
};

const Button = styled.button<{
  isPrimary?: boolean;
  isOutline?: boolean;
  disabled?: boolean;
  flexInherit?: boolean;
}>`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -1px;
  color: #333333;
  border: 1px solid #333333;
  border-radius: 100px;
  padding: 14px;
  text-align: center;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  background-color: #ffffff;

  ${(props) =>
    props.isPrimary &&
    css`
      background: #1b58f1;
      border-color: #1b58f1;
      color: white;
    `};

  ${(props) =>
    props.isOutline &&
    css`
      border: 1px solid #1b58f1;
      color: #1b58f1;
    `};

  ${(props) =>
    props.disabled &&
    css`
      background: #e1e1e1;
      border-radius: 100px;
      border-color: #e1e1e1;
      color: white;
    `};

  ${(props) =>
    props.flexInherit &&
    css`
      flex: inherit !important;
    `};
`;
