import { AddressBookRequestType, AddressBookResponseType } from "types/types";
import { getHeaderWithToken, serverApi } from "./serverApi";
import { addParameterToURL } from "utils/addParameterToURL";

/**
 * 주소록 그룹 등록
 */
export const postAddressBookGroup = async (
  groupName: string
): Promise<{ created: boolean; message?: string; data?: any }> => {
  const body = {
    group_name: groupName,
  };

  return serverApi
    .post("/manage/books/group", body, getHeaderWithToken())
    .then((response: any) => {
      return {
        created: true,
        data: response.data,
      };
    })
    .catch((error) => {
      console.error(error);
      return {
        created: false,
        message: error.detail,
      };
    });
};

/**
 * 주소록 그룹 불러오기
 */
export const getAddressBookGroups = async () => {
  return serverApi
    .get("/manage/books/group", getHeaderWithToken())
    .then((response: any) => response.data)
    .catch((error) => {
      console.error(error);
    });
};

/**
 * 주소록 그룹 삭제하기
 */
export const deleteAddressBookGroup = async (groupId: number) => {
  return serverApi
    .delete(`/manage/books/group/${groupId}/detail`, getHeaderWithToken())
    .then((response: any) => response)
    .catch((error) => {
      console.error(error);
    });
};

/**
 * 주소록 불러오기
 */
export const getAddressList = async (
  groupId: number | null = null,
  page: number = 1,
  size: number = 10
): Promise<AddressBookResponseType> => {
  let url = "/manage/books/address";

  if (groupId) {
    url = addParameterToURL(
      "/manage/books/address",
      "group_id",
      groupId.toString()
    );
  }
  url = addParameterToURL(url, "page", page);
  url = addParameterToURL(url, "size", size);

  return serverApi
    .get(url, getHeaderWithToken())
    .then((response: any) => response.data);
};

/**
 * 주소록 추가하기
 */
export const postAddressBook = async (
  phone: string,
  username: string,
  groupId: number | null = null,
  memo: string = ""
): Promise<{ created: boolean; message?: string }> => {
  const body: AddressBookRequestType[] = [
    {
      name: username,
      memo: memo,
      phone_number: phone,
      group_id: groupId,
    },
  ];

  return serverApi
    .post("/manage/books/address", body, getHeaderWithToken())
    .then((response: any) => response.data)
    .catch((error) => {
      console.error(error);
    });
};

/**
 * 주소록 일괄 삭제하기
 */
export const deleteAddressBooks = async (
  bookIds: number[]
): Promise<{ deleted: boolean; message?: string }> => {
  const data = {
    num_list: bookIds,
  };

  return serverApi
    .delete("/manage/books/address", {
      ...getHeaderWithToken(),
      data,
    })
    .then(() => {
      return { deleted: true };
    })
    .catch((error) => {
      return { deleted: false, message: error.detail };
    });
};

/**
 * 주소록 일괄 삭제하기
 */
export const moveGroupAddressBooks = async (
  addressBooks: AddressBookRequestType[]
): Promise<{ moved: boolean; message?: string }> => {
  const data = {
    num_list: addressBooks,
  };

  return serverApi
    .put("/manage/books/address", data, getHeaderWithToken())
    .then(() => {
      return { moved: true };
    })
    .catch((error) => {
      return { moved: false, message: error.detail };
    });
};

/**
 * 주소록 일괄 삭제하기
 */
export const copyGroupAddressBooks = async (
  addressBooks: AddressBookRequestType[]
): Promise<{ moved: boolean; message?: string }> => {
  // TODO: API 수정 필요
  const data = {
    num_list: addressBooks,
  };

  return serverApi
    .post("/manage/books/address", data, getHeaderWithToken())
    .then(() => {
      return { moved: true };
    })
    .catch((error) => {
      return { moved: false, message: error.detail };
    });
};
/**
 * 주소록 일괄 수정하기
 */
export const updateAddressBooks = async (
  addressBooks: AddressBookRequestType[]
): Promise<{ modifed: boolean; message?: string }> => {
  const data = {
    num_list: addressBooks,
  };

  return serverApi
    .put("/manage/books/address", data, getHeaderWithToken())
    .then(() => {
      return { modifed: true };
    })
    .catch((error) => {
      return { modifed: false, message: error.detail };
    });
};

/**
 * 주소록 엑셀로 등록하기
 */
export const uploadAddressBookExcel = async (
  file: File
): Promise<{ modifed: boolean; message?: string }> => {
  const formData = new FormData();
  formData.append("file", file);
  return serverApi
    .post("/manage/books/bulk", formData, getHeaderWithToken())
    .then(() => {
      return { modifed: true };
    })
    .catch((error) => {
      return { modifed: false, message: error.detail };
    });
};

/**
 * 주소록 키워드로 검색하기
 */
export const searchAddressBookByKeyword = async (
  groupId: number,
  keyword: string
) => {
  let url = "/manage/books/search";
  const body = {
    group_id: groupId,
    keyword: keyword,
  };
  return serverApi
    .post(url, body, getHeaderWithToken())
    .then((response: any) => response.data);
};
