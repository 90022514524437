import { CallerType } from "types/types";
import styled from "styled-components";
import { CallerListItem } from "./SenderListItem";
import { Pagination } from "components/Pagination";
import { Loading } from "components/Icons";

interface Props {
  callers: CallerType[] | null;
  callerTotalCount: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  openRemoveCallerModal: (caller: CallerType) => void;
  openFailReasonModal: (caller: CallerType) => void;
}
export const CallerList = ({
  callers,
  callerTotalCount,
  currentPage,
  setCurrentPage,
  openRemoveCallerModal,
  openFailReasonModal,
}: Props) => {
  return (
    <Container>
      <TBody>
        <div>등록일자</div>
        <div>발신번호</div>
        <div>발신번호명</div>
        <div>상태</div>
        <div>관리</div>
      </TBody>

      {callers && callers.length > 0 ? (
        <div>
          {callers.map((caller) => (
            <CallerListItem
              key={`caller-${caller.id}`}
              caller={caller}
              openRemoveCallerModal={openRemoveCallerModal}
              openFailReasonModal={openFailReasonModal}
            />
          ))}
          <PaginationContainer>
            <Pagination
              size={callerTotalCount}
              defaultPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </PaginationContainer>
        </div>
      ) : (
        <>
          {callers ? (
            <div>
              <NoData>
                <div className="pb-16">등록된 발신번호가 없습니다</div>
              </NoData>
            </div>
          ) : (
            <LoadingContainer>
              <Loading width={50} height={50} />
            </LoadingContainer>
          )}
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  border-radius: 4px;
`;

const NoData = styled.div`
  text-align: center;
  padding: 78px 0px 280px;
  width: 100%;
  color: gray;
  border-bottom: solid 1px lightgray;
`;
const TBody = styled.div`
  display: flex;
  div {
    flex: 1;
    text-align: center;
    background-color: #f9f9f9;
    padding: 12px 0px;
    margin-left: -1px;

    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: -1px;

    color: #333333;
  }
`;

const LoadingContainer = styled.div`
  border-bottom: solid 1px lightgray;
  text-align: center;
  padding: 100px 40px;
  width: 100%;
`;

const PaginationContainer = styled.div`
  display: flex;
  padding: 24px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  div {
    letter-spacing: -1px;
    color: #999999;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
  }

  .active {
    color: var(--primary-color);
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: -1px;
    color: #1b58f1;
  }
`;
