import styled, { css } from "styled-components";

export const Container = styled.div`
  width: fit-content;
`;
export const Inner = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 32px 24px;
`;

export const PageTitle = styled.h3`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  letter-spacing: -1px;
  padding-bottom: 24px;

  color: #333333;
`;

export const Descriptions = styled.div`
  padding-bottom: 24px;
`;

export const Li = styled.div`
  padding-bottom: 8px;
  color: rgb(85, 85, 85);
  &::before {
    content: "•";
    padding: 0 8px;
  }
`;

export const Flex = styled.div`
  display: flex;
  gap: 50px;
`;
export const LeftSection = styled.div`
  width: 402px;
`;
export const RightSection = styled.div`
  width: 321px;
`;

export const Overlay = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  background-color: #ebebeb4a;
`;

export const Button = styled.div<{ active: boolean }>`
  background: #e1e1e1;
  margin-top: 8px;
  border-radius: 100px;
  text-align: center;
  padding: 16px;
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -1px;
  color: #ffffff;
  cursor: not-allowed;

  ${(props) =>
    props.active &&
    css`
      cursor: pointer;
      background-color: #1b58f1;
    `}
`;

export const ButtonOutline = styled(Button)`
  border: 0.5px solid #333333;
  color: #333333;
  background-color: #ffffff;
`;

export const SubTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -1px;
  color: #333333;
  padding-bottom: 16px;
`;
export const OptionContainer = styled.div`
  display: flex;
  gap: 10px;
  padding-bottom: 16px;
  cursor: pointer;
`;

export const OptionText = styled.div<{ active: boolean }>`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -1px;

  color: #666666;
  align-items: center;

  ${(props) =>
    props.active &&
    css`
      font-weight: 700;
      color: #333333;
      span {
        font-weight: 400;
        color: #999999;
      }
    `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 12px;
  div {
    flex: 1;
  }
`;
