// Next.js API route support: https://nextjs.org/docs/api-routes/introduction
import { addParameterToURL } from "utils/addParameterToURL";
import { getHeaderWithToken, serverApi } from "./serverApi";
import { CreateCallerRequestType } from "types/types";

/** 발신번호 등록 요청 */
export const createCaller = async (
  body: CreateCallerRequestType,
  options?: any
) => {
  const formData = new FormData();
  formData.append("phone_number", body.phone_number);
  if (body.imp_uid) {
    formData.append("imp_uid", String(body.imp_uid));
  }
  if (body.file_paper) {
    formData.append("file_paper", String(body.file_paper));
  }

  return serverApi
    .post("/manage/caller", formData, {
      ...getHeaderWithToken(),
      ...options,
    })
    .then((response: any) => response.data);
};

/** 발신번호 리스트 확인 */
export const fetchCallers = async ({
  page = 1,
  size = 10,
}: {
  page: number;
  size: number;
}) => {
  let url = "/manage/caller";
  url = addParameterToURL(url, "page", page);
  url = addParameterToURL(url, "size", size);
  return serverApi
    .get(url, getHeaderWithToken())
    .then((response: any) => response.data);
};

/** 발신번호 삭제 */
export const removeCaller = async (callerId: number) => {
  return serverApi
    .delete(`/manage/caller/${callerId}/detail`, getHeaderWithToken())
    .then((response: any) => response.data)
    .catch((error) => {
      console.error(error);
      return false;
    });
};

/** 발신번호 정보 수정 */
export const requestGetCallerDetail = async (callerId: number) => {
  return serverApi
    .get(`/manage/caller/${callerId}/detail`, getHeaderWithToken())
    .then((response: any) => response.data)
    .catch((error) => {
      console.error(error);
      return false;
    });
};

/** 발신번호 정보 수정 */
export const requestPutCallerDetail = async (
  callerId: number,
  body: {
    status: number;
    phone_number: string;
  }
) => {
  var bodyFormData = new FormData();
  bodyFormData.append("phone_number", body.phone_number);
  bodyFormData.append("status", String(body.status));
  return serverApi
    .put(`/manage/caller/${callerId}/detail`, getHeaderWithToken())
    .then((response: any) => response.data)
    .catch((error) => {
      console.error(error);

      return false;
    });
};
