import styled, { css } from "styled-components";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import { ImgFloscelleStamp } from "assets";
import { Close } from "components/Icons/Close";
import { ButtonNormal, Padding } from "components";
import { BillingType } from "types/types";
import {
  DATE_FORMAT_1,
  DATE_FORMAT_3,
  changeDateFormat,
  getCurrentTime,
} from "utils/datetime";
import { getPaymentTypeLabel } from "utils/getPaymentType";

import { useCallback, useContext } from "react";
import UserStore from "stores/UserStore";

interface Props {
  billing: BillingType;
  onClose: () => void;
}
export const ReceiptModal = ({ billing, onClose }: Props) => {
  const userStore = useContext(UserStore);
  const user = userStore.getUser();

  const handlePrint = useCallback(() => {
    window.print();
  }, []);

  return (
    <Rodal
      visible={true}
      showCloseButton={false}
      closeOnEsc={true}
      onClose={onClose}
      customStyles={{
        borderRadius: "24px",
        padding: "24px",
        maxHeight: "640px",
        height: "fit-content",
        width: "700px",
        overflowY: "auto",
      }}
    >
      <Container>
        <header>
          <Title>영수증</Title>
          <CloseButton onClick={onClose}>
            <Close width="30" height="30" />
          </CloseButton>
        </header>
        {/* 받는 이 */}
        <Receiver>{user.username} 귀하</Receiver>
        {/* 영수증 */}
        <TableTitle>공급자</TableTitle>
        <TableContainer>
          <Row>
            <TH>상호</TH>
            <TD>주식회사 플로셀</TD>
            <TH>성명</TH>
            <TD>박건, 이태환</TD>
          </Row>
          <Row>
            <TH>사업자등록번호</TH>
            <TD>266-88-01567</TD>
          </Row>
          <Row>
            <TH>업태</TH>
            <TD>서비스업 외</TD>
            <TH>종목</TH>
            <TD>광고대행업 외</TD>
          </Row>
          <Row>
            <TH>사업장 소재지</TH>
            <TD>266-88-01567</TD>
          </Row>
        </TableContainer>

        <Padding height="40px" />
        <TableTitle>공급내역</TableTitle>
        <TableContainer>
          <Row>
            <TH>결제날짜</TH>
            <TD>{getCurrentTime(DATE_FORMAT_1)}</TD>
          </Row>
          <Row>
            <TH>결제수단</TH>
            <TD>{getPaymentTypeLabel(billing.pay_method)}</TD>
          </Row>
          <Row>
            <TH>결제금액</TH>
            <TD>{billing.amount.toLocaleString()}</TD>
          </Row>
          <Row>
            <TD center>위 금액을 영수(청구)함</TD>
          </Row>
        </TableContainer>

        {/* 도장 및 충전 날짜 */}
        <IssueContainer>
          <img
            src={ImgFloscelleStamp}
            width={182}
            height={46}
            alt="플로셀 도장"
          />
          <IssueDate>
            {changeDateFormat(billing.created, DATE_FORMAT_3)} 충전함
          </IssueDate>
        </IssueContainer>

        <ButtonWrapper>
          <ButtonNormal
            label="인쇄하기"
            isPrimary
            onClick={handlePrint}
            width={650}
          />
        </ButtonWrapper>
      </Container>
    </Rodal>
  );
};

const Container = styled.div`
  header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 24px;
  }
`;

const CloseButton = styled.div`
  cursor: pointer;

  @media print {
    visibility: hidden;
  }
`;

const ButtonWrapper = styled.div`
  @media print {
    visibility: hidden;
  }
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 39px;
  letter-spacing: -1px;
  color: #333333;
`;

const Receiver = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  letter-spacing: -1px;
  color: #666666;
  padding-bottom: 20px;
`;

const TableContainer = styled.div`
  border-top: 0.5px solid #999999;
`;

const TableTitle = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -1px;

  color: #333333;
  padding-bottom: 16px;
`;
const Row = styled.div`
  display: flex;
  border-bottom: 0.5px solid #999999;
`;
const TD = styled.div<{ center?: boolean }>`
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;
  letter-spacing: -1px;
  color: #333333;
  padding: 12px 16px;
  flex: 1;
  ${(props) =>
    props.center &&
    css`
      justify-content: center;
    `};
`;

const TH = styled(TD)`
  width: 152px;
  flex: none;
  background: #f2f2f2;
`;

const IssueContainer = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 182px;
  }
`;
const IssueDate = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -1px;

  color: #666666;
`;
