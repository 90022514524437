import { Checkbox } from "components/Icons/Checkbox";
import { useState } from "react";
import styled, { css } from "styled-components";

interface Props {
  chargeAmount: number;
  onClickChargeButton: () => void;
}

export const Submit: React.FC<Props> = ({
  onClickChargeButton,
  chargeAmount,
}) => {
  const [agreeyPoliy1, setAgreeyPoliy1] = useState(false);
  const [agreeyPoliy2, setAgreeyPoliy2] = useState(false);

  const submitValidate = () => {
    return chargeAmount > 100 && agreeyPoliy1;
  };
  const _onClickChargeButton = () => {
    if (submitValidate()) {
      onClickChargeButton();
    }
  };
  const openPolicyLink = () => {
    const PolicyLink =
      "https://married-oak-0f8.notion.site/413c9b5aa21e4c38b5b3a72a82119449";
    window.open(PolicyLink);
  };

  return (
    <Container>
      <Condition onClick={() => setAgreeyPoliy1(!agreeyPoliy1)}>
        <CheckboxIcon>
          <Checkbox active={agreeyPoliy1} />
        </CheckboxIcon>
        <div>
          <EmPrimary>(필수)</EmPrimary> 본인은&nbsp;
          <Link onClick={openPolicyLink}>
            환불정책을 포함한 디지털 화폐 정책
          </Link>
          을 모두 확인하였으며, 구매에 동의합니다.
        </div>
      </Condition>
      <Condition onClick={() => setAgreeyPoliy2(!agreeyPoliy2)}>
        <CheckboxIcon>
          <Checkbox active={agreeyPoliy2} />
        </CheckboxIcon>
        <div>
          <Em>(선택)</Em> 본인은 최저가 적용 잔여 기간 안내를 포함한 혜택 및
          이벤트 마케팅 문자 수신 활용에 동의합니다.
        </div>
      </Condition>
      <SubmitButton active={submitValidate()} onClick={_onClickChargeButton}>
        충전하기
      </SubmitButton>
    </Container>
  );
};

const Container = styled.div`
  padding-top: 40px;
`;

const SubmitButton = styled.div<{ active: boolean }>`
  margin-top: 12px;

  ${(props) =>
    props.active
      ? css`
          background: #1b58f1;
        `
      : css`
          background: #e1e1e1;
        `}

  border-radius: 100px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -1px;
  width: 100%;
  text-align: center;
  padding: 16px;
  cursor: pointer;
  color: #ffffff;
`;

const Condition = styled.div`
  display: flex;
  gap: 10px;

  font-size: 16px;
  line-height: 24px;
  letter-spacing: -1px;
  color: #666666;
  padding-bottom: 12px;
  cursor: pointer;
`;

const CheckboxIcon = styled.div`
  width: 24px;
`;

const EmPrimary = styled.span`
  font-weight: 700;
  color: #1b58f1;
`;
const Em = styled.span`
  font-weight: 700;
  color: #333333;
`;

const Link = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;
