import styled from "styled-components";
import { ImgPricingMockSet2, ImgPricingMockSet3 } from "assets";
import { MEDIA_QUERY } from "properties/constant";
import { PricingSectionBottom, SectionTitle } from "./styled";
import { MessageCardText } from "./components/MessageCardText";
import { Pad } from "styles/styled";
import useResponsive from "hooks/useResponsive";

export const SmartMessagePricing = () => {
  const { isMobile } = useResponsive();
  return (
    <Container>
      <Content>
        <Inner>
          <Pad pb={60}>
            <SectionTitle>
              <b>스마트 비즈메시지 {isMobile && <br />}이용 요금</b>
            </SectionTitle>
          </Pad>
          <MockUp src={ImgPricingMockSet2} />
        </Inner>
        <SectionBottom>
          <MessageCardText
            type={"SMS"}
            limit={"100자 이내"}
            desc={<>액션버튼 최대 1개</>}
            origin={"15"}
            discounted={"10.5"}
            color={"#333333"}
          />
          <MessageCardText
            type={"LMS"}
            limit={"1,300자 이내"}
            desc={<>액션버튼 최대 3개</>}
            origin={"36"}
            discounted={"32"}
            color={"#1B58F1"}
          />
        </SectionBottom>
      </Content>
      <Line />
      <Content>
        <Inner>
          <MockUp src={ImgPricingMockSet3} />
        </Inner>
        <SectionBottom>
          <MessageCardText
            type={"MMS"}
            limit={"이미지/영상 포함, 1300자 이내"}
            desc={<>액션버튼 최대 2개</>}
            origin={"72"}
            discounted={"65"}
            color={"#652D90"}
          />
        </SectionBottom>
      </Content>
    </Container>
  );
};
const Container = styled.div``;

const MockUp = styled.img`
  width: 650px;
  height: 353px;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    display: none;
  }
`;
const Inner = styled.div`
  padding: 0px 20px 0px;
  text-align: center;
`;

const SectionBottom = styled(PricingSectionBottom)`
  padding: 0px 20px;
`;

const Content = styled.div`
  padding: 100px 20px;
`;

const Line = styled.div`
  width: 80%;
  margin: 0 auto;
  height: 1px;
  background-color: #999999;
`;
