import styled from "styled-components";

import { ImgPricingHeaderSwiper2 } from "assets";
import { MEDIA_QUERY } from "properties/constant";
import { RoundedButton, SectionTitle } from "../styled";
import { Flex, Pad } from "styles/styled";
import { ArrowRight } from "components/Icons";

export const HeaderCard2 = () => {
  return (
    <Container>
      <Inner>
        <div>
          <Pad pb={24}>
            <Title>
              깔끔하게 정리된 <br />
              <b>알림 메시지</b>
            </Title>
          </Pad>
          <Pad pb={42}>
            <Text>
              타이틀 자유형 테마로 가시성 뛰어난
              <br /> 메시지 템플릿을 만들 수 있습니다.
            </Text>
          </Pad>
          <ButtonContainer>
            <RoundedButton
              href={"https://forms.gle/zbg8HVnbSfn2GZf78"}
              target="_blank"
              rel="noreferrer"
            >
              <Flex gap={38}>
                <span>무료 상담받기</span> <ArrowRight fill={"#ffffff"} />
              </Flex>
            </RoundedButton>
          </ButtonContainer>
        </div>
        <Image src={ImgPricingHeaderSwiper2} alt="mockup" />
      </Inner>
    </Container>
  );
};

const Container = styled.section`
  color: #ffffff;
  padding: 54px 20px;
  height: 100%;
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;
const Inner = styled.div`
  max-width: 850px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${MEDIA_QUERY.MOBILE_S.MAX_WIDTH}px) {
    display: block;
  }
`;

const ButtonContainer = styled.div`
  @media (max-width: ${MEDIA_QUERY.MOBILE_S.MAX_WIDTH}px) {
    display: flex;
    justify-content: center;
  }
`;
const Title = styled(SectionTitle)`
  text-align: left;
  @media (max-width: ${MEDIA_QUERY.MOBILE_S.MAX_WIDTH}px) {
    text-align: center;
  }
`;
const Text = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -1px;
  color: #333333;
  text-align: left;
  @media (max-width: ${MEDIA_QUERY.MOBILE_S.MAX_WIDTH}px) {
    text-align: center;
  }
`;
const Image = styled.img`
  width: 362px;
  height: 461px;
  margin-right: 60px;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 200px;
    height: 245px;
    margin-right: 30px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE_S.MAX_WIDTH}px) {
    display: none;
  }
`;
