import { useEffect, useState } from "react";
import { Loading } from "components/Icons";
import {
  Li,
  Input,
  InputContainer,
  Label,
  Title,
  Descriptions,
  Button,
  LoadingIcon,
} from ".";
import { InnerContainer, Pad } from "styles/styled";
import { validateEmail } from "utils/email";

interface Props {
  loading: boolean;

  onSubmitSignIn: (password, email) => void;
}
export const PasswordConfirm = ({ onSubmitSignIn, loading }: Props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validateErrorReason, setValidateErrorReason] = useState("");

  useEffect(() => {
    /**
     * 로그인 유효성 검사
     */
    const validate = () => {
      setValidateErrorReason(null);
      if (!email) {
        setValidateErrorReason("이메일 주소를 입력해주세요");
      } else if (!validateEmail(email)) {
        setValidateErrorReason("이메일 형식을 확인해주세요");
      } else if (!password) {
        setValidateErrorReason("비밀번호를 입력해주세요");
      } else if (password.length < 6) {
        setValidateErrorReason("비밀번호를 6자 이상 입력해주세요");
      }
    };
    validate();
  }, [email, password]);

  const onClickSubmitButton = () => {
    if (validateErrorReason) {
      alert(validateErrorReason);
      return;
    }
    onSubmitSignIn(email, password);
  };

  const onEnterKeyDown = (e) => {
    if (e.key === "Enter") {
      onClickSubmitButton();
    }
  };

  return (
    <InnerContainer>
      <Pad pb={180}>
        <Pad pb={40}>
          <Title>비밀번호 확인</Title>
          <Descriptions>
            <Li>회원님의 개인정보를 보호하기 위해 비밀번호를 확인합니다.</Li>
          </Descriptions>
        </Pad>
        <InputContainer>
          <div>
            <Label>이메일</Label>
            <Input
              written={email.length > 0}
              type="text"
              placeholder="이메일을 입력해주세요"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <Label>비밀번호</Label>
          <Input
            written={password.length > 0}
            type="password"
            placeholder="비밀번호를 입력해주세요"
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={onEnterKeyDown}
          />
          <Pad pt={16}>
            <Button active={!validateErrorReason} onClick={onClickSubmitButton}>
              <span>확인</span>
              {loading && (
                <LoadingIcon>
                  <Loading />
                </LoadingIcon>
              )}
            </Button>
          </Pad>
        </InputContainer>
      </Pad>
    </InnerContainer>
  );
};
