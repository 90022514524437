import { ModalLayout } from "components";
import { useState } from "react";
import styled, { css } from "styled-components";
import { validatePhoneNumberOnly } from "utils/phone";
import * as session from "utils/session";

interface Props {
  onClose: () => void;
  onSendMessage: (phoneNumber: string) => void;
}
export const ModalTestSend: React.FC<Props> = ({ onClose, onSendMessage }) => {
  const testPhone = session.getTestPhone();
  const [testPhoneNumber, setTestPhoneNumber] = useState<string>(
    testPhone ? testPhone : ""
  );
  const validate = validatePhoneNumberOnly(testPhoneNumber);
  const onClickSendButton = () => {
    if (validate) {
      onSendMessage(testPhoneNumber);
      session.setTestPhone(testPhoneNumber);
    }
  };

  return (
    <ModalLayout title="테스트 전송하기" onClose={onClose}>
      <Container>
        <Text>테스트 전송에도 전송 포인트가 차감됩니다.</Text>
        <Input
          placeholder={`휴대폰 번호를 입력해주세요. (숫자만)`}
          onChange={(e) => setTestPhoneNumber(e.target.value)}
          value={testPhoneNumber}
        />
        <Button onClick={onClickSendButton} active={validate}>
          전송하기
        </Button>
      </Container>
    </ModalLayout>
  );
};

const Container = styled.div``;

const Input = styled.input`
  background: #ffffff;
  border: 1px solid #d7dbe2;
  border-radius: 6px;
  border: 1px solid #d7dbe2;
  width: 100%;
  padding: 16px;
  margin-bottom: 24px;
`;

const Text = styled.div`
  padding-top: 36px;
  padding-bottom: 16px;

  color: rgb(102, 102, 102);
  line-height: 24px;
  font-size: 16px;
`;

const Button = styled.div<{ active: boolean }>`
  background: #e1e1e1;
  margin-top: 8px;
  border-radius: 100px;
  text-align: center;
  padding: 16px;
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -1px;
  color: #ffffff;
  cursor: not-allowed;

  ${(props) =>
    props.active &&
    css`
      cursor: pointer;
      background-color: #1b58f1;
    `}
`;
