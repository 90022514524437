import { useEffect, useState } from "react";
import styled, { css } from "styled-components";

import { MessageLayout } from "components";
import { fetchMessageResultDetail } from "apis/messageApi";
import { ResultDetailType, SendResultDetailType } from "types/types";
import { changeDateFormat, DATETIME_FORMAT_1 } from "utils/datetime";
import { getSendResultDetailStatistics } from "utils/statistics";
import { StatListItem } from "./StatListItem";
import { StatisticListItem } from "./StatisticListItem";
import { Loading } from "components/Icons";
import { Pad, Center } from "styles/styled";
import { MessageText } from "./MessageText";
import { ResultReason } from "./ResultReason";
import { Receivers } from "./Receivers";
import { useCustomNavigate } from "hooks/useCustomNavigate";
import { PATH } from "properties/menu";
import { getPrevPath } from "utils/session";

interface Props {
  type: "RESERVED" | "SEND";
}
export const MessageSendResultDetail: React.FC<Props> = ({ type }) => {
  const navigate = useCustomNavigate();
  const { groupId } = navigate.query;
  const [sendResult, setSendResult] = useState<SendResultDetailType | null>(
    null
  );
  const [activeTab, setActiveTab] = useState<"message" | "result" | "receiver">(
    "message"
  );
  const [filteredReceivers, setFilteredReceivers] = useState<
    ResultDetailType[]
  >([]);

  useEffect(() => {
    if (groupId) {
      fetchResultDetail();
    }
  }, [groupId]);

  /**
   * 발송결과 상세조회
   */
  const fetchResultDetail = async () => {
    const response: SendResultDetailType = await fetchMessageResultDetail(
      String(groupId)
    );
    setSendResult(response);
    setFilteredReceivers(response.detail);
  };

  // 발송일자

  const createdDate = sendResult
    ? changeDateFormat(sendResult.group.created, DATETIME_FORMAT_1)
    : "2000.01.01 00:00 ";

  const reservedDate =
    sendResult && type === "RESERVED"
      ? changeDateFormat(sendResult.group.reserved_date, DATETIME_FORMAT_1)
      : null;

  const onSearchPhone = (phone: string) => {
    const result = sendResult!.detail.filter((item) =>
      item.receiver.includes(phone)
    );
    setFilteredReceivers(result);
  };
  // 총 발송건수 통계
  const totalStats = getSendResultDetailStatistics(
    sendResult ? sendResult.group.status_info : null
  );

  const onClickPrev = () => {
    const prevPath = getPrevPath();
    if (prevPath) {
      navigate.moveTo(prevPath);
    } else if (navigate.pathname.includes(PATH.MESSAGE_SEND_RESULT)) {
      navigate.moveTo(PATH.MESSAGE_SEND_RESULT);
    } else {
      navigate.moveTo(PATH.MESSAGE_RESERVED_RESULT);
    }
  };
  return (
    <MessageLayout>
      <Container>
        <Header>
          <SubTitle>
            {type === "SEND" ? "발송결과 상세보기" : "예약결과 상세보기"}
          </SubTitle>
          <FlexEnd>
            <Text>
              {type === "SEND" ? "발송일자" : "생성 일자"} : {createdDate}
            </Text>
            {reservedDate && <Text>예약 발송 일자 : {reservedDate}</Text>}
          </FlexEnd>
        </Header>
        {/* 총 발송건수 통계 */}
        <StatTitleContainer>
          <StatListItem
            name="총 발생건수"
            value={totalStats.totalCount}
            unit="건"
          />
          <StatListItem name="성공" value={totalStats.successRatio} />
          <StatListItem name="실패" value={totalStats.failureRatio} />
          <StatListItem name="대기" value={totalStats.waitRatio} />
        </StatTitleContainer>
        {/* 타입별 발송 건수 */}
        {sendResult && (
          <StatResultContainer>
            <StatisticListItem
              name={sendResult.group.msg_type}
              stats={sendResult.group.status_info}
            />
          </StatResultContainer>
        )}
        {/* 상세내용 */}
        {sendResult ? (
          <>
            <TabContainer>
              <TabItem
                onClick={() => setActiveTab("message")}
                active={activeTab === "message"}
              >
                메시지 내용
              </TabItem>
              <TabItem
                onClick={() => setActiveTab("result")}
                active={activeTab === "result"}
              >
                결과
              </TabItem>
              <TabItem
                onClick={() => setActiveTab("receiver")}
                active={activeTab === "receiver"}
              >
                수신자 번호
              </TabItem>
            </TabContainer>
            <DetailContainer>
              {activeTab === "message" && (
                <Pad pt={24} pb={28}>
                  <MessageText group={sendResult.group} />
                </Pad>
              )}
              {activeTab === "result" && (
                <Pad pt={40} pb={28}>
                  <ResultReason group={sendResult.group} />
                </Pad>
              )}
              {activeTab === "receiver" && (
                <Pad pt={40} pb={28}>
                  <Receivers
                    receivers={filteredReceivers}
                    onSearchPhone={onSearchPhone}
                  />
                </Pad>
              )}
            </DetailContainer>
          </>
        ) : (
          <Pad p={24}>
            <Loading />
          </Pad>
        )}

        <Center>
          <ButtonPrimary onClick={onClickPrev}>목록으로</ButtonPrimary>
        </Center>
      </Container>
    </MessageLayout>
  );
};

const SubTitle = styled.h4`
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  letter-spacing: -1px;
  color: #333333;
  padding-bottom: 16px;
`;

const Container = styled.div`
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 40px 24px;
`;

const Header = styled.div`
  padding-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Text = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -1px;

  color: #666666;
`;
const StatResultContainer = styled.div`
  padding: 12px 0px 40px;
`;
const StatTitleContainer = styled.div`
  display: flex;
  gap: 8px;

  div {
    width: 112px;
  }
  div:nth-child(1) {
    flex: 1;
    color: #333333;
    background: rgba(51, 51, 51, 0.08);
  }

  div:nth-child(2) {
    color: #1b58f1;
    background: rgba(27, 88, 241, 0.1);
  }

  div:nth-child(3) {
    background: rgba(238, 64, 54, 0.1);
    color: #ec1c24;
  }

  div:nth-child(4) {
    background: rgba(250, 175, 64, 0.1);
    color: #faaf40;
  }

  b {
    padding-right: 4px;
  }
  label {
    font-weight: 700;
  }
`;

const TabContainer = styled.div`
  display: flex;
  gap: 8px;
`;
const TabItem = styled.button<{ active?: boolean }>`
  background: #f4f4f4;
  cursor: pointer;
  flex: 1;
  padding: 10px;
  text-align: center;
  border: 0.5px solid #d7dbe2;
  border-radius: 6px;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -1px;
  color: #666666;

  ${(props) =>
    props.active &&
    css`
      background: rgba(27, 88, 241, 0.1);
      border: 1px solid #1b58f1;
      color: #1b58f1;
      font-weight: 700;
    `}
`;

const ButtonPrimary = styled.button`
  border-radius: 100px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  padding: 8px 36px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -1px;
  color: #ffffff;
  background: #1b58f1;
  border-radius: 100px;
  cursor: pointer;
`;

const DetailContainer = styled.div`
  min-height: 430px;
`;

const FlexEnd = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
