import styled from "styled-components";
import { observer } from "mobx-react-lite";

import { MenuBar } from "../../MenuBar";

interface Props {
  children: React.ReactNode;
  title?: string;
  minWidth?: number;
  maxWidth?: number;
}
export const MessageLayout = observer(
  ({ children, title, minWidth, maxWidth }: Props) => {
    return (
      <Container>
        <MenuBar />
        <Content>
          {title && <LayoutTitle>{title}</LayoutTitle>}
          <Inner minWidth={minWidth} maxWidth={maxWidth}>
            {children}
          </Inner>
        </Content>
      </Container>
    );
  }
);

const LayoutTitle = styled.h3`
  color: #333;
  font-size: 32px;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -1px;
  padding-bottom: 38px;
`;
const Inner = styled.div<{ minWidth?: number; maxWidth?: number }>`
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.06) 0px 4px 8px;
  border-radius: 10px;
  min-width: ${(props) => (props.minWidth ? props.minWidth + "px" : "820px")};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth + "px" : "960px")};
`;
const Container = styled.div`
  background-color: #f6f8ff;
  min-height: 100vh;

  @media print {
    min-height: auto;
  }
`;

const Content = styled.div`
  margin-left: 240px;
  overflow-x: auto;
  padding: 48px 40px;
`;

export const MessageLayoutInner = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding-bottom: 40px;
`;

export const Li = styled.li`
  padding-left: 13px;
  text-indent: -13px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -1px;
  color: #666666;

  &::before {
    content: "• ";
    padding-right: 4px;
  }
`;
