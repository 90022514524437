import { ResultGroupType } from "types/types";
import { changeDateFormat, DATETIME_FORMAT_1 } from "utils/datetime";
import styled, { css } from "styled-components";
import { useCustomNavigate } from "hooks/useCustomNavigate";
import { PATH } from "properties/menu";
import { Loading } from "components/Icons";

interface Props {
  groups: ResultGroupType[] | null;
  setTargetGroup: (group: ResultGroupType) => void;
  setVisibleCancelReservationModal: (visible: boolean) => void;
}

export const MessageReservedGroup = ({
  groups,
  setTargetGroup,
  setVisibleCancelReservationModal,
}: Props) => {
  const navigate = useCustomNavigate();
  const onClickCancelButton = (e: any, group: ResultGroupType) => {
    e.preventDefault();
    setTargetGroup(group);
    setVisibleCancelReservationModal(true);
  };
  return (
    <Container>
      <TBody>
        <TR>요청일시</TR>
        <TR>발송예약일시</TR>
        <TR>제목</TR>
        <TR>메시지 타입</TR>
        <TR>전송예정건수</TR>
        <TR>예약취소</TR>
      </TBody>

      {groups && groups.length > 0 ? (
        groups.map((group) => (
          <TRow key={`m-result-${group.id}`}>
            <TD>{changeDateFormat(group.modified, DATETIME_FORMAT_1)}</TD>
            <TD>{changeDateFormat(group.reserved_date!, DATETIME_FORMAT_1)}</TD>
            <TD
              hasLink={true}
              onClick={() =>
                navigate.moveTo(
                  PATH.MESSAGE_RESERVED_RESULT_DETAIL.replace(
                    ":groupId",
                    group.group_id.toString()
                  )
                )
              }
            >
              {group.title}
            </TD>
            <TD>{group.msg_type}</TD>
            <TD>{group.status_info.total_count}</TD>
            <TD>
              <CancelButton onClick={(e) => onClickCancelButton(e, group)}>
                취소
              </CancelButton>
            </TD>
          </TRow>
        ))
      ) : (
        <>
          {groups ? (
            <NoData>예약 내역이 없습니다.</NoData>
          ) : (
            <LoadingContainer>
              <Loading width={50} height={50} />
            </LoadingContainer>
          )}
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  border-radius: 4px;
  min-width: 700px;
`;

const TBody = styled.div`
  display: flex;
  padding-left: 12px;
  background: #fafafa;
`;

const TR = styled.div`
  flex: 1;
  text-align: center;
  padding: 12px;
  margin-left: -1px;

  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -1px;
  color: #333333;
`;

const NoData = styled.div`
  text-align: center;
  padding: 40px;
  width: 100%;
  color: gray;
`;

const TRow = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  padding-left: 12px;
`;

const TD = styled.div<{ hasLink?: boolean; fontSize?: number }>`
  flex: 1;
  text-align: center;
  padding: 4px;
  font-size: 14px;
  font-size: ${(props) => (props.fontSize ? props.fontSize : 16)}px;

  margin-left: -1px;
  margin-top: -1px;
  cursor: pointer;

  &:last-child {
    border-width: 0px;
  }

  ${(props) =>
    props.hasLink &&
    css`
      text-decoration-line: underline;
      color: #1b58f1;
    `}
`;

const LoadingContainer = styled.div`
  text-align: center;
  padding: 40px;
  width: 100%;
`;

const CancelButton = styled.button`
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -1px;
  text-decoration-line: underline;
  color: #ec1c24;
  background-color: #ffffff;
  cursor: pointer;
`;
