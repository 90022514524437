import InputText from "components/InputText";
import SelectBasic from "components/SelectBasic";
import { useState } from "react";
import styled from "styled-components";
import fonts from "styles/fonts";

const EmailDomainList = [
  {
    value: "etc",
    text: "직접입력",
  },
  {
    value: "gmail",
    text: "gmail.com",
  },
  {
    value: "kakao",
    text: "kakao.com",
  },
  {
    value: "naver",
    text: "naver.com",
  },
  {
    value: "daum",
    text: "daum.net",
  },
  {
    value: "nate",
    text: "nate.com",
  },
];

const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;

  > span {
    display: inline-block;
    margin: 0 8px;
  }

  select {
    flex: 1;
    height: 100%;
    padding: 16px;
    ${fonts.Body1};
  }
`;
const InputTextDisabled = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 17px 16px 16px;
  border-radius: 6px;
  border: 1px solid rgb(215, 219, 226);
  background: rgb(255, 255, 255);
  box-sizing: border-box;
  letter-spacing: -1px;
  background-color: #fafafa;
`;

const InputWrapper = styled.div`
  flex: 1;
  height: 100%;
  margin-right: 8px;
`;

interface IProps {
  onChangeText: (target: HTMLInputElement) => void;
  value: string;
  text?: string;
  onSelect: (value: string) => void;
}

const InputEmail = ({ onChangeText, text, onSelect, value }: IProps) => {
  const [isDirectInput, setIsDirectInput] = useState(false);
  const onHandleChangeSelectbox = (target: HTMLSelectElement) => {
    const domain = target.selectedOptions[0].innerHTML;

    if (domain === "직접입력") {
      setIsDirectInput(true);
      onSelect("");
    } else {
      setIsDirectInput(false);
      onSelect(domain);
    }
  };

  const onHandleChangeInputText = (target: HTMLInputElement) => {
    onChangeText(target);
  };

  return (
    <Container>
      <span>@</span>

      <InputWrapper>
        {isDirectInput ? (
          <InputText
            value={text}
            onChange={onHandleChangeInputText}
            bgColor={text !== "" ? "white" : "#FAFAFA"}
          />
        ) : (
          <InputTextDisabled>{value}</InputTextDisabled>
        )}
      </InputWrapper>
      <SelectBasic
        onChange={(target) => onHandleChangeSelectbox(target)}
        value={value}
        options={EmailDomainList}
      />
    </Container>
  );
};

export default InputEmail;
