import styled from "styled-components";

export const Container = styled.div`
  border-radius: 4px;
  min-width: 700px;
`;

export const LoadingContainer = styled.div`
  text-align: center;
  padding: 40px;
  width: 100%;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
`;
