import { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import {
  CallerType,
  CreatePlainTemplateType,
  TemplateType,
  UpdatePlainTemplateType,
} from "types/types";

import * as S from "./styled";
import { Flex, Pad } from "styles/styled";
import {
  AlertModal,
  ConfirmModal,
  InputWithLabel,
  Line,
  MessageLayout,
} from "components";
import { CallerSelection } from "pages/message/MessageSend/components/CallerSection";
import { MessageEditor } from "pages/message/MessageSend/components/MessageEditor";
import { useCustomNavigate } from "hooks/useCustomNavigate";
import { FileUpload } from "pages/message/MessageSend/components/FileUpload";
import { MessageMockUp } from "components/MessageMockUp";
import { PATH } from "properties/menu";

import TemplateStore from "stores/TemplateStore";
import CallerStore from "stores/CallerStore";
import MessageStore from "stores/MessageStore";
import { useParams } from "react-router";
import { FullScreenLoading } from "components/FullScreenLoading";
import { set } from "mobx";

interface Props {
  isUpdate: boolean;
}
export const TemplateDetailPlain = observer(({ isUpdate }: Props) => {
  const navigate = useCustomNavigate();
  const params = useParams();
  const callerStore = useContext(CallerStore);
  const templateStore = useContext(TemplateStore);
  const messageStore = useContext(MessageStore);

  const [templateName, setTemplateName] = useState<string>("");

  // 메시지 작성
  const [imageSrc1, setImageSrc1] = useState<string | null>(null);
  const [imageSrc2, setImageSrc2] = useState<string | null>(null);
  const [imageSrc3, setImageSrc3] = useState<string | null>(null);

  const [imageFiles, setImageFiles] = useState<FileList | null>(null);

  // alert modal
  const [alertText, setAlertText] = useState("");

  // confirm modal
  const [confirmText, setConfirmText] = useState({
    content: "",
    confirmButtonText: "",
    closeButtonText: "",
  });
  const [visibleConfirmMddal, setVisibleConfirmModal] = useState(false);

  const [warningText, setWariningText] = useState<string | null>();
  const [currentActionType, setCurrentActionType] = useState<
    "create" | "update" | "remove" | "fail"
  >();

  useEffect(() => {
    if (templateName.length <= 0) {
      setWariningText("템플릿명을 입력해주세요.");
    } else if (!callerStore.targetCaller) {
      setWariningText("발신 번호를 선택해주세요.");
    } else if (messageStore.targetTitle.length === 0) {
      setWariningText("메시지 제목을 업력해주세요.");
    } else if (messageStore.targetContent.length === 0) {
      setWariningText("메시지 내용을 업력해주세요.");
    } else {
      setWariningText(null);
    }
  }, [
    templateName,
    callerStore.targetCaller,
    messageStore.targetTitle,
    messageStore.targetContent,
  ]);

  useEffect(() => {
    if (isUpdate) {
      const templateId = Number(params.templateId);
      fetchTemplateDetail(templateId);
    }
    fetchCallers();
    return () => {
      resetTemplate();
    };
  }, []);

  const resetTemplate = () => {
    messageStore.resetMessage();
    templateStore.resetTemplate();
    setTemplateName("");
  };

  const fetchCallers = async () => {
    await callerStore.fetchCallers();
    if (callerStore.targetCaller) {
      messageStore.setTargetCaller(callerStore.targetCaller);
    }
  };

  const addImageFile = (file: FileList | null) => {
    setImageFiles(file);
    messageStore.setHasImages(true);
  };
  /** 변경 모드인 경우,  템플릿 상세정보 불러오기 */
  const fetchTemplateDetail = async (templateId) => {
    await templateStore.fetchTemplateDetail(templateId);
    const template: TemplateType = templateStore.targetTemplate;

    setTemplateName(template.template_name);
    messageStore.updateMessageTitle(template.title);
    messageStore.updateMessageContent(template.content);
    messageStore.setIsAd(template.is_ad);
  };

  // 이미지 업로드 시, 미리보기
  useEffect(() => {
    setImageSrc1(null);
    setImageSrc2(null);
    setImageSrc3(null);

    if (imageFiles && imageFiles.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(imageFiles[0]);
      reader.onload = () => {
        setImageSrc1(reader.result as string);
      };
    }
    if (imageFiles && imageFiles.length > 1) {
      const reader = new FileReader();
      reader.readAsDataURL(imageFiles[1]);
      reader.onload = () => {
        setImageSrc2(reader.result as string);
      };
    }
    if (imageFiles && imageFiles.length > 2) {
      const reader = new FileReader();
      reader.readAsDataURL(imageFiles[2]);
      reader.onload = () => {
        setImageSrc3(reader.result as string);
      };
    }
  }, [imageFiles]);

  const moveToTemplateList = () => {
    navigate.moveTo(PATH.TEMPLATE_LIST_PLAIN);
  };
  const onUpdateTemplate = async () => {
    if (warningText) {
      setCurrentActionType("fail");
      setAlertText(warningText);
      return;
    }
    const messageTypeIndex =
      messageStore.targetMessageType === "SMS"
        ? 1
        : messageStore.targetMessageType === "LMS"
        ? 2
        : 3;

    const requestParams: UpdatePlainTemplateType = {
      template_name: templateName,
      title: messageStore.targetTitle,
      content: messageStore.targetContent,
      call_number: callerStore.targetCaller?.phone_number,
      msg_type: messageTypeIndex,
      imageFiles: imageFiles,
      is_ad: messageStore.isAd,
    };

    const isUpdated = await templateStore.updatePlainTemplate(
      Number(params.templateId),
      requestParams
    );
    if (isUpdated) {
      setConfirmText({
        content: "템플릿 변경 사항이 저장되었습니다.",
        confirmButtonText: "목록으로",
        closeButtonText: "닫기",
      });
      setVisibleConfirmModal(true);
      setCurrentActionType("update");
    } else {
      setAlertText("템플릿 저장에 실패했습니다. 다시 시도해주세요.");
      setCurrentActionType("fail");
    }
  };

  const onCreateTemplate = async () => {
    if (warningText) {
      setCurrentActionType("fail");
      setAlertText(warningText);
      return;
    }
    const messageTypeIndex =
      messageStore.targetMessageType === "SMS"
        ? 1
        : messageStore.targetMessageType === "LMS"
        ? 2
        : 3;

    const params: CreatePlainTemplateType = {
      template_name: templateName,
      title: messageStore.targetTitle,
      content: messageStore.targetContent,
      call_number: callerStore.targetCaller?.phone_number,
      msg_type: messageTypeIndex,
      imageFiles: imageFiles,
      is_ad: messageStore.isAd,
    };

    const isCreated = await templateStore.createPlainTemplate(params);

    if (isCreated) {
      setAlertText("템플릿이 생성되었습니다.");
      setCurrentActionType("create");
    } else {
      setAlertText("템플릿 생성에 실패했습니다.");
      setCurrentActionType("fail");
    }
  };

  /** Confirm 모달의 확인 버튼 클릭 이벤트*/
  const onConfirmModal = () => {
    setVisibleConfirmModal(false);
    if (currentActionType === "remove") {
      onRemoveTemplate();
    } else {
      navigate.moveTo(PATH.TEMPLATE_LIST_PLAIN);
      templateStore.resetTemplate();
    }
  };

  /** 확인 버튼 클릭 이벤트 : 삭제 모달이 켜짐 */
  const openRemoveConfirmModal = () => {
    setCurrentActionType("remove");
    setConfirmText({
      content: "템플릿을 삭제하시겠습니까?",
      confirmButtonText: "삭제",
      closeButtonText: "취소",
    });
    setVisibleConfirmModal(true);
  };

  /** 템플릭 삭제 로직  */
  const onRemoveTemplate = async () => {
    setCurrentActionType("remove");
    const isRemoved = await templateStore.removePlainTemplate(
      Number(params.templateId)
    );
    if (isRemoved) {
      setAlertText("템플릿이 삭제되었습니다.");
      setCurrentActionType("remove");
    } else {
      setAlertText("템플릿 삭제에 실패했습니다.");
      setCurrentActionType("fail");
    }
  };

  const onCloseAlertModal = () => {
    setAlertText(null);
    if (currentActionType !== "fail") {
      navigate.moveTo(PATH.TEMPLATE_LIST_PLAIN);
      templateStore.resetTemplate();
    }
  };

  return (
    <MessageLayout
      title={isUpdate ? "템플릿 수정/삭제" : "일반 문자 템플릿 추가"}
    >
      <S.Container>
        {isUpdate
          ? !templateStore.targetTemplate && <FullScreenLoading />
          : null}
        <header>
          <Pad pb={24}>
            <h3>일반 문자 템플릿</h3>
          </Pad>
          <Pad pb={50}>
            <ul>
              <li>일반 문자 템플릿 양식입니다.</li>
            </ul>
          </Pad>
          <Pad pb={20}>
            <InputWithLabel
              label={"템플릿명 입력"}
              placeholder={"템플릿명을 입력해주세요."}
              value={templateName}
              isLabelBold={true}
              isGreyBg={true}
              onInputChange={setTemplateName}
            />
          </Pad>
        </header>
        <Line margin="40px 0px" />

        <main>
          <Flex gap={16}>
            <S.LeftSection>
              <CallerSelection
                callers={callerStore.approvedCallers}
                targetCaller={callerStore.targetCaller}
                setTargetCaller={callerStore.setTargetCaller}
              />

              {/* -------- 메시지 내용 입력  --------*/}
              <Pad pb={40}>
                <MessageEditor />
              </Pad>
              {/* -------- 이미지 업로드 -------*/}

              <FileUpload
                imageFiles={imageFiles}
                setImageFiles={addImageFile}
              />
              <Line margin="40px 0px" />

              <S.ButtonContainer>
                {isUpdate && (
                  <S.Button
                    bgColor="#EC1C24"
                    onClick={openRemoveConfirmModal}
                    active={true}
                  >
                    삭제
                  </S.Button>
                )}
                <S.ButtonBlack onClick={moveToTemplateList} active={true}>
                  템플릿 목록
                </S.ButtonBlack>
                {isUpdate ? (
                  <S.Button onClick={onUpdateTemplate} active={!warningText}>
                    저장하기
                  </S.Button>
                ) : (
                  <S.Button onClick={onCreateTemplate} active={!warningText}>
                    저장하기
                  </S.Button>
                )}
              </S.ButtonContainer>
            </S.LeftSection>

            <S.RightSection>
              <MessageMockUp
                imageSrc1={imageSrc1}
                imageSrc2={imageSrc2}
                imageSrc3={imageSrc3}
              />
            </S.RightSection>
          </Flex>
        </main>
        {/* -------- 확인 모달 --------*/}
        {visibleConfirmMddal && (
          <ConfirmModal
            title={"알림"}
            confirmButtonText={confirmText.confirmButtonText}
            closeButtonText={confirmText.closeButtonText}
            closeModal={() => setVisibleConfirmModal(false)}
            onConfirm={onConfirmModal}
          >
            <>{confirmText.content}</>
          </ConfirmModal>
        )}
        {/* ------- 삭제 모달 --------*/}
        {alertText && (
          <AlertModal
            title={"알림"}
            confirmButtonText={"확인"}
            closeModal={onCloseAlertModal}
          >
            <>{alertText}</>
          </AlertModal>
        )}
      </S.Container>
    </MessageLayout>
  );
});
