import { useContext, useEffect, useState } from "react";

import styled, { css } from "styled-components";
import { observer } from "mobx-react-lite";
import UserStore from "stores/UserStore";
import { MessageLayout } from "components";
import { PasswordConfirm } from "./PasswordConfirm";
import { ChangeProfileInfo } from "./ChangeProfileInfo";
import * as session from "utils/session";

export const ChangeProfile = observer(() => {
  const userStore = useContext(UserStore);
  const [loading, setLoading] = useState(true);
  const [pageStep, setPageStep] = useState<number>(1);

  useEffect(() => {
    loadUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadUser = async () => {
    const loggined = session.getIsLoggined();
    if (loggined) {
      setPageStep(2);
    }
    await userStore.fetchUserInfo();
    setLoading(false);
  };
  /** 로그인 요청 */
  const onSubmitSignIn = async (email: string, password: string) => {
    if (loading) {
      return;
    }

    setLoading(true);
    const loggined = await userStore.signIn(email, password, false);
    if (loggined) {
      setPageStep(2);
      session.setIsLoggined(true);
    } else {
      alert("아이디와 비밀번호를 확인해주세요.");
    }
    setLoading(false);
  };

  return (
    <MessageLayout title="회원 정보변경" maxWidth={820}>
      <Container>
        {pageStep === 1 && (
          <PasswordConfirm loading={loading} onSubmitSignIn={onSubmitSignIn} />
        )}
        {pageStep === 2 && (
          <ChangeProfileInfo loading={loading} user={userStore.getUser()} />
        )}

        {/* 회원 정보 */}
      </Container>
    </MessageLayout>
  );
});

const Container = styled.div``;
export const Descriptions = styled.div`
  padding-bottom: 24px;
`;

export const Li = styled.div`
  padding-bottom: 8px;
  color: rgb(85, 85, 85);
  &::before {
    content: "•";
    padding: 0 8px;
  }
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;

  letter-spacing: -1px;

  color: #333333;
  padding-bottom: 16px;
`;
export const Label = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -1px;
  color: #666666;
  padding-bottom: 8px;
`;

export const InputContainer = styled.div`
  margin: 0 auto;
  padding-bottom: 20px;
  max-width: 380px;
  min-width: 320px;
`;

export const Input = styled.input<{ written?: boolean }>`
  border: 1px solid #999999;
  border-radius: 4px;
  padding: 12px 16px;
  height: 44px;
  width: 100%;
  margin-bottom: 24px;
  font-size: 16px;
  &::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -1px;
    color: #999999;
  }

  ${(props) =>
    props.written &&
    css`
      border: 1px solid #333333;
    `}
`;

export const Button = styled.div<{ active: boolean }>`
  border-radius: 100px;
  width: 100%;
  margin: 0 auto;
  cursor: pointer;
  display: flex;

  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -1px;

  background: ${(props) => (props.active ? "#1b58f1" : "#e1e1e1")};

  color: #ffffff;
  height: 100%;
  width: 100%;
  padding: 16px;
  position: relative;
`;

export const LoadingIcon = styled.div`
  position: absolute;
  top: 10px;
  left: 56%;
`;
