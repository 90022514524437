import styled from "styled-components";
import { changeDateFormat, DATE_FORMAT_2 } from "utils/datetime";

interface Props {
  date: string;
  setDate: (date: string) => void;
  minDate?: string;
  maxDate?: string;
  width?: string;
  height?: string;
}
export const InputDate: React.FC<Props> = ({
  date,
  setDate,
  minDate,
  maxDate,
  width,
  height,
}) => {
  const min = minDate ? changeDateFormat(minDate, DATE_FORMAT_2) : "";
  const max = maxDate ? changeDateFormat(maxDate, DATE_FORMAT_2) : "";

  return (
    <Container height={height} width={width}>
      <input
        type="date"
        defaultValue={date}
        min={min}
        max={max}
        onChange={(e) => setDate(e.target.value)}
      />
    </Container>
  );
};

const Container = styled.div<{ height?: string; width?: string }>`
  border: 1px solid #333333;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 12px;
  ${(props) => props.height && `height: ${props.height};`}
  width: ${(props) => (props.width ? props.width : "fit-content")};

  input:focus-visible {
    outline: none;
  }
  input[type="date"] {
    margin-left: 10px;
    padding-left: 24px;
    width: 123px;
    position: relative;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -1px;
    color: #333333;
    span {
      padding-left: 45px;
    }
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    left: 0;
    top: 4px;
    bottom: 0;
    z-index: 1;
    cursor: pointer;
  }
`;
