// 이메일 정규표현식 체크
export const validatePassword = (password: string): boolean => {
  // 대문자 포함
  const hasUpperCase = /[A-Z]/.test(password);
  // 소문자 포함
  const hasLowerCase = /[a-z]/.test(password);
  // 특수문자 포함 (이 예에서는 !@#$%^&*()-+를 특수문자로 가정)
  const hasSpecialCharacter = /[!@#$%^&*()\-+]/.test(password);
  // 길이는 6자리 이상
  const isAtLeastSixChars = password.length >= 6;

  return (
    hasUpperCase && hasLowerCase && hasSpecialCharacter && isAtLeastSixChars
  );
};
