import styled from "styled-components";
import { StatisticsType } from "types/types";
import { getTotalStatistic } from "utils/statistics";
import { StatisticListItem } from "./StatisticListItem";

interface Props {
  sendStatistics: StatisticsType | null;
}
export const SendResult: React.FC<Props> = ({ sendStatistics }) => {
  const statistics = getTotalStatistic(sendStatistics);

  return (
    <div>
      <Container>
        <Title>이번달 발송결과</Title>
        <StatContainer>
          <StatTitleContainer>
            <StatTitle>
              <label>총 발송건수</label>
              <span>
                <b>{statistics.total_count}</b>
                <span>건</span>
              </span>
            </StatTitle>
            <StatTitle>
              <label>성공</label>
              <span>
                <b>{statistics.ratio_total_success}</b>
                <span>%</span>
              </span>
            </StatTitle>
            <StatTitle>
              <label>실패</label>
              <span>
                <b>{statistics.ratio_total_failed}</b>
                <span>%</span>
              </span>
            </StatTitle>
            <StatTitle>
              <label>대기</label>
              <span>
                <b>{statistics.ratio_total_ready}</b>
                <span>%</span>
              </span>
            </StatTitle>
          </StatTitleContainer>
          {/* 발송결과 */}
          <StatResultContainer>
            <StatisticListItem name="단문 (SMS)" statistic={statistics.SMS} />
            <StatisticListItem name="장문 (LMS)" statistic={statistics.LMS} />
            <StatisticListItem name="포토 (MMS)" statistic={statistics.MMS} />
            <StatisticListItem
              name="비즈스마트 (SMS)"
              statistic={statistics.RCS_SMS}
            />
            <StatisticListItem
              name="비즈스마트 (LMS)"
              statistic={statistics.RCS_LMS}
            />
            <StatisticListItem
              name="비즈스마트 (MMS)"
              statistic={statistics.RCS_MMS}
            />
          </StatResultContainer>
        </StatContainer>
      </Container>
    </div>
  );
};

const Container = styled.section`
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 24px;
  margin-bottom: 24px;
  flex: 1;
`;

const Title = styled.h4`
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  letter-spacing: -1px;
  color: #333333;
  padding-bottom: 16px;
`;

const StatContainer = styled.div``;
const StatTitleContainer = styled.div`
  display: flex;
  gap: 8px;

  div {
    width: 112px;
  }
  div:nth-child(1) {
    flex: 1;
    color: #333333;
    background: rgba(51, 51, 51, 0.08);
  }

  div:nth-child(2) {
    color: #1b58f1;
    background: rgba(27, 88, 241, 0.1);
  }

  div:nth-child(3) {
    background: rgba(238, 64, 54, 0.1);
    color: #ec1c24;
  }

  div:nth-child(4) {
    background: rgba(250, 175, 64, 0.1);
    color: #faaf40;
  }

  b {
    padding-right: 4px;
  }
  label {
    font-weight: 700;
  }
`;
const StatTitle = styled.div`
  display: flex;
  justify-content: space-between;
  background: rgba(51, 51, 51, 0.08);
  border-radius: 10px;
  padding: 16px;
`;

const StatResultContainer = styled.div`
  padding: 12px 0px;
`;
