import { ModalLayout } from "components";
import { useState } from "react";
import styled from "styled-components";
import { ButtonRoundOutline, ButtonRoundPrimary, Flex } from "styles/styled";
import { SendReceiverAddressType } from "types/types";
import { validateAndConvertPhone } from "utils/phone";

export * as Icons from "components/Icons";

interface Props {
  receivers: SendReceiverAddressType[];
  addBulkAddressesToReceiver: (receivers: SendReceiverAddressType[]) => void;
  onClose: () => void;
  openAlertModal: () => void;
  setAlertMessage: (message: string) => void;
}
export const BulkAddressModal: React.FC<Props> = ({
  receivers,
  addBulkAddressesToReceiver,
  openAlertModal,
  setAlertMessage,
  onClose,
}) => {
  const [inputAddressList, setInputAddressList] = useState<string[]>([]);
  const [validate, setValidate] = useState<boolean>(false);

  const onChagneTextarea = (text: string) => {
    const textLine = text?.split("\n");
    setValidate(textLine?.length > 0);
    setInputAddressList(textLine);
  };

  const onSaveAddressList = () => {
    if (inputAddressList.length === 0) return;
    const filteredRecevers = [];
    inputAddressList.forEach((phone) => {
      const validatedPhone = validateAndConvertPhone(phone);
      const validatedPhoneOrigin = !receivers.some((receiver) => {
        return receiver.phone_number === validatedPhone;
      });
      const isNotIncludeCurrent = !filteredRecevers.some((receiver) => {
        return receiver.phone_number === validatedPhone;
      });
      if (validatedPhone && validatedPhoneOrigin && isNotIncludeCurrent) {
        filteredRecevers.push({
          name: "",
          phone_number: validatedPhone,
        });
      }
    });

    addBulkAddressesToReceiver(filteredRecevers);
    setAlertMessage(
      `${filteredRecevers.length}개의 번호가 추가되었습니다. (중복/오류 제외)`
    );
    openAlertModal();
    onClose();
  };
  const placeholder = `여기에 번호를 입력 후, 
  우측 하단의 [등록하기] 버튼을 클릭해주세요.

최대 1,000건 입력 가능합니다.
`;

  return (
    <ModalLayout title="번호 일괄 등록하기" onClose={onClose} width={600}>
      <Container>
        <div className="textContainer">
          <Textarea
            placeholder={placeholder}
            onChange={(e) => onChagneTextarea(e.target.value)}
          />
        </div>

        <Flex gap={10}>
          <ButtonRoundOutline width={274} onClick={onClose}>
            취소
          </ButtonRoundOutline>
          <ButtonRoundPrimary
            disabled={!validate}
            width={274}
            onClick={onSaveAddressList}
          >
            등록하기
          </ButtonRoundPrimary>
        </Flex>
      </Container>
    </ModalLayout>
  );
};

const Container = styled.div`
  min-height: 300px;
  .textContainer {
    padding: 20px 0px 40px;
  }
`;

export const Textarea = styled.textarea<{}>`
  letter-spacing: -1px;

  height: 100%;
  min-height: 260px;
  line-height: 1.5;
  width: 100%;
  font-size: 16px;
  white-space: pre-line;
  outline: none;
  position: relative;
  background: rgb(250, 250, 250);
  border: 1px solid rgb(215, 219, 226);
  box-sizing: border-box;
  padding: 16px 16px;
  border-radius: 6px;
  height: 360px;

  &::placeholder {
    content: attr(placeholder);
    color: #acacaccf;
  }
`;
