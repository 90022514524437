import { getHeaderWithToken, serverApi } from "./serverApi";
import { CreateProfileSenderType, VerifyProfileSenderType } from "types/types";

/**
 * 전체 카테고리 조회
 */
export const getSenderProfileCategory = async () => {
  return serverApi
    .get(`/api/kakao/profile/category/all`, getHeaderWithToken())
    .then((response: any) => response.data);
};

/**
 * 발신프로필 토큰 발송
 */
export const createProfileSender = async (body: CreateProfileSenderType) => {
  return serverApi
    .post(`/api/kakao/profile/sender/create`, body, getHeaderWithToken())
    .then((response: any) => response.data);
};

/**
 * 발신프로필 토큰 인증/생성
 */
export const verifyProfileSender = async (body: VerifyProfileSenderType) => {
  return serverApi
    .post(`/api/kakao/profile/sender/verify`, body, getHeaderWithToken())
    .then((response: any) => response.data);
};
