import { SVGProps } from "react";

export const ArrowRightSimple = ({
  width = 9,
  height = 18,
  fill = "#333333",
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.28033 0.970678C0.987437 0.676441 0.512563 0.676441 0.21967 0.970678C-0.0732231 1.26492 -0.0732231 1.74197 0.21967 2.03621L7.18934 9.03786L0.295048 15.9638C0.00215463 16.258 0.00215467 16.7351 0.295048 17.0293C0.587941 17.3236 1.06281 17.3236 1.35571 17.0293L8.78033 9.57063C9.07322 9.27639 9.07322 8.79933 8.78033 8.5051C8.76766 8.49237 8.75464 8.48019 8.74132 8.46855C8.72974 8.45517 8.71762 8.4421 8.70495 8.42937L1.28033 0.970678Z"
        fill={fill}
      />
    </svg>
  );
};
