import { useCustomNavigate } from "hooks/useCustomNavigate";
import { MEDIA_QUERY } from "properties/constant";
import { PATH } from "properties/menu";
import styled from "styled-components";
import { ImgSignupIconCompany, ImgSignupIconRegular } from "assets";

export const SignUp = () => {
  const navigate = useCustomNavigate();

  const handleSignUpRegular = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate.moveTo(PATH.SIGN_UP_REGULAR);
  };

  const handleSignUpCompany = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate.moveTo(PATH.SIGN_UP_COMPANY);
  };

  const handleSignIn = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate.moveTo(PATH.SIGN_IN);
  };

  return (
    <div>
      <Container>
        <Inner>
          <Title>회원가입을 환영합니다!</Title>
          <Desc>
            스마트 메시지 발송을 원하신다면 <br />
            기업 회원가입을 진행해주세요.
          </Desc>
          <BoxContainer>
            <LinkBox href={PATH.SIGN_UP_REGULAR} onClick={handleSignUpRegular}>
              <Circle bgImage={ImgSignupIconRegular} bgColor="#29F2FF"></Circle>
              <LinkText>
                <h3>일반 회원가입</h3>
                <p>
                  누구나 가입 가능
                  <br />
                  일반 문자를 하루에 500건 미만
                  <br />
                  으로 보내신다면
                </p>
              </LinkText>
            </LinkBox>
            <LinkBox href={PATH.SIGN_UP_COMPANY} onClick={handleSignUpCompany}>
              <Circle bgImage={ImgSignupIconCompany}></Circle>
              <LinkText>
                <h3>기업 회원가입</h3>
                <p>
                  기업 회원가입을 원하시나요? <br />
                  스마트 메세지와 카카오톡의
                  <br />
                  알림톡/친구톡 발송을 원하신다면
                </p>
              </LinkText>
            </LinkBox>
          </BoxContainer>
          <Bottom>
            이미 회원이신가요?
            <Link href={PATH.SIGN_IN} onClick={handleSignIn}>
              로그인하기
            </Link>
          </Bottom>
        </Inner>
      </Container>
    </div>
  );
};

const Container = styled.div``;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 532px;
  background-color: white;
  padding: 124px 32px 48px;
  margin: 0 auto;
  text-align: center;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    max-width: 100%;
    padding: 48px 24px;
  }
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 24px;
  line-height: 39px;
  letter-spacing: -1px;
  padding-bottom: 40px;
`;

const Desc = styled.p`
  color: #333;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -1px;
`;

const BoxContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 80px 0;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    flex-direction: column;
    margin: 24px 0;
  }
`;

const LinkBox = styled.a`
  display: flex;
  position: relative;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 242px;
  height: 280px;
  margin: 0 12px;
  flex-shrink: 0;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
  cursor: pointer;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    flex-direction: row;
    justify-content: start;
    width: 100%;
    max-width: 340px;
    height: auto;
    margin: 12px 0;
    padding: 24px;
  }
`;

const Circle = styled.div<{ bgImage: string; bgColor?: string }>`
  flex-shrink: 0;
  width: 100px;
  height: 100px;
  background-color: ${(props) => props.bgColor ?? "#2984ff"};
  background-image: url(${(props) => props.bgImage});
  background-repeat: no-repeat;
  background-position: 50% 50%;
  box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.25) inset;
  border-radius: 100%;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 80px;
    height: 80px;
  }
`;

const LinkText = styled.div`
  > h3 {
    margin: 16px 0;
    color: #333;
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -1px;
  }

  > p {
    white-space: pre-line;
    color: #333;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -1px;
  }

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    margin-left: 20px;
    text-align: left;

    > h3 {
      margin: 0 0 10px;
    }
  }
`;

const Bottom = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -1px;
`;

const Link = styled.a`
  margin-left: 16px;
  color: #1b58f1;
  text-decoration-line: underline;
  cursor: pointer;
`;
