import { CheckboxCircle, CheckboxCircleFilled } from "components/Icons";
import styled from "styled-components";

interface PolicyItemProps {
  text: string;
  isRequired?: boolean;
  isChecked: boolean;
  toggle: (value: boolean) => void;
  openModal?: () => void;
}
export const PolicyItem = ({
  text,
  isRequired = false,
  isChecked,
  toggle,
  openModal,
}: PolicyItemProps) => {
  return (
    <Container onClick={() => toggle(!isChecked)}>
      <div>
        {isChecked ? (
          <CheckboxCircleFilled height={22} width={22} />
        ) : (
          <CheckboxCircle height={22} width={22} fill={"#C4C9D1"} />
        )}
      </div>
      <PolicyText onClick={openModal}>
        <Text underline={!!openModal}>{text}</Text>
        {isRequired ? "(필수)" : "(선택)"}
      </PolicyText>
    </Container>
  );
};

export const Container = styled.div`
  padding: 16px 0 0 16px;
  display: flex;
  gap: 8px;
  cursor: pointer;
`;

export const PolicyText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
`;

const Text = styled.span<{ underline: boolean }>`
  ${(props) => props.underline && `text-decoration-line: underline;`}
`;
