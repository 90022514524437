import styled, { css } from "styled-components";
import {
  CheckboxCircle,
  CheckboxCircleFilled,
  Loading,
} from "components/Icons";
import { AddressBookType } from "types/types";
import { convertPhoneWithDash } from "utils/phone";

interface Props {
  selectedAddressBooks: AddressBookType[];
  addressBooks: AddressBookType[];
  isEditMode: boolean;

  onClickAddressBook: (book: AddressBookType) => void;
  onChangeAddressBook: (
    index: number,
    type: "phone" | "username",
    value: string
  ) => void;
}

export const AddressBooks: React.FC<Props> = ({
  selectedAddressBooks,
  addressBooks,
  isEditMode,
  onClickAddressBook,
  onChangeAddressBook,
}) => {
  const isActiveCheckbox = (adressBook: AddressBookType) => {
    return selectedAddressBooks.includes(adressBook);
  };
  const onStopDefaultEvent = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  if (addressBooks) {
    return (
      <Container>
        <TableTbody />
        {addressBooks.length > 0 ? (
          addressBooks.map((book, index) => (
            <TR key={book.id} onClick={() => onClickAddressBook(book)}>
              <TD>
                <CheckboxContainer>
                  {isActiveCheckbox(book) ? (
                    <CheckboxCircleFilled width="20" height="20" />
                  ) : (
                    <CheckboxCircle fill="#C4C9D1" width="20" height="20" />
                  )}
                </CheckboxContainer>
              </TD>

              {isEditMode ? (
                <>
                  <InputTD
                    invalid={book.invalid === "phone" || book.invalid === "all"}
                    width={120}
                    defaultValue={book.phone_number}
                    onBlur={(e) =>
                      onChangeAddressBook(index, "phone", e.target.value)
                    }
                    onClick={onStopDefaultEvent}
                  />
                  <InputTD
                    invalid={
                      book.invalid === "username" || book.invalid === "all"
                    }
                    width={100}
                    defaultValue={book.name}
                    onBlur={(e) =>
                      onChangeAddressBook(index, "username", e.target.value)
                    }
                    onClick={onStopDefaultEvent}
                  />
                </>
              ) : (
                <>
                  <TD>{convertPhoneWithDash(book.phone_number)}</TD>
                  <TD>{book.name}</TD>
                </>
              )}

              <TD>{book.group_name}</TD>
              {book.blocked ? <TD active={true}>수신거부</TD> : <TD>-</TD>}
            </TR>
          ))
        ) : (
          <NoData>등록된 번호가 없습니다</NoData>
        )}
      </Container>
    );
  } else {
    return (
      <Container>
        <TableTbody />
        <NoData>
          <Loading />
        </NoData>
      </Container>
    );
  }
};

const TableTbody = () => {
  return (
    <TBody>
      <TH>선택</TH>
      <TH>휴대폰번호</TH>
      <TH>이름</TH>
      <TH>그룹명</TH>
      <TH>수신거부</TH>
    </TBody>
  );
};

const Container = styled.div`
  border-radius: 6px;
  padding: 12px 10px;
  flex: 1;
  min-height: 200px;
`;

const TR = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 4px;
  height: 36px;
  align-items: center;

  div {
    text-align: center;
  }
  div:nth-child(1) {
    max-width: 50px;
    cursor: pointer;
  }
  div:nth-child(2) {
    width: 120px;
  }
  div:nth-child(3),
  div:nth-child(4) {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  div:nth-child(5) {
    width: 80px;
  }
`;

const TBody = styled(TR)`
  background: #fafafa;
`;

const TD = styled.div<{ active?: boolean; isEditable?: boolean }>`
  padding: 4px 10px;
  color: #333333;

  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  letter-spacing: -1px;

  svg {
    display: inline-block;
  }

  ${(props) =>
    props.active &&
    css`
      color: #ec1c24;
      font-weight: 700;
    `}

  ${(props) =>
    props.isEditable &&
    css`
      border: 1px solid #d7dbe2;
      border-radius: 6px;
    `}
`;

const CheckboxContainer = styled.div`
  margin-top: 4px;
`;
const InputTD = styled.input<{ width: number; invalid?: boolean }>`
  padding: 4px 10px;
  color: #333333;
  text-align: center;

  border: 1px solid ${(props) => (props.invalid ? "#EC1C24" : "#d7dbe2")};
  border-radius: 6px;

  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -1px;

  width: ${(props) => props.width}px;
`;

const TH = styled(TD)``;

const NoData = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: -1px;

  color: #666666;
  display: flex;
  padding-top: 78px;
  justify-content: center;
`;
