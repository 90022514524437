import { SVGProps } from "react";

export const Calendar = ({
  width = 22,
  height = 22,
  fill = "#333333",
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 22 22`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5833 4.58333H6.41667C5.40414 4.58333 4.58333 5.40414 4.58333 6.41667V15.5833C4.58333 16.5959 5.40414 17.4167 6.41667 17.4167H15.5833C16.5959 17.4167 17.4167 16.5959 17.4167 15.5833V6.41667C17.4167 5.40414 16.5959 4.58333 15.5833 4.58333ZM6.41667 2.75C4.39162 2.75 2.75 4.39162 2.75 6.41667V15.5833C2.75 17.6084 4.39162 19.25 6.41667 19.25H15.5833C17.6084 19.25 19.25 17.6084 19.25 15.5833V6.41667C19.25 4.39162 17.6084 2.75 15.5833 2.75H6.41667Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 7H4V4H18V7Z"
        fill={fill}
      />
      <circle cx="8" cy="10" r="2" fill={fill} />
    </svg>
  );
};
