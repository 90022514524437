import React from 'react';
import styled, { CSSObject } from 'styled-components';
import { FlexWrapper } from 'components/Flex';
import { Small } from 'styles/styled';
import ButtonSquare from 'components/ButtonSquare';


interface ITemplateMessageButton{
  text: string;
  type?: string;
  url?: string;
  hasBackgroundColor?: boolean;
}
export interface IMessageTemplateProps{
  title?: string;
  boldTitle?: boolean;
  titleUnderline?: boolean;
  priceTag?: string;
  children?: JSX.Element | JSX.Element[];
  buttonList?: ITemplateMessageButton[];
  height?: string;
  css?: CSSObject;
}

const MessageTemplateLayout = ({
  title,
  boldTitle,
  titleUnderline,
  priceTag,
  children,
  buttonList,
  height,
  css
}:IMessageTemplateProps) => {
  return (
    <Container
      title={title}
      height={height}
      css={css}
    >
      {
        title ? (
          <FlexWrapper
            css={{
              justifyContent:"space-between",
              borderBottom: titleUnderline ? 
                "2px solid #333"
                : "none",
              paddingBottom: "8px",
              marginBottom: "10px"
            }}
          >
            <Small 
              css={{
                fontWeight: boldTitle?
                  "bold"
                  :"normal"
              }}
            >
              {title}
            </Small>
            {
              priceTag ? (
                <Small
                  css={{
                    color: "#0A98FE",
                    fontWeight:"bold"
                  }}
                >
                  {priceTag}
                </Small>
              ) : null
            }
          </FlexWrapper>
        ) : null
      }
      <ContentText>
        {children}
      </ContentText>
      {
        buttonList ? (
          <TextWrapper
            title={title}
          >
            <ButtonsWrapper>
              {
                buttonList.map((button, index)=>(
                  <li key={`button-list-${index}`}>
                    <ButtonSquare
                      css={{
                        background: button.hasBackgroundColor ?   
                          "#E4E4E4"
                          :"transparent"
                      }}
                    >
                      {button.text}
                    </ButtonSquare>
                  </li>
                ))
              }
            </ButtonsWrapper>
          </TextWrapper>
        ) : null
      }
    </Container>
  );
};

export default MessageTemplateLayout;

const Container = styled.div<{
  title?: string;
  height?: string;
  css?: CSSObject;
}>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 209px;
  min-height: ${props=>
    props.height ? 
      props.height
      : "250px"
  };
  padding: ${props=>
    props.title ? 
      "16px"
      : "0"
  };
  background-color: #F8F8F8;
  border: 0.5px solid #E2E2E2;
  border-radius: 10px;
  font-size: 12px;
  letter-spacing: 0px;
  line-height: 0.5;
  overflow: hidden;

  ${props=>
    props.css ? 
      props.css
      : ""
  };
`;
const ContentText = styled.div`
  white-space: pre-line; 
  line-height: normal;
`;
const TextWrapper = styled.div<{
  title?: string;
}>`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${props=>
    props.title ? 
      "0"
      : "0 16px 16px"
  };
`;
const ButtonsWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: flex-end; 
  flex:1;
  li{
    >div{
      height: 32px;
      min-height: 32px;
      margin-top: 4px;
      border: none;
      font-size: 12px;
      font-weight: bold;
      color:#0A98FE;
    }
  }
  li:first-child{
    >div{
      margin-top: 0;
    }
  }
`;
