import styled from "styled-components";
import { observer } from "mobx-react-lite";

import { MenuBar } from "../../MenuBar";

interface Props {
  children: JSX.Element;
  title?: string;
}
export const DashboardLayout = observer(({ children, title }: Props) => {
  return (
    <Container>
      <MenuBar />
      <Content>
        {title && <LayoutTitle>{title}</LayoutTitle>}
        <div>{children}</div>
      </Content>
    </Container>
  );
});

const LayoutTitle = styled.h3`
  color: #333;
  font-size: 32px;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -1px;
  padding-bottom: 38px;
`;
const Container = styled.div`
  background-color: #f6f8ff;
  min-height: 100vh;
`;

const Content = styled.div`
  margin-left: 240px;
  overflow-x: auto;
  padding: 48px 40px;
`;

export const MessageLayoutInner = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding-bottom: 40px;
`;

export const MessageLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 800px;
`;

export const Li = styled.li`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -1px;
  color: #666666;

  &::before {
    content: "• ";
    padding-right: 4px;
  }
`;
