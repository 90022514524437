import styled from "styled-components";

export const RollingUp = styled.div`
  @keyframes bannerUp {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-100%);
    }
  }
  animation: bannerUp 20s linear infinite;
`;

export const RollingDown = styled.div`
  @keyframes bannerDown {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0%);
    }
  }
  animation: bannerDown 20s linear infinite;
`;
export const FadeIn = styled.div<{ speed: number; delay: number }>`
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  opacity: 0;
  animation: fadeIn ${(props) => props.speed}s ${(props) => props.delay}s
    forwards;
`;
