import * as S from "./styled";
import * as Icons from "components/Icons";

interface Props {
  phoneNumber: string;
  onRemove: () => void;
}
export const ReceiverListItem: React.FC<Props> = ({
  phoneNumber,
  onRemove,
}) => {
  return (
    <S.ListContainer>
      <S.ListFlex>
        <S.PhoneNumber>{phoneNumber}</S.PhoneNumber>
        <S.RemoveButton onClick={onRemove}>
          <Icons.CloseRoundFilled />
        </S.RemoveButton>
      </S.ListFlex>
    </S.ListContainer>
  );
};
