import { useState } from "react";
import styled from "styled-components";
import { messageTypes } from "properties/content";
import { MessageType as MessageTypeCard } from "components/MessageType";
import { MEDIA_QUERY } from "properties/constant";
import useResponsive from "hooks/useResponsive";

export const MessageType = () => {
  const [hoveredTypeId, setHoveredTypeId] = useState<number | null>(null);
  const { isMobile } = useResponsive();

  return (
    <Container>
      <Title>
        {isMobile ? (
          <span>
            <b>고객이 반응하는 문자</b>를 <br />
            만들어드립니다
          </span>
        ) : (
          <span>
            <b>고객이 반응하는 문자</b>를 만들어드립니다
          </span>
        )}
      </Title>
      {isMobile ? (
        <Text>
          문자만 단순 발송 하는것이 아닌, 각 마케팅
          <b> 타겟에 최적화 된 메시징 </b>
          서비스를 제공하고
          <b> 회수율이 낮은 고객에게는 문자 발송을 하지 않음</b>으로써 광고
          비용을 혁신적으로 줄일 수 있어요.
        </Text>
      ) : (
        <Text>
          문자만 단순 발송 하는것이 아닌, 각 마케팅
          <b> 타겟에 최적화 된 메시징 </b>
          서비스를 제공하고 <br />
          <b>회수율이 낮은 고객에게는 문자 발송을 하지 않음</b>으로써 광고
          비용을 혁신적으로 줄일 수 있어요.
        </Text>
      )}

      <TypeList>
        {messageTypes.map((type) => (
          <MessageTypeCard
            key={type.id}
            type={type}
            hoveredTypeId={hoveredTypeId}
            onMouseEnter={() => setHoveredTypeId(type.id)}
            onMouseLeave={() => setHoveredTypeId(null)}
          />
        ))}
      </TypeList>
    </Container>
  );
};

const Container = styled.div`
  padding: 100px 20px;
  text-align: center;
  background: #f6f8ff;
`;
const Title = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  line-height: 39px;
  letter-spacing: -1px;
  color: #333333;
  padding-bottom: 16px;
  b {
    font-weight: 700;
  }

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 28px;
  }
`;
const Text = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  letter-spacing: -1px;

  color: #333333;
  padding-bottom: 60px;
  b {
    font-weight: 700;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 16px;
    word-break: keep-all;
    text-align: left;
    max-width: 480px;
    margin: 0 auto;
  }
`;
const TypeList = styled.div`
  display: flex;
  gap: 0px;
  justify-content: center;
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    flex-direction: column;
  }
`;
