import { MEDIA_QUERY } from "properties/constant";
import styled from "styled-components";
import { SuccessfulCaseType } from "types/types";

export const SuccessfulCaseCard = ({
  successfulCase,
}: {
  successfulCase: SuccessfulCaseType;
}) => {
  return (
    <Container>
      <div className="card">
        <Image src={successfulCase.imageName} alt="mockup" />
      </div>
      <TextContainer className="text">
        <div>
          <Title>{successfulCase.title}</Title>
          <Text>{successfulCase.text}</Text>
        </div>
        <Division />
        <Stats>
          <StatContainer>
            <Label>평균 월 매출</Label>
            <Value>{successfulCase.totalSales.toLocaleString()}원</Value>
          </StatContainer>
          <StatContainer>
            <Label>하루 문자 발송 비용</Label>
            <Value>{successfulCase.costPerDay.toLocaleString()}원</Value>
          </StatContainer>
          <StatContainer>
            <Label>투자 대비 효과</Label>
            <Value primary>{successfulCase.roi}배</Value>
          </StatContainer>
        </Stats>
      </TextContainer>
    </Container>
  );
};
const Container = styled.div`
  padding-top: 60px;
  display: flex;
  gap: 120px;
  justify-content: center;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    gap: 10px;
    .card {
      flex: 1;
      margin-left: -5px;
    }
    .text {
      flex: 1;
    }
  }
`;
const Image = styled.img`
  width: 290px;
  height: 553.12px;
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 100%;
    height: auto;
  }
`;
const TextContainer = styled.div`
  text-align: left;
  width: 397px;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 100%;
  }
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 34px;
  line-height: 45px;
  letter-spacing: -1px;

  padding-bottom: 24px;

  color: #333333;
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 18px;
    line-height: 24px;
    word-break: keep-all;
  }
`;

const Text = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -1px;
  padding-bottom: 24px;
  white-space: pre-line;

  color: #666666;
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    white-space: inherit;
    font-size: 16px;
    line-height: 20px;
  }
`;
const Division = styled.div`
  height: 1px;
  width: 100%;
  background-color: #666666;
  margin-bottom: 24px;
`;
const Stats = styled.div``;

const StatContainer = styled.div`
  padding-bottom: 36px;
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    padding-bottom: 16px;
  }
`;
const Label = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -1px;
  padding-bottom: 4px;

  color: #666666;
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 16px;
    line-height: 16px;
  }
`;
const Value = styled.div<{ primary?: boolean }>`
  font-weight: 700;
  font-size: 28px;
  line-height: 45px;

  display: flex;
  align-items: center;
  letter-spacing: -1px;

  color: ${(props) => (props.primary ? "#2984FF" : "#333333")};

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 18px;
    line-height: 24px;
  }
`;
