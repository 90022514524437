import * as userApi from "apis/userApi";
import { createContext } from "react";
import { observable, makeObservable, runInAction, action } from "mobx";
import {
  CreateChangeToCompanyUser,
  CompanyType,
  CreateCompanySignUp,
  CreateUserRequestType,
  UserType,
} from "types/types";

import * as session from "utils/session";
import * as storage from "utils/storage";

class UserStore {
  @observable user: UserType | null = null;
  @observable accessToken: string | null = null;
  @observable companyInfo: CompanyType[] | null = null;
  @observable activeCompany: CompanyType | null = null;

  constructor() {
    makeObservable(this);
  }

  @action getUser = () => {
    if (!this.user) {
      this.user = session.getUser();
      this.accessToken = session.getAccessToken();
    }
    if (!this.user) {
      this.user = storage.getUser();
      this.user && session.saveUser(this.user);
      this.accessToken = storage.getAccessToken();
      this.accessToken && session.saveAccessToken(this.accessToken);
    }

    runInAction(() => {
      if (this.user?.company_info?.length > 0) {
        this.companyInfo = this.user.company_info;
        const activeCompany = this.companyInfo?.find(
          (company) => company.status === 1
        );
        this.activeCompany = activeCompany ? activeCompany : null;
      }
    });

    return this.user;
  };

  @action signIn = async (email: string, password: string, isAutoLogin) => {
    try {
      const response = await userApi.signIn({
        email,
        password,
      });

      runInAction(() => {
        this.user = response.user;
        this.accessToken = response.access;
        session.saveAccessToken(response.access);
        session.saveUser(response.user);
        if (isAutoLogin) {
          storage.saveAccessToken(response.access);
          storage.saveRefreshToken(response.refresh);
          storage.saveUser(response.user);
          // TODO: 24시간 내 로그인 유지
        }
      });

      return true;
    } catch (err) {
      return false;
    }
  };

  logout = () => {
    storage.removeTokens();
    session.removeTokens();
    window.location.href = "/";
  };

  signUp = async (body: CreateUserRequestType | CreateCompanySignUp) => {
    const response = await userApi.signUp(body);
    if (!response || response instanceof Error) {
      alert("회원가입에 실패했습니다.");
      return false;
    } else {
      return true;
    }
  };

  checkUniqueEmail = async (email: string) => {
    const response = await userApi.checkDuplicateEmail(email);
    if (!response || response instanceof Error) {
      return {
        isUnique: false,
        message: "서버 연동에 실패했습니다. \n관리자에게 문의해주세요.",
      };
    } else if (response.is_exists) {
      return {
        isUnique: false,
        message: "사용 중인 이메일입니다.",
      };
    } else {
      return {
        isUnique: true,
        message: "사용 가능한 이메일입니다.",
      };
    }
  };

  checkUniquePhone = async (phoneNumber: string) => {
    const response = await userApi.checkDuplicatePhone(phoneNumber);
    if (!response || response instanceof Error) {
      return {
        isUnique: false,
        message: "서버 연동에 실패했습니다. \n관리자에게 문의해주세요.",
      };
    } else if (response.is_exists) {
      return {
        isUnique: false,
        message: "이미 등록된 핸드폰 번호 입니다. ",
      };
    } else {
      return {
        isUnique: true,
        message: "",
      };
    }
  };

  searchEmail = async (
    username: string,
    impUid: string,
    phoneNumber: string
  ) => {
    try {
      const response = await userApi.searchEmail(username, impUid, phoneNumber);
      return { searched: true, email: response.email };
    } catch (err) {
      return { searched: false, message: err.msg };
    }
  };

  fetchUserInfo = async () => {
    try {
      const response = await userApi.fetchUserInfo();
      runInAction(() => {
        this.user = response;
        session.saveUser(response);

        if (this.user?.company_info?.length > 0) {
          this.companyInfo = this.user.company_info;
          const activeCompany = this.companyInfo?.find(
            (company) => company.status === 1
          );
          this.activeCompany = activeCompany ? activeCompany : null;
        }
      });

      return { fetched: true, user: response.id };
    } catch (err) {
      return { fetched: false, message: err.msg };
    }
  };

  resetPassword = async (
    username: string,
    impUid: string,
    phoneNumber: string,
    email: string,
    newPassword: string
  ) => {
    try {
      await userApi.resetPassword(
        username,
        impUid,
        phoneNumber,
        email,
        newPassword
      );
      return { updated: true };
    } catch (err) {
      return { updated: false, message: err.msg };
    }
  };

  changeToCompanyUser = async (body: CreateChangeToCompanyUser) => {
    try {
      await userApi.changeToCompanyUser(body);
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  withdrawal = async () => {
    const response = await userApi.withdrawal();
    if (!response || response instanceof Error) {
      alert("회원탈퇴에 실패했습니다.");
      return false;
    } else {
      this.logout();
      return true;
    }
  };
}

export default createContext(new UserStore());
