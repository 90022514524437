import styled from "styled-components";
import * as Icons from "components/Icons";

interface Props {
  text: string;
  height?: string;
  width?: string;
  borderColor?: string;
  onClick: () => void;
}
export const InputButtonWithBottomArrow: React.FC<Props> = ({
  text,
  height,
  width,
  borderColor,
  onClick,
}) => {
  return (
    <Container
      onClick={onClick}
      height={height}
      width={width}
      borderColor={borderColor}
    >
      <span>{text}</span>
      <Icons.ArrowBottomFilled />
    </Container>
  );
};

const Container = styled.div<{
  height?: string;
  width?: string;
  borderColor?: string;
}>`
  border: 1px solid
    ${(props) => (props.borderColor ? props.borderColor : "#333333")};
  border-radius: 4px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${(props) => (props.width ? props.width : "fit-content")};
  gap: 10px;
  cursor: pointer;
  ${(props) => props.height && `height: ${props.height};`}
`;
