import { SVGProps } from "react";

export const ArrowBottom = ({
  width = 24,
  height = 24,
  fill = "#333333",
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.2793 9.28033C20.5736 8.98744 20.5736 8.51256 20.2793 8.21967C19.9851 7.92678 19.508 7.92678 19.2138 8.21967L12.2121 15.1893L5.28621 8.29505C4.99197 8.00215 4.51492 8.00215 4.22068 8.29505C3.92644 8.58794 3.92644 9.06281 4.22068 9.35571L11.6794 16.7803C11.9736 17.0732 12.4507 17.0732 12.7449 16.7803C12.7576 16.7677 12.7698 16.7546 12.7814 16.7413C12.7948 16.7297 12.8079 16.7176 12.8206 16.705L20.2793 9.28033Z"
        fill={fill}
      />
    </svg>
  );
};
