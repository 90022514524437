import styled from "styled-components";

export const Descriptions = styled.ul``;
export const Li = styled.li`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -1px;
  color: #666666;

  &::before {
    content: "• ";
    padding-right: 4px;
  }
`;
export const Inner = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  width: 1000px;
  min-height: 600px;
  display: flex;
  flex-direction: column;
`;
export const Header = styled.div`
  padding: 24px;
`;
export const SubTitle = styled.h3`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  letter-spacing: -1px;
  color: #333333;
  padding-bottom: 24px;
`;

export const Flex = styled.div`
  display: flex;
  gap: 40px;
  padding: 20px;
  height: 100%;
  flex: 1;
`;

export const GroupManageContainer = styled.div`
  width: 200px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 80px;
  h4 {
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: -1px;
    color: #333333;
  }
  label {
    cursor: pointer;
  }
`;
export const Line = styled.div`
  background-color: #999999;
  height: 1px;
  width: 100%;
`;

export const GroupSearchContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export const PrimaryButton = styled.div`
  cursor: pointer;
  width: 74px;
  background: #1b58f1;
  border-radius: 6px;
  color: #ffffff;
  padding: 12px;

  font-weight: 700;
  letter-spacing: -1px;
  font-size: 16px;
  line-height: 23px;

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
`;
