import { ConfirmModal } from "components";
import styled from "styled-components";
interface Props {
  onClose: () => void;
}
export const UnableNewCallerModal = ({ onClose }: Props) => {
  return (
    <ConfirmModal
      title={"발신번호 등록 불가"}
      confirmButtonText="확인"
      onConfirm={onClose}
      closeModal={onClose}
    >
      <Container>
        <li>발신 번호는 최대 3개까지 등록 가능합니다.</li>
        <li>승인 되거나 검수 중인 번호 3개일 경우 추가 등록이 불가합니다.</li>
        <li>번호 중 1개를 삭제한 후 다시 등록해주세요.</li>
      </Container>
    </ConfirmModal>
  );
};

const Container = styled.div`
  li {
    margin-left: 26px;
    list-style-position: inside;
    text-indent: -26px;
    color: rgb(102, 102, 102);
  }
`;
