import * as kakaoApi from "apis/kakaoApi";
import { makeObservable, action } from "mobx";
import { createContext } from "react";
import { CreateProfileSenderType, VerifyProfileSenderType } from "types/types";

class KakaoStore {
  constructor() {
    makeObservable(this);
  }

  /** 전체 카테고리 조회 */
  @action fetchCategories = async () => {
    try {
      const result = await kakaoApi.getSenderProfileCategory();

      if (result.data) {
        const parsedData = result.data.map((item) => {
          const [main, sub, detail] = item.name.split(",");
          return {
            code: item.code,
            main,
            sub,
            detail,
          };
        });

        return parsedData;
      } else {
        throw Error("발신프로필 전체 카테고리 조회를 실패했습니다.");
      }
    } catch (err) {
      console.debug(err);
      return null;
    }
  };

  /** 발신프로필 토큰 발송 */
  @action createProfileSender = async (body: CreateProfileSenderType) => {
    try {
      const result = await kakaoApi.createProfileSender(body);
      if (result.detail === "success") {
        return {
          isSuccess: true,
          message: result.detail,
        };
      }
    } catch (err) {
      return {
        isSuccess: false,
        message: err.detail ?? "발신프로필 토큰 발송을 실패했습니다.",
      };
    }
  };

  /** 발신프로필 토큰 인증/생성 */
  @action verifyProfileSender = async (body: VerifyProfileSenderType) => {
    try {
      const result = await kakaoApi.verifyProfileSender(body);
      if (result.detail === "success") {
        return {
          isSuccess: true,
          message: result.detail,
        };
      }
    } catch (err) {
      return {
        isSuccess: false,
        message: err.detail ?? "발신프로필 토큰 인증을 실패했습니다.",
      };
    }
  };
}

export default createContext(new KakaoStore());
