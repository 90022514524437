import { FlexWrapper } from 'components/Flex';
import InputText from 'components/InputText';
import SelectBasic from 'components/SelectBasic';
import styled from 'styled-components';
import { BodyBold } from 'styles/styled';

const actionButtonOptions = [
  {
    value: "",
    text: "미사용"
  },
  {
    value: "url",
    text: "url Action"
  }
];

interface IButtonDetail{
  value: string;
  text: string;
  url: string;
  hasBackgroundColor?: boolean;
};

export const TemplateButtonList = ({
  button1Details,
  button2Details,
  setButton1Details,
  setButton2Details
}:{
  button1Details:IButtonDetail;
  button2Details:IButtonDetail;
  setButton1Details: React.Dispatch<React.SetStateAction<IButtonDetail>>;
  setButton2Details: React.Dispatch<React.SetStateAction<IButtonDetail>>;
}) => {

  const onHandleChangeButton = (target, name) => {
    const splited = name.split("-");
    const number = Number(splited[1]);
    const type = splited[2];
    if(number === 1){
      setButton1Details((button1Details)=>({
        ...button1Details,
        [type]: target.value
      }));
    }else{
      setButton2Details((button2Details)=>({
        ...button2Details,
        [type]: target.value
      }));
    }
  };

  const onDecideButtonVisibility = (target, name)=>{
    const splited = name.split("select-button-");
    const number = Number(splited[1]);
    if(number === 1){
      setButton1Details((button1Details)=>({
        ...button1Details,
        value: target.value
      }));
    }else{
      setButton2Details((button2Details)=>({
        ...button2Details,
        value: target.value
      }));
    }
  };

  return (
    <TemplateButtonListWrapper>
      <li>
        <BodyBold
          className="label"
        >
          버튼1
        </BodyBold>
        <FlexWrapper  
          className="detail-setting"
          css={{
            flexDirection: "column"
          }}
        >
          <SelectBasic
            onChange={onDecideButtonVisibility}
            value={button1Details.value}
            options={actionButtonOptions}
            name="select-button-1"
          />

          {
            button1Details.value !== "" ? (
              <TemplateButtonDetailSettings>
                <li>
                  <BodyBold
                    className="label"
                  >
                    버튼 명
                  </BodyBold>
                  <InputText
                    onChange={onHandleChangeButton}
                    value={button1Details.text}
                    name="button-1-text"
                    placeholder=""
                  />
                </li>
                <li>
                  <BodyBold
                    className="label"
                  >
                    URL
                  </BodyBold>
                  <InputText
                    onChange={onHandleChangeButton}
                    value={button1Details.url}
                    name="button-1-url"
                    placeholder=""
                  />
                </li>
              </TemplateButtonDetailSettings>
            ) : null
          }
        </FlexWrapper>
      </li>
      <li>
        <BodyBold
          className="label"
        >
          버튼2
        </BodyBold>
        <FlexWrapper  
          className="detail-setting"
          css={{
            flexDirection: "column"
          }}
        >
          <SelectBasic
            onChange={onDecideButtonVisibility}
            value={button2Details.value}
            options={actionButtonOptions}
            name="select-button-2"
          />
          {
            button2Details.value !== "" ? (
              <TemplateButtonDetailSettings>
                <li>
                  <BodyBold
                    className="label"
                  >
                    버튼 명
                  </BodyBold>
                  <InputText
                    onChange={onHandleChangeButton}
                    value={button2Details.text}
                    name="button-2-text"
                    placeholder=""
                  />
                </li>
                <li>
                  <BodyBold
                    className="label"
                  >
                    URL
                  </BodyBold>
                  <InputText
                    onChange={onHandleChangeButton}
                    value={button2Details.url}
                    name="button-2-url"
                    placeholder=""
                  />
                </li>
              </TemplateButtonDetailSettings>
            ) : null
          }
          
        </FlexWrapper>
      </li>
    </TemplateButtonListWrapper>
  );
};

export default TemplateButtonList;


const TemplateButtonListWrapper = styled.ul`
  >li{
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    >.label{
      display: flex;
      align-items: center;
      align-self: flex-start;
      width: 40px;
      height: 56px;
      margin-bottom: 0;
      margin-right: 15px;
    }
  }
`;

const TemplateButtonDetailSettings = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  >li{
    display: flex;
    align-items: center;
    width: 410px;
    margin-top: 16px;
    >.label{
      width: 45px;
      margin-bottom: 0;
      margin-right: 15px;
      text-align: right;
    }
  }
`;