import styled, { CSSObject } from "styled-components";

export const FlexWrapper = styled.div<{
  css?: CSSObject
}>`
  width: 100%;
  display: flex;
  ${(props)=>
    props.css? props.css : ""
  };
`;