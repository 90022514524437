import InputText from 'components/InputText';
import SelectBasic from 'components/SelectBasic';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import TemplateStore from 'stores/TemplateStore';
import styled from 'styled-components';
import { BodyBold } from 'styles/styled';


const brandSelectOptions = [
  {
    value: "",
    text: "브랜드를 선택해주세요."
  },
  {
    value: "bizpost",
    text: "비즈포스트"
  },
];
const templateFormatOptions = [
  {
    value: "",
    text: "템플릿 양식을 선택해주세요."
  },
  {
    value: "orderDesc",
    text: "주문desc"
  }
];

interface IProps{}

const TemplateCreationForm = observer(({
}:IProps) => {
  const templateStore = useContext(TemplateStore);
  const templateConfiguration = templateStore.templateConfiguration;

  const onHandleChangeBrand = (target)=>{
    templateStore.setTemplateConfiguration(target, "brandName");
  };
  const onHandleChangeTemplateFormat = (target)=>{
    templateStore.setTemplateConfiguration(target, "templateFormat");
  };
  const onHandleChangeTemplateName = (target)=>{
    templateStore.setTemplateConfiguration(target, "templateName");
  };
  return (
    <CreationSettings>
      <li>
        <BodyBold>
          브랜드 선택
        </BodyBold>
        <SelectBasic
          value={templateConfiguration.brandName}
          options={brandSelectOptions}
          onChange={onHandleChangeBrand}
          css={{
            height:"56px"
          }}
        />
      </li>
      <li>
        <BodyBold>
          템플릿 양식 선택
        </BodyBold>
        <SelectBasic
          value={templateConfiguration.templateFormat}
          options={templateFormatOptions}
          onChange={onHandleChangeTemplateFormat}
          css={{
            height:"56px"
          }}
        />
      </li>
      <li>
        <BodyBold>
          템플릿 명 입력
        </BodyBold>
        <InputText
          value={templateConfiguration.templateName}
          onChange={onHandleChangeTemplateName}
          placeholder="템플릿명을 입력해주세요"
        />
      </li>
    </CreationSettings>
  );
});

export default TemplateCreationForm;

const CreationSettings = styled.ul`
  width: 100%;
  li{
    margin-bottom: 40px;
  }
`;