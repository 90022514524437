import { useEffect, useState } from "react";
import * as S from "./styled";

import * as messageApi from "apis/messageApi";
import {
  ResultGroupType,
  SendResultGroupResponseType,
  SmsType,
} from "types/types";
import { Flex, Pad, PageSubTitle } from "styles/styled";
import {
  InputDate,
  InputSelectionWithDropdown,
  MessageLayout,
} from "components";
import { DATE_FORMAT_2, getCurrentTime, subtractDate } from "utils/datetime";
import { smsTypeOptions } from "properties/content";
import { Pagination } from "components/Pagination";
import { MessageResultGroup } from "./SendResultGroup";
import { useCustomNavigate } from "hooks/useCustomNavigate";
import { PATH } from "properties/menu";
import { addParameterToURL } from "utils/addParameterToURL";
import { MessageLayoutInner, Li } from "components/layout/MessageLayout";

export const MessageSendResult = () => {
  const navigate = useCustomNavigate();
  const { start, end, page, msgType } = navigate.parsed;
  const today = getCurrentTime(DATE_FORMAT_2);
  const MIN_DATE = subtractDate(today, DATE_FORMAT_2, 90);
  const MAX_DATE = today;

  const [resultGroups, setResultGroups] = useState<ResultGroupType[] | null>(
    null
  );

  const [startDate, setStartDate] = useState<string>(
    start ? String(start) : MIN_DATE
  );
  const [endDate, setEndDate] = useState<string>(start ? String(end) : today);
  const [resultCount, setResultCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(page ? Number(page) : 1);

  const [selectedMessageType, setSelectedMessageType] =
    useState<SmsType | null>(
      msgType
        ? smsTypeOptions.find((option) => option.uppercaseKey === msgType)
        : null
    );

  useEffect(() => {
    fetchMessageResult();
  }, [currentPage]);

  /** 발송 내역 필터 검색  */
  const onClickSearchButton = () => {
    setCurrentPage(1);
    fetchMessageResult();
    navigate.replace(generateUrlWithParams());
  };

  /** 발송 내역 결과 리스트 불러오기 */
  const fetchMessageResult = async () => {
    setResultGroups(null);
    const SESSION_KEY = "MESSAGE_SEND_RESULT_FETCH_PARAMS";
    const currentAPIParam = `${startDate}-${endDate}-${currentPage}-${selectedMessageType?.uppercaseKey}`;
    const lastAPIParam = sessionStorage.getItem(SESSION_KEY);
    if (lastAPIParam === currentAPIParam) {
      return;
    } else {
      try {
        const response: SendResultGroupResponseType =
          await messageApi.fetchMessageSendResults({
            page: currentPage,
            startDate,
            endDate,
            messageType: selectedMessageType?.uppercaseKey,
          });

        setResultGroups(response.results);
        setResultCount(response.count);
        sessionStorage.setItem(SESSION_KEY, currentAPIParam);
      } catch (error: any) {
        setResultGroups(null);
        setResultCount(0);
        setCurrentPage(1);
      }
    }
    sessionStorage.removeItem(SESSION_KEY);
  };

  const generateUrlWithParams = () => {
    let url = addParameterToURL(PATH.MESSAGE_SEND_RESULT, "start", startDate);
    if (endDate) url = addParameterToURL(url, "end", endDate);
    if (selectedMessageType)
      url = addParameterToURL(url, "msgType", selectedMessageType.uppercaseKey);
    url = addParameterToURL(url, "page", currentPage);

    return url;
  };

  const onClickMessageType = (type: SmsType) => {
    setCurrentPage(1);
    setSelectedMessageType(type);
  };

  return (
    <MessageLayout>
      <MessageLayoutInner>
        <Pad p={24}>
          <PageSubTitle>일반 문자</PageSubTitle>
          <ul>
            <Li>최근 90일 동안 전송된 메시지를 확인할 수 있습니다.</Li>
            <Li>
              전송결과 완료 후 메시지 전송 실패 건은 사용한 충전/적립 포인트가
              재충전 됩니다.
            </Li>
            <Li>
              문자메시지(SMS/LMS/MMS)는 최대 72시간, 스마트 메시지는 최대
              24시간까지 ‘대기’상태 일 수 있습니다.
            </Li>
          </ul>
        </Pad>
        <Pad px={24} py={16}>
          <Flex gap={8} justifyContent="flex-end">
            <InputDate
              date={startDate}
              minDate={MIN_DATE}
              maxDate={MAX_DATE}
              setDate={setStartDate}
              height="46px"
              width="140px"
            />
            <span>~</span>
            <InputDate
              date={endDate}
              minDate={MIN_DATE}
              maxDate={MAX_DATE}
              setDate={setEndDate}
              height="46px"
              width="140px"
            />

            <InputSelectionWithDropdown
              noSelectOptionLabel={"메시지 타입"}
              selectedOptionLabel={selectedMessageType?.uppercaseKey}
              options={smsTypeOptions}
              onClickOption={onClickMessageType}
            />
            <S.ButtonPrimaryStyled
              radius={4}
              padding="10px 16px"
              onClick={onClickSearchButton}
            >
              검색
            </S.ButtonPrimaryStyled>
          </Flex>
        </Pad>
        <section>
          <MessageResultGroup groups={resultGroups} />
        </section>
        <Pad pb={36} />
        <Pagination
          setCurrentPage={setCurrentPage}
          size={resultCount}
          defaultPage={currentPage}
        />
      </MessageLayoutInner>
    </MessageLayout>
  );
};
