import { ImgPricingSmart1, ImgPricingSmart2, ImgPricingSmart3 } from "assets";
import useResponsive from "hooks/useResponsive";
import styled from "styled-components";

export const SmartMessage = () => {
  const { isMobile } = useResponsive();
  return (
    <Container>
      <Title>
        <div>비즈포스트의</div>
        <b>스마트메시지</b>는 다릅니다
      </Title>
      <Description>
        일반메시지보다<b>클릭율 580%, 유입율 5배</b> 상승하는 새로운 문자
        서비스입니다.
        <br />
        <br />
        비즈포스트는{" "}
        <b>
          각 업종 맞춤형으로 {isMobile && <br />}구매를 유도하는 문구와 디자인
          제작
        </b>
        까지 지원합니다.
      </Description>

      <CardContainer>
        <Card>
          <Image src={ImgPricingSmart1} alt="smart message" />
          <CardTitle>브랜드 홍보</CardTitle>
          <Text>
            특정 문구를 사용자 단말이
            <br />
            자동 복사할 수 있게 합니다.
          </Text>
        </Card>
        <Card>
          <Image src={ImgPricingSmart2} alt="smart message" />
          <CardTitle>기본문자 앱 기반 사용성</CardTitle>
          <Text>다른 번호로 메시지를 보낼 수 있도록 대화방을 엽니다.</Text>
        </Card>
        <Card>
          <Image src={ImgPricingSmart3} alt="smart message" />
          <CardTitle>신뢰할 수 있는 메시지</CardTitle>
          <Text>
            특정 전화번호로 전화를
            <br /> 걸 수 있습니다.
          </Text>
        </Card>
      </CardContainer>
    </Container>
  );
};

const Container = styled.div`
  padding: 120px 20px 100px;
  text-align: center;
  background: #f6f8ff;
`;

const Title = styled.h2`
  font-size: 32px;
  line-height: 39px;
  letter-spacing: -1px;
  color: #333333;
  padding-bottom: 20px;
`;

const Description = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -1px;
  color: #666666;
  padding-bottom: 80px;
  word-break: keep-all;
`;
const CardContainer = styled.div`
  display: flex;
  gap: 24px;
  justify-content: center;
  flex-wrap: wrap;
`;
const Card = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  width: 242px;
  height: 242px;
  padding: 24px;
`;
const Image = styled.img`
  width: 70px;
  height: 70px;
  margin-bottom: 20px;
`;
const CardTitle = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  letter-spacing: -1px;
  padding-bottom: 8px;

  color: #333333;
`;
const Text = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -1px;
  word-break: keep-all;

  color: #666666;
`;
