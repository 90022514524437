import { Banner, BannerMain, ServiceIntro } from "components";
import { Footer } from "components/Footer";
import { UseCase } from "./UseCase/UseCase";
import { MessageType } from "./MessageType";
import { ActionButton } from "./ActionButton";
import { SuccessfulCase } from "./SuccessfulCase";
import { Pricing } from "./Pricing";
import { Screenshot } from "./Screenshot";
import { QnA } from "./QnA";
import useResponsive from "hooks/useResponsive";

export const Home = () => {
  const { isDesktop } = useResponsive();
  return (
    <div>
      <BannerMain />
      <ServiceIntro />
      <UseCase />
      <Banner />
      <MessageType />
      <ActionButton />
      <SuccessfulCase />
      {isDesktop && <Pricing />}
      <Screenshot />
      <QnA />
      <Banner />
      <Footer />
    </div>
  );
};
