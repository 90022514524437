import { getHeaderWithToken, serverApi } from "./serverApi";
import { AxiosResponse } from "axios";
import {
  BillingType,
  DepositCreateBodyType,
  ImportPaymentParam,
  PaymentSerialType,
  RequestCreatePaymentCallbackParam,
  WalletType,
} from "types/types";
import { addParameterToURL } from "utils/addParameterToURL";

interface BillingResponse {
  count: number;
  next: number;
  previous: number;
  results: BillingType[];
}
/**
 * 결제 전 payment_serial 가져오기
 */
export const getPaymentSerial = async (): Promise<PaymentSerialType> => {
  const body = {
    before_paid: true,
  };

  return serverApi
    .post("/api/payments/callback", body, getHeaderWithToken())
    .then((response: any) => response.data)
    .catch((error) => {
      console.error(error);
      return false;
    });
};

/**
 * 결제 후 서버 callback 호출
 */
export const createPayment = async (
  impUid: string,
  merchantUid: string,
  paymentParam: ImportPaymentParam
) => {
  const body: RequestCreatePaymentCallbackParam = {
    imp_uid: impUid,
    merchant_uid: merchantUid,
    status: "paid",
    name: paymentParam.name,
    paid_amount: paymentParam.amount,
    buyer_name: paymentParam.buyer_name,
    buyer_tel: paymentParam.buyer_tel,
    custom_data: paymentParam.custom_data,
    pay_method: paymentParam.pay_method,
  };

  return serverApi
    .post("/api/payments/callback", body, getHeaderWithToken())
    .then((response: any) => response.data)
    .catch((error) => {
      console.error(error);
      return false;
    });
};

export const fetchBillings = ({
  startDate,
  endDate,
  page,
  size = 10,
}): Promise<BillingResponse> => {
  let url = "/api/billing/";
  if (page) url = addParameterToURL(url, "page", page);
  url = addParameterToURL(url, "size", size);
  if (startDate) url = addParameterToURL(url, "start_date", startDate);
  if (endDate) url = addParameterToURL(url, "end_date", endDate);

  return serverApi
    .get(url, getHeaderWithToken())
    .then((response: AxiosResponse<BillingResponse>) => {
      return response.data;
    });
};

export const fetchBillingById = (id: number): Promise<BillingResponse> => {
  const uri = `/api/billing/${id}`;

  return serverApi
    .get(uri, getHeaderWithToken())
    .then((response: AxiosResponse<BillingResponse>) => {
      return response.data;
    });
};

export const fetchBilingWallet = (): Promise<WalletType> => {
  const uri = `/api/wallet`;

  return serverApi
    .get(uri, getHeaderWithToken())
    .then((response: AxiosResponse<WalletType>) => {
      return response.data;
    });
};
/**
 * 무통장 입금 결제 요청 생성하기
 */
export const createDeposit = async (body: DepositCreateBodyType) => {
  return serverApi
    .post("/api/payments/deposit", body, getHeaderWithToken())
    .then((response: any) => response.data)
    .catch((error) => {
      console.error(error);
      return false;
    });
};
