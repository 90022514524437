import * as S from "./styled";
import { CloseRoundFilled as DeleteIcon, Loading } from "components/Icons";
import { AddressGroupType } from "types/types";
import { Flex } from "styles/styled";

interface Props {
  groups: AddressGroupType[] | null;
  activeGroup: AddressGroupType | null;
  setActiveGroup: (group: AddressGroupType) => void;
  setVisibleRemoveGroupModal: (groupId) => void;
}
export const GroupList: React.FC<Props> = ({
  groups,
  activeGroup,
  setActiveGroup,
  setVisibleRemoveGroupModal,
}) => {
  return (
    <S.GroupListContainer>
      {groups &&
        groups.length > 0 &&
        groups.map((group) => {
          const isActive = activeGroup?.id === group.id;
          const groupName = `${group.group_name} (${group.total_count})`;
          return (
            <S.GroupListItem
              key={group.id}
              active={isActive}
              onClick={() => setActiveGroup(group)}
            >
              <div>{groupName}</div>
              <div onClick={() => setVisibleRemoveGroupModal(group.id)}>
                <DeleteIcon />
              </div>
            </S.GroupListItem>
          );
        })}
      {groups && groups.length === 0 && (
        <S.GroupListItem active={true}>
          <div>미지정 (0)</div>
        </S.GroupListItem>
      )}
      {!groups && (
        <Flex gap={0} justifyContent="center">
          <Loading />
        </Flex>
      )}
    </S.GroupListContainer>
  );
};
