import { useState } from "react";
import { messagePricing, pricings } from "properties/content";
import styled, { css } from "styled-components";
import { PricingType } from "types/types";
import { ImgHomePricingLine } from "assets";

export const Pricing = () => {
  const [activePricing, setActivePricing] = useState<PricingType>(pricings[0]);

  return (
    <Container>
      <div>
        <Title>
          <b>요금부담</b>을 덜어드립니다
        </Title>
        <Text>
          국내 최저 수준의 발송요금과 이용/충전 구간에 따른 추가 할인을
          제공합니다.
        </Text>
      </div>

      <CaseButtonList>
        {pricings.map((pricing) => {
          const isActive = activePricing.id === pricing.id;
          return (
            <PricingTypeItem key={pricing.id}>
              <TypeImage
                onClick={() => setActivePricing(pricing)}
                image={isActive ? pricing.iamgeNameActive : pricing.imageName}
              />
              <PricingTitle active={isActive}>{pricing.title}</PricingTitle>
            </PricingTypeItem>
          );
        })}
      </CaseButtonList>
      <PricingLine src={ImgHomePricingLine} />

      <MessagePricingContainer>
        {messagePricing.map((pricing) => {
          const isActive = activePricing.activeIds.includes(pricing.id);
          return (
            <MessagePrice active={isActive} key={pricing.id}>
              <PricingName active={isActive}>
                {pricing.name}
                <br />({pricing.subname})
              </PricingName>
              <PricingPrice active={isActive}>{pricing.price}원</PricingPrice>
            </MessagePrice>
          );
        })}
      </MessagePricingContainer>
    </Container>
  );
};

const Container = styled.div`
  padding: 105px 20px 55px;
  text-align: center;
`;

const Title = styled.div`
  font-weight: 400;
  font-size: 34px;
  line-height: 39px;
  padding-bottom: 16px;

  letter-spacing: -1px;

  color: #333333;
  b {
    font-weight: 700;
  }
`;
const Text = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -1px;
  padding-bottom: 40px;

  color: #333333;
  b {
    font-weight: 700;
  }
`;

const CaseButtonList = styled.div`
  display: flex;
  justify-content: center;
  gap: 84px;
`;
const TypeImage = styled.div<{ image: string }>`
  transition: all 0.2s ease-in-out;
  background-image: url(${(props) => props.image});
  background-size: contain;
  width: 110px;
  height: 110px;
  padding-bottom: 16px;
`;
const PricingTypeItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  cursor: pointer;
`;

const PricingTitle = styled.div<{ active: boolean }>`
  font-weight: ${(props) => (props.active ? 700 : 400)};
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  letter-spacing: -1px;
  white-space: pre-line;

  color: ${(props) => (props.active ? "#2984FF" : "#333333")};
`;

const PricingLine = styled.img`
  margin: 0 auto;
  padding: 32px 0;
  width: 500px;
`;

const MessagePricingContainer = styled.div`
  display: flex;
  min-width: 1000px;
  margin: 0 auto;
  justify-content: center;
  gap: 10px;
`;
const MessagePrice = styled.div<{ active: boolean }>`
  background: #ededed;
  width: 113px;
  height: 113px;
  border-radius: 50%;
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  transition: all 0.2s ease-in-out;
  ${(props) =>
    props.active &&
    css`
      background: #2984ff;
      box-shadow: inset 4px 4px 4px rgba(0, 0, 0, 0.25);
    `}
`;
const PricingName = styled.div<{ active: boolean }>`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -1px;
  transition: all 0.2s ease-in-out;
  color: ${(props) => (props.active ? "#FFFFFF" : "#666666")};
`;
const PricingPrice = styled.div<{ active: boolean }>`
  font-weight: 700;
  font-size: 22px;
  line-height: 19px;
  padding-top: 8px;

  letter-spacing: -1px;
  transition: all 0.2s ease-in-out;
  color: ${(props) => (props.active ? "#FFFFFF" : "#333333")};
  padding-bottom: 10px;
`;
