export const formatToPhoneNumber = (phoneNumber) => {
  let formated;

  if (phoneNumber.length === 8) {
    formated = phoneNumber
      .replace(/^(\d{0,4})(\d{0,4})$/g, "$1-$2")
      .replace(/\-{1,2}$/g, "");
  } else if (phoneNumber.length === 9) {
    // 02-123-01234
    formated = phoneNumber
      .replace(/^(\d{0,2})(\d{0,3})(\d{0,4})$/g, "$1-$2-$3")
      .replace(/\-{1,2}$/g, "");
  } else if (phoneNumber.length === 10) {
    formated = phoneNumber
      .replace(/^(\d{0,3})(\d{0,3})(\d{0,4})$/g, "$1-$2-$3")
      .replace(/\-{1,2}$/g, "");
  } else {
    formated = phoneNumber
      .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
      .replace(/\-{1,2}$/g, "");
  }
  return formated;
};
export const validatePhone = (phone: string) => {
  const reg = /^\d{3}-\d{4}-\d{4}$/;
  return reg.test(phone);
};

export const validatePhoneNumberOnly = (phone: string) => {
  // var regExp = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{4}$/;
  const reg = /^010([0-9]{8})$/;
  return reg.test(phone);
};

export const validateNumberOnly = (number: string) => {
  const reg = /^[0-9]*$/;
  return reg.test(number);
};

export const validatePhoneAll = (phones: string[]) => {
  return (
    phones.length > 0 && phones.every((phone) => validateAndConvertPhone(phone))
  );
};

export const validateMessage = (title: string, context: string) => {
  return title && title.length > 0 && context && context.length > 0;
};

export const validateAndConvertPhone = (phone: string) => {
  const reg = /^\d{3}-\d{4}-\d{4}$/;
  if (reg.test(phone)) {
    return phone.replace(/-/g, "");
  } else if (validatePhoneNumberOnly(phone)) {
    return phone;
  } else {
    return null;
  }
};

export const convertPhoneOnlyNumber = (phone: string) => {
  return phone.replace(/-/g, "");
};

export const convertPhoneWithDash = (phone: string) => {
  return phone.replace(/(\d{3})(\d{3,4})(\d{4})/, "$1-$2-$3");
};
