import { MenuBar } from 'components/MenuBar';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Descriptions, Inner, Li, PaddingHorizontal, PageTitle, PageTitleSmall, TemplateLayout } from '../TemplateListSmart';
import { Line, Padding } from 'components';
import { FlexWrapper } from 'components/Flex';
import { AutoCenterCoverSizeImage, BodyBold, ButtonRound, Div, Small } from 'styles/styled';
import InputText from 'components/InputText';
import MessageTemplateLayout from 'components/template/MessageTemplateLayout';
import MockupBasic from 'components/MockupBasic';
import NavTopBack from 'components/Modals/NavTopBack';
import { PATH } from 'properties/menu';
import { useCustomNavigate } from 'hooks/useCustomNavigate';
import TemplateConfiguration from 'components/template/TemplateConfiguration';
import TemplateStore from 'stores/TemplateStore';
import TemplateSampleImageList1 from "assets/images/template/template-sample-1.jpg";
import TemplateCellList from 'components/template/TemplateCellList';
import { Description } from 'components/Modals/LayoutStyled';

import ButtonCloseSrc from "assets/images/button/btn-close-round-black.svg";
import ButtonCheckSrc from "assets/images/button/btn-check-round-white.svg";
import IConPlus from "assets/images/icons/ico-plus-blue-16.svg";
import TemplateButtonList from 'components/template/TemplateButtonList';
import SelectBasic from 'components/SelectBasic';
import MainImageRatio from 'components/template/MainImageRatio';

const VariableOptions = [
  {
    value: "",
    text: "변수 선택"
  }
];

const messageProps = {
  height: "364px",
  children: <div>
    <AutoCenterCoverSizeImage
      width="100%"
      height="195px"
    >
      <img 
        src={TemplateSampleImageList1} 
        alt="" 
      />
    </AutoCenterCoverSizeImage>

    <FlexWrapper
      css={{
        flexDirection: "column",
        padding: "16px",
        boxSizing: "border-box"
      }}
    >
      <Small
        css={{
          fontWeight: "bold"
        }}
      >
        2주간 즐기는 프리미엄 회원 특별혜택!
      </Small>
      <TemplateCellList
        noLine
        boldFont
        cellList={
          [
            {
              title: "행사기간",
              content: "2023.02.01 ~ 2023.02.28"
            },
            {
              title: "참여대상",
              content: "프리미엄 등급 고객"
            },
            {
              title: "제공혜택",
              content: "22% 추가 적립 + 무료배송"
            },
          ]
        }
        valueLeftAlign
      />
    </FlexWrapper>
  </div>,
  buttonList: [
    {
      text: "자세히 보러가기",
      hasBackgroundColor: true
    }
  ],
};

interface ICell {
  title: string;
  content: string;
}

export const TemplateSmartImageTitle = () => {
  const templateStore = useContext(TemplateStore);
  const configurations = templateStore.templateConfiguration;
  const navigation = useCustomNavigate();
  const [messageTitle, setMessageTitle] = useState("");
  const [isPassValidation, setIsPassValidation] = useState(false);
  const [titleVariable, setTitleVariable] = useState("");
  const [imageRatio, setImageRatio] = useState("1:1");
  const [mainImagePreview, setMainImagePreview] = useState(null);
  const [mainImage, setMainImage] = useState(null);
  const [cellList, setCellList] = useState<ICell[]>([
    {
      title: "",
      content: ""
    },
    {
      title: "",
      content: ""
    }
  ]);

  const [messageInfo, setMessageInfo] = useState({
    messageProps: {
      height: "364px",
      children: <div>
        <AutoCenterCoverSizeImage
          width="100%"
          height="195px"
        >
          <img 
            src={TemplateSampleImageList1} 
            alt="" 
          />
        </AutoCenterCoverSizeImage>
        <FlexWrapper
          css={{
            flexDirection: "column",
            padding: "16px",
            boxSizing: "border-box"
          }}
        >
          <Small
            css={{
              fontWeight: "bold"
            }}
          >
            2주간 즐기는 프리미엄 회원 특별혜택!
          </Small>
          <TemplateCellList
            noLine
            boldFont
            cellList={cellList}
            valueLeftAlign
          />
        </FlexWrapper>
      </div>,
      buttonList: [
        {
          text: "버튼",
          value: "",
          url:""
        }
      ],
    }
  });

  const [button1Details, setButton1Details] = useState({
    value: "",
    text: "",
    url: "",
    hasBackgroundColor: true
  });

  const [button2Details, setButton2Details] = useState({
    value: "",
    text: "",
    url: "",
    hasBackgroundColor: true
  });

  const onHandleAddCell = () => {
    const assigned = [...cellList, {
      title: "",
      content: ""
    }];
    setCellList(assigned);
  };

  const onHandleDeleteCell = (index) => {
    const assigned = [...cellList];
    assigned.splice(index, 1);
    setCellList(assigned);
  };

  const onHandleMessageName = (target) => {
    setMessageTitle(target.value);
  };

  const onChangeTitleVariable = (target) => {
    setTitleVariable(target.value);
  }

  const onHandleChangeCellList = useCallback((target) => {
    const cellIndex = target.name.split("-")[2];
    const cellType = target.name.split("-")[1];
    const assigned = [...cellList];
    assigned[cellIndex][cellType] = target.value; 
    setCellList(assigned);
  }, [
    cellList
  ]);

  const moveToTemplateList = useCallback(() => {
    navigation.moveTo(PATH.TEMPLATE_LIST_SMART);
  },[navigation]);

  const validateTemplateInput = useCallback(() => {
    const result = configurations.brandName !== "" &&
    configurations.templateFormat !== "" &&
    configurations.templateName !== "" && ( 
      button1Details.value !== "" || 
      button2Details.value !== "" 
    ) &&
    (
      button1Details.value !== "" ? 
      button1Details.text !== "" &&
      button1Details.url !== "" 
      : true
    ) &&
    (
      button2Details.value !== "" ? 
      button2Details.text !== "" &&
      button2Details.url !== ""
      : true
    );
    setIsPassValidation(result);
  },[
    configurations.brandName,
    configurations.templateName,
    configurations.templateFormat,
    button1Details,
    button2Details,
    setIsPassValidation
  ]);

  useEffect(() => {
    setMessageInfo((messageInfo)=>({
      ...messageInfo,
      messageProps:{
        ...messageInfo.messageProps,
        children: 
        <div>
          <AutoCenterCoverSizeImage
            width="100%"
            height="195px"
          >
            <img 
              src={mainImagePreview ? mainImagePreview : TemplateSampleImageList1} 
              alt="" 
            />
          </AutoCenterCoverSizeImage>
      
          <FlexWrapper
            css={{
              flexDirection: "column",
              padding: "16px",
              boxSizing: "border-box"
            }}
          >
            <Small
              css={{
                fontWeight: "bold"
              }}
            >
              {messageTitle ? messageTitle : "제목"}
            </Small>
            <TemplateCellList
              noLine
              boldFont
              cellList={cellList}
              valueLeftAlign
            />
          </FlexWrapper>
        </div>,
        buttonList: 
          button1Details.value === "" && 
          button2Details.value === "" ? 
            [
              {
                text: "버튼",
                value: "",
                url:"",
                hasBackgroundColor: true
              }
            ]
            : button1Details && button2Details && button1Details.value !== "" && button2Details.value !== ""
              ? [button1Details, button1Details]
              : button1Details 
                ? [button1Details]
                : [button2Details]
      }
    }));
    validateTemplateInput();
  }, [
    cellList,
    button1Details, 
    button2Details,
    messageTitle,
    validateTemplateInput,
    mainImagePreview
  ]);

  return (
    <TemplateLayout>
      <MenuBar/>
      <Container>
        <PageTitle>이미지 템플릿 추가</PageTitle>
        <Inner>
          <PaddingHorizontal>
            <PageTitleSmall>이미지 & 타이틀 강조형 템플릿</PageTitleSmall>
            <Descriptions>
              <Li>이미지 & 타이틀 강조형 템플릿입니다.</Li>
            </Descriptions>
            <Padding height="26px"/>
            <FlexWrapper
              css={{
                paddingBottom: "40px",
                marginBottom: "40px",
                borderBottom: "1px solid #D9D9D9",
              }}
            >
              <FlexWrapper
                css={{
                  width: "495px",
                  minWidth: "495px"
                }}
              >
                <TemplateConfiguration/>
              </FlexWrapper>
              <FlexWrapper
                css={{
                  flex: "1",
                  marginLeft: "24px",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <MessageTemplateLayout
                  {...messageProps}
                  css={{
                    backgroundColor: "white",
                    border: "none"
                  }}
                />
              </FlexWrapper>
            </FlexWrapper>
            <FlexWrapper>
              <Div
                css={{
                  width: "495px",
                  paddingBottom: "40px"
                }}
              >
                
                <MainImageRatio
                  imageRatio={imageRatio}
                  setImageRatio={setImageRatio}
                  setMainImage={setMainImage}
                  setMainImagePreview={setMainImagePreview}
                />
                <BodyBold>
                  제목 입력
                </BodyBold>
                <FlexWrapper
                  css={{
                    marginBottom: "8px"
                  }}
                >
                  <InputText
                    placeholder="제목을 입력해주세요"
                    value={messageTitle}
                    onChange={onHandleMessageName}
                    bgColor={messageTitle?"white":"#FAFAFA"}
                  />
                  <SelectBasic
                    options={VariableOptions}
                    value={titleVariable}
                    onChange={onChangeTitleVariable}
                    placeholderEmphasis
                    padding="16px"
                    css={{
                      maxWidth: "132px",
                      width: "132px",
                      marginLeft: "10px"
                    }}
                  />
                </FlexWrapper>
                <Li color="#999">
                  [변수 선택]을 통해 본문에 사용된 변수를 제목 우측에 넣을 수 있습니다.
                </Li>
                <Padding height="40px"/>
                <BodyBold>
                  내용 입력
                </BodyBold>
                <Description>
                  <Li 
                    paddingBottom="0px"
                    color="#999"
                  >
                    {
                      `변수로 설정하고자 하는 애용을 {{ }} 표시로 작성해주세요.
                      { 변수명은 영문, 숫자 가능 }
                      예) 이름 출금일 금액을 변수설정 : {{ name }}`
                    }
                  </Li>
                  <Li color="#999">
                    {
                      `메시지 전송 시 변수 값의 총합의 90자 이내로 전송해주세요.
                      예) name date, amount 변수의 글자 갯수 종합이 90자 이내`
                    }
                  </Li>
                </Description>
                <CellListLayout>
                  {
                    cellList && 
                    cellList.length > 0 &&
                    cellList.map((cell, index)=>(
                      <li key={`cell-${index}`}>
                        <Div
                          css={{
                            display: "flex",
                            alignItems: "center",
                            alignSelf: "flex-start",
                            width: "55px",
                            height: "56px",
                            marginRight: "29px",
                            fontWeight: "bold"
                          }}
                        >
                          셀 {index+1}
                        </Div>
                        <Div>
                          <FlexWrapper
                            css={{
                              alignItems: "center"
                            }}
                          >
                            <InputText
                              value={cell.title}
                              name={`cell-title-${index}`}
                              onChange={onHandleChangeCellList}
                              placeholder="셀 제목 (최대 4글자)"
                              margin="0 10px 0 0"
                            />
                            <InputText
                              value={cell.content}
                              name={`cell-content-${index}`}
                              onChange={onHandleChangeCellList}
                              placeholder="내용 (최대 33글자)"
                            />
                            <CellInnerButtonWrapper
                              onClick={() => onHandleDeleteCell(index)}
                            >
                              <img src={ButtonCloseSrc} alt="셀 삭제"/>
                            </CellInnerButtonWrapper>
                          </FlexWrapper>
                          <FlexWrapper
                            css={{
                              alignItems: "center",
                              height: "37px"
                            }}
                          >
                            <Line
                              css={{
                                backgroundColor: "#999"
                              }}
                            />
                            <CellInnerButtonWrapper>
                              <img src={ButtonCheckSrc} alt="확인"/>
                            </CellInnerButtonWrapper>
                          </FlexWrapper>
                        </Div>
                      </li>
                    ))
                  }
                </CellListLayout>

                <ButtonAddCell
                  onClick={onHandleAddCell}
                >
                  <img src={IConPlus} alt=""/> 
                  셀 추가하기
                </ButtonAddCell>
                <BodyBold>
                  버튼 추가
                </BodyBold>
                <TemplateButtonList
                  button1Details={button1Details}
                  button2Details={button2Details}
                  setButton1Details={setButton1Details}
                  setButton2Details={setButton2Details}
                />
                <FlexWrapper
                  css={{
                    flex: "1",
                    height: "96px",
                    maxHeight: "96px",
                    marginTop: "24px",
                    paddingTop: "40px",
                    borderTop: "1px solid #D9D9D9",
                  }}
                >
                  <ButtonRound
                    css={{
                      width: "158px",
                      fontWeight: "normal",
                      border: "1px solid #333",
                      marginRight: "10px"
                    }}
                  >
                    임시저장
                  </ButtonRound>
                  <ButtonRound
                    css={{
                      width: "158px",
                      fontWeight: "normal",
                      backgroundColor: "#333",
                      color: "white",
                      marginRight: "10px"
                    }}
                    onClick={moveToTemplateList}
                  >
                    템플릿 목록
                  </ButtonRound>
                  <ButtonRound
                    css={{
                      width: "158px",
                      fontWeight: "bold",
                      backgroundColor: isPassValidation ?
                        "#1B58F1" 
                        :"#E1E1E1",
                      color: "white"
                    }}
                  >
                    승인요청
                  </ButtonRound>
                </FlexWrapper>
              </Div>
              <FlexWrapper
                css={{
                  flexDirection: "column",
                  alignItems: "flex-end",
                  paddingTop: "10px"
                }}
              >
                <MockupBasic>
                  <NavTopBack 
                    messageType="MMS"
                  />
                  <MockupContent>
                    <MessageTemplateLayout
                      {...messageInfo.messageProps}
                      css={{
                        width: "100%"
                      }}
                    />
                  </MockupContent>
                </MockupBasic>
              </FlexWrapper>
            </FlexWrapper>
          </PaddingHorizontal>
        </Inner>
      </Container>
    </TemplateLayout>
  );
};

export const Container = styled.div`
  padding: 48px 40px;
  width: fit-content;
  min-width: 976px;
`;

const MockupContent = styled.div`
  padding: 12px;
  box-sizing: border-box;
`;

const CellListLayout = styled.ul`
  li{
    display: flex;
    align-items: center;
  }
`;

const CellInnerButtonWrapper = styled.div`
  margin-left: 16px; 
  img{
    display: block;
    width: 20px;
    height: "20px;
  }
`
const ButtonAddCell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin: 0 auto 55px;
  padding: 5px 12px;
  color: #1B58F1;
  cursor: pointer; 
  img{
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
`;