import { convertPhoneWithDash } from "utils/phone";
import * as Icons from "components/Icons";
import { ImgMockupSvg } from "assets";
import { observer } from "mobx-react-lite";
import { useContext } from "react";
import MessageStore from "stores/MessageStore";
import * as S from "./styled";

interface Prop {
  imageSrc1: string | null;
  imageSrc2: string | null;
  imageSrc3: string | null;
}

export const MessageMockUp: React.FC<Prop> = observer(
  ({ imageSrc1, imageSrc2, imageSrc3 }) => {
    const messageStore = useContext(MessageStore);
    const phoneNumber = messageStore.targetCaller
      ? convertPhoneWithDash(messageStore.targetCaller.phone_number)
      : "발신번호";

    const messageType = messageStore.targetMessageType;

    return (
      <S.Container>
        <img src={ImgMockupSvg} alt="목업" width="321" height="612" />
        <S.ContentContainer>
          <S.Header>
            <S.PhoneContainer>
              <S.Icon>
                <Icons.ArrowLeftSimple />
              </S.Icon>
              <S.PhoneNumber>{phoneNumber}</S.PhoneNumber>
            </S.PhoneContainer>
            <S.SmsType messageType={messageStore.targetMessageType}>
              {messageType}
            </S.SmsType>
          </S.Header>
          <S.BodyContainer>
            {imageSrc1 && <S.PreviewImage src={imageSrc1} />}
            {imageSrc2 && <S.PreviewImage src={imageSrc2} />}
            {imageSrc3 && <S.PreviewImage src={imageSrc3} />}

            {!!messageStore.targetTitle && messageType !== "SMS" && (
              <S.MessageContainer>
                <S.Message>{messageStore.completedTitle}</S.Message>
              </S.MessageContainer>
            )}
            <S.MessageContainer>
              <S.Message isPlaceholder={!messageStore.targetContent}>
                {messageStore.completedContent
                  ? `${messageStore.completedContent}`
                  : "내용"}
              </S.Message>
            </S.MessageContainer>
          </S.BodyContainer>
        </S.ContentContainer>
      </S.Container>
    );
  }
);
