import { Checkbox } from "components/Icons/Checkbox";
import { MEDIA_QUERY } from "properties/constant";
import styled, { css } from "styled-components";
import { PaymentMethodType } from "types/types";
interface Props {
  paymentMethod: PaymentMethodType;
  setPaymentMethod: (method: PaymentMethodType) => void;
}

export const PaymentMethod: React.FC<Props> = ({
  paymentMethod,
  setPaymentMethod,
}) => {
  return (
    <Container>
      <h3>결제수단</h3>
      <div className="paymentTypeContaienr">
        <PaymentType
          active={paymentMethod === "card"}
          onClick={() => setPaymentMethod("card")}
        >
          <div>
            <Checkbox active={paymentMethod === "card"} />
          </div>
          <div>신용카드</div>
        </PaymentType>
        <PaymentType
          active={paymentMethod === "trans"}
          onClick={() => setPaymentMethod("trans")}
        >
          <div>
            <Checkbox active={paymentMethod === "trans"} />
          </div>
          <div>실시간 계좌이체</div>
        </PaymentType>
        <PaymentType
          active={paymentMethod === "deposit"}
          onClick={() => setPaymentMethod("deposit")}
        >
          <div>
            <Checkbox active={paymentMethod === "deposit"} />
          </div>
          <div>무통장 입금</div>
        </PaymentType>
      </div>
    </Container>
  );
};

const Container = styled.div`
  padding: 40px 0 40px;

  .paymentTypeContaienr {
    display: flex;
    gap: 8px;

    @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
      flex-direction: column;
    }
  }
`;

const PaymentType = styled.div<{ active: boolean }>`
  flex: 1;
  height: 46px;
  position: relative;
  border-radius: 6px;
  cursor: pointer;

  div:nth-child(1) {
    left: 16px;
    top: 11px;
    position: absolute;
  }
  div:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    height: 46px;
  }

  ${(props) =>
    props.active
      ? css`
          color: #1b58f1;
          font-weight: 700;
          background: rgba(27, 88, 241, 0.1);
          border: 1px solid #1b58f1;
        `
      : css`
          color: #333333;
          font-weight: 400;
          background: #f4f4f4;
          border: 0.5px solid #d7dbe2;
        `}
`;
