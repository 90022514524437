import { SVGProps } from "react";

export const Trash = ({
  width = 18,
  height = 16,
  fill = "#333333",
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.818359 1.54532C0.818359 1.04324 1.22537 0.63623 1.72745 0.63623H3.45343L4.81707 13.3635L13.1833 13.3635L14.5469 0.63623H16.2729C16.775 0.63623 17.182 1.04324 17.182 1.54532C17.182 2.0474 16.775 2.45441 16.2729 2.45441H16.1807L14.9911 13.5572C14.8921 14.4811 14.1125 15.1817 13.1833 15.1817H4.81707C3.88789 15.1817 3.10822 14.4811 3.00923 13.5572L1.81965 2.45441H1.72745C1.22537 2.45441 0.818359 2.0474 0.818359 1.54532Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.59108 2.46588C7.1214 2.43086 7.58137 2.80849 7.61845 3.30934L8.15561 10.5644C8.19269 11.0652 7.79285 11.4996 7.26253 11.5346C6.73222 11.5697 6.27225 11.192 6.23517 10.6912L5.698 3.43617C5.66092 2.93532 6.06077 2.50091 6.59108 2.46588Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4046 2.46576C11.9349 2.50078 12.3347 2.9352 12.2976 3.43605L11.7605 10.6911C11.7234 11.1919 11.2634 11.5695 10.7331 11.5345C10.2028 11.4995 9.80295 11.0651 9.84003 10.5642L10.3772 3.30922C10.4143 2.80837 10.8742 2.43074 11.4046 2.46576Z"
        fill={fill}
      />
    </svg>
  );
};
