import { ImgHomeBanner1, ImgHomeBanner2, ImgHomeVs } from "assets";
import useResponsive from "hooks/useResponsive";
import { MEDIA_QUERY } from "properties/constant";
import styled from "styled-components";

export const ServiceIntro = () => {
  const { isMobile } = useResponsive();
  return (
    <Container>
      <Inner>
        {!isMobile && (
          <SectionTextContainer>
            <Text1>
              어떤 문자를 <b>클릭하고 싶으세요?</b>
            </Text1>
            <Text2>맞춤 스마트 메시지로</Text2>
            <Text3>
              클릭율 <span>580%</span>, 유입율 <span>5배</span> 상승!
            </Text3>
          </SectionTextContainer>
        )}
        {isMobile && (
          <Text1>
            어떤 문자를 <b>클릭하고 싶으세요?</b>
          </Text1>
        )}
        {isMobile && <ImageLabelLight>기존 문자메시지</ImageLabelLight>}
        <Section1Image>
          <div>
            <img
              className="type1"
              src={ImgHomeBanner1}
              alt="소개"
              width="385"
              height="480"
            />

            {!isMobile && <ImageLabelLight>기존 문자메시지</ImageLabelLight>}
          </div>
          <div>
            <img
              className="versus"
              src={ImgHomeVs}
              alt="소개"
              width="117"
              height="117"
            />
          </div>

          <div>
            {isMobile && <ImageLabelDark>스마트 메시지</ImageLabelDark>}
            <img
              className="type2"
              src={ImgHomeBanner2}
              alt="소개"
              width="382"
              height="480"
            />
            {!isMobile && <ImageLabelDark>스마트 메시지</ImageLabelDark>}
          </div>

          {isMobile && (
            <SectionTextContainer>
              <Text2>맞춤 스마트 메시지를 선택하면</Text2>
              <Text3>
                클릭율 <span>580%</span>, 유입율 <span>5배</span> 상승!
              </Text3>
            </SectionTextContainer>
          )}
        </Section1Image>
      </Inner>
    </Container>
  );
};
export const Container = styled.div`
  border-radius: 4px;

  z-index: 2;
  position: relative;
  background-color: white;
`;

export const Inner = styled.div`
  margin: 0 auto;
  justify-content: space-between;
  padding: 100px 20px 105px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 800px;
  margin: 0 auto;
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    max-width: 460px;
    width: 100%;
  }
`;

export const SectionTextContainer = styled.div`
  white-space: pre-line;
  text-align: center;
  padding-bottom: 50px;
`;
export const Section1Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Text2 = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #333333;
`;

export const Section1Image = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;

  h6 {
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -1px;
    color: #333333;
    padding-bottom: 14px;
  }
  img {
    height: auto;
    width: 110%;
    margin-left: -5%;
  }

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    flex-direction: column;
    gap: 36px;
    img.versus {
      width: 60px;
    }
  }
`;

export const Text1 = styled.h2`
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  line-height: 39px;
  letter-spacing: -1px;
  color: #333333;
  padding-bottom: 24px;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 28px;
    line-height: 24px;
    padding-bottom: 48px;
  }
`;

export const Text3 = styled.h3`
  font-weight: 700;
  font-size: 28px;
  line-height: 41px;
  text-align: center;
  letter-spacing: -1px;
  color: #333333;
  span {
    color: rgb(27, 88, 241);
  }

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const ImageLabel = styled.div`
  border-radius: 100px;
  width: fit-content;
  padding: 6px 24px;
  margin: 0 auto;

  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -1px;
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 16px;
    padding: 6px 20px 4px;
  }
`;

const ImageLabelLight = styled(ImageLabel)`
  border: 0.5px solid #333333;
  color: #333333;
`;
const ImageLabelDark = styled(ImageLabel)`
  font-weight: 700;
  color: #ffffff;
  background: #2984ff;
  filter: drop-shadow(0px 4px 15px rgba(12, 77, 239, 0.25));
`;
