import { SVGProps } from "react";

export const CheckboxCircle = ({
  width = 22,
  height = 22,
  fill = "#666666",
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.1296 10.9999C18.1296 14.9375 14.9376 18.1295 11 18.1295C7.06239 18.1295 3.87035 14.9375 3.87035 10.9999C3.87035 7.06233 7.06239 3.87029 11 3.87029C14.9376 3.87029 18.1296 7.06233 18.1296 10.9999ZM20.1666 10.9999C20.1666 16.0625 16.0626 20.1666 11 20.1666C5.93737 20.1666 1.83331 16.0625 1.83331 10.9999C1.83331 5.93731 5.93737 1.83325 11 1.83325C16.0626 1.83325 20.1666 5.93731 20.1666 10.9999ZM15.3601 9.59915C15.7115 9.1599 15.6403 8.51895 15.2011 8.16755C14.7618 7.81615 14.1209 7.88737 13.7695 8.32662L10.2979 12.6661L7.51791 10.6804C7.06017 10.3534 6.42406 10.4594 6.0971 10.9172C5.77015 11.3749 5.87617 12.011 6.3339 12.338L9.11389 14.3237C9.99428 14.9525 11.2127 14.7834 11.8886 13.9386L15.3601 9.59915Z"
        fill={fill}
      />
    </svg>
  );
};
