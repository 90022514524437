import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import ReactMarkdown from "react-markdown";
import * as userApi from "apis/userApi";

import styled from "styled-components";
import { observer } from "mobx-react-lite";
import UserStore from "stores/UserStore";
import { Flex, FlexBetween, Pad } from "styles/styled";
import { CreateCompanySignUp } from "types/types";

import {
  CheckboxCircle,
  CheckboxCircleFilled,
  Loading,
} from "components/Icons";
import { v4 as uuidv4 } from "uuid";
import {
  Container,
  Inner,
  Title,
  InputContainer,
  InputButtonContainer,
  Input,
  SideButton,
  TermSection,
  TermAll,
  TermContent,
  InvalidText,
} from "./Regular";
import { InputFile } from "../ChangeCompanyProfile/InputFile";
import { InputListItem } from "components/InputListItem";
import { Stepper } from "components/Stepper";
import { Label } from "components/Label";
import { PolicyItem } from "components/PolicyItem";
import { PRIVACY_USE_URL, TERMS_OF_USE_URL } from "properties/externalLink";
import { ModalLayout } from "components";

import { uploadS3File } from "utils/awsS3";
import { validateEmail } from "utils/email";
import { validatePassword } from "utils/password";
import { importCeriticatePhone } from "utils/import";
import { validatePhoneNumberOnly } from "utils/phone";
import { CompleteSection } from "components/CompleteSection";

enum Step {
  MANAGER_INFO,
  COMPANY_INFO,
  COMPLETE,
}

enum InvalidMessage {
  EMAIL = "이메일 형식이 올바르지 않습니다.",
  EMAIL_CHECK_DUPLICATE = "이메일 중복확인을 해주세요.",
  PASSWORD = "비밀번호를 대소문자, 특수문자 포함 6자리 이상 입력해주세요.",
  PASSWORD2 = "비밀번호가 일치하지 않습니다.",
  MANAGER_NAME = "담당자 이름을 입력해주세요",
  PHONE = "담당자 핸드폰 번호를 입력해주세요.",
  USER_VERIFIED = "담당자 핸드폰 번호를 인증해주세요.",
  EMPLOYEE_FILE = "대표가 본인이 아닌 경우, 재직 증명서를 첨부해주세요.",
  COMPANY_NAME = "기업명을 입력해주세요.",
  CEO_NAME = "대표자 명을 입력해주세요.",
  REGISTRY_NUMBER = "사업자 등록번호 10자리를 입력해주세요.",
  FILE_PAPER = "사업자 등록증을 첨부해주세요.",
  COMPANY_ADDRESS = "사업장 주소지를 입력해주세요.",
  INDUSTRY_CATEGORY1 = "업종을 입력해주세요.",
  INDUSTRY_CATEGORY2 = "업태를 입력해주세요.",
  AGREE_TERMS_OF_USE = "서비스 이용약관에 동의해주세요.",
  AGREE_PRIVACY_USE = "개인정보 수집 및 이용에 동의해주세요.",
}

export const SignUpCompany = observer(() => {
  const userStore = useContext(UserStore);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState<Step>(Step.MANAGER_INFO);

  const [email, setEmail] = useState("");
  const [isUniqueEmail, setIsUniqueEmail] = useState<boolean>(false);
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [phone, setPhone] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [ceoName, setCeoName] = useState("");
  const [businessNumber, setBusinessNumber] = useState("");
  const [industryCategory1, setIndustryCategory1] = useState("");
  const [industryCategory2, setIndustryCategory2] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [managerName, setManagerName] = useState("");
  const [isCeo, setIsCeo] = useState(false);
  const [impUid, setImpUid] = useState("");
  const [businessFile, setBusinessFile] = useState<File | null>(null);
  const [employeeFile, setEmployeeFile] = useState<File | null>(null);
  const [businessFileKey, setBusinessFileKey] = useState<string | null>(null);
  const [employeeFileKey, setEmployeeFileKey] = useState<string | null>(null);
  const [uuid] = useState<string>(uuidv4());

  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [userVerified, setUserCeritificated] = useState(false);
  const [invalidMessage, setInvalidMessage] = useState<InvalidMessage | null>(
    null
  );
  const [showInvalid, setShowInvalid] = useState(false);

  const [agreeAllTerm, setAgreeAllTerm] = useState(false);
  const [agreeTermsofUse, setAgreeTermsofUse] = useState(false);
  const [agreePrivacyUse, setAgreePrivacyUse] = useState(false);
  const [agreeReceiveMessage, setAgreeReceiveMessage] = useState(false);
  const [agreeReceiveEmail, setAgreeReceiveEmail] = useState(false);
  const [targetTerm, setTerm] = useState<{
    title: string;
    content: string;
  } | null>(null);

  /** 유효성 검사 */
  useEffect(() => {
    const validate = () => {
      setShowInvalid(false);
      setInvalidMessage(null);

      if (step === Step.MANAGER_INFO) {
        switch (true) {
          case email.length < 2:
            setInvalidMessage(InvalidMessage.EMAIL);
            return;
          case !isUniqueEmail:
            setInvalidMessage(InvalidMessage.EMAIL_CHECK_DUPLICATE);
            return;
          case password.length < 1 || !isValidPassword:
            setInvalidMessage(InvalidMessage.PASSWORD);
            return;
          case password2.length < 1 || password !== password2:
            setInvalidMessage(InvalidMessage.PASSWORD2);
            return;
          case managerName.length < 2:
            setInvalidMessage(InvalidMessage.MANAGER_NAME);
            return;
          case !isValidPhone:
            setInvalidMessage(InvalidMessage.PHONE);
            return;
          case !userVerified:
            setInvalidMessage(InvalidMessage.USER_VERIFIED);
            return;
          case !isCeo && !employeeFile:
            setInvalidMessage(InvalidMessage.EMPLOYEE_FILE);
            return;
          default:
            return;
        }
      }

      if (step === Step.COMPANY_INFO) {
        switch (true) {
          case companyName.length < 2:
            setInvalidMessage(InvalidMessage.COMPANY_NAME);
            return;
          case ceoName.length < 2:
            setInvalidMessage(InvalidMessage.CEO_NAME);
            return;
          case businessNumber.length !== 10:
            setInvalidMessage(InvalidMessage.REGISTRY_NUMBER);
            return;
          case !businessFile:
            setInvalidMessage(InvalidMessage.FILE_PAPER);
            return;
          case companyAddress.length < 2:
            setInvalidMessage(InvalidMessage.COMPANY_ADDRESS);
            return;
          case industryCategory1.length < 2:
            setInvalidMessage(InvalidMessage.INDUSTRY_CATEGORY1);
            return;
          case industryCategory2.length < 2:
            setInvalidMessage(InvalidMessage.INDUSTRY_CATEGORY2);
            return;
          case !agreeTermsofUse:
            setInvalidMessage(InvalidMessage.AGREE_TERMS_OF_USE);
            return;
          case !agreePrivacyUse:
            setInvalidMessage(InvalidMessage.AGREE_PRIVACY_USE);
            return;
          default:
            return;
        }
      }
    };

    validate();
  }, [
    step,
    email,
    employeeFile,
    isCeo,
    isUniqueEmail,
    isValidPassword,
    managerName,
    password,
    password2,
    isValidPhone,
    userVerified,
    companyName,
    ceoName,
    businessNumber,
    industryCategory1,
    industryCategory2,
    companyAddress,
    businessFile,
    agreeTermsofUse,
    agreePrivacyUse,
  ]);

  const isInvalidStep1 = useMemo(
    () =>
      [
        InvalidMessage.EMAIL,
        InvalidMessage.EMAIL_CHECK_DUPLICATE,
        InvalidMessage.PASSWORD,
        InvalidMessage.PASSWORD2,
        InvalidMessage.MANAGER_NAME,
        InvalidMessage.PHONE,
        InvalidMessage.USER_VERIFIED,
        InvalidMessage.EMPLOYEE_FILE,
      ].includes(invalidMessage),
    [invalidMessage]
  );

  const isInvalidStep2 = useMemo(
    () =>
      [
        InvalidMessage.COMPANY_NAME,
        InvalidMessage.CEO_NAME,
        InvalidMessage.REGISTRY_NUMBER,
        InvalidMessage.FILE_PAPER,
        InvalidMessage.COMPANY_ADDRESS,
        InvalidMessage.INDUSTRY_CATEGORY1,
        InvalidMessage.INDUSTRY_CATEGORY2,
        InvalidMessage.AGREE_TERMS_OF_USE,
        InvalidMessage.AGREE_PRIVACY_USE,
      ].includes(invalidMessage),
    [invalidMessage]
  );

  const onSubmitStep1 = useCallback(() => {
    if (isInvalidStep1) {
      setShowInvalid(true);
      return;
    }

    setShowInvalid(false);
    setStep(Step.COMPANY_INFO);
    window.scrollTo(0, 0);
  }, [isInvalidStep1]);

  const onClickPrev = useCallback(() => {
    setStep(Step.MANAGER_INFO);
    setShowInvalid(false);
    window.scrollTo(0, 0);
  }, []);

  const onSubmitStep2 = async () => {
    if (loading) {
      return;
    }

    if (isInvalidStep2) {
      setShowInvalid(true);
      return;
    }

    setShowInvalid(false);
    setLoading(true);

    const body: CreateCompanySignUp = {
      email,
      password,
      password2,
      username: isCeo ? ceoName : managerName,
      phone_number: phone,
      imp_uid: impUid,
      is_sms_agree: agreeReceiveMessage,
      is_email_agree: agreeReceiveEmail,
      address: "",
      address_detail: "",
      postcode: "",
      company_name: companyName,
      ceo_name: ceoName,
      registry_number: businessNumber,
      industry_category1: industryCategory1,
      industry_category2: industryCategory2,
      company_address: companyAddress,
      manager_name: managerName,
      employment_certificate: employeeFileKey,
      file_paper: businessFileKey,
      is_employment_ceo: isCeo,
    };

    try {
      const result = await userStore.signUp(body);
      if (result) {
        setLoading(false);
        setStep(Step.COMPLETE);
        window.scrollTo(0, 0);
      } else {
        throw Error();
      }
    } catch (e) {
      setLoading(false);
      alert("회원가입에 실패했습니다. ");
    }
  };

  const onHandleChangeEmail = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const email = e.target.value;
      const validated = validateEmail(email);
      setIsValidEmail(validated);
      setEmail(email);
    },
    []
  );

  const onHandleChangePassword = useCallback((value: string) => {
    const isValid = validatePassword(value);
    setIsValidPassword(isValid);
    setPassword(value);
  }, []);

  const onHandleChangePhone = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const phone = e.target.value;
      const validated = validatePhoneNumberOnly(phone);
      setIsValidPhone(validated);
      setPhone(phone);
    },
    []
  );

  const onClickCheckDuplicateEmail = async () => {
    if (!isValidEmail) {
      alert("이메일을 입력해주세요.");
      return;
    }

    const result = await userStore.checkUniqueEmail(email);

    if (result.isUnique) {
      setIsUniqueEmail(true);
    } else {
      setIsUniqueEmail(false);
    }
    alert(result.message);
  };

  const onClickVerifyPhone = async () => {
    if (!isValidPhone) {
      alert("전화번호를 입력해주세요.");
      return;
    }

    // 이미 등록된 번호인지 확인
    const result = await userStore.checkUniquePhone(phone);
    if (!result.isUnique) {
      alert(result.message);
      return;
    }

    // 인증번호 요청
    importCeriticatePhone(
      phone,
      callbackCertificatePhoneSuccess,
      callbackCertificatePhoneFail
    );
  };

  const callbackCertificatePhoneFail = (errorMesasge) => {
    // alert(errorMesasge);
    setUserCeritificated(false);
  };

  const callbackCertificatePhoneSuccess = async (imp_uid: string) => {
    // 서버 인증
    const response = await userApi.userCerification({ imp_uid: imp_uid });
    if (!response || response instanceof Error) {
      alert("문제가 발생했습니다.");
      console.error(response);
    } else {
      setImpUid(imp_uid);
      setUserCeritificated(true);
    }
  };

  const onClickCeoCheckbox = () => {
    setIsCeo((prev) => !prev);
  };

  const onHandleChangeBusinessFile = async (e) => {
    try {
      const file = e.target.files[0];
      setBusinessFile(file);
      const response = await uploadS3File("bf", file, uuid);
      setBusinessFileKey(response.Key);
    } catch (err) {
      console.error("파일 업로드 실패");
    }
  };

  const onHandleChangeEmployeeFile = async (e) => {
    try {
      const file = e.target.files[0];
      setEmployeeFile(file);
      const response = await uploadS3File("ef", file, uuid);
      setEmployeeFileKey(response.Key);
    } catch (err) {
      console.error("파일 업로드 실패");
    }
  };

  const onClickAreeTermAll = () => {
    if (agreeAllTerm) {
      setAgreeAllTerm(false);
      setAgreeTermsofUse(false);
      setAgreePrivacyUse(false);
      setAgreeReceiveMessage(false);
      setAgreeReceiveEmail(false);
    } else {
      setAgreeAllTerm(true);
      setAgreeTermsofUse(true);
      setAgreePrivacyUse(true);
      setAgreeReceiveMessage(true);
      setAgreeReceiveEmail(true);
    }
  };

  return (
    <>
      {step !== Step.COMPLETE ? (
        /**
         * 회원가입
         */
        <Container>
          <Inner>
            <Title>기업 회원가입</Title>
            <Stepper
              steps={["담당자 정보", "기업 정보", "가입 완료"]}
              activeStep={step}
            />

            {/**
             * 1단계: 담당자 정보
             */}
            {step === Step.MANAGER_INFO && (
              <InputContainer>
                <Pad pb={24}>
                  <Label isBold={true}>이메일</Label>
                  <InputButtonContainer>
                    <Input
                      type="email"
                      placeholder="이메일을 입력해주세요"
                      onChange={onHandleChangeEmail}
                      value={email}
                      isInvalid={
                        showInvalid && invalidMessage === InvalidMessage.EMAIL
                      }
                    />
                    <SideButton
                      active={isValidEmail}
                      onClick={onClickCheckDuplicateEmail}
                    >
                      중복 확인
                    </SideButton>
                  </InputButtonContainer>
                </Pad>
                <Pad pb={24}>
                  <InputListItem
                    label="비밀번호"
                    type="password"
                    isLabelBold={true}
                    value={password}
                    placeholder="대소문자, 특수문자 포함 6자리 이상 입력해주세요"
                    onChange={onHandleChangePassword}
                    isInvalid={
                      showInvalid && invalidMessage === InvalidMessage.PASSWORD
                    }
                  />
                </Pad>
                <Pad pb={24}>
                  <InputListItem
                    label="비밀번호 재입력"
                    type="password"
                    isLabelBold={true}
                    value={password2}
                    placeholder="비밀번호를 다시 입력해주세요"
                    onChange={setPassword2}
                    isInvalid={
                      showInvalid && invalidMessage === InvalidMessage.PASSWORD2
                    }
                  />
                </Pad>
                <Pad pb={24}>
                  <InputListItem
                    label="담당자 이름"
                    isLabelBold={true}
                    value={managerName}
                    placeholder="기업 담당자 이름 입력"
                    onChange={setManagerName}
                    isInvalid={
                      showInvalid &&
                      invalidMessage === InvalidMessage.MANAGER_NAME
                    }
                  />
                </Pad>
                <InputContainer>
                  <Label isBold={true}>담당자 핸드폰 번호</Label>
                  <InputButtonContainer>
                    <Input
                      type="phone"
                      placeholder="-를 제외하고 입력"
                      onChange={onHandleChangePhone}
                      isInvalid={
                        showInvalid && invalidMessage === InvalidMessage.PHONE
                      }
                    />
                    <SideButton
                      active={isValidPhone}
                      onClick={onClickVerifyPhone}
                    >
                      {userVerified ? "인증완료" : "인증하기"}
                    </SideButton>
                  </InputButtonContainer>
                </InputContainer>
                <Pad pb={54}>
                  <Label isBold={true}>담당자 재직증명서</Label>
                  <InputFile
                    inputId="employeeFileInput"
                    labelText="재직 증명서 첨부"
                    placeholder="jpg, png, pdf (최대5M)"
                    fileName={employeeFile?.name}
                    disabled={isCeo}
                    onHandleChangeFile={onHandleChangeEmployeeFile}
                  />
                  <Pad pt={20}>
                    <Flex
                      gap={8}
                      onClick={onClickCeoCheckbox}
                      className="pointer"
                    >
                      {isCeo ? (
                        <CheckboxCircleFilled
                          height={22}
                          width={22}
                          fill="#1B58F1"
                        />
                      ) : (
                        <CheckboxCircle height={22} width={22} fill="#C4C9D1" />
                      )}
                      <div>대표자 본인이 직접 신청합니다.</div>
                    </Flex>
                  </Pad>
                </Pad>
                {showInvalid && (
                  <InvalidText>
                    <p>{invalidMessage}</p>
                  </InvalidText>
                )}
                <Button active={!isInvalidStep1} onClick={onSubmitStep1}>
                  <span>다음</span>
                </Button>
              </InputContainer>
            )}

            {/**
             * 2단계: 기업 정보
             */}
            {step === Step.COMPANY_INFO && (
              <InputContainer>
                <Pad pb={24}>
                  <InputListItem
                    label="기업 이름"
                    isLabelBold={true}
                    value={companyName}
                    placeholder="기업/업체/단체명 입력"
                    onChange={setCompanyName}
                    isInvalid={
                      showInvalid &&
                      invalidMessage === InvalidMessage.COMPANY_NAME
                    }
                  />
                </Pad>
                <Pad pb={24}>
                  <InputListItem
                    label="기업 대표자 이름"
                    isLabelBold={true}
                    value={ceoName}
                    placeholder="대표자명 입력 (1명만)"
                    onChange={setCeoName}
                    isInvalid={
                      showInvalid && invalidMessage === InvalidMessage.CEO_NAME
                    }
                  />
                </Pad>
                <Pad pb={10}>
                  <InputListItem
                    label="사업자 등록번호"
                    isLabelBold={true}
                    value={businessNumber}
                    placeholder="사업자 번호 입력 (-제외)"
                    onChange={setBusinessNumber}
                    isInvalid={
                      showInvalid &&
                      invalidMessage === InvalidMessage.REGISTRY_NUMBER
                    }
                  />
                </Pad>
                <Pad pb={24}>
                  <InputFile
                    inputId="businessFileInput"
                    labelText="사업자 등록증 첨부"
                    placeholder="jpg, png, pdf (최대5M)"
                    fileName={businessFile?.name}
                    onHandleChangeFile={onHandleChangeBusinessFile}
                  />
                </Pad>
                <Pad pb={24}>
                  <InputListItem
                    label="사업장 주소지"
                    isLabelBold={true}
                    value={companyAddress}
                    placeholder="사업장 주소지 입력"
                    onChange={setCompanyAddress}
                    isInvalid={
                      showInvalid &&
                      invalidMessage === InvalidMessage.COMPANY_ADDRESS
                    }
                  />
                </Pad>
                <Pad pb={24}>
                  <FlexBetween gap={10}>
                    <InputListItem
                      label="업종"
                      isLabelBold={true}
                      value={industryCategory1}
                      placeholder="업종 입력"
                      onChange={setIndustryCategory1}
                      isInvalid={
                        showInvalid &&
                        invalidMessage === InvalidMessage.INDUSTRY_CATEGORY1
                      }
                    />

                    <InputListItem
                      label="업태"
                      isLabelBold={true}
                      value={industryCategory2}
                      placeholder="업태 입력"
                      onChange={setIndustryCategory2}
                      isInvalid={
                        showInvalid &&
                        invalidMessage === InvalidMessage.INDUSTRY_CATEGORY2
                      }
                    />
                  </FlexBetween>
                </Pad>
                <Pad pb={12}>
                  {/* 서비스 전체 약관 동의 */}
                  <TermSection>
                    <TermAll onClick={onClickAreeTermAll}>
                      {agreeAllTerm ? (
                        <CheckboxCircleFilled
                          height={22}
                          width={22}
                          fill={"#1B58F1"}
                        />
                      ) : (
                        <CheckboxCircle
                          height={22}
                          width={22}
                          fill={"#C4C9D1"}
                        />
                      )}
                      <div>서비스 전체 약관에 동의합니다</div>
                    </TermAll>
                    <div>
                      <PolicyItem
                        text="서비스 이용약관"
                        isRequired
                        isChecked={agreeTermsofUse}
                        toggle={setAgreeTermsofUse}
                        // openModal={() => setTargetTerm("TermsOfUse")}
                        openModal={() => window.open(TERMS_OF_USE_URL)}
                      />

                      <PolicyItem
                        text="개인정보 수집 및 이용"
                        isRequired
                        isChecked={agreePrivacyUse}
                        toggle={setAgreePrivacyUse}
                        // openModal={() => setTargetTerm("PrivacyUse")}
                        openModal={() => window.open(PRIVACY_USE_URL)}
                      />

                      <PolicyItem
                        text="이벤트 및 혜택 문자 수신"
                        isChecked={agreeReceiveMessage}
                        toggle={setAgreeReceiveMessage}
                      />

                      <PolicyItem
                        text="이벤트 및 혜택 이메일 수신"
                        isChecked={agreeReceiveEmail}
                        toggle={setAgreeReceiveEmail}
                      />
                    </div>
                  </TermSection>
                </Pad>

                {targetTerm && (
                  <ModalLayout
                    title={targetTerm.title}
                    onClose={() => setTerm(null)}
                  >
                    <TermContent>
                      <ReactMarkdown>{targetTerm.content}</ReactMarkdown>
                    </TermContent>
                  </ModalLayout>
                )}

                {showInvalid && (
                  <InvalidText>
                    <p>{invalidMessage}</p>
                  </InvalidText>
                )}
                <Flex gap={10}>
                  <Button active={false} onClick={onClickPrev}>
                    <span>이전</span>
                  </Button>
                  <Button active={!isInvalidStep2} onClick={onSubmitStep2}>
                    <span>
                      다음
                      {loading && (
                        <LoadingContainer>
                          <Loading />
                        </LoadingContainer>
                      )}
                    </span>
                  </Button>
                </Flex>
              </InputContainer>
            )}
          </Inner>
        </Container>
      ) : (
        /**
         * 회원가입 완료
         */
        <CompleteSection memberType="company" />
      )}
    </>
  );
});

const Button = styled.div<{ active: boolean }>`
  border-radius: 100px;
  position: relative;
  width: 100%;
  margin: 0 auto;
  cursor: pointer;
  display: flex;

  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -1px;

  background: ${(props) => (props.active ? "#1b58f1" : "#e1e1e1")};

  color: #ffffff;
  height: 100%;
  width: 100%;
  padding: 16px;
  position: relative;
`;

const LoadingContainer = styled.div`
  position: absolute;
  right: 100px;
  top: 12px;
`;
