import styled from "styled-components";
import { Icons } from "components";
import ReactPaginate from "react-paginate";
import { Pad } from "styles/styled";
import { useEffect } from "react";

interface Props {
  size: number;
  itemsPerPage?: number;
  defaultPage: number;
  setCurrentPage: (page: number) => void;
  arrowColor?: string;
}

export const Pagination: React.FC<Props> = ({
  size,
  itemsPerPage = 10,
  setCurrentPage,
  defaultPage = 1,
  arrowColor,
}) => {
  const pageCount = size ? Math.ceil(size / itemsPerPage) : 1;

  const handlePageClick = (event) => {
    setCurrentPage(event.nextSelectedPage + 1);
  };

  return (
    <Container>
      <ReactPaginate
        key={defaultPage}
        breakLabel="..."
        previousLabel={
          <Pad pt={5}>
            <Icons.ArrowLeftSimple fill={arrowColor ? arrowColor : "#333333"} />
          </Pad>
        }
        nextLabel={
          <Pad pt={5}>
            <Icons.ArrowRightSimple
              fill={arrowColor ? arrowColor : "#333333"}
            />
          </Pad>
        }
        initialPage={defaultPage - 1}
        onClick={handlePageClick}
        pageRangeDisplayed={3}
        pageCount={pageCount}
        pageClassName="pageItem"
        pageLinkClassName="pageLink"
        previousClassName="pageItem"
        previousLinkClassName="pageLink"
        nextClassName="pageItem"
        nextLinkClassName="pageLink"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
      />
    </Container>
  );
};

const Container = styled.div`
  justify-content: center;
  align-items: center;
  padding-bottom: 24px;
  display: flex;
  .pageItem a {
    display: block;
    width: 20px;
    text-align: center;
  }

  .pageLink {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: -1px;
    color: #999999;
  }

  .active .pageLink {
    font-weight: 700;
    color: #1b58f1;
  }

  .pagination {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    gap: 4px;
  }

  .active {
    font-weight: 700;
    color: #1b58f1;
  }
`;
