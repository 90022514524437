import { MenuBar } from "components/MenuBar";
import React from "react";
import styled, { css } from "styled-components";

const BrandLayoutContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  padding-bottom: 64px;
  margin-left: 239px;
  background: #f6f8ff;
`;

interface IProps {
  children?: JSX.Element | JSX.Element[];
}

const BrandLayout = ({ children }: IProps) => {
  return (
    <BrandLayoutContainer>
      <MenuBar />
      {children}
    </BrandLayoutContainer>
  );
};

export default BrandLayout;
