import { InputSelectionWithDropdown, Pagination } from "components";
import { ModalLayout } from "components";
import { Loading } from "components/Icons";
import { useCallback, useContext, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { SmsType, TemplateType } from "types/types";
import { TableTbody, TemplateRow } from "./TemplateRow";
import { observer } from "mobx-react-lite";
import {
  Pad,
  Center,
  TextGray6,
  ButtonRoundPrimary,
  ButtonRoundOutline,
  Flex,
  Input,
  ButtonPrimary,
} from "styles/styled";
import TemplateStore from "stores/TemplateStore";
import { smsTypeOptions } from "properties/content";
import { useCustomNavigate } from "hooks/useCustomNavigate";
import { PATH } from "properties/menu";

export * as Icons from "components/Icons";

interface Props {
  onClose: () => void;
  openConfirmModal: () => void;
  checkedTemplate: TemplateType;
  setCheckedTemplate: (template: TemplateType) => void;
}

export const TemplateModal: React.FC<Props> = observer(
  ({ onClose, openConfirmModal, checkedTemplate, setCheckedTemplate }) => {
    const store = useContext(TemplateStore);
    const navigate = useCustomNavigate();
    const [searchKeyword, setSearchKeyword] = useState<string>("");
    const [selectedMessageType, setSelectedMessageType] =
      useState<SmsType | null>(null);

    useEffect(() => {
      store.fetchTemplates();
    }, []);

    const moveTemplateDetail = useCallback((template: TemplateType) => {
      navigate.moveTo(
        PATH.TEMPLATE_DETAIL_PLAIN.replace(":templateId", String(template.id))
      );
    }, []);
    const onClickTemplate = (template: TemplateType) => {
      if (checkedTemplate?.id === template.id) {
        setCheckedTemplate(null);
      } else {
        setCheckedTemplate(template);
      }
    };

    const isActiveCheckbox = useCallback(
      (template: TemplateType) => {
        return template.id === checkedTemplate?.id;
      },
      [checkedTemplate]
    );

    const onClickMessageType = useCallback((type: SmsType) => {
      setSelectedMessageType(type);
    }, []);

    const onClickSearchButton = useCallback(() => {
      // TODO: 로직 구현
      if (selectedMessageType && searchKeyword !== "") {
        console.log("둘다 필터링");
      } else if (selectedMessageType) {
        console.log("메시지 타입만 필터링");
      } else if (searchKeyword) {
        console.log("템플릿 이름만 필터링");
      } else {
        store.fetchTemplates();
        console.log("필터링 안함");
      }
    }, []);
    return (
      <ModalContainer>
        <ModalLayout title="템플릿 불러오기" onClose={onClose} width={844}>
          <Container>
            {/* 로딩 */}
            {!store.templates && (
              <LoadingContainer>
                <Loading />
              </LoadingContainer>
            )}

            {store.templates && (
              <Container>
                <Pad pt={32} pb={16}>
                  <Flex gap={8} justifyContent="flex-end">
                    <InputSelectionWithDropdown
                      borderColor={"#D7DBE2"}
                      noSelectOptionLabel={"메시지 타입"}
                      selectedOptionLabel={selectedMessageType?.uppercaseKey}
                      options={smsTypeOptions}
                      onClickOption={onClickMessageType}
                    />
                    <Input
                      width={280}
                      placeholder="템플릿 이름"
                      value={searchKeyword}
                      onChange={(e) => setSearchKeyword(e.target.value)}
                    />
                    <ButtonPrimary
                      radius={6}
                      bgColor="#333"
                      padding="12px 24px"
                      onClick={onClickSearchButton}
                    >
                      검색
                    </ButtonPrimary>
                  </Flex>
                </Pad>
                <Pad pb={32}>
                  <Table>
                    <TableTbody />
                    {store.templates &&
                      store.templates.map((template) => (
                        <TemplateRow
                          key={template.id}
                          template={template}
                          onClickTemplate={() => onClickTemplate(template)}
                          isActiveCheckbox={() => isActiveCheckbox(template)}
                          moveTemplateDetail={() =>
                            moveTemplateDetail(template)
                          }
                        />
                      ))}
                    {store.templates && store.templates.length === 0 && (
                      <Pad pt={40} pb={20}>
                        <Center>
                          <TextGray6>등록된 템플릿이 없습니다.</TextGray6>
                        </Center>
                      </Pad>
                    )}
                  </Table>
                </Pad>

                <Pagination
                  defaultPage={store.currentPage}
                  size={1}
                  setCurrentPage={store.setCurrentPage}
                />

                <Flex gap={20} justifyContent="center">
                  <ButtonRoundOutline width={274} onClick={onClose}>
                    취소
                  </ButtonRoundOutline>
                  <ButtonRoundPrimary
                    disabled={!checkedTemplate}
                    width={274}
                    onClick={openConfirmModal}
                  >
                    템플릿 적용하기
                  </ButtonRoundPrimary>
                </Flex>
              </Container>
            )}
          </Container>
        </ModalLayout>
      </ModalContainer>
    );
  }
);

const ModalContainer = styled.div`
  .rodal-dialog {
    overflow-y: inherit !important;
  }
`;
const Container = styled.div`
  min-height: 300px;

  .groupContainer {
    width: 210px;
  }

  .groupListContainer {
    border: 1px solid #d7dbe2;
    border-radius: 6px;
    padding: 12px 10px;
    margin-bottom: 24px;
    min-height: 300px;
    overflow-y: auto;
  }

  .subTitle {
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    padding: 16px 0px;
    letter-spacing: -1px;
    color: #333333;
  }
  .rodal-dialog {
    overflow-y: auto;
    transform: scale(0.95);
    min-width: 844px !important;
    width: 80% !important;
    max-width: 1000px;
  }
`;
const TR = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 4px;
  div {
    text-align: center;
  }
  div:nth-child(1) {
    width: 60px;
  }
  div:nth-child(2) {
    width: 120px;
  }
  div:nth-child(3),
  div:nth-child(4) {
    flex: 1;
  }
  div:nth-child(5) {
    width: 70px;
  }
`;

const TBody = styled(TR)`
  background: #fafafa;
  margin-bottom: 8px;
`;

const TD = styled.div<{ active?: boolean; isEditable?: boolean }>`
  padding: 4px 10px;
  color: #333333;
  text-align: center;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    display: inline-block;
  }

  ${(props) =>
    props.active &&
    css`
      color: #ec1c24;
      font-weight: 700;
    `}

  ${(props) =>
    props.isEditable &&
    css`
      border: 1px solid #d7dbe2;
      border-radius: 6px;
    `}
`;

const TH = styled(TD)``;

const GroupItem = styled.div<{ active?: boolean }>`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: flex-start;
  letter-spacing: -1px;
  color: #333333;
  padding-bottom: 10px;
  cursor: pointer;
  &::before {
    content: "• ";
    padding: 0 10px;
  }

  ${(props) =>
    props.active &&
    css`
      font-weight: 700;
      color: #1b58f1;
    `}
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const Table = styled.div`
  min-height: 380px;
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;
