import styled, { css } from "styled-components";

export const Container = styled.div`
  padding: 24px;
  header {
    max-width: 402px;
  }
  h3 {
    color: #333;
    font-size: 24px;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -1px;
  }
  li {
    color: #666;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -1px;
    &::before {
      content: "• ";
      padding-right: 4px;
    }
  }
  section {
    min-height: 400px;
  }
`;

export const LineVertical = styled.div<{ height: number }>`
  height: ${(props) => props.height}px;
  width: 1px;
  background: #999;
`;

export const LeftSection = styled.div`
  width: 402px;
`;
export const RightSection = styled.div`
  width: 321px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 12px;
  div {
    flex: 1;
  }
`;
export const Button = styled.div<{ active?: boolean; bgColor?: string }>`
  background: #e1e1e1;
  margin-top: 8px;
  border-radius: 100px;
  text-align: center;
  padding: 16px;
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -1px;
  color: #ffffff;
  ${(props) =>
    props.active &&
    css`
      cursor: pointer;
      background-color: #1b58f1;
    `}
  ${(props) =>
    props.bgColor &&
    css`
      background-color: ${props.bgColor};
    `}
`;

export const ButtonBlack = styled(Button)`
  background-color: #333333;
`;
export const ButtonOutline = styled(Button)`
  border: 0.5px solid #333333;
  color: #333333;
  background-color: #ffffff;
`;
