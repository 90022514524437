import styled from "styled-components";

import { ButtonPrimary } from "components";
import { SmsType, WalletType } from "types/types";
import { getMembershipPricing } from "utils/getMembershipPricing";

interface Props {
  smsTypeOptions: SmsType[];
  wallet: WalletType | null;
}

export const MyPricingPlan: React.FC<Props> = ({ smsTypeOptions, wallet }) => {
  return (
    <Container>
      <TitleContainer>
        <SubTitle>나의 요금제</SubTitle>

        <ButtonPrimary
          label={"대량발송 상담받기"}
          onClick={() => window.open("https://forms.gle/zbg8HVnbSfn2GZf78")}
        />
      </TitleContainer>
      <PricingPlan>
        {wallet ? wallet.membership.name : "표준 요금제"}
      </PricingPlan>
      <Desc>대량 발송 요금제를 이용하시면 더 저렴하게 이용하실 수 있어요</Desc>
      <ul>
        {smsTypeOptions.map((type, index) => (
          <GrayLi key={index}>
            <div>{type.label}</div>
            <div>
              <b>
                {wallet ? getMembershipPricing(wallet.membership, type.key) : 0}
              </b>
              원
            </div>
          </GrayLi>
        ))}
      </ul>
    </Container>
  );
};
const Container = styled.section`
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 24px;
  flex: 1;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Desc = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -1px;
  color: #999999;
  padding-bottom: 10px;
  border-bottom: 0.5px solid #999999;
  margin-bottom: 24px;
`;

const Li = styled.li`
  padding: 0 16px;
  letter-spacing: -1px;
  color: #333333;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  padding-bottom: 12px;
  display: flex;
  justify-content: space-between;
`;

const SubTitle = styled.h4`
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  letter-spacing: -1px;
  color: #333333;
  padding-bottom: 16px;
`;

const GrayLi = styled(Li)`
  color: #333333;
`;

const PricingPlan = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -1px;

  color: #1b58f1;
  padding: 4px 10px;

  background: rgba(27, 88, 241, 0.08);
  border-radius: 6px;
  width: fit-content;
  margin-bottom: 8px;
`;
