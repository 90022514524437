import { InputWithSearch } from "components";
import { useState } from "react";
import styled from "styled-components";
import { FlexEnd } from "styles/styled";
import { ResultDetailType } from "types/types";

interface Props {
  receivers: ResultDetailType[];
  onSearchPhone: (phone: string) => void;
}
export const Receivers: React.FC<Props> = ({ receivers, onSearchPhone }) => {
  const [searchedPhone, setSearchedPhone] = useState("");
  return (
    <Container>
      <FlexEnd gap={8} pb={16}>
        <div>
          <InputWithSearch
            placeholder={"수신자번호 검색"}
            value={""}
            onInputChange={setSearchedPhone}
            onClickSearchButton={() => onSearchPhone(searchedPhone)}
          />
        </div>
      </FlexEnd>

      <TableContainer>
        <Thead>
          <div className="tr">
            <div className="td">No</div>
            <div className="td">수신자번호</div>
            <div className="td">이름</div>
            <div className="td">결과</div>
            <div className="td">상세사유</div>
          </div>
        </Thead>
        <TBody>
          {receivers.map((receiver, index) => (
            <div className="tr" key={receiver.id}>
              <div className="td">{index + 1}</div>
              <div className="td">{receiver.receiver} </div>
              <div className="td">
                {receiver.receiver_name === "" ? "-" : receiver.receiver_name}
              </div>
              <div className="td">
                {receiver.result_code === "발송 완료" && (
                  <StatusText color="#1b58f1">
                    {receiver.result_code}
                  </StatusText>
                )}
                {receiver.result_code === "발송 실패" && (
                  <StatusText color="#EC1C24">
                    {receiver.result_code}
                  </StatusText>
                )}
                {receiver.result_code === "발송 대기" ||
                  (receiver.result_code === "발송 중" && (
                    <StatusText color="#FAAF40">
                      {receiver.result_code}
                    </StatusText>
                  ))}
              </div>
              <div className="td">
                {receiver.response_msg === "" ? "-" : receiver.response_msg}
              </div>
            </div>
          ))}
        </TBody>
      </TableContainer>
    </Container>
  );
};

const Container = styled.div``;

const TableContainer = styled.div`
  .tr {
    padding: 0 24px;
    display: flex;
    width: 100%;
  }
  .td {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: -1px;
    color: #333333;
    padding: 12px;
    text-align: center;
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      width: 120px;
    }
    &:nth-child(5) {
      flex: 1;
    }
  }
`;
const Thead = styled.div`
  display: block;
  background: #fafafa;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -1px;
  color: #333333;
  margin: 0 -24px;
`;
const TBody = styled.div`
  display: block;

  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -1px;
  color: #333333;
  margin: 0 -24px;
`;

const StatusText = styled.div<{ color: string }>`
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -1px;
  color: ${(props) => props.color};
`;
