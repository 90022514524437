import { MessageUppercaseType, SendMessageRequestType } from "types/types";
import { getHeaderWithToken, serverApi } from "./serverApi";
import { addParameterToURL } from "utils/addParameterToURL";
import { getAccessToken } from "utils/session";

/** 메시지 발송 */
export const sendMessage = async (body: SendMessageRequestType) => {
  const url = body.reserve_time
    ? "/api/v2/messages/reserved"
    : "/api/v2/messages";

  var formData = new FormData();
  formData.append("title", body.title);
  formData.append("content", String(body.content));
  formData.append("receiver", JSON.stringify(body.receiver));
  formData.append("call_number", String(body.call_number));
  formData.append("is_ad", String(body.is_ad ? 1 : 0));
  formData.append("group_ids", JSON.stringify(body.group_ids));
  formData.append("image_link", JSON.stringify(body.image_link));

  if (body.reserve_time) {
    formData.append("reserve_time", body.reserve_time);
  }
  const headers = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      "Content-Type": "multipart/form-data",
    },
  };

  return serverApi
    .post(url, formData, headers)
    .then((response: any) => response.data);
};

/** 메시지 발송 내역 조회 */
export const fetchMessageSendResults = async ({
  page = 1,
  size = 10,
  endDate,
  startDate,
  messageType,
  isReserved = false,
}: {
  page?: number;
  size?: number;
  startDate: string;
  endDate: string;
  messageType: MessageUppercaseType | null;
  isReserved?: boolean;
}) => {
  let url = "/api/v2/messages/group/";
  url = addParameterToURL(url, "page", page);
  url = addParameterToURL(url, "size", size);
  url = addParameterToURL(url, "start_date", startDate);
  url = addParameterToURL(url, "end_date", endDate);
  if (messageType) url = addParameterToURL(url, "msg_type", messageType);
  if (isReserved)
    url = addParameterToURL(url, "is_reserved", String(isReserved));

  const response = await serverApi.get(url, getHeaderWithToken());
  return response.data;
};

/** 발송 통계 정보 조회 */
export const fetchStatistics = async () => {
  return serverApi
    .get("/api/v2/messages/statistics", getHeaderWithToken())
    .then((response: any) => response.data);
};

/** 메시지 발송 내역 조회 */
export const fetchRejectPhoneNumbers = async ({
  page = 1,
  size = 10,
  endDate,
  startDate,
  sender,
}: {
  page?: number;
  size?: number;
  startDate: string;
  endDate: string;
  sender?: string;
}) => {
  let url = "/api/rejectnum/";
  url = addParameterToURL(url, "page", page);
  url = addParameterToURL(url, "size", size);
  url = addParameterToURL(url, "start_date", startDate);
  url = addParameterToURL(url, "end_date", endDate);
  if (sender) url = addParameterToURL(url, "sender", sender);

  const response = await serverApi.get(url, getHeaderWithToken());
  return response.data;
};

/** 메시지 발송 내역 상세 조회 */
export const fetchMessageResultDetail = async (groupId: string) => {
  let url = "/api/v2/messages/detail";
  url = addParameterToURL(url, "group_id", groupId);

  return serverApi
    .get(url, getHeaderWithToken())
    .then((response: any) => response.data);
};

// 예약 메시지 취소
export const cancelReservedMessage = async (groupId: string) => {
  const data = {
    group_id: groupId,
  };
  return serverApi
    .delete(`/api/v2/messages/reserved`, {
      ...getHeaderWithToken(),
      data,
    })
    .then((response: any) => response.data);
};
