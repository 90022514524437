import { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { smsTypeOptions } from "properties/content";
import { SmsType } from "types/types";

import * as S from "./styled";
import { ButtonPrimary, Flex, Input, Pad } from "styles/styled";
import { InputSelectionWithDropdown, MessageLayout } from "components";
import { TemplateTable } from "./components/TemplateTable";
import TemplateStore from "stores/TemplateStore";
import { useCustomNavigate } from "hooks/useCustomNavigate";
import { PATH } from "properties/menu";

export const TemplateListPlain = observer(() => {
  const navigation = useCustomNavigate();
  const templateStore = useContext(TemplateStore);

  const [selectedMessageType, setSelectedMessageType] =
    useState<SmsType | null>(null);
  const [searchTemplateName, setSearchTemplateName] = useState<string>("");

  useEffect(() => {
    templateStore.fetchTemplates();
  }, [templateStore]);

  const onClickMessageType = (type: SmsType) => {
    setSelectedMessageType(type);
  };

  const onClickSearchButton = () => {
    // TODO: 로직 구현
    if (selectedMessageType && searchTemplateName !== "") {
      console.log("둘다 필터링");
    } else if (selectedMessageType) {
      console.log("메시지 타입만 필터링");
    } else if (searchTemplateName) {
      console.log("템플릿 이름만 필터링");
    } else {
      templateStore.fetchTemplates();
      console.log("필터링 안함");
    }
  };

  return (
    <MessageLayout title="템플릿 관리">
      <S.Container>
        <Pad pb={60}>
          <header>
            <Pad pb={24}>
              <h3>일반 문자</h3>
            </Pad>
            <Pad pb={62}>
              <ul>
                <li>고객님의 일반문자 템플릿 목록입니다.</li>
                <li>
                  템플릿을 추가하시려면 템플릿 추가 버튼을 통해 추가 등록
                  해주세요.
                </li>
              </ul>
            </Pad>
            <Flex gap={20} justifyContent="flex-end">
              <Flex gap={8}>
                <Input
                  width={280}
                  placeholder="템플릿 이름"
                  value={searchTemplateName}
                  onChange={(e) => setSearchTemplateName(e.target.value)}
                />
                <InputSelectionWithDropdown
                  borderColor={"#D7DBE2"}
                  noSelectOptionLabel={"메시지 타입"}
                  selectedOptionLabel={selectedMessageType?.uppercaseKey}
                  options={smsTypeOptions}
                  onClickOption={onClickMessageType}
                />
              </Flex>
              <ButtonPrimary
                radius={6}
                bgColor="#333"
                padding="12px 24px"
                onClick={onClickSearchButton}
              >
                조회
              </ButtonPrimary>
              <S.LineVertical height={36} />
              <ButtonPrimary
                radius={6}
                onClick={() => navigation.moveTo(PATH.TEMPLATE_NEW_PLAIN)}
              >
                템플릿 추가
              </ButtonPrimary>
            </Flex>
          </header>
          <section>
            <TemplateTable templates={templateStore.templates} />
          </section>
        </Pad>
      </S.Container>
    </MessageLayout>
  );
});
