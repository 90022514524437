import { ImgHomeScreenshot, ImgHomeScreenshotMobile } from "assets";
import useResponsive from "hooks/useResponsive";
import { MEDIA_QUERY } from "properties/constant";
import styled from "styled-components";

export const Screenshot = () => {
  const { isDesktop } = useResponsive();
  return (
    <Container>
      <div>
        <Title>
          메시지 발송 후 <b>실시간으로 전송현황 및 결과</b>를<br /> 확인할 수
          있어요!
        </Title>
        <Image
          src={isDesktop ? ImgHomeScreenshot : ImgHomeScreenshotMobile}
          alt="스크린샷"
        />
      </div>
    </Container>
  );
};

const Container = styled.div`
  padding: 100px 20px;
  text-align: center;
  background: #f6f8ff;
`;

const Title = styled.div`
  font-weight: 400;
  font-size: 34px;
  line-height: 50px;
  letter-spacing: -1px;
  color: #333333;
  padding-bottom: 50px;
  b {
    font-weight: 700;
  }

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 28px;
    line-height: 36px;
    word-break: keep-all;
    max-width: 480px;
    margin: 0 auto;
  }
`;
const Image = styled.img`
  max-width: 853px;
  width: 100%;
  margin: 0 auto;
`;
