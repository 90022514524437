import { MEDIA_QUERY } from "properties/constant";
import styled from "styled-components";

export const SectionTitle = styled.h2<{ color?: string }>`
  font-size: 34px;
  line-height: 120%;
  font-weight: 700;
  letter-spacing: -1px;
  text-align: center;
  padding-bottom: 24px;
  color: ${(props) => (props.color ? props.color : "#333333")};
  b {
    font-weight: 700;
  }

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 28px;
  }
`;

export const SectionSubTitle = styled.h3<{ color?: string }>`
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -1px;
  color: ${(props) => (props.color ? props.color : "#333333")};

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 16px;
  }
`;

export const PricingSectionBottom = styled.div<{ shadow?: boolean }>`
  display: flex;
  justify-content: center;
  padding: 24px 20px 120px;
  gap: 110px;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    gap: 20px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE_S.MAX_WIDTH}px) {
    gap: 60px;
    padding-bottom: 80px;
    flex-direction: column;
    div:nth-child(1) {
      text-align: left;
    }
  }
`;

export const RoundedButton = styled.a<{ bgColor?: string; color?: string }>`
  padding: 8px 24px;
  display: block;
  width: fit-content;
  background-color: ${(props) => (props.bgColor ? props.bgColor : "#1b58f1")};
  border-radius: 24px;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: ${(props) => (props.color ? props.color : "#ffffff")};
  cursor: pointer;
`;
