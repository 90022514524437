import styled from "styled-components";

import {
  ImgPricingService1,
  ImgPricingService2,
  ImgPricingService3,
  ImgPricingService4,
} from "assets";
import { MEDIA_QUERY } from "properties/constant";
export const Service = () => {
  return (
    <Container>
      <Card>
        <Image src={ImgPricingService1} />
        <Title>발신번호 사전등록 안내</Title>
        <Text>사전 인증받은 발신번호만 문자발송이 가능합니다.</Text>
      </Card>
      <Card>
        <Image src={ImgPricingService2} />
        <Title>주소록 무료 등록대행</Title>
        <Text>고객님의 주소록을 무료 등록대행해드립니다.</Text>
      </Card>
      <Card>
        <Image src={ImgPricingService3} />
        <Title>광고문자 전송가이드 안내</Title>
        <Text>무료 080 수신거부서비스 번호 지원합니다.</Text>
      </Card>
      <Card>
        <Image src={ImgPricingService4} />
        <Title>엑셀 대량문자발송</Title>
        <Text>
          여러 수신인에게 각각 다른 문자 내용을 한번에 보낼수 있습니다.
        </Text>
      </Card>
    </Container>
  );
};

const Container = styled.div`
  padding: 180px 20px 100px;
  display: flex;
  justify-content: center;
  gap: 52px;
  flex-wrap: wrap;
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    padding: 60px 20px;
  }
`;
const Image = styled.img`
  width: 70px;
  margin-bottom: 24px;
`;

const Card = styled.div`
  width: 216px;
  text-align: center;
  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    width: 180px;
  }
`;
const Title = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  text-align: center;
  letter-spacing: -1px;
  padding-bottom: 8px;
  color: #333333;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    font-size: 18px;
  }
`;
const Text = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -1px;

  color: #666666;
  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    font-size: 15px;
  }
`;
