import styled from "styled-components";

export const Container = styled.div`
  header {
    padding: 24px;
  }
  h3 {
    color: #333;
    font-size: 24px;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -1px;
  }
  li {
    color: #666;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -1px;
    &::before {
      content: "• ";
      padding-right: 4px;
    }
  }
  section {
    min-height: 400px;
  }
`;

export const LineVertical = styled.div<{ height: number }>`
  height: ${(props) => props.height}px;
  width: 1px;
  background: #999;
`;
