import { Label } from "components/Label";
import styled, { css } from "styled-components";

interface Props {
  label: string;
  type?: string;
  value: string;
  placeholder: string;
  disabled?: boolean;
  isLabelBold?: boolean;
  isInvalid?: boolean;
  onChange: (value: string) => void;
  onEnter?: () => void;
}
export const InputListItem = ({
  label,
  type = "text",
  value,
  placeholder,
  disabled = false,
  isLabelBold,
  isInvalid = false,
  onChange,
  onEnter,
}: Props) => {
  return (
    <Container>
      <Label isBold={isLabelBold}>{label}</Label>
      <Input
        type={type}
        placeholder={placeholder}
        defaultValue={value}
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled}
        isInvalid={isInvalid}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onEnter && onEnter();
          }
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  margin: 0 auto;
`;

const Input = styled.input<{
  disabled?: boolean;
  active?: boolean;
  isInvalid?: boolean;
}>`
  width: 100%;
  height: 56px;
  padding: 17px 16px 16px;
  border: 1px solid #d7dbe2;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s ease;

  &::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -1px;
    color: #999999;
  }

  ${(props) =>
    props.disabled &&
    css`
      background-color: #ededed;
    `}

  ${(props) =>
    props.isInvalid &&
    css`
      &:not(:focus) {
        border-color: #ec1c24;
      }
    `}
`;
