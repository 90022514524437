import OutsideClickHandler from "react-outside-click-handler";
import { useEffect, useState } from "react";
import * as S from "./styled";
import { CallerType } from "types/types";
import { Icons } from "components";
import { SubTitle } from "../../styled";

interface Props {
  callers: CallerType[] | null;
  targetCaller: CallerType | null;
  setTargetCaller: (caller: CallerType) => void;
}
export const CallerSelection: React.FC<Props> = ({
  callers,
  targetCaller,
  setTargetCaller,
}) => {
  const [visibleCallers, setVisibleCallers] = useState<boolean>(false);

  useEffect(() => {
    if (callers && callers.length === 1) setTargetCaller(callers[0]);
  }, []);

  return (
    <S.Container>
      <SubTitle>발신번호</SubTitle>

      <OutsideClickHandler onOutsideClick={() => setVisibleCallers(false)}>
        <S.GroupSelection
          className="pointer activeItem"
          onClick={() => setVisibleCallers(!visibleCallers)}
        >
          {targetCaller ? (
            <S.Option>{targetCaller.phone_number}</S.Option>
          ) : (
            <S.Option isPlaceholder>전송할 발신번호를 선택해주세요.</S.Option>
          )}

          <Icons.ArrowBottom color="#121212" />
          {visibleCallers && callers && (
            <S.GroupOptions>
              {callers.map((caller) => (
                <S.GroupOption
                  key={caller.id}
                  onClick={() => setTargetCaller(caller)}
                >
                  {caller.phone_number}
                </S.GroupOption>
              ))}
            </S.GroupOptions>
          )}
        </S.GroupSelection>
      </OutsideClickHandler>
    </S.Container>
  );
};
