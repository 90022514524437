import { SVGProps } from "react";

export const ArrowLeftSimple = ({
  width = 9,
  height = 18,
  fill = "#333333",
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.71967 17.0293C8.01256 17.3236 8.48744 17.3236 8.78033 17.0293C9.07322 16.7351 9.07322 16.258 8.78033 15.9638L1.81066 8.96214L8.70495 2.03621C8.99785 1.74197 8.99785 1.26492 8.70495 0.970678C8.41206 0.676441 7.93719 0.67644 7.64429 0.970678L0.219669 8.42937C-0.073223 8.72361 -0.073223 9.20067 0.219669 9.4949C0.232343 9.50763 0.245358 9.51981 0.258683 9.53145C0.270256 9.54483 0.282378 9.5579 0.295048 9.57063L7.71967 17.0293Z"
        fill={fill}
      />
    </svg>
  );
};
