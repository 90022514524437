import { observable, makeObservable, action } from "mobx";
import { createContext } from "react";
import { StoreAddressType } from "types/types";

class BrandStore {
  constructor() {
    makeObservable(this);
  }

  /********* Brand Creation ***********/
  /********* HomeInfo ***********/
  @observable brandHomeInfo = {
    backgroundImage: null,
    profileImage: null,
    name: "",
    description: "",
    phoneNumber: "",
    quickButtonType: "Chat",
    webSite: "",
    email: {
      id: "",
      domain: "",
    },
    storeAddress: {
      address: "",
      zonecode: "",
      detail: "",
    },
  };

  @action setBrandHomeInfo = (key: string, value: string) => {
    this.brandHomeInfo[key] = value;
  };

  @action setBrandHomeEmailInfo = (type, value) => {
    this.brandHomeInfo.email[type] = value;
  };

  @action setBrandHomeAddress = (addressInfo: StoreAddressType) => {
    this.brandHomeInfo.storeAddress = addressInfo;
  };

  @observable backgroundImageSrc = null;
  @action setBackgroundImageSrc = (src) => {
    this.backgroundImageSrc = src;
  };
  @observable profileImageSrc = null;
  @action setProfileImageSrc = (src) => {
    this.profileImageSrc = src;
  };

  /********* ChatRoom ***********/
  @observable brandChatRoomInfo = {
    name: "",
    senderNumber: "",
    communicationProofImage: null,
  };
  @action setBrandChatRoomInfo = (type, value) => {
    this.brandChatRoomInfo[type] = value;
  };

  /********* ServiceManagement ***********/

  @observable brandServiceManagementInfo = {
    category1: "",
    category2: "",
    keyword: "",
  };
  @action setBrandServiceManagementInfo = (type, value) => {
    this.brandServiceManagementInfo[type] = value;
  };

  /********* Brand Home Basic Tab ***********/
  @observable brandHomeBasicTabInfo = {
    tabPriority: "news",
    brandNewsDefaultSetting: {
      selectedType: "phoneCall",
      selectedValue: "",
    },
  };

  @action setBrandHomeBasicTabInfo = (type, value) => {
    this.brandHomeBasicTabInfo[type] = value;
  };

  @action setBasicTabType = (value) => {
    this.initializeBasicTabSelectedValue();
    this.brandHomeBasicTabInfo.brandNewsDefaultSetting.selectedType = value;
  };

  @action setBasicTabValue = (value) => {
    this.brandHomeBasicTabInfo.brandNewsDefaultSetting.selectedValue = value;
  };

  @action initializeBasicTabSelectedValue = () => {
    this.brandHomeBasicTabInfo.brandNewsDefaultSetting.selectedValue = "";
  };
}
export default createContext(new BrandStore());
