import { BillingType } from "types/types";
import styled from "styled-components";
import { BliingListItem } from "./BillingListItem";
import { Pagination } from "components/Pagination";
import { Loading } from "components/Icons";

interface Props {
  billings: BillingType[] | null;
  billingCount: number;
  currentPage: number;
  onClickBilling: (billing: BillingType) => void;
  setCurrentPage: (page: number) => void;
}
export const BllingList = ({
  billings,
  billingCount,
  currentPage,
  onClickBilling,
  setCurrentPage,
}: Props) => {
  return (
    <Container>
      <TBody>
        <div>충전일자</div>
        <div>충전수단</div>

        <div>충전금액</div>
        <div>적립포인트</div>
        <div>영수증</div>
        <div>결제상태</div>
        <div>비고</div>
      </TBody>

      {billings && billings.length > 0 ? (
        <div>
          {billings.map((billing) => (
            <BliingListItem
              key={`billing-${billing.id}`}
              billing={billing}
              onClickBilling={onClickBilling}
            />
          ))}
          <PaginationContainer>
            <Pagination
              size={billingCount}
              defaultPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </PaginationContainer>
        </div>
      ) : (
        <>
          {billings ? (
            <div>
              <NoData>
                <div className="pb-16">조회된 충전내역이 없습니다</div>
              </NoData>
            </div>
          ) : (
            <LoadingContainer>
              <Loading width={50} height={50} />
            </LoadingContainer>
          )}
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  border-radius: 4px;
`;

const NoData = styled.div`
  text-align: center;
  padding: 78px 0px 280px;
  width: 100%;
  color: gray;
  border-bottom: solid 1px lightgray;
`;
const TBody = styled.div`
  display: flex;
  div {
    flex: 1;
    text-align: center;
    background-color: #f9f9f9;
    padding: 12px 0px;
    margin-left: -1px;

    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: -1px;

    color: #333333;
  }
`;
const LoadingContainer = styled.div`
  border-bottom: solid 1px lightgray;
  text-align: center;
  padding: 100px 40px;
  width: 100%;
`;

const PaginationContainer = styled.div`
  display: flex;
  padding: 24px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  div {
    letter-spacing: -1px;
    color: #999999;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
  }

  .active {
    color: var(--primary-color);
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: -1px;
    color: #1b58f1;
  }
`;
