import React from 'react';
import styled from 'styled-components';
import { ImgMockupBasicSvg } from "assets";

const Container = styled.div`
  position: relative;
  width: 321px;
  background-image: url(ImgMockupSvg);
`;
const ContentLayout = styled.div`
  position: absolute;
  top: 32px;
  left: 46px;
  width: 258px;
  height: 522.79px;
  border-radius: 28px;
  overflow: hidden;
`;
interface IProps{
  children?: JSX.Element | JSX.Element[];
}

const MockupBasic = ({
  children
}:IProps) => {
  return (
    <Container>
      <img src={ImgMockupBasicSvg} alt="목업" width="321" height="612" />
      <ContentLayout>
        {children}
      </ContentLayout>
    </Container>
  );
};

export default MockupBasic;

