import styled, { CSSObject } from "styled-components";
import TBodyList, { THeadList, TableLayout } from "components/TableLayout";
import { FlexWrapper } from 'components/Flex';
import { Padding, Pagination } from "components";
import ButtonSquare from "components/ButtonSquare";
import { MenuBar } from "components/MenuBar";
import InputText from "components/InputText";
import SelectBasic from "components/SelectBasic";
import { useCallback, useState } from "react";
import TemplateModal from "./TemplateModal";

export const TemplateListSmart = () => {
  const [templateName, setTemplateName] = useState("");
  const [brandName, setBrandName] = useState("");
  const [isTemplateAddModalOpen, setIsTemplateAddModalOpen] = useState(false);

  const onHandleChangeTemplateName = useCallback((target) => {
    setTemplateName(target.value);
  }, []);
  const onHandleChangeBrandName = useCallback((target) => {
    setBrandName(target.value);
  }, []);

  return (
    <TemplateLayout>
      <MenuBar />
      <Container>
        <PageTitle>템플릿 관리</PageTitle>
        <Inner>
          <PaddingHorizontal>
            <PageTitleSmall>스마트 메시지</PageTitleSmall>
            <Descriptions>
              <Li>고객님의 브랜드별 템플릿 목록입니다.</Li>
              <Li>
                템플릿을 추가하시려면 템플릿 추가 버튼을 통해 추가 등록
                해주세요.
              </Li>
              <Li>RCS Biz Center 승인 후에 정상적으로 이용이 가능합니다.</Li>
            </Descriptions>
            <Padding height="16px" />
            <FlexWrapper
              css={{
                height: "46px",
                alignItems: "center",
                marginBottom: "16px",
              }}
            >
              <InputText
                placeholder="템플릿 이름"
                value={templateName}
                onChange={onHandleChangeTemplateName}
              />
              <InputText
                placeholder="브랜드명"
                value={brandName}
                onChange={onHandleChangeBrandName}
                margin="0 0 0 8px"
              />
              <SelectBasic
                css={{
                  marginLeft: "8px",
                  fontWeight: "bold",
                }}
                padding="14px"
                value=""
                onChange={() => {}}
                options={[
                  {
                    text: "전체",
                    value: "all",
                  },
                ]}
              />
              <ButtonSquare
                css={{
                  maxWidth: "74px",
                  marginLeft:"8px",
                  marginRight:"19px",
                  background:"#333",
                  color:"white",
                  fontWeight:"bold"
                }}
              >
                조회
              </ButtonSquare>
              <BorderVertical />
              <ButtonSquare
                css={{
                  maxWidth: "106px",
                  color: "#333",
                  fontWeight: "bold",
                  border: "1px solid #D7DBE2",
                  marginLeft: "19px",
                  marginRight: "8px",
                }}
                onClick={() => {}}
              >
                임시저장 목록
              </ButtonSquare>
              <ButtonSquare
                bgColor="#1B58F1"
                css={{
                  maxWidth: "106px",
                  color: "white",
                  fontWeight: "bold",
                  border: "none",
                }}
                onClick={() => setIsTemplateAddModalOpen(true)}
              >
                템플릿 추가
              </ButtonSquare>
            </FlexWrapper>
          </PaddingHorizontal>

          <TableLayout
            css={{
              minHeight: "416px",
              marginBottom: "16px",
            }}
          >
            <THeadList
              headList={[
                {
                  text: "번호",
                  css: {
                    minWidth: "13%",
                    maxWidth: "13%",
                  },
                },
                {
                  text: "문자종류",
                  css: {
                    minWidth: "14%",
                    maxWidth: "14%",
                  },
                },
                {
                  text: "템플릿 이름",
                  css: {
                    minWidth: "26%",
                    maxWidth: "26%",
                  },
                },
                {
                  text: "브랜드명",
                  css: {
                    minWidth: "13%",
                    maxWidth: "13%",
                  },
                },
                {
                  text: "상태",
                  css: {
                    minWidth: "14%",
                    maxWidth: "14%",
                  },
                },
                {
                  text: "최종수정일",
                },
              ]}
            />
            <TBodyList>
              <tr>
                <Td
                  css={{
                    minWidth: "13%",
                    maxWidth: "13%",
                  }}
                >
                  1
                </Td>
                <Td
                  css={{
                    minWidth: "14%",
                    maxWidth: "14%",
                  }}
                >
                  이미지
                </Td>
                <Td
                  css={{
                    minWidth: "26%",
                    maxWidth: "26%",
                    textDecoration: "underline",
                  }}
                >
                  SNS형 (중간버튼)
                </Td>
                <Td
                  css={{
                    minWidth: "13%",
                    maxWidth: "13%",
                  }}
                >
                  비즈포스트
                </Td>
                <Td
                  css={{
                    minWidth: "14%",
                    maxWidth: "14%",
                  }}
                >
                  승인
                </Td>
                <Td>2022.12.01</Td>
              </tr>
            </TBodyList>
          </TableLayout>
          <Pagination
            size={1}
            defaultPage={1}
            setCurrentPage={() => {}}
            arrowColor="#999999"
          />
        </Inner>
      </Container>

      {isTemplateAddModalOpen ? (
        <TemplateModal onClose={() => setIsTemplateAddModalOpen(false)} />
      ) : null}
    </TemplateLayout>
  );
};

export const TemplateLayout = styled.div`
  width: 100%;
  min-height: 100vh;
  padding-bottom: 65px;
  margin-left: 240px;
  background: #f6f8ff;
`;

export const Container = styled.div`
  padding: 48px 40px;
  width: fit-content;
  width: 90%;
  min-width: 820px;
`;

export const PaddingHorizontal = styled.div`
  padding: 0 24px;
`;

export const PageTitle = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 46px;
  letter-spacing: -1px;
  color: #333333;
  padding-bottom: 27px;
`;

export const PageTitleSmall = styled.h3`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 46px;
  letter-spacing: -1px;
  color: #333333;
  padding-bottom: 16px;
`;

export const Inner = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 24px 0 0;
`;

export const Descriptions = styled.div`
  padding-bottom: 24px;
`;

export const Li = styled.div<{
  paddingBottom?: string;
  color?: string;
}>`
  display: flex;
  padding-bottom: ${props=>
    props ? 
      props.paddingBottom  
      :"8px"
  };
  color: ${props=>
    props.color
    ? props.color
    : "#666"
  };
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -1px;
  white-space: pre-line;
  &::before {
    content: "•";
    display: inline-block;
    height: 100%;
    padding: 0 8px;
  }
`;

const Td = styled.td<{
  css?: CSSObject;
}>`
  ${(props) => (props.css ? props.css : "")};
`;

const BorderVertical = styled.div`
  width: 1px;
  height: 36px;
  background: #999;
`;
