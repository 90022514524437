import { formatToPhoneNumber } from "utils/phone";
import { Trash } from "components/Icons";
import { changeDateFormat, DATE_FORMAT_1 } from "utils/datetime";
import { CallerType } from "types/types";
import styled, { css } from "styled-components";
import { Center } from "styles/styled";

interface Props {
  caller: CallerType;
  openRemoveCallerModal: (caller: CallerType) => void;
  openFailReasonModal: (caller: CallerType) => void;
}
export const CallerListItem: React.FC<Props> = ({
  caller,
  openRemoveCallerModal,
  openFailReasonModal,
}) => {
  const statusCode =
    caller.status === 1 ? "normal" : caller.status === 0 ? "waiting" : "fail";
  return (
    <TR key={`caller=${caller.id}`} status={statusCode}>
      <TD>{changeDateFormat(caller.created, DATE_FORMAT_1)}</TD>
      <TD>{formatToPhoneNumber(caller.phone_number)}</TD>
      <TD>{caller.user.username}</TD>

      {statusCode === "fail" ? (
        <TD className="status" onClick={() => openFailReasonModal(caller)}>
          {caller.status_msg}
        </TD>
      ) : (
        <TD className="status">{caller.status_msg}</TD>
      )}

      <TD className="pointer" onClick={() => openRemoveCallerModal(caller)}>
        <Center>
          <Trash />
        </Center>
      </TD>
    </TR>
  );
};

const TD = styled.div`
  flex: 1;
  text-align: center;
  padding: 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  border-collapse: collapse;
  margin-left: -1px;
  margin-top: -1px;
  letter-spacing: -1px;
`;

const TR = styled.div<{ status: string }>`
  display: flex;
  div {
    ${(props) =>
      props.status === "waiting" &&
      css`
        color: #999999;
      `};

    ${(props) =>
      props.status === "fail" &&
      css`
        color: #999999;
      `};
  }

  .status {
    ${(props) =>
      props.status === "normal" &&
      css`
        font-weight: 700;
        color: #1b58f1;
      `};

    ${(props) =>
      props.status === "waiting" &&
      css`
        color: #faaf40;
      `};

    ${(props) =>
      props.status === "fail" &&
      css`
        color: #999999;
        text-decoration: underline;
        cursor: pointer;
      `};
  }
`;
