import { SVGProps } from "react";

export const List = ({
  width = 22,
  height = 22,
  fill = "#666666",
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="8" y="4" width="10" height="2" rx="1" fill={fill} />
      <rect x="4" y="4" width="2" height="2" rx="1" fill={fill} />
      <rect x="4" y="10" width="14" height="2" rx="1" fill={fill} />
      <rect x="4" y="16" width="14" height="2" rx="1" fill={fill} />
    </svg>
  );
};
