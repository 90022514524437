import styled from "styled-components";
import { SendResultDetailStatusType } from "types/types";

interface Props {
  name: string;
  stats: SendResultDetailStatusType;
}
export const StatisticListItem: React.FC<Props> = ({ name, stats }) => {
  return (
    <StatResultItem>
      <StatResultTotal>
        <span>{name}</span>
        <span>
          <b>{stats.total_count}</b>건
        </span>
      </StatResultTotal>
      {/* 성공 건수 */}
      <StatResult>
        <b>{stats.success_count}</b>건
      </StatResult>
      {/* 실패 건수 */}
      <StatResult>
        <b>{stats.failure_count}</b>건
      </StatResult>
      {/* 대기 건수 */}
      <StatResult>
        <b>{stats.wait_count}</b>건
      </StatResult>
    </StatResultItem>
  );
};

const StatResultItem = styled.div`
  display: flex;
  gap: 8px;
`;
const StatResult = styled.div`
  padding: 0px 16px 12px;
  text-align: right;
  width: 112px;
  b {
    padding-right: 2px;
  }
`;

const StatResultTotal = styled(StatResult)`
  display: flex;
  justify-content: space-between;
  flex: 1;
`;
