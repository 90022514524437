import { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { TemplateType } from "types/types";
import { ConfirmModal, Icons } from "components";
import TemplateStore from "stores/TemplateStore";

import { TemplateModal } from "../modals/TemplateModal";
import { SubTitle } from "../../styled";
import * as S from "./styled";

export const TemplateSelection: React.FC = observer(() => {
  const store = useContext(TemplateStore);
  const [visibleModal, setVisibleModal] = useState<boolean>();
  const [visibleConfirmModal, setVisibleConfirmModal] = useState<boolean>();
  const [checkedTemplate, setCheckedTemplate] = useState<TemplateType>();

  useEffect(() => {
    store.fetchTemplates();
  }, []);

  const onCloseModal = () => {
    setVisibleModal(false);
  };

  const openConfirmModal = () => {
    setVisibleConfirmModal(true);
    setVisibleModal(false);
  };

  /** 템픞릿 적용하기 */
  const onConfirmTargetTemplate = () => {
    if (!checkedTemplate) {
      return;
    }
    store.setTargetTemplate(checkedTemplate);
    setVisibleConfirmModal(false);
  };

  const closeConfirmModal = () => {
    setVisibleConfirmModal(false);
    setVisibleModal(true);
  };

  return (
    <S.Container>
      <SubTitle>템플릿 선택</SubTitle>

      <S.GroupSelection
        className="pointer activeItem"
        onClick={() => setVisibleModal(true)}
      >
        {store.targetTemplate ? (
          <S.Option>{store.targetTemplate.template_name}</S.Option>
        ) : (
          <S.Option isPlaceholder={true} onClick={onCloseModal}>
            템플릿을 선택할 수 있습니다.
          </S.Option>
        )}

        <Icons.ArrowBottom color="#121212" />
      </S.GroupSelection>
      {visibleModal && (
        <TemplateModal
          onClose={onCloseModal}
          openConfirmModal={openConfirmModal}
          checkedTemplate={checkedTemplate}
          setCheckedTemplate={setCheckedTemplate}
        />
      )}
      {visibleConfirmModal && (
        <ConfirmModal
          title={"템플릿 선택"}
          confirmButtonText={"적용하기"}
          onConfirm={onConfirmTargetTemplate}
          closeModal={closeConfirmModal}
        >
          <>
            작성 중인 내용이 사라질 수 있습니다.
            <br /> <b>[{checkedTemplate.template_name}]</b> 템플릿을
            적용하겠습니까?
          </>
        </ConfirmModal>
      )}
    </S.Container>
  );
});
