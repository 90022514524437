import styled from "styled-components";
import { WalletType } from "types/types";

interface Props {
  wallet: WalletType | null;
}

export const MyPoint: React.FC<Props> = ({ wallet }) => {
  const chargedPoint = wallet ? wallet.amount : 0;
  const earnedPoint = wallet ? wallet.point : 0;
  return (
    <section>
      <h3>나의 포인트</h3>
      <TotalPointContainer>
        <h5>총 포인트 (p)</h5>
        <AmountContainer>
          <TotalPointAmount>
            {(chargedPoint + earnedPoint).toLocaleString()}
          </TotalPointAmount>
          <AmountUnit>P</AmountUnit>
        </AmountContainer>
      </TotalPointContainer>

      <PointList>
        <PointItem>
          <PointType>충전 포인트</PointType>
          <PointAmount>
            {chargedPoint.toLocaleString()}
            <span>p</span>
          </PointAmount>
        </PointItem>
        <div>|</div>
        <PointItem>
          <PointType>적립 포인트</PointType>
          <PointAmount>
            {earnedPoint.toLocaleString()}
            <span>p</span>
          </PointAmount>
        </PointItem>
      </PointList>
      <Line />
    </section>
  );
};

const AmountContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const TotalPointAmount = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -1px;
  color: #1b58f1;
`;

const TotalPointContainer = styled.div`
  background: rgba(27, 88, 241, 0.08);
  padding: 16px 24px 16px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: 10px;
  margin-bottom: 16px;
`;

const AmountUnit = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #1b58f1;
`;
const PointList = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  padding-bottom: 40px;
`;
const PointItem = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;
const PointType = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -1px;
  color: #666666;
`;
const PointAmount = styled.div`
  font-weight: 700;
  span {
    font-weight: 400;
    &::before {
      content: " ";
    }
  }
`;

const Line = styled.div`
  height: 1px;
  width: 100%;
  background-color: #999999;
  margin-bottom: 40px;
`;
