import { ConfirmModal, Icons } from "components";
import OutsideClickHandler from "react-outside-click-handler";
import { AddressBookType, AddressGroupType } from "types/types";
import { useState } from "react";
import {
  GroupSelectionContaienr,
  GroupOptions,
  GroupSelection,
  Option,
} from "./styled";

interface Props {
  groups: AddressGroupType[];
  activeGroup: AddressGroupType;
  selectedAddressBooks: AddressBookType[];
  onClose: () => void;
  onMoveAddressBooks: (targetGroup: AddressGroupType) => void;
}

export const MoveAddressBookModal = ({
  groups,
  activeGroup,
  selectedAddressBooks,
  onClose,
  onMoveAddressBooks,
}: Props) => {
  const [visibleGroups, setVisibleGroups] = useState<boolean>(false);
  const [targetGroup, setTargetGroup] = useState<AddressGroupType>(activeGroup);

  const onConfirmMoveButton = () => {
    onMoveAddressBooks(targetGroup);
    setTargetGroup(null);
  };

  return (
    <ConfirmModal
      title="번호 이동"
      confirmButtonText="이동"
      closeModal={onClose}
      onConfirm={onConfirmMoveButton}
    >
      <div>
        선택한 {selectedAddressBooks.length}개의 주소를 <br />
        아래 그룹으로 이동하시겠습니까?
        <OutsideClickHandler onOutsideClick={() => setVisibleGroups(false)}>
          <GroupSelectionContaienr>
            <GroupSelection
              className="pointer activeItem"
              onClick={() => setVisibleGroups(!visibleGroups)}
            >
              <label>
                <b>{targetGroup ? targetGroup.group_name : "그룹 미지정"}</b>
              </label>
              <Icons.ArrowBottom color="#121212" />

              {visibleGroups && groups && (
                <GroupOptions>
                  {groups.map((group) => (
                    <Option
                      width={220}
                      height={36}
                      key={group.id}
                      onClick={() => setTargetGroup(group)}
                    >
                      {group.group_name}
                    </Option>
                  ))}
                </GroupOptions>
              )}
            </GroupSelection>
          </GroupSelectionContaienr>
        </OutsideClickHandler>
      </div>
    </ConfirmModal>
  );
};
