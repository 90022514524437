import styled from "styled-components";

import OutsideClickHandler from "react-outside-click-handler";
import { useState } from "react";
import { InputButtonWithBottomArrow } from "components";

interface Props {
  options: any;
  selectedOptionLabel?: string;
  noSelectOptionLabel?: string;
  onClickOption: (option: any) => void;
  borderColor?: string;
}
export const InputSelectionWithDropdown = ({
  options,
  selectedOptionLabel,
  noSelectOptionLabel,
  onClickOption,
  borderColor,
}: Props) => {
  const [visibleDropdown, setVisibleDropdown] = useState(false);
  const _onClickOption = (option: any) => {
    onClickOption(option);
    setVisibleDropdown(false);
  };
  return (
    <OutsideClickHandler onOutsideClick={() => setVisibleDropdown(false)}>
      <Container>
        <InputButtonWithBottomArrow
          borderColor={borderColor}
          onClick={() => setVisibleDropdown(true)}
          text={selectedOptionLabel ? selectedOptionLabel : noSelectOptionLabel}
          width="160px"
          height="46px"
        />

        {visibleDropdown && (
          <Selection>
            {selectedOptionLabel && (
              <Option
                width={140}
                height={44}
                onClick={() => _onClickOption(null)}
              >
                {noSelectOptionLabel}
              </Option>
            )}
            {options.map((option, index) => (
              <Option
                width={140}
                height={44}
                key={index}
                onClick={() => _onClickOption(option)}
              >
                {option.label}
              </Option>
            ))}
          </Selection>
        )}
      </Container>
    </OutsideClickHandler>
  );
};

const Container = styled.div`
  position: relative;
`;

const Selection = styled.div`
  position: absolute;
`;

const Option = styled.div<{
  width?: number;
  height: number;
  borderColor?: string;
}>`
  padding: 8px;
  width: ${(props) => (props.width ? `${props.width}px` : `100%`)};
  height: ${(props) => props.height}px;
  border: 1px solid
    ${(props) => (props.borderColor ? props.borderColor : " #d7dbe2")};
  background-color: white;
  margin-bottom: -1px;
  display: flex;
  align-items: center;
  color: #333333;

  cursor: pointer;

  &:hover {
    background: #eef3ff;
  }
`;
