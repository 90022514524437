import { createContext } from "react";
import { observable, makeObservable, action, runInAction } from "mobx";
import {
  AddressBookRequestType,
  AddressBookType,
  AddressGroupType,
} from "types/types";

import * as addressbookApi from "apis/addressbookApi";

class AddressBookStore {
  @observable activeGroup: AddressGroupType | null = null;
  @observable addressBookGroups: AddressGroupType[] | null = null;
  @observable addressBooks: AddressBookType[] | null = null;
  @observable addressBookTotalCount: number = 0;
  @observable currentPage: number = 1;

  @observable selectedAddressBooks: AddressBookType[];
  @observable selectedAddressGroup: AddressGroupType | null;

  constructor() {
    makeObservable(this);
  }

  @action setCurrentPageAndReload = async (page: number, isSearch: boolean) => {
    if (this.currentPage === page) {
      return;
    }
    this.currentPage = page;
    if (isSearch) {
      // this.searchAddressBooksByKeyword();
    } else {
      await this.fetchAddressBooks(true);
    }
  };
  @action setCurrentPage = (page: number) => {
    this.currentPage = page;
  };

  @action setActiveGroup = (activeGroup: AddressGroupType | null) => {
    if (this.activeGroup?.id !== activeGroup?.id) {
      this.activeGroup = activeGroup;
      this.currentPage = 1;
      this.fetchAddressBooks(true);
    }
  };

  @action setAddressBooks = (addressBooks: AddressBookType[] | null) => {
    this.addressBooks = addressBooks;
  };

  @action initAddressBookPage = () => {
    const targetGroup =
      this.addressBookGroups.length > 0 ? this.addressBookGroups[0] : null;
    if (targetGroup?.id !== this.activeGroup?.id) {
      this.activeGroup = targetGroup;
      this.fetchAddressBooks(true);
    }
  };

  @action fetchAddressBookGroups = async (isReload?: boolean) => {
    if (isReload || !this.addressBookGroups) {
      const activeGroupId = this.activeGroup?.id;
      const groups: AddressGroupType[] =
        await addressbookApi.getAddressBookGroups();

      if (!groups || groups instanceof Error) {
        alert("그룹을 불러오는데 문제가 발생했습니다");
      } else {
        runInAction(() => {
          // 선택된 그룹이 있는 경우
          if (activeGroupId) {
            const index = groups.findIndex(
              (group) => group.id === activeGroupId
            );
            this.activeGroup = groups[index];
          } else if (groups.length > 0) {
            // 기본 그룹 선택
            const index = groups.findIndex(
              (group) => group.group_name === "기본"
            );
            // 기본 그룹이 없으면 첫번째 그룹 선택
            if (index !== -1) {
              const group = groups.splice(index, 1)[0];
              groups.unshift(group);
            }
            this.activeGroup = groups[0];
          }
          this.addressBookGroups = groups;
        });
      }
    }
  };

  /**
   * 전화번호 목록 불러오기
   */
  @action fetchAddressBooks = async (isReload?: boolean) => {
    if (isReload || !this.addressBooks) {
      const groupId = this.activeGroup?.id;
      const page = this.currentPage;
      const response = await addressbookApi.getAddressList(groupId, page);

      if (!response || response instanceof Error) {
        alert("그룹을 불러오는데 문제가 발생했습니다");
      } else {
        runInAction(() => {
          this.addressBooks = response.results;
          this.addressBookTotalCount = response.count;
        });
      }
    }
  };

  /**
   * 전화번호 목록 불러오기
   */
  @action getAddressBookBulkFromGroup = async (
    groupId: number,
    page: number,
    size: number = 100
  ) => {
    try {
      const response = await addressbookApi.getAddressList(groupId, page, size);
      return response.results;
    } catch (err) {
      alert("그룹을 불러오는데 문제가 발생했습니다");
    }
  };

  /**
   * 주소록 그룹과 주소록 목록 새로고침
   */
  onReloadAddressBooksAndGroups = async () => {
    this.fetchAddressBookGroups(true);
    this.fetchAddressBooks(true);
  };

  onCreateAddressBook = async (
    phone: string,
    username: string,
    groupId: number | null
  ) => {
    const response = await addressbookApi.postAddressBook(
      phone,
      username,
      groupId
    );

    if (!response || response instanceof Error) {
      alert("주소록을 생성하는데 문제가 생겼습니다");

      return false;
    } else {
      this.onReloadAddressBooksAndGroups();
      return true;
    }
  };

  onMoveGroupAddressBooks = async (
    books: AddressBookRequestType[],
    targetGroup: AddressGroupType
  ) => {
    const response = await addressbookApi.moveGroupAddressBooks(books);
    if (response.moved) {
      await this.setActiveGroup(targetGroup);
      return true;
    } else {
      alert("주소록을 이동하는데 문제가 생겼습니다");
      return false;
    }
  };

  onCopyGroupAddressBooks = async (books: AddressBookRequestType[]) => {
    const response = await addressbookApi.copyGroupAddressBooks(books);
    if (response.moved) {
      this.onReloadAddressBooksAndGroups();
      return true;
    } else {
      alert("주소록을 복사하는데 문제가 생겼습니다");
      return false;
    }
  };
  onSaveModifiedAddressBooks = async (
    addressBooks: AddressBookRequestType[]
  ) => {
    const response = await addressbookApi.updateAddressBooks(addressBooks);
    if (response.modifed) {
      this.onReloadAddressBooksAndGroups();
      return true;
    } else {
      alert("주소록을 수정하는데 문제가 생겼습니다");
      return false;
    }
  };

  @action onClickGroup = (group: AddressGroupType) => {
    if (group.id !== this.activeGroup?.id) {
      this.setActiveGroup(group);
      this.setAddressBooks(null);
      this.fetchAddressBooks();
    }
  };

  /**
   * 그룹 생성
   * @param groupName 생성할 그룹의 이름
   * @param setGroupName 그룹 이름을 초기화하는 함수
   */
  onCreateNewGroup = async (groupName: string, successCallback: () => void) => {
    if (groupName.length < 2) {
      alert("이름을 두글자 이상 입력해주세요.");
      return;
    }

    const response = await addressbookApi.postAddressBookGroup(groupName);
    // 생성 성공
    if (response.created) {
      const groupId = response.data.id;
      runInAction(() => {
        this.fetchAddressBookGroups(groupId);
        this.fetchAddressBooks(groupId);
      });

      successCallback();
    } else {
      // 생성 실패
      if (response?.message) {
        alert(response.message);
      }
    }
  };

  /**
   * 그룹 삭제
   * @param groupId 삭제할 그룹의 id
   */

  removeGroup = async (groupId: number) => {
    const response = await addressbookApi.deleteAddressBookGroup(groupId);

    if (!response || response instanceof Error) {
      alert("그룹을 삭제하는데 문제가 생겼습니다");
    } else {
      this.onReloadAddressBooksAndGroups();
    }
  };

  onDeleteAddressBooks = async (bookIds: number[]) => {
    const response = await addressbookApi.deleteAddressBooks(bookIds);

    if (!response || response instanceof Error) {
      alert("주소록을 삭제하는데 문제가 생겼습니다");
      return false;
    } else {
      this.onReloadAddressBooksAndGroups();
      return true;
    }
  };

  @action createAddressBookByExcel = async (file: File | null) => {
    try {
      await addressbookApi.uploadAddressBookExcel(file);

      return {
        created: true,
        message:
          "등록이 완료되었습니다. \n실 반영까지 몇 초의 시간이 소요될 수 있습니다. \n주소록이 보이지 않는다면 페이지를 새로고침 해주세요.",
      };
    } catch (err: any) {
      console.error(err);

      return { created: false, message: err?.detail };
    }
  };

  /** 주소록 검색 (이름 또는 폰번호) */
  @action searchAddressBooksByKeyword = async (
    groupId: number,
    keyword: string
  ) => {
    if (keyword.includes("-")) {
      keyword = keyword.replace(/-/g, "");
    }
    const response = await addressbookApi.searchAddressBookByKeyword(
      groupId,
      keyword
    );
    if (keyword !== "")
      if (response) {
        this.addressBooks = response.results;
        return response.results;
      }
    return [];
  };
}

export default createContext(new AddressBookStore());
