import { ImgArrowBottomBlack } from "assets";
import { MEDIA_QUERY } from "properties/constant";
import styled from "styled-components";

interface Props {
  type: string;
  limit: string;
  desc: JSX.Element;
  origin: string;
  discounted: string;
  color: string;
}
export const MessageCardText = ({
  type,
  limit,
  desc,
  origin,
  discounted,
  color,
}: Props) => {
  return (
    <Container>
      <div>
        <Badge color={color}>{type}</Badge>
        <LetterLimit>{limit}</LetterLimit>
        <Desc>{desc}</Desc>
      </div>
      <BottomArrow src={ImgArrowBottomBlack} alt="bottom arrow" />
      <Pricing>
        <Origin>{origin}원</Origin>
        <Discounted color={color}>
          <span>{discounted}</span>원
        </Discounted>
      </Pricing>
    </Container>
  );
};

const Container = styled.div`
  width: 240px;
  text-align: center;

  @media (max-width: ${MEDIA_QUERY.MOBILE_S.MAX_WIDTH}px) {
    width: 300px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const Badge = styled.div<{ color: string }>`
  background: ${(props) => props.color};
  border-radius: 100px;
  margin: 0 auto;
  color: white;
  width: fit-content;
  padding: 8px 30px 6px;
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -1px;
  color: #ffffff;
  @media (max-width: ${MEDIA_QUERY.MOBILE_S.MAX_WIDTH}px) {
    margin-left: 0px;
  }
`;
const LetterLimit = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  letter-spacing: -1px;
  padding-bottom: 8px;

  color: #333333;
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 16px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE_S.MAX_WIDTH}px) {
    max-width: 120px;
  }
`;
const Desc = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -1px;

  color: #666666;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 14px;
    line-height: 20px;
  }
`;
const BottomArrow = styled.img`
  width: 11px;
  margin: 20px;
  @media (max-width: ${MEDIA_QUERY.MOBILE_S.MAX_WIDTH}px) {
    transform: rotate(-90deg);
  }
`;
const Pricing = styled.div`
  display: flex;
  gap: 8px;
  align-items: baseline;
  justify-content: center;
`;
const Origin = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 39px;
  letter-spacing: -1px;
  text-decoration-line: line-through;
  color: #666666;
  @media (max-width: ${MEDIA_QUERY.MOBILE_S.MAX_WIDTH}px) {
    font-size: 16px;
  }
`;
const Discounted = styled.div<{ color: string }>`
  font-weight: 400;
  font-size: 20px;
  line-height: 39px;
  display: flex;
  justify-content: center;
  align-items: baseline;

  color: #333333;
  span {
    color: ${(props) => props.color};
    font-weight: 700;
    font-size: 40px;
    line-height: 39px;
  }

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 16px;
    line-height: 24px;
    span {
      font-size: 28px;
      line-height: 24px;
    }
  }
`;
