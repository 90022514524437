import { BillingType } from "types/types";
import styled, { css } from "styled-components";
import { changeDateFormat, DATETIME_FORMAT_1 } from "utils/datetime";
import { getPaymentTypeLabel } from "utils/getPaymentType";

interface Props {
  billing: BillingType;
  onClickBilling: (billing: BillingType) => void;
}
export const BliingListItem = ({ billing, onClickBilling }: Props) => {
  const StatusBadge =
    billing.status === "paid" ? (
      <StatusText color="#1B58F1" bold>
        결제 성공
      </StatusText>
    ) : billing.status === "before_paid" ? (
      <StatusText>결제 전</StatusText>
    ) : (
      <StatusText color="#EC1C24" bold>
        결제 실패
      </StatusText>
    );

  return (
    <TRow key={`billing=${billing.id}`}>
      <TD fontSize={14}>
        {changeDateFormat(billing.created, DATETIME_FORMAT_1)}
      </TD>
      <TD>{getPaymentTypeLabel(billing.pay_method)}</TD>
      <TD>{billing.amount.toLocaleString()}</TD>
      <TD>-</TD>
      {billing.status === "paid" ? (
        <TD hasLink={true} onClick={() => onClickBilling(billing)}>
          영수증
        </TD>
      ) : (
        <TD>-</TD>
      )}

      <TD>{StatusBadge}</TD>
      <TD>-</TD>
    </TRow>
  );
};

export const TRow = styled.div`
  display: flex;
`;

export const TD = styled.div<{ hasLink?: boolean; fontSize?: number }>`
  flex: 1;
  text-align: center;
  padding: 12px;
  font-weight: 400;
  font-size: ${(props) => (props.fontSize ? props.fontSize : 16)}px;
  line-height: 23px;
  border-collapse: collapse;
  margin-left: -1px;
  margin-top: -1px;
  letter-spacing: -1px;
  color: #333333;

  ${(props) =>
    props.hasLink &&
    css`
      text-decoration-line: underline;
      color: #1b58f1;
      cursor: pointer;
    `}
`;

export const RemoveButton = styled(TD)`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StatusText = styled.span<{ color?: string; bold?: boolean }>`
  font-weight: ${(props) => (props.bold ? "700" : "400")};
  color: ${(props) => (props.color ? props.color : "#333333")};
`;
