import styled from "styled-components";

export const Container = styled.div``;

export const ImageOptionContainer = styled.div`
  padding-bottom: 40px;
`;
export const Header = styled.div`
  display: flex;
  gap: 40px;
  padding-bottom: 24px;
`;

export const Options = styled.div`
  display: flex;

  gap: 40px;
`;
export const Descriptions = styled.div`
  padding-bottom: 20px;
`;
export const Description = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -1px;
  color: #666666;
  &::before {
    content: "• ";
    padding: 0px 6px;
  }
`;
export const ButtonOutline = styled.label`
  border: 1px solid #333333;
  display: block;
  border-radius: 4px;
  cursor: pointer;
  width: 410px;
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;

  text-align: center;
  letter-spacing: -1px;

  color: #333333;
  padding: 11px;
  &:hover {
    background: #fafafa;
  }
`;

export const ImageInput = styled.input`
  display: none;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -1px;
  color: #333333;
`;

export const OptionContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
`;

export const FileContainer = styled.div`
  background: rgba(101, 45, 144, 0.05);
  border-radius: 6px;
  width: 410px;
  margin-bottom: 8px;
  padding: 12px 16px;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const FileName = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -1px;
  color: #666666;
`;

export const Close = styled.div`
  cursor: pointer;
`;
