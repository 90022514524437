import styled from "styled-components";

import {
  ImgPricingHeader1,
  ImgPricingHeader2,
  ImgPricingHeader3,
  ImgPlusPrimary,
} from "assets";
import { MEDIA_QUERY } from "properties/constant";
import { SectionSubTitle, SectionTitle } from "../styled";
import colors from "styles/colors";
import { Pad } from "styles/styled";

export const HeaderCard1 = () => {
  return (
    <Container>
      <SectionSubTitle color="#ffffff"></SectionSubTitle>
      <Pad pb={16}>
        <Text>3가지 혜택이 포함된</Text>
        <SectionTitle color="#ffffff">
          국내 최저가 문자 발송 서비스입니다!
        </SectionTitle>
      </Pad>

      <HeaderCardContainer>
        <HeaderCard>
          <img src={ImgPricingHeader1} alt="고객 반응 문구" />
          <div>클릭률 UP 문구 제작</div>
          <div>메시지 컨설팅</div>
        </HeaderCard>
        <IconPlus src={ImgPlusPrimary} alt="plus" />
        <HeaderCard>
          <img src={ImgPricingHeader2} alt="일반문자 SMS, LMS, MMS" />
          <div>일반문자 SMS, LMS, MMS</div>
          <div>500포인트 무료 지급</div>
        </HeaderCard>
        <IconPlus src={ImgPlusPrimary} alt="plus" />
        <HeaderCard>
          <img src={ImgPricingHeader3} alt="차세대 스마트 비즈메세지" />
          <div>차세대 스마트 비즈메세지</div>
          <div>템플릿 무료 제공</div>
        </HeaderCard>
      </HeaderCardContainer>
    </Container>
  );
};

const Container = styled.section`
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 54px 20px;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    padding: 80px 10px;
    box-sizing: border-box;
  }
`;

const Text = styled.div`
  font-weight: 700;
  font-size: 28px;
  line-height: 41px;
  letter-spacing: -1px;
  color: ${colors.primary1};
  padding-bottom: 8px;
`;
const IconPlus = styled.img`
  width: 60px;
  height: 60px;
  margin-bottom: 50px;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    margin-bottom: 0px;
    width: 40px;
    height: 40px;
  }
`;
const HeaderCardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    display: none;
    flex-direction: column;
    height: auto;
  }
`;
const HeaderCard = styled.div`
  img {
    width: 164px;
    height: 164px;
  }
  div:nth-child(2) {
    margin-top: -10px;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;

    letter-spacing: -1px;
    color: #999999;
  }
  div:nth-child(3) {
    font-weight: 700;
    font-size: 20px;
    line-height: 29px;
    letter-spacing: -1px;

    color: #ffffff;
  }
`;
