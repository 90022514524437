import { CheckboxCircle, CheckboxCircleFilled } from "components/Icons";
import styled, { css } from "styled-components";
import { ellipsis } from "styles/mixin";
import { AddressBookType } from "types/types";
import { convertPhoneWithDash } from "utils/phone";

interface Props {
  book: AddressBookType;
  onClickAddressBook: () => void;
  isActiveCheckbox: () => boolean;
}
export const AddressBookRow: React.FC<Props> = ({
  book,
  onClickAddressBook,
  isActiveCheckbox,
}) => {
  return (
    <TR key={book.id} onClick={onClickAddressBook}>
      <TD>
        {isActiveCheckbox() ? (
          <CheckboxCircleFilled width="20" height="20" />
        ) : (
          <CheckboxCircle fill="#C4C9D1" width="20" height="20" />
        )}
      </TD>
      <TD>{convertPhoneWithDash(book.phone_number)}</TD>
      <TD>{book.name}</TD>

      <TD>{book.group_name}</TD>
      {book.blocked ? <TD active={true}>수신거부</TD> : <TD>-</TD>}
    </TR>
  );
};

export const TableTbody = () => {
  return (
    <TBody>
      <TH>선택</TH>
      <TH>휴대폰번호</TH>
      <TH>이름</TH>
      <TH>그룹명</TH>
      <TH>수신거부</TH>
    </TBody>
  );
};

export const TR = styled.div`
  display: flex;
  justify-content: space-around;
  div {
    text-align: center;
  }
  div:nth-child(1) {
    width: 60px;
  }
  div:nth-child(2) {
    width: 120px;
  }
  div:nth-child(3),
  div:nth-child(4) {
    flex: 1;
    ${ellipsis};
  }
  div:nth-child(5) {
    width: 70px;
  }
`;

export const TD = styled.div<{ active?: boolean; isEditable?: boolean }>`
  padding: 4px 10px;

  height: 32px;
  color: #333333;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  letter-spacing: -1px;

  color: #333333;

  svg {
    display: inline-block;
  }

  ${(props) =>
    props.active &&
    css`
      color: #ec1c24;
      font-weight: 700;
    `}

  ${(props) =>
    props.isEditable &&
    css`
      border: 1px solid #d7dbe2;
      border-radius: 6px;
    `}
`;

const TH = styled(TD)``;

const TBody = styled(TR)`
  background: #fafafa;
`;
