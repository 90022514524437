import React from "react";
import styled, { CSSObject } from "styled-components";

interface IProps {
  children: JSX.Element | JSX.Element[];
  css?: CSSObject;
}

export const TableLayout = ({ children, css }: IProps) => {
  return <Container css={css}>{children}</Container>;
};

const Container = styled.table<{ css }>`
  ${(props) => (props.css ? props.css : "")};
`;

interface IHeaderBodyList {
  text: string;
  css?: CSSObject;
}
interface PropsHeader {
  headList: IHeaderBodyList[];
}

export const THeadList: React.FC<PropsHeader> = ({ headList }) => {
  return (
    <THeaderContainer>
      <tr>
        {headList &&
          headList.length > 0 &&
          headList.map((h, i) => <LiHead css={h.css}>{h.text}</LiHead>)}
      </tr>
    </THeaderContainer>
  );
};

const THeaderContainer = styled.thead<{}>`
  width: 100%;
  tr {
    width: 100%;
    display: flex;
  }
`;
const LiHead = styled.th<{
  css: CSSObject;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  //width: 100%;
  height: 46px;
  background: #fafafa;
  ${(props) => (props.css ? props.css : "")};
`;

interface PropsBody {
  children: JSX.Element | JSX.Element[];
}

export const TBodyList: React.FC<PropsBody> = ({ children }) => {
  return <TBodyContainer>{children}</TBodyContainer>;
};

export default TBodyList;

const TBodyContainer = styled.tbody`
  tr {
    display: flex;
    width: 100%;
    td {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      //width: 100%;
      min-height: 46px;
      padding: 10px;
      background: white;
      box-sizing: border-box;
    }
  }
`;
