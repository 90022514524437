import { SVGProps } from "react";

export const Point = ({
  width = 22,
  height = 22,
  fill = "#666666",
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.68671 16H9.89571V12.217H11.4557C13.5487 12.217 14.9787 11.281 14.9787 9.27901C14.9787 7.18601 13.5357 6.47101 11.4037 6.47101H8.68671V16ZM9.89571 11.242V7.45901H11.2477C12.9377 7.45901 13.7827 7.88801 13.7827 9.27901C13.7827 10.631 12.9767 11.242 11.3127 11.242H9.89571Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.28674 16.4V6.07104H11.4037C12.5011 6.07104 13.4938 6.25255 14.2206 6.7628C14.9747 7.29223 15.3787 8.129 15.3787 9.27904C15.3787 10.3852 14.9779 11.2434 14.2486 11.8139C13.5344 12.3724 12.5542 12.617 11.4557 12.617H10.2957V16.4H8.28674ZM9.89574 16H8.68674V6.47105H11.4037C13.5357 6.47105 14.9787 7.18604 14.9787 9.27904C14.9787 11.281 13.5487 12.217 11.4557 12.217H9.89574V16ZM10.2957 7.85905V10.842H11.3127C12.1089 10.842 12.6186 10.6935 12.9246 10.453C13.2102 10.2286 13.3827 9.87198 13.3827 9.27904C13.3827 8.67182 13.2052 8.36528 12.9274 8.18044C12.6093 7.9688 12.0791 7.85905 11.2477 7.85905H10.2957ZM9.89574 11.242H11.3127C12.9767 11.242 13.7827 10.631 13.7827 9.27904C13.7827 7.88805 12.9377 7.45905 11.2477 7.45905H9.89574V11.242Z"
        fill={fill}
      />
      <circle cx="11" cy="11" r="8" stroke={fill} strokeWidth="2" />
    </svg>
  );
};
