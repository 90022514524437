import styled from "styled-components";

export const GroupSelection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #d7dbe2;

  &.activeItem {
    padding: 12px 16px;
  }
`;

export const GroupOptions = styled.div`
  padding-bottom: 20px;
  position: absolute;
  top: 68px;
  left: 0px;
  width: 100%;
  border-radius: 3px;
  overflow-y: auto;
  /* max-height: 110px; */
`;

export const GroupSelectionContaienr = styled.div`
  padding-top: 16px;
  position: relative;
`;

export const Option = styled.div<{ width: number; height: number }>`
  padding: 4px 12px;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  border: 1px solid #d7dbe2;
  background-color: white;
  margin-bottom: -1px;
  color: #333333;
  display: flex;
  align-items: center;
  color: #333333;

  cursor: pointer;

  &:hover {
    background: #eef3ff;
  }
`;
