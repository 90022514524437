import Rodal from "rodal";
import OutsideClickHandler from "react-outside-click-handler";

// include styles
import "rodal/lib/rodal.css";
import { Close } from "components/Icons/Close";
import styled, { CSSObject } from "styled-components";

interface Props {
  title?: string;
  onClose: () => void;
  children: JSX.Element | JSX.Element[];
  width?: number;
  css?: CSSObject;
}

export const ModalLayout = ({
  title,
  onClose,
  children,
  width = 420,
  css,
}: Props) => {
  return (
    <Container>
      <OutsideClickHandler onOutsideClick={onClose}>
        <Rodal
          visible={true}
          showCloseButton={false}
          closeOnEsc={true}
          onClose={onClose}
          customStyles={{
            borderRadius: "24px",
            padding: "24px",
            width: `${width}px`,
            height: "fit-content",
            maxHeight: "100vh",
            ...css,
          }}
        >
          <Header>
            <Title>{title}</Title>
            <CloseButton onClick={onClose}>
              <Close />
            </CloseButton>
          </Header>
          <Body>{children}</Body>
        </Rodal>
      </OutsideClickHandler>
    </Container>
  );
};

const Container = styled.div`
  .rodal-dialog {
    overflow-y: auto;
    transform: scale(0.95);
    max-width: 100%;
  }
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Body = styled.div`
  word-break: keep-all;
`;
const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 39px;
  letter-spacing: -1px;
  color: #333333;
`;
const CloseButton = styled.div`
  cursor: pointer;
`;
