import { SVGProps } from "react";

export const CloseRoundFilled = ({
  width = 16,
  height = 16,
  fill = "#333333",
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_738_1492)">
        <circle cx="8.00033" cy="7.99999" r="7.33333" fill={fill} />
        <rect
          width="0.733493"
          height="10.2689"
          rx="0.366746"
          transform="matrix(0.716879 0.697198 -0.716879 0.697198 11.4736 4.32916)"
          fill="#D9D9D9"
        />
        <rect
          width="0.733493"
          height="10.2689"
          rx="0.366746"
          transform="matrix(-0.716879 0.697198 -0.716879 -0.697198 11.8877 11.1595)"
          fill="#D9D9D9"
        />
      </g>
      <defs>
        <clipPath id="clip0_738_1492">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
