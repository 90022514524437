import { SVGProps } from "react";

export const CheckboxCircleFilled = ({
  width = 24,
  height = 24,
  fill = "#1B58F1",
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill={fill} />
      <path
        d="M6.16596 10.4347C6.45885 10.1418 6.93373 10.1418 7.22662 10.4347L10.9389 14.1471L16.7726 8.31342C17.0655 8.02053 17.5403 8.02053 17.8332 8.31342C18.1261 8.60631 18.1261 9.08119 17.8332 9.37408L11.5556 15.6517C11.215 15.9923 10.6628 15.9923 10.3223 15.6517L6.16596 11.4954C5.87307 11.2025 5.87307 10.7276 6.16596 10.4347Z"
        fill="white"
      />
    </svg>
  );
};
