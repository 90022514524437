import styled, { css } from "styled-components";
import { MessageUppercaseType } from "types/types";

export const Container = styled.div`
  width: 100%;
  border-radius: 4px;
  height: fit-content;
`;
export const Header = styled.div`
  display: flex;
  gap: 24px;
  align-items: flex-start;
  div {
    display: flex;
    align-items: center;
  }
`;
export const Agreement = styled.div`
  cursor: pointer;
`;
export const Link = styled.span`
  color: #1b58f1;
  text-decoration: underline;
  cursor: pointer;
`;

export const PolicyContainer = styled.div`
  line-height: 20px;
`;
export const Icon = styled.div`
  transform: scale(0.8);
  padding-right: 8px;
`;

export const Caption = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -1px;
  color: #ee3737;
  padding: 0px 0px 8px 4px;
`;

export const MessageTitleInput = styled.input<{
  messageType?: MessageUppercaseType;
}>`
  background: #fafafa;
  border: 1px solid #d7dbe2;
  width: 100%;
  padding: 16px;
  border-radius: 6px;
  line-height: 1.5;
  font-size: 16px;
  white-space: pre-line;
  margin-bottom: 8px;
  letter-spacing: -1px;

  &::placeholder {
    content: attr(placeholder);
    color: #acacaccf;
  }

  ${(props) =>
    props.messageType === "LMS" &&
    css`
      background: rgba(27, 88, 241, 0.05);
      border: 1px solid #1b58f1;
    `}
  ${(props) =>
    props.messageType === "MMS" &&
    css`
      background: rgba(101, 45, 144, 0.05);
      border: 1px solid #652d90;
    `}
`;
export const MessageInputContainer = styled.div<{
  messageType?: MessageUppercaseType;
  isAd: boolean;
}>`
  position: relative;
  background: #fafafa;
  border: 1px solid #d7dbe2;
  box-sizing: border-box;
  padding: 16px 16px ${(props) => (props.isAd ? "72px" : "40px")};
  border-radius: 6px;
  height: 360px;

  ${(props) =>
    props.messageType === "LMS" &&
    css`
      background: rgba(27, 88, 241, 0.05);
      border: 1px solid #1b58f1;
    `}
  ${(props) =>
    props.messageType === "MMS" &&
    css`
      background: rgba(101, 45, 144, 0.05);
      border: 1px solid #652d90;
    `}
`;

export const MessageContentInput = styled.textarea<{
  messageType?: MessageUppercaseType;
}>`
  letter-spacing: -1px;
  margin-top: -1px;
  height: 100%;
  min-height: 260px;

  line-height: 1.5;
  width: 100%;
  font-size: 16px;
  white-space: pre-line;

  border: none;
  background: transparent;
  outline: none;

  &::placeholder {
    content: attr(placeholder);
    color: #acacaccf;
  }
`;
export const MessageByteContainer = styled.div`
  position: absolute;
  bottom: 16px;
  right: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -1px;
  .messageByte {
    display: flex;
    align-items: center;
    justify-content: end;
    font-size: 14px;
    height: 100%;
    width: 100%;
  }

  .messageButtons {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    gap: 4px;
  }

  .targetByte {
    color: black;
    padding-right: 4px;
  }
`;

export const MessageByteSize = styled.span<{ color?: string }>`
  color: ${(props) => (props.color ? props.color : "#333333")};
`;

export const ConfirmButton = styled.button`
  width: 100%;
  justify-content: center;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -1px;

  color: #ffffff;
  padding: 16px;
  background: #1b58f1;
  border-radius: 100px;
`;

export const BottomMessage = styled.div<{ hasMessage?: boolean }>`
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -1px;
  white-space: pre-line;
  color: ${(props) => (props.hasMessage ? "#333333" : "#999999")};
  position: absolute;
  bottom: 16px;
  left: 16px;
`;
