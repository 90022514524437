import styled, { css } from "styled-components";

export const GroupListContainer = styled.div`
  border: 1px solid #d7dbe2;
  border-radius: 6px;
  padding: 12px 10px;
  flex: 1;
  min-height: 395px;
  max-height: 395px;
  overflow-y: auto;
`;
export const GroupListItem = styled.div<{ active?: boolean }>`
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -1px;

  div:nth-child(1) {
    &::before {
      content: "· ";
      font-size: 20px;
      padding-top: 3px;
      line-height: 0;
    }
  }

  ${(props) =>
    props.active &&
    css`
      font-weight: 700;
      color: #1b58f1;
    `}
`;
