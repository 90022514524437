import { ConfirmModal, ModalLayout } from "components";
import { useContext, useEffect, useState } from "react";

import styled from "styled-components";
import {
  DepositCreateBodyType,
  ImportPaymentParam,
  PaymentMethodType,
  WalletType,
} from "types/types";
import { requestImportPayment } from "utils/import";
import { ChargeAmount } from "./ChargeAmount";
import { Notice } from "./Notice";
import { PaymentMethod } from "./PaymentMethod";
import { Submit } from "./Submit";
import { UserType } from "types/types";
import * as pointApi from "apis/pointApi";
import { MyPoint } from "./MyPoint";
import { useCustomNavigate } from "hooks/useCustomNavigate";
import UserStore from "stores/UserStore";
import { observer } from "mobx-react-lite";
import { Content } from "components/Modals/LayoutStyled";
import { MEDIA_QUERY } from "properties/constant";
import { Li } from "components/layout/MessageLayout";
import { PATH } from "properties/menu";

export const PointCharge: React.FC = observer(() => {
  const userStore = useContext(UserStore);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodType>("card");
  const [chargeAmount, setChargeAmount] = useState<number>(0);
  const [depositerName, setDepositerName] = useState<string>();

  const [wallet, setWallet] = useState<WalletType | null>(null);
  const [visibleAlertModal, setVisibleAlertModal] = useState(false);
  const [visibleDepositinConfirmModal, setVisibleDepositinConfirmModal] =
    useState(false);
  const [visibleDepositCompleteModal, setVisibleDepositCompleteModal] =
    useState(false);

  const [alertMessage, setAlertMessage] = useState({ title: "", text: "" });
  const navigate = useCustomNavigate();
  const MIN_CHARGE_AMOUNT = 10000;
  const user: UserType = userStore.getUser();

  useEffect(() => {
    _fetchBilingWallet();
  }, []);
  const _fetchBilingWallet = async () => {
    const response = await pointApi.fetchBilingWallet();

    setWallet(response);
  };

  const onCreatePayment = async () => {
    if (paymentMethod === "deposit") {
      setVisibleDepositinConfirmModal(true);
      return;
    } else if (chargeAmount < MIN_CHARGE_AMOUNT) {
      setAlertMessage({
        title: "충전 금액 안내",
        text: "최소 충전 금액은 1만원입니다.",
      });
      setVisibleAlertModal(true);
      return;
    }

    let customData: object | null = null;
    if (chargeAmount <= 0) {
      alert("충전금액을 입력해주세요.");
      return;
    } else if (chargeAmount > 1000000) {
      alert("최대 충전금액은 1,000,000원 입니다.");
      return;
    }

    const response = await pointApi.getPaymentSerial();
    if (response?.custom_data) {
      customData = response.custom_data;
    }

    const paymentParam: ImportPaymentParam = {
      name: "비즈포스트 충전금",
      amount: chargeAmount,
      buyer_email: user?.email,
      buyer_name: user?.username,
      buyer_tel: user?.phone_number,
      custom_data: customData ? customData : {},
      pay_method: paymentMethod,
    };

    requestImportPayment(
      paymentParam,
      chargeSuccessCallback,
      chargeFailCallback
    );
  };

  const chargeSuccessCallback = async (
    impUid: string,
    merchantUid: string,
    paymentParam: ImportPaymentParam
  ) => {
    try {
      alert("충전이 완료되었습니다.");
      await pointApi.createPayment(impUid, merchantUid, paymentParam);
      navigate.reload();
    } catch (err) {
      console.error(err);
    }
  };
  const chargeFailCallback = () => {
    // TODO: fail 로직 추가
    // alert("충전에 실패했습니다.");
  };

  /** 무통장 입금 */
  const onCreatePaymentDeposit = async () => {
    if (depositerName && depositerName.length < 2) {
      return;
    }
    const body: DepositCreateBodyType = {
      amount: chargeAmount,
      depositor: depositerName,
    };
    const response = await pointApi.createDeposit(body);
    if (response) {
      setVisibleDepositinConfirmModal(false);
      setVisibleDepositCompleteModal(true);
    }
  };

  return (
    <Container>
      <SectionTitle>
        <b>충전하기</b>
      </SectionTitle>
      <MyPoint wallet={wallet} />

      <ChargeAmount
        onChangeAmount={setChargeAmount}
        amount={chargeAmount}
        wallet={wallet}
      />
      <PaymentMethod
        paymentMethod={paymentMethod}
        setPaymentMethod={setPaymentMethod}
      />
      <Notice />
      <Submit
        onClickChargeButton={onCreatePayment}
        chargeAmount={chargeAmount}
      />
      {visibleAlertModal && (
        <ModalLayout
          title={alertMessage.title}
          onClose={() => {
            setVisibleAlertModal(false);
          }}
        >
          <Content>{alertMessage.text}</Content>
        </ModalLayout>
      )}

      {visibleDepositinConfirmModal && (
        <ConfirmModal
          title="무통장 입금 안내"
          confirmButtonText={"입금 완료"}
          confirmButtonColor={
            depositerName && depositerName.length > 1 ? "#1b58f1" : "#999999"
          }
          onConfirm={onCreatePaymentDeposit}
          closeModal={() => setVisibleDepositinConfirmModal(false)}
        >
          <ModalContent>
            <Li>
              하단의 입금하실 <b>입금자 명</b>을 정확하게 입력해주세요.{" "}
            </Li>
            <Li>
              입금이 완료된 후, <b>입금 완료</b> 버튼을 눌러주세요.{" "}
            </Li>
            <Li>
              입금 후 충전은 <b>1시간 이내</b>로 처리됩니다.
            </Li>
            <br />
            <div className="container">
              <label>계좌번호</label>
              <h6>
                기업은행 &nbsp;&nbsp; 940-049076-04-011 &nbsp;
                <span>주식회사 플로셀</span>
              </h6>
            </div>

            <div className="container">
              <label>입금자명</label>
              <input
                placeholder="입금자명을 정확히 입력해주세요"
                type="text"
                onChange={(e) => {
                  setDepositerName(e.target.value);
                }}
              />
            </div>
          </ModalContent>
        </ConfirmModal>
      )}

      {visibleDepositCompleteModal && (
        <ConfirmModal
          title="무통장 입금 신청 완료"
          confirmButtonText={"확인"}
          onConfirm={() => {
            setVisibleDepositCompleteModal(false);
            navigate.moveTo(PATH.DASHBOARD);
          }}
          closeModal={() => setVisibleDepositCompleteModal(false)}
        >
          <ModalContent>
            <Li>
              입금 후 충전은 <b>1시간 이내</b>로 처리됩니다.
            </Li>
            <Li>처리가 늦어지는 경우, 채널톡으로 문의해주세요</Li>
          </ModalContent>
        </ConfirmModal>
      )}
    </Container>
  );
});

const ModalContent = styled(Content)`
  padding: 0px;
  h6 {
    background-color: #f3f3f3;
    font-weight: 700;
    padding: 16px;
    border-radius: 8px;
    color: #333333;
    display: flex;
    flex-wrap: wrap;

    span {
      font-weight: 500;
    }
  }
  .container {
    margin-top: 16px;
    label {
      padding-bottom: 12px;
      display: block;
      font-weight: 500;
      color: #333333;
    }
    input {
      height: 60px;
      border: solid 1px #999999;
      border-radius: 8px;
      width: 100%;
      padding: 10px;
      font-size: 16px;
    }
  }
`;
const Container = styled.div`
  padding: 48px 20px 120px;
  margin: 0 auto;
  width: 620px;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 100%;
  }

  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -1px;
    color: #333333;
    padding-bottom: 20px;
  }

  h5 {
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: -1px;
    color: #1b58f1;
  }
`;
const SectionTitle = styled.div`
  font-size: 32px;
  line-height: 39px;
  letter-spacing: -1px;
  color: #333333;
  padding-bottom: 40px;
`;
