import { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { uploadS3File } from "utils/awsS3";
import { v4 as uuidv4 } from "uuid";
import { toJS } from "mobx";
import dayjs from "dayjs";

import { AlertModal, ConfirmModal, Line } from "components";
import * as S from "./styled";

import { MessageEditor } from "./components/MessageEditor";
import { CallerSelection } from "./components/CallerSection";

import { MessageLayout } from "components/layout/MessageLayout";
import { Loading } from "components/Icons/Loading";

import { validatePhoneAll, validateMessage } from "utils/phone";
import { PATH } from "properties/menu";
import { useCustomNavigate } from "hooks/useCustomNavigate";
import {
  AddressGroupType,
  SendMessageParamsType,
  SendReceiverAddressType,
} from "types/types";

import { ModalTestSend } from "./components/modals/ModalTestSend";
import { CheckboxChecked, CheckboxUnchecked } from "components/Icons/Checkbox";
import { ModalReservation } from "./components/modals/ModalReservation";
import { TemplateSelection } from "./components/TemplateSelection";

import { Pad } from "styles/styled";
import { ManageReceiver } from "./components/ManageReceiver";
import { MessageMockUp } from "components/MessageMockUp";
import { FileUpload } from "./components/FileUpload";

import CallerStore from "stores/CallerStore";
import MessageStore from "stores/MessageStore";
import WalltetStore from "stores/WalltetStore";
import TemplateStore from "stores/TemplateStore";

/** 메시지 전송 페이지 */
export const MessageSend = observer(() => {
  const callerStore = useContext(CallerStore);
  const messageStore = useContext(MessageStore);
  const walletStore = useContext(WalltetStore);
  const templateStore = useContext(TemplateStore);

  const [loading, setLoading] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [visibleAlert, setVisibleAlert] = useState(false);

  // 메시지 작성
  const [validateSendMessage, setValidateSendMessage] = useState(false);
  const [imageSrc1, setImageSrc1] = useState<string | null>(null);
  const [imageSrc2, setImageSrc2] = useState<string | null>(null);
  const [imageSrc3, setImageSrc3] = useState<string | null>(null);
  const [s3ImageUrl1, setS3ImageUrl1] = useState<string>();
  const [s3ImageUrl2, setS3ImageUrl2] = useState<string>();
  const [s3ImageUrl3, setS3ImageUrl3] = useState<string>();
  const [uuid] = useState<string>(uuidv4());

  // 테스트 발송
  const [visibleTestSend, setVisibleTestSend] = useState<boolean>(false);
  const [visibleCalendarModal, setVisibleCalendarModal] =
    useState<boolean>(false);
  const { isReservation, setIsReservation, setReservedDatetime } = messageStore;

  const [imageFileList, setImageFileList] = useState<FileList | null>(null);
  const [confirmText, setConfirmText] = useState("");
  const [targetMoveUrl, setTargetMoveUrl] = useState("");
  const [visibleConfirmModal, setVisibleConfirmModal] = useState(false);

  const navigate = useCustomNavigate();

  useEffect(() => {
    fetchCallers();
    templateStore.setTargetTemplate(null);
    return () => {
      resetPage();
    };
  }, []);

  useEffect(() => {
    // 템플릿 선택 시, 템플릿 내용으로 메시지 내용 변경
    if (templateStore.targetTemplate) {
      const template = templateStore.targetTemplate;
      messageStore.setIsAd(template.is_ad);
      messageStore.updateMessageTitle(template.title);
      messageStore.updateMessageContent(template.content);
    }
  }, [templateStore.targetTemplate]);

  useEffect(() => {
    // 메시지 내용 변경 시, 유효성 검사
    const validate = checkValidateSendMessage(
      messageStore.receiverGroups,
      messageStore.receivers
    );
    setValidateSendMessage(validate);
  }, [
    callerStore.targetCaller,
    messageStore.receivers,
    messageStore.receiverGroups,
    messageStore.targetTitle,
    messageStore.targetContent,
  ]);

  // 이미지 업로드 시, 미리보기
  useEffect(() => {
    updateImagePreview();
  }, [imageFileList]);

  /** 페이지 리셋 */
  const resetPage = () => {
    setLoading(false);
    setValidateSendMessage(false);
    setVisibleTestSend(false);
    setImageFiles(null);
    setImageSrc1("");
    setImageSrc2("");
    setImageSrc3("");
    messageStore.updateMessageTitle("");
    messageStore.updateMessageContent("");
    callerStore.setTargetCaller(null);
    messageStore.setReceivers([]);
    messageStore.setReceiverGroups([]);
    messageStore.setIsAd(false);
  };

  /** 이미지 파일 set*/
  const setImageFiles = (images: FileList | null) => {
    setImageFileList(images);
    messageStore.setHasImages(images && images.length > 0);

    // S3에 업로드 후, 이미지 URL을 받아옴
    for (let i = 0; i < images?.length; i++) {
      onUploadImagesToS3(images[i], i);
    }
  };

  /** 유저가 선택한 이미지 S3 임시 업로드 */
  const onUploadImagesToS3 = async (image: File, index: number) => {
    try {
      const response = await uploadS3File("mms", image, `${uuid}_${index}`);
      if (index === 0) {
        setS3ImageUrl1(response.Key);
      } else if (index === 1) {
        setS3ImageUrl2(response.Key);
      } else if (index === 2) {
        setS3ImageUrl3(response.Key);
      }
    } catch (err) {
      console.error("파일 업로드 실패");
    }
  };

  /** 이미지 미리보기 */
  const updateImagePreview = () => {
    setImageSrc1(null);
    setImageSrc2(null);
    setImageSrc3(null);

    if (imageFileList && imageFileList.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(imageFileList[0]);
      reader.onload = () => {
        setImageSrc1(reader.result as string);
      };
    }
    if (imageFileList && imageFileList.length > 1) {
      const reader = new FileReader();
      reader.readAsDataURL(imageFileList[1]);
      reader.onload = () => {
        setImageSrc2(reader.result as string);
      };
    }
    if (imageFileList && imageFileList.length > 2) {
      const reader = new FileReader();
      reader.readAsDataURL(imageFileList[2]);
      reader.onload = () => {
        setImageSrc3(reader.result as string);
      };
    }
  };

  // 옵션 토글
  const onClickReservationOption = () => {
    if (isReservation) {
      setIsReservation(false);
      setVisibleCalendarModal(false);
      setReservedDatetime(null);
    } else {
      setVisibleCalendarModal(true);
    }
  };

  /** 발신번호 리스트 가져오기 */
  const fetchCallers = async () => {
    await callerStore.fetchCallers();
    callerStore.updateFilteredApprovedCallers();
    if (callerStore.targetCaller) {
      messageStore.setTargetCaller(callerStore.targetCaller);
    } else {
      openAlert("등록된 발신 번호가 없습니다. \n발신번호를 먼저 등록해주세요.");
    }
  };

  /** 문자 발송 전 유효성 검사*/
  const checkValidateSendMessage = (
    receiverGroups: AddressGroupType[],
    receiverPhones: SendReceiverAddressType[],
    callback?: (errorMessage: string) => void
  ) => {
    let errorMessage = "";
    const phones = receiverPhones.map((receiver) => receiver.phone_number);

    if (callerStore.targetCaller === null) {
      errorMessage = "발신번호를 선택해주세요.";
    } else if (
      !validateMessage(messageStore.targetTitle, messageStore.targetContent)
    ) {
      errorMessage = "문자 내용을 확인해주세요.";
    } else if (receiverGroups.length === 0 && phones.length === 0) {
      errorMessage = "발신 대상 전화번호를 추가해주세요.";
    } else if (receiverGroups.length === 0 && !validatePhoneAll(phones)) {
      errorMessage = "번호 형식을 확인해주세요.";
    }

    if (callback) {
      callback(errorMessage);
    }

    return errorMessage ? false : true;
  };

  const alertErrorMessage = (message: string) => {
    if (message && message.length > 0) {
      openAlert(message);
    }
  };

  /** 알림창 열기 */
  const openAlert = (text: string) => {
    setAlertText(text);
    setVisibleAlert(true);
  };

  const checkSendMessageValidate = () => {
    if (
      !checkValidateSendMessage(
        messageStore.receiverGroups,
        messageStore.receivers,
        alertErrorMessage
      )
    ) {
      return false;
    }
    if (!messageStore.targetCaller) {
      openAlert("발신 번호를 다시 선택해주세요.");

      return false;
    }
    if (messageStore.isReservation && !checkReservationTime()) {
      openAlert(
        "예약 시간이 10분 이내면 예약할 수 없습니다.\n예약 시간을 다시 설정해주세요."
      );
      return false;
    }
    return true;
  };

  const getImageUrls = () => {
    let imageUrls = null;
    if (s3ImageUrl1) {
      imageUrls = [s3ImageUrl1];
    }
    if (s3ImageUrl2) {
      imageUrls.push(s3ImageUrl2);
    }
    if (s3ImageUrl3) {
      imageUrls.push(s3ImageUrl3);
    }
    return imageUrls;
  };

  const generateSendTestMessageParams = (testPhoneNumber: string) => {
    const testReceiver: SendReceiverAddressType[] = [
      { name: "-", phone_number: testPhoneNumber },
    ];
    const params: SendMessageParamsType = {
      receivers: testReceiver,
      caller: callerStore.targetCaller,
    };
    if (getImageUrls) {
      params.image_link = getImageUrls();
    }

    return params;
  };

  /** 문자 발송 파라미터 생성 */
  const generateSendMessageParams = () => {
    const group_ids = messageStore.receiverGroups.map((group) => group.id);

    const params: SendMessageParamsType = {
      receivers: toJS(messageStore.receivers),
      caller: toJS(callerStore.targetCaller),
      group_ids: group_ids,
    };

    if (getImageUrls) {
      params.image_link = getImageUrls();
    }
    return params;
  };

  /** 테스트 문자 발송 파라미터 생성*/
  const sendMessage = async () => {
    if (loading) {
      return;
    }
    const validated = checkSendMessageValidate();
    if (!validated) {
      return;
    }

    const groupReceiverCount = messageStore.receiverGroups.reduce(
      (count, group) => count + group.total_count,
      0
    );
    const totalCount = messageStore.receivers.length + groupReceiverCount;
    if (totalCount === 0) {
      openAlert("발송 대상인 전화 번호를 등록해주세요.");
      return;
    }

    // 문자 발송 API 호출
    setLoading(true);
    // 중복번호 한번 발송되도록 필터링

    try {
      const param = generateSendMessageParams();
      const result = await messageStore.sendMessage(param);

      if (result.is_bulk) {
        setVisibleConfirmModal(true);
        setConfirmText(
          "[묶음 발송]으로 순차적으로 발송 처리될 예정입니다.\n결과 페이지에서 결과를 확인해주세요."
        );
        setTargetMoveUrl(PATH.MESSAGE_SEND_RESULT);
      } else {
        callbackSendMessageSuccess(result.group_id);
      }
    } catch (err: any) {
      openAlert(`메시지 발송에 실패하였습니다.\n[에러 메시지] ${err.detail}`);
    } finally {
      setLoading(false);
    }
  };

  /** 테스트 문자 발송 */
  const sendTestMessage = async (testPhoneNumber: string) => {
    if (loading) {
      return;
    }
    let errorMessage = "";
    if (callerStore.targetCaller === null) {
      errorMessage = "발신번호를 선택해주세요.";
    } else if (
      !validateMessage(messageStore.targetTitle, messageStore.targetContent)
    ) {
      errorMessage = "문자 내용을 확인해주세요.";
    }
    if (errorMessage !== "") {
      openAlert(errorMessage);
      return;
    }

    // 문자 발송 API 호출
    setLoading(true);

    try {
      const params = generateSendTestMessageParams(testPhoneNumber);
      await messageStore.sendMessage(params);
      callbackSendTestMessageSuccess();
    } catch (err: any) {
      openAlert(`메시지 발송에 실패하였습니다.\n[에러 메시지] ${err.detail}`);
    } finally {
      setLoading(false);
    }
  };

  /** 문자 발송 성공시 콜백 */
  const callbackSendMessageSuccess = async (groupId) => {
    // 왼쪽 상단 잔여금액 갱신
    await walletStore.fetchWallet(true);
    // 예약 문자인 경우

    if (messageStore.isReservation) {
      setConfirmText(
        `문자가 정상적으로 예약 되었습니다.\n예약 결과 페이지로 이동하시겠습니까?`
      );
      setTargetMoveUrl(
        PATH.MESSAGE_RESERVED_RESULT_DETAIL.replace(":groupId", groupId)
      );
    } else {
      setConfirmText(
        `문자가 정상적으로 발송 요청 되었습니다.\n발송 결과 페이지로 이동하시겠습니까?`
      );
      setTargetMoveUrl(
        PATH.MESSAGE_SEND_RESULT_DETAIL.replace(":groupId", groupId)
      );
    }

    setVisibleConfirmModal(true);
  };

  /** 테스트 문자 발송 성공시 콜백 */
  const callbackSendTestMessageSuccess = async () => {
    setVisibleTestSend(false);

    // 왼쪽 상단 잔여금액 갱신
    await walletStore.fetchWallet(true);
    openAlert("테스트 문자가 발송 요청 되었습니다");
  };

  const checkReservationTime = () => {
    const tenMinuteAfter = dayjs().add(10, "minute");
    return dayjs(messageStore.reservedDatetime).isAfter(tenMinuteAfter);
  };

  return (
    <MessageLayout maxWidth={820} title="일반문자">
      <S.Container>
        <S.Inner>
          <S.PageTitle>발송하기</S.PageTitle>
          <Pad pb={40}>
            <S.Descriptions>
              <S.Li>
                {`문자 발송 시 ‘충전금 > 포인트' 순서로 사용되며, 성공건 기준으로 요금이 부과 됩니다.`}
              </S.Li>
              <S.Li>{`광고성 문자는 ‘광고성 정보' 를 체크해주세요.`}</S.Li>
              <S.Li>{`웹 이모지는 인식할 수 없습니다. ?로 변환되어 발송됩니다.`}</S.Li>
            </S.Descriptions>
          </Pad>

          <S.Flex>
            <S.LeftSection>
              {/* -------- 템플릿 선택 --------*/}
              <Pad pb={40}>
                <TemplateSelection />
              </Pad>

              {/* -------- 발신번호 선택 --------*/}
              <CallerSelection
                callers={callerStore.approvedCallers}
                targetCaller={messageStore.targetCaller}
                setTargetCaller={messageStore.setTargetCaller}
              />
              {/* -------- 메시지 내용 입력  --------*/}
              <MessageEditor />
            </S.LeftSection>
            {/* ------- 목업 -------*/}
            <S.RightSection>
              <MessageMockUp
                imageSrc1={imageSrc1}
                imageSrc2={imageSrc2}
                imageSrc3={imageSrc3}
              />
            </S.RightSection>
          </S.Flex>

          {/* -------- 이미지 업로드 */}
          <FileUpload
            imageFiles={imageFileList}
            setImageFiles={setImageFiles}
          />

          {/* -------수신번호 --------*/}
          <ManageReceiver
            receivers={messageStore.receivers}
            receiverGroups={messageStore.receiverGroups}
          />

          {/* ------- 예약발송 ----------*/}
          <Line margin="24px 0px" />
          <div>
            <S.OptionContainer>
              <CheckboxUnchecked />
              {/* TODO: 기능 점검중 표시 */}
              {/* <div onClick={onClickReservationOption}>
                {isReservation ? <CheckboxChecked /> : <CheckboxUnchecked />}
              </div> */}
              <S.OptionText
                active={false}
                onClick={() => alert('기능 점검 중입니다. \n이용에 불편을 드려 죄송합니다.')}
              // onClick={() => setVisibleCalendarModal(true)}
              >
                예약 발송 설정하기 (기능 점검 중)
                {/* {isReservation && messageStore.getReservedDatetime() && (
                  <span> (예약일시: {messageStore.getReservedDatetime()})</span>
                )} */}
              </S.OptionText>
            </S.OptionContainer>

            {visibleCalendarModal && (
              <ModalReservation setVisibleModal={setVisibleCalendarModal} />
            )}
          </div>
          {/* 중복번호 한 번만 발송하기 */}
          <div>
            <S.OptionContainer>
              <CheckboxChecked />

              <S.OptionText active={true}>
                중복번호 한 번만 발송하기
              </S.OptionText>
            </S.OptionContainer>
          </div>

          {loading && (
            <S.Overlay>
              <Loading width={80} height={80} />
            </S.Overlay>
          )}
          <S.ButtonContainer>
            <S.ButtonOutline
              onClick={() => setVisibleTestSend(true)}
              active={true}
            >
              테스트 전송하기
            </S.ButtonOutline>
            <S.Button
              onClick={() => sendMessage()}
              active={validateSendMessage}
            >
              {messageStore.isReservation
                ? "메시지 발송 예약하기"
                : "메시지 발송하기"}
            </S.Button>
          </S.ButtonContainer>
        </S.Inner>
        {visibleTestSend && (
          <ModalTestSend
            onClose={() => setVisibleTestSend(false)}
            onSendMessage={sendTestMessage}
          />
        )}
        {visibleAlert && (
          <AlertModal title={"안내"} closeModal={() => setVisibleAlert(false)}>
            <>{alertText}</>
          </AlertModal>
        )}
        {visibleConfirmModal && (
          <ConfirmModal
            title={"문자 전송 성공"}
            confirmButtonText={"이동하기"}
            onConfirm={() => navigate.moveTo(targetMoveUrl)}
            closeModal={() => {
              setVisibleConfirmModal(false);
              window.scrollTo({
                top: 0,
              });
            }}
          >
            <div>{confirmText}</div>
          </ConfirmModal>
        )}
      </S.Container>
    </MessageLayout>
  );
});
