import styled, { CSSObject } from "styled-components";

const Container = styled.div<{
  css: CSSObject;
}>`
  flex: 1;

  label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border: 1px solid #333333;
    color: #333333;
    font-weight: 700;
    border-radius: 4px;
  }

  input[type="file"] {
    display: block;
    width: 0;
    height: 0;
    overflow: hidden;
  }

  ${(props) => (props.css ? props.css : "")}
`;

interface IProps {
  id: string;
  text: string;
  setInputImage: (fileImage: File) => void;
  css?: CSSObject;
  setPreviewImage?: (readerResult: string | ArrayBuffer) => void;
}

const InputFile = ({
  id,
  text,
  setInputImage,
  css,
  setPreviewImage,
}: IProps) => {
  const encodeFileToBase64 = (fileBlob) => {
    const reader = new FileReader();
    reader.readAsDataURL(fileBlob);
    return new Promise((resolve) => {
      reader.onload = () => {
        console.dir(reader);
        setPreviewImage(reader.result);
        resolve(true);
      };
    });
  };

  const handleFileChange = (e) => {
    if (!e.target.files) {
      return;
    } else if (e.target.files && e.target.files[0]) {
      setInputImage(e.target.files[0]);
      if (setPreviewImage) {
        encodeFileToBase64(e.target.files[0]);
      }
    }
  };

  return (
    <Container css={css}>
      <label htmlFor={id}>{text}</label>
      <input type="file" id={id} onChange={handleFileChange} />
    </Container>
  );
};

export default InputFile;
