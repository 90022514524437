import { ImportPaymentParam, PortOneResponseType } from "types/types";

/**
 * 휴대폰 본인인증 함수
 * @param phone 휴대폰 번호
 * @param callbackSuccess 본인인증 성공시 호출되는 콜백함수
 * @param callbackFail 본인인증 실패시 호출되는 콜백함수
 */
export const importCeriticatePhone = (phone, callbackSuccess, callbackFail) => {
  /* 본인인증 데이터 정의하기 */
  const IMP_ID = process.env.REACT_APP_PORTONE_IMPUID;
  const { IMP } = window;
  IMP.init(IMP_ID);

  const data = {
    merchant_uid: `ORD${new Date().getTime()}`,
    phone: phone,
    popup: true,
  };
  /*  본인인증 창 호출하기 */

  IMP.certification(data, (res) =>
    certificationCallback(res, callbackSuccess, callbackFail)
  );
};

/**
 * 본인인증 콜백 함수
 * @param response
 * @param callbackSuccess
 * @param callabackFail
 */
const certificationCallback = (response, callbackSuccess, callabackFail) => {
  const { success, imp_uid, error_msg } = response;
  if (success) {
    callbackSuccess(imp_uid);
  } else {
    alert(`본인인증 실패: ${error_msg}`);
    callabackFail();
  }
};

/**
 * 충전 결제 함수
 * @param paymentParam 충전 정보
 * @param callbackSuccess 충전 성공시 호출되는 콜백함수
 * @param callbackFail 충전 실패시 호출되는 콜백함수
 */
export const requestImportPayment = (
  paymentParam: ImportPaymentParam,
  callbackSuccess: any,
  callbackFail: any
) => {
  const IMP_ID = process.env.REACT_APP_PORTONE_IMPUID;
  const { IMP } = window;
  IMP.init(IMP_ID);
  IMP.request_pay(
    {
      // param
      pg: process.env.REACT_APP_PORTONE_CPID,
      merchant_uid: `ORD${new Date().getTime()}`,
      ...paymentParam,
    },
    (res) => paymentCallback(res, paymentParam, callbackSuccess, callbackFail)
  );
};

const paymentCallback = (
  response: PortOneResponseType,
  paymentParam: ImportPaymentParam,
  callbackSuccess: (impUid, merchantUid, paymentParam) => void,
  callabackFail: () => void
) => {
  const {
    success,
    imp_uid: impUid,
    merchant_uid: merchantUid,
    error_msg,
  } = response;

  if (success) {
    callbackSuccess(impUid, merchantUid, paymentParam);
  } else {
    alert(`결제 실패: ${error_msg}`);
    callabackFail();
  }
};
