import * as Icons from "components/Icons";
import * as S from "./styled";

interface Props {
  optionValue: "none" | "image";
  optionLabel: string;
  selectedOptionValue: string;
  setSelectedOption: (optionValue: "none" | "image") => void;
}

export const FileUploadOption: React.FC<Props> = ({
  optionValue,
  optionLabel,
  selectedOptionValue,
  setSelectedOption,
}) => {
  return (
    <S.OptionContainer onClick={() => setSelectedOption(optionValue)}>
      {selectedOptionValue === optionValue ? (
        <Icons.RadioChecked />
      ) : (
        <Icons.RadioUnchecked />
      )}
      <span>{optionLabel}</span>
    </S.OptionContainer>
  );
};
