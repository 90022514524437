import styled from "styled-components";
import { MenuItems } from "properties/menu";
import { MainMenuItem } from "./MainMenuItem";

import { SubMenuItem } from "./SubMenuItem";
import { MenuBarUserPanel } from "components/MenuBarUserPanel";
import { useCustomNavigate } from "hooks/useCustomNavigate";

export const MenuBar: React.FC = () => {
  const navigate = useCustomNavigate();

  return (
    <Container>
      <MenuBarUserPanel />
      <Inner>
        <ul>
          {MenuItems.map((menu) => {
            // 메인 메뉴 활성화 여부
            const activeMainMenu =
              navigate.pathname.includes(menu.path) ||
              !!menu.subMenus?.some((subMenu) =>
                navigate.pathname.includes(subMenu.path)
              );

            return (
              <div key={menu.id}>
                <MainMenuItem
                  key={menu.id}
                  menu={menu}
                  active={activeMainMenu}
                />
                {menu.subMenus &&
                  activeMainMenu &&
                  menu.subMenus.map((subMenu) => {
                    const isActive = navigate.pathname.includes(subMenu.path);

                    return (
                      <SubMenuItem
                        key={subMenu.id}
                        menu={subMenu}
                        active={isActive}
                      />
                    );
                  })}
              </div>
            );
          })}
        </ul>
      </Inner>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  left: 0;
  width: 240px;
  height: calc(100vh - 64px);
  border-right: solid 1px #f2f2f2;
  background-color: white;
  box-sizing: border-box;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.05);

  @media print {
    height: auto;
  }
`;

const Inner = styled.div`
  padding: 48px 0px 24px 48px;
  background-color: white;

  padding-top: 36px;
  border-radius: 10px 10px 0px 0px;
`;
