import { SendResultDetailStatusType, StatisticsType } from "types/types";

export const getStatisticsInit = (statistics: StatisticsType | null) => {
  if (!statistics) {
    const initStatistics: StatisticsType = {
      SMS: {
        total_waiting: 0,
        total_processing: 0,
        total_failed: 0,
        total_reserved: 0,
        total_success: 0,
        total_count: 0,
      },
      LMS: {
        total_waiting: 0,
        total_processing: 0,
        total_failed: 0,
        total_reserved: 0,
        total_success: 0,
        total_count: 0,
      },
      MMS: {
        total_waiting: 0,
        total_processing: 0,
        total_failed: 0,
        total_reserved: 0,
        total_success: 0,
        total_count: 0,
      },
      RCS_SMS: {
        total_waiting: 0,
        total_processing: 0,
        total_failed: 0,
        total_reserved: 0,
        total_success: 0,
        total_count: 0,
      },
      RCS_LMS: {
        total_waiting: 0,
        total_processing: 0,
        total_failed: 0,
        total_reserved: 0,
        total_success: 0,
        total_count: 0,
      },
      RCS_MMS: {
        total_waiting: 0,
        total_processing: 0,
        total_failed: 0,
        total_reserved: 0,
        total_success: 0,
        total_count: 0,
      },
      total_count: 0,
    };
    return initStatistics;
  }

  return statistics;
};

export const getTotalStatistic = (statistics: StatisticsType | null) => {
  const _statistics = getStatisticsInit(statistics);
  const totalReady =
    getStatisticSum(_statistics, "total_waiting") +
    getStatisticSum(_statistics, "total_reserved") +
    getStatisticSum(_statistics, "total_processing");
  const denominator =
    _statistics.total_count === 0 ? 1 : _statistics.total_count;

  const resultStatistics = {
    ratio_total_failed: Math.round(
      (getStatisticSum(_statistics, "total_failed") / denominator) * 100
    ),
    ratio_total_success: Math.round(
      (getStatisticSum(_statistics, "total_success") / denominator) * 100
    ),
    ratio_total_ready: Math.round((totalReady / denominator) * 100),
    ..._statistics,
  };
  return resultStatistics;
};

const getStatisticSum = (statistics: StatisticsType, type: string) => {
  const { SMS, LMS, MMS, RCS_SMS, RCS_LMS, RCS_MMS } = statistics;
  const sum =
    SMS[`${type}`] +
    LMS[`${type}`] +
    MMS[`${type}`] +
    RCS_SMS[`${type}`] +
    RCS_LMS[`${type}`] +
    RCS_MMS[`${type}`];
  return statistics.total_count === 0 ? 0 : sum;
};

export const getSendResultDetailStatistics = (
  stats: SendResultDetailStatusType | null
) => {
  const result = {
    totalCount: 0,
    successRatio: 0,
    failureRatio: 0,
    waitRatio: 0,
  };
  if (stats) {
    result.totalCount = stats.total_count;
    result.successRatio = Math.round(
      (stats.success_count / stats.total_count) * 100
    );
    result.failureRatio = Math.round(
      (stats.failure_count / stats.total_count) * 100
    );
    result.waitRatio = Math.round((stats.wait_count / stats.total_count) * 100);
  }
  return result;
};
