import { ImgLoading } from "assets";
import styled from "styled-components";
export const Loading = ({
  width,
  height,
}: {
  width?: number;
  height?: number;
}) => {
  return (
    <LoadingIcon
      className="icon"
      width={width}
      height={height}
      src={ImgLoading}
      alt="로딩"
    />
  );
};

const LoadingIcon = styled.img<{ width?: number; height?: number }>`
  margin: 0 auto;
  width: ${(props) => (props.width ? props.width : 30)}px;
  height: ${(props) => (props.height ? props.height : 30)}px;
`;
