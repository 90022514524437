import { Search as SearchIcon } from "components/Icons";
import styled from "styled-components";

interface Props {
  type?: string;
  placeholder: string;
  isFocused?: boolean;
  value: string;
  width?: string;
  onInputChange: (element: any) => void;
  onClickSearchButton: () => void;
}
export const InputWithSearch = ({
  type = "text",
  placeholder,
  value,
  width,
  onInputChange,
  onClickSearchButton,
}: Props) => {
  const onEnterKeyDown = (e) => {
    if (e.key === "Enter") {
      onClickSearchButton();
    }
  };
  return (
    <Container width={width ? width : "100%"}>
      <Input
        type={type}
        placeholder={placeholder}
        onChange={(e) => onInputChange(e.target.value)}
        onKeyDown={onEnterKeyDown}
        defaultValue={value}
      />
      <IconContainer onClick={onClickSearchButton}>
        <SearchIcon />
      </IconContainer>
    </Container>
  );
};

const Container = styled.div<{ width: string }>`
  position: relative;
  width: ${(props) => props.width};

  &:focus-visible {
    outline: none;
  }
  &::placeholder {
    color: #999999;
  }
  &:focus {
    border-color: #1b58f1;
  }
`;

const Input = styled.input`
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #d7dbe2;
  border-radius: 6px;
  padding: 10px 40px 10px 10px;
  overflow: auto;
  font-weight: 400;
  font-size: 16px;
  width: 100%;
  line-height: 23px;

  &:focus-visible {
    outline: none;
  }
  &::placeholder {
    color: #999999;
  }
  &:focus {
    border-color: #1b58f1;
  }
`;
const IconContainer = styled.div`
  position: absolute;
  right: 12px;
  top: 13px;
  cursor: pointer;
`;
