import styled from "styled-components";
import { ButtonOutline } from "components/ButtonOutline";
import { PeriodOptionType } from "types/types";

import { DATE_FORMAT_2, getCurrentTime, subtractDate } from "utils/datetime";
import dayjs from "dayjs";
import { periodOptions } from "properties/content";
import { InputDate } from "components";

interface Props {
  startDate: string;
  endDate: string;
  activePeriod: PeriodOptionType | null;
  setStartDate: (startDate: string) => void;
  setEndDate: (endDate: string) => void;
  setActivePeriod: (period: PeriodOptionType | null) => void;
}

export const DateSelection: React.FC<Props> = ({
  startDate,
  endDate,
  activePeriod,
  setStartDate,
  setEndDate,
  setActivePeriod,
}) => {
  const today = getCurrentTime(DATE_FORMAT_2);
  const MIN_DATE = subtractDate(today, DATE_FORMAT_2, 730);
  const MAX_DATE = today;
  const onClickPeriod = (period: PeriodOptionType) => {
    setStartDate(subtractDate(dayjs(), DATE_FORMAT_2, period.days));
    setEndDate(getCurrentTime(DATE_FORMAT_2));
    setActivePeriod(period);
  };

  return (
    <FlexBetween>
      <PeriodButtonList>
        {periodOptions.map((period) => (
          <ButtonOutline
            key={period.id}
            text={period.text}
            onClick={() => onClickPeriod(period)}
            active={period.text === activePeriod?.text}
          />
        ))}
      </PeriodButtonList>
      <Flex>
        <InputDate
          date={startDate}
          minDate={MIN_DATE}
          maxDate={MAX_DATE}
          setDate={setStartDate}
          height="46px"
          width="140px"
        />

        <div>~</div>
        <InputDate
          date={endDate}
          minDate={MIN_DATE}
          maxDate={MAX_DATE}
          setDate={setEndDate}
          height="46px"
          width="140px"
        />
      </Flex>
    </FlexBetween>
  );
};
const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`;
const Flex = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const PeriodButtonList = styled.div`
  display: flex;
  gap: 4px;
`;
