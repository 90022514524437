import { SVGProps } from "react";

export const SelectBoxCheck = ({
  width = 9,
  height = 18,
  fill = "#333333",
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M20.2793 8.28033C20.5736 7.98744 20.5736 7.51256 20.2793 7.21967C19.9851 6.92678 19.508 6.92678 19.2138 7.21967L12.2121 14.1893L5.28621 7.29505C4.99197 7.00215 4.51492 7.00215 4.22068 7.29505C3.92644 7.58794 3.92644 8.06281 4.22068 8.35571L11.6794 15.7803C11.9736 16.0732 12.4507 16.0732 12.7449 15.7803C12.7576 15.7677 12.7698 15.7546 12.7814 15.7413C12.7948 15.7297 12.8079 15.7176 12.8206 15.705L20.2793 8.28033Z" fill="#121212"/>
    </svg>
  );
};
