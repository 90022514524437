import styled from "styled-components";
import { useCustomNavigate } from "hooks/useCustomNavigate";
import { ArrowRight } from "../Icons";
import { PATH } from "properties/menu";
import { ImgHomeBannerBg } from "assets";
import { MEDIA_QUERY } from "properties/constant";

export const Banner = () => {
  const navigate = useCustomNavigate();

  return (
    <Container bgImage={ImgHomeBannerBg}>
      <Title>{`지금 회원가입 시\n신규 회원 포인트 지급`}</Title>
      <Button onClick={() => navigate.moveTo(PATH.SIGN_UP)}>
        <div>지금 회원가입 하러가기</div>
        <ArrowRight />
      </Button>
    </Container>
  );
};

const Container = styled.div<{ bgImage: string }>`
  background-image: url(${(props) => props.bgImage});
  background-size: cover;
  background-position: center;
  height: 340px;

  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    height: 280px;
  }
`;

const Title = styled.h3`
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
  letter-spacing: -1px;

  color: #ffffff;
  white-space: pre-line;
  text-align: center;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 28px;
    line-height: 34px;
  }
`;

const Button = styled.div`
  cursor: pointer;

  background: #ffffff;
  border-radius: 100px;
  display: flex;
  padding: 14px 20px 12px;

  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;

  display: flex;
  align-items: center;
  gap: 8px;

  letter-spacing: -1px;

  color: #0a308f;
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
