import { Calendar } from "components/Icons";
import styled, { css } from "styled-components";

interface Props {
  text: string;
  icon?: "date";
  active?: boolean;
  onClick: () => void;
}

/**
 *
 * @param text
 * @param onClick
 * @param styles
 * @returns
 */
export const ButtonOutline: React.FC<Props> = ({
  text,
  icon,
  active = false,
  onClick,
}) => {
  return (
    <Container onClick={onClick} active={active}>
      {icon === "date" && <Calendar />}
      <div>{text}</div>
    </Container>
  );
};

const Container = styled.button<{ active: boolean }>`
  min-width: 76px;
  padding: 10px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 24px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: #ffffff;

  ${(props) =>
    props.active
      ? css`
          border: 1px solid #1b58f1;
          color: #1b58f1;
          font-weight: 700;
        `
      : css`
          border: 1px solid #333333;
          color: #333333;
          font-weight: 400;
        `};
`;
