import { ModalLayout, ButtonNormal } from "components";
import { CallerType } from "types/types";
import { changeDateFormat, DATE_FORMAT_1 } from "utils/datetime";
import { formatToPhoneNumber } from "utils/phone";
import { ButtonContainer, Container, Content } from "../LayoutStyled";
import { Pad } from "styles/styled";

interface Props {
  caller: CallerType;
  onRemoveCaller: (caller: CallerType) => void;
  onClose: () => void;
  loading: boolean;
}
export const RemoveCallerModal = ({
  loading,
  caller,
  onRemoveCaller,
  onClose,
}: Props) => {
  return (
    <ModalLayout title={"발신번호 삭제"} onClose={onClose}>
      {caller && (
        <Container>
          <Pad py={24}>
            <Content>
              아래의 발신번호를 삭제하시겠습니까?
              <Pad pt={8}>
                <li>
                  등록일자 : {changeDateFormat(caller.created, DATE_FORMAT_1)}
                </li>
                <li>발신번호 : {formatToPhoneNumber(caller.phone_number)}</li>
                <li>발신번호명 : {caller.user.username}</li>
                <li>상태 : {caller.status === 1 ? "검수 완료" : "검수 중"}</li>
              </Pad>
            </Content>
          </Pad>
          <ButtonContainer>
            <ButtonNormal label="취소" onClick={onClose} />
            <ButtonNormal
              label="삭제하기"
              isPrimary
              disabled={loading}
              onClick={() => onRemoveCaller(caller)}
            />
          </ButtonContainer>
        </Container>
      )}
    </ModalLayout>
  );
};
