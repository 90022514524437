import { Icons } from "components";
import styled, { CSSObject } from "styled-components";
import fonts from "styles/fonts";

const Container = styled.div<{
  borderColor;
  margin;
  padding;
  css;
  value;
  placeholderEmphasis;
}>`
  position: relative;
  flex: 1;
  height: 100%;
  margin: ${(props) => (props.margin ? props.margin : "0")};
  ${(props) => (props.css ? props.css : "")};

  select {
    width: 100%;
    min-width: 132px;
    height: 100%;
    padding: ${(props) =>
      props.padding ? props.padding : "16px 40px 16px 16px"};
    font-family: inherit;
    background-size: 8px;
    border: 1px solid
      ${(props) => (props.borderColor ? props.borderColor : "#D7DBE2")};
    box-sizing: border-box;
    color: ${(props) =>
      props.value ? "#333" : props.placeholderEmphasis ? "#333" : "#999"};
    font-weight: ${(props) => (props.placeholderEmphasis ? "bold" : "normal")};
    border-radius: 4px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    &::-ms-expand {
      display: none;
    }

    ${fonts.Body1};
  }

  .ml-8 {
    margin-left: 8px;
  }
`;

const SelectOption = styled.option`
  padding: 12px;
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 12px;
  display: flex;
  align-items: center;
  width: 24px;
  height: 100%;
`;

interface IProps {
  onChange: (target: HTMLSelectElement, name?: string) => void;
  value: string;
  options: { value: string; text: string }[];
  defaultValue?: string;
  borderColor?: string;
  margin?: string;
  padding?: string;
  name?: string;
  placeholderEmphasis?: boolean;
  css?: CSSObject;
}

const SelectBasic = ({
  onChange,
  value,
  options,
  borderColor,
  defaultValue,
  margin,
  padding,
  name,
  placeholderEmphasis,
  css,
}: IProps) => {
  return (
    <Container
      borderColor={borderColor}
      margin={margin}
      padding={padding}
      css={css}
      value={value}
      placeholderEmphasis={placeholderEmphasis}
    >
      <select
        required
        defaultValue={defaultValue}
        onChange={(e) => onChange(e.target, name)}
        name={name}
      >
        {options.map((option, index) => (
          <SelectOption key={index} value={option.value}>
            {option.text}
          </SelectOption>
        ))}
      </select>

      <IconWrapper>
        <Icons.SelectBoxCheck></Icons.SelectBoxCheck>
      </IconWrapper>
    </Container>
  );
};

export default SelectBasic;
