import { QnaList } from "properties/content";
import styled from "styled-components";
import QnaListItem from "./QnaListItem";
import { MEDIA_QUERY } from "properties/constant";

export const QnA = () => {
  return (
    <Container>
      <header>
        <TextContainer>
          <Title>FAQ</Title>
        </TextContainer>
        {QnaList.map((qna) => (
          <QnaListItem
            key={qna.id}
            question={qna.question}
            answer={qna.answer}
          />
        ))}
      </header>
    </Container>
  );
};

const Container = styled.div`
  background: #ffffff;
  padding: 100px 0px;
`;

const TextContainer = styled.div`
  padding-bottom: 40px;
  text-align: center;
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    padding-bottom: 20px;
  }
`;

const Title = styled.div`
  text-align: center;
  font-weight: 700;
  font-size: 34px;
  line-height: 49px;
  letter-spacing: -1px;
  color: #333333;
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 28px;
    line-height: 36px;
  }
`;
