import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";

import "swiper/css";
import "swiper/css/navigation";

import { MEDIA_QUERY } from "properties/constant";

import { HeaderCard1 } from "./HeaderCard1";
import { HeaderCard2 } from "./HeaderCard2";
import { HeaderCard3 } from "./HeaderCard3";

export const Header = () => {
  return (
    <Container>
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={0}
        slidesPerView={1}
        navigation
      >
        <SwiperSlide>
          <Layout bgColor="#1e1e1e">
            <HeaderCard1 />
          </Layout>
        </SwiperSlide>
        <SwiperSlide>
          <Layout bgColor="#ffcc6f">
            <HeaderCard2 />
          </Layout>
        </SwiperSlide>
        <SwiperSlide>
          <Layout bgColor="#08173d">
            <HeaderCard3 />
          </Layout>
        </SwiperSlide>
      </Swiper>
    </Container>
  );
};

const Container = styled.section`
  text-align: center;
  .swiper-button-next:after,
  .swiper-button-prev:after {
    color: white;
  }
`;

const Layout = styled.div<{ bgColor: string }>`
  background-color: ${(props) => props.bgColor};
  box-sizing: border-box;
  height: 540px;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    height: 400px;
  }
`;
