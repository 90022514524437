import styled from "styled-components";
import * as S from "styles/styled";
import { Icons, Line, Padding } from "components";
import { ButtonOutline } from "components/ButtonOutline";
import InputText from "components/InputText";
import MockupBasic from "components/MockupBasic";
import TextareaBasic from "components/TextareaBasic";
import InputEmail from "components/InputEmail";
import { FlexWrapper } from "components/Flex";
import SelectBasic from "components/SelectBasic";
import BrandStore from "stores/BrandStore";
import InputFile from "components/InputFile";
import { useCallback, useContext } from "react";
import { observer } from "mobx-react";
import InputAddress from "components/InputAddress";
import { formatToPhoneNumber } from "utils/phone";
import BrandLayout from "../BrandLayout";

/** image List */
import MockupThumbnail from "assets/images/mockup/thumbnail/thumbnail-1.svg";
import MockupChatroom from "assets/images/mockup/mockup-chatroom-1.jpg";

/** option type list */
const QuickButtonOptionList = [
  "Chat",
  "Call",
  "Moreinfo",
  "Order",
  "Buy",
  "Ticket",
  "Web",
  "Store",
];

const PriorityTabsList = [
  {
    text: "소식 탭 우선",
    value: "news",
  },
  {
    text: "정보 탭 우선",
    value: "information",
  },
];

const ContactMethodsList = [
  {
    text: "전화걸기",
    value: "phoneCall",
    inputPlaceholder: "‘-’ 없이",
  },
  {
    text: "웹사이트",
    value: "website",
    inputPlaceholder: "예시) https://www.bizpost.kr ",
  },
  {
    text: "포탈검색",
    value: "potalSearch",
    inputPlaceholder: "브랜드명을 입력해주세요",
  },
];

/** style component */
const Container = styled.div`
  padding: 48px 40px;
  width: fit-content;
`;
export const Inner = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 32px 24px;
`;

const StepSection = styled.div`
  width: 100%;
  display: flex;
`;

const StepLeftSideWrapper = styled.section`
  width: 495px;
`;
const StepRightSideWrapper = styled.section<{ paddingTop? }>`
  margin-left: 16px;
  padding-top: ${(props) => (props.paddingTop ? props.paddingTop : "136px")};
`;
const Descriptions = styled.div`
  padding-bottom: 24px;
`;

const Li = styled.div`
  display: flex;
  padding-bottom: 8px;
  color: #666;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -1px;
  &::before {
    content: "•";
    display: inline-block;
    height: 100%;
    padding: 0 8px;
  }
`;

const LiStep = styled.ul`
  > li {
    margin-bottom: 40px;
  }
`;

export const PageTitle = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 46px;
  letter-spacing: -1px;
  color: #333333;
  padding-bottom: 27px;
`;

export const PageTitleSmall = styled.h3`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 46px;
  letter-spacing: -1px;
  color: #333333;
  padding-bottom: 16px;
`;

const TitleByStep = styled.h4`
  color: #333;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1px;
`;

const Body = styled.div`
  color: #666;
  font-size: 16px;
  line-height: normal;
  letter-spacing: -1px;
`;

const FlexButtonWrap = styled.div`
  display: flex;
  width: 100%;
  > button {
    width: 243px;
    margin-right: 9px;
    font-weight: bold;
    letter-spacing: -1px;
  }
`;

const ThumbnailLarge = styled.div``;
const ThumbnailMini = styled.div``;

const CheckBoxWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  li {
    display: flex;
    align-items: center;
    width: 25%;
    margin-bottom: 16px;
    span {
      margin-left: 8px;
    }
  }
`;

const CheckableInputList = styled.ul`
  li {
    margin-bottom: 16px;
    > div {
      align-items: center;
      span {
        margin: 0 16px 0 8px;
      }
    }
  }
`;

const BrandProfile = styled.div<{
  margin?: string;
}>`
  display: flex;
  padding: 0 16px;
  margin: ${(props) => (props.margin ? props.margin : "0")};
`;

const ChatroomImageWrapper = styled.div`
  width: 258px;
  height: 358px;
  background: #f2f2f2;
  img {
    width: 100%;
  }
`;

interface IProps {}

export const BrandCreation = observer(({}: IProps) => {
  const brandStore = useContext(BrandStore);
  return (
    <BrandLayout>
      <Container>
        <PageTitle>브랜드 관리</PageTitle>
        <Inner>
          <PageTitleSmall>브랜드 개설</PageTitleSmall>
          <Descriptions>
            <Li>
              {`신청한 브랜드 정보는 운영자 심사를 거쳐 노출 여부가 결정되며 승인 결과는 문자메시지(SMS) 및 이메일로 알려드립니다.`}
            </Li>
            <Li>{`승인 심사는 영업일 기준 48시가 이내이며 내부 사정상 지체될 수 있습니다.`}</Li>
          </Descriptions>
          <Line />
          <Padding height="24px" />
          {/* STEP 1 */}
          <Step1Section />
          <Line margin="0" />
          <Padding height="40px" />
          {/* STEP 2 */}
          <Step2Section />
          <Line margin="0" />
          <Padding height="40px" />
          {/* STEP 3 */}
          <Step3Section />
          <Line margin="0" />
          <Padding height="40px" />
          {/* STEP 4 */}
          <Step4Section />

          <Padding height="40px" />
          <S.ButtonRound
            css={{
              width: "495px",
              backgroundColor: "#E1E1E1",
              color: "white",
            }}
          >
            브랜드 개설 신청하기
          </S.ButtonRound>
        </Inner>
      </Container>
    </BrandLayout>
  );
});

interface PropsStep1 {}
const Step1Section: React.FC<PropsStep1> = observer(({}) => {
  const brandStore = useContext(BrandStore);
  const brandHomeInfo = brandStore.brandHomeInfo;

  const setBackgroundImage = useCallback((imageFile) => {
    brandStore.setBrandHomeInfo("backgroundImage", imageFile);
  }, []);
  const setBackgroundBasicImage = useCallback(() => {
    brandStore.setBrandHomeInfo("backgroundImage", null);
  }, []);
  const setProfileImage = useCallback((imageFile) => {
    brandStore.setBrandHomeInfo("profileImage", imageFile);
  }, []);
  const setProfileBasicImage = useCallback(() => {
    brandStore.setBrandHomeInfo("profileImage", null);
  }, []);

  const setBrandHomeInputText = useCallback((target, name) => {
    brandStore.setBrandHomeInfo(name, target.value);
  }, []);

  const onHandleQuickButtonType = useCallback((selectedButtonType: string) => {
    brandStore.setBrandHomeInfo("quickButtonType", selectedButtonType);
  }, []);
  const setBrandHomeEmailInfo = useCallback((target, name) => {
    brandStore.setBrandHomeEmailInfo(name, target.value);
  }, []);
  const setBrandHomeEmailDomain = useCallback((value) => {
    brandStore.setBrandHomeEmailInfo("domain", value);
  }, []);
  const handleDirectInputEmailChange = useCallback((target) => {
    brandStore.setBrandHomeEmailInfo("domain", target.value);
  }, []);
  const setBrandHomeAddress = useCallback((addressInfo) => {
    brandStore.setBrandHomeAddress(addressInfo);
    console.log(brandStore.brandHomeInfo.storeAddress);
  }, []);

  return (
    <StepSection>
      <StepLeftSideWrapper>
        <TitleByStep className="pb-16">STEP 1. 브랜드 홈 정보 입력</TitleByStep>
        <Padding height="16px" />
        <Body>
          사용자에게 안내할 브랜드 정보를 입력하세요. 입력한 정보는 메시지
          앱에서 제공하는 브랜드 홈에서 확인 할 수 있습니다.
        </Body>
        <Padding height="40px" />
        <LiStep>
          <li>
            <S.RequiredBodyHeader>백그라운드 이미지</S.RequiredBodyHeader>
            <FlexButtonWrap>
              <ButtonOutline
                onClick={setBackgroundBasicImage}
                text="기본 이미지"
              />
              <InputFile
                text="사용자 이미지"
                setInputImage={setBackgroundImage}
                setPreviewImage={brandStore.setBackgroundImageSrc}
                id="background-image-upload"
              />
            </FlexButtonWrap>
            <Padding height="8px" />
            <Body>
              사이즈 : 388x388px ~ 1080x1080pa / 1:1 비율 / jpg, png (최대1MB)
            </Body>
          </li>
          <li>
            <S.RequiredBodyHeader onClick={setProfileBasicImage}>
              프로필 이미지
            </S.RequiredBodyHeader>
            <FlexButtonWrap>
              <ButtonOutline onClick={() => {}} text="기본 이미지" />
              <InputFile
                text="사용자 이미지"
                setInputImage={setProfileImage}
                setPreviewImage={brandStore.setProfileImageSrc}
                id="profile-image-upload"
              />
            </FlexButtonWrap>
            <Padding height="8px" />
            <Body>
              사이즈 : 388x388px ~ 1080x1080pa / 1:1 비율 / png (최대1MB)
            </Body>
          </li>
          <li>
            <S.RequiredBodyHeader>브랜드 명</S.RequiredBodyHeader>
            <InputText
              onChange={setBrandHomeInputText}
              name="name"
              value={brandHomeInfo.name}
              maxByte={20}
              placeholder="비즈포스트"
            />
          </li>
          <li>
            <S.RequiredBodyHeader>브랜드 설명</S.RequiredBodyHeader>
            <TextareaBasic
              value={brandHomeInfo.description}
              maxByte={150}
              onChange={setBrandHomeInputText}
              name="description"
              placeholder={"브랜드의 슬로건이나 브랜드의 특징을 나타내는 설명문구를 작성해주세요."}
            ></TextareaBasic>
          </li>
          <li>
            <S.RequiredBodyHeader>전화번호</S.RequiredBodyHeader>
            <InputText
              onChange={setBrandHomeInputText}
              name="phoneNumber"
              value={brandHomeInfo.phoneNumber}
              placeholder="‘-’ 없이"
              bgColor={false ? "white" : "#FAFAFA"}
              onlyNumber
            />
          </li>
          <li>
            <S.BodyBold>퀵 버튼 설정</S.BodyBold>
            <CheckBoxWrapper>
              {QuickButtonOptionList.map((el, index) => (
                <li
                  key={`quick-button-${index}`}
                  onClick={() => onHandleQuickButtonType(el)}
                >
                  {el === brandHomeInfo.quickButtonType ? (
                    <Icons.RadioChecked />
                  ) : (
                    <Icons.RadioUnchecked />
                  )}
                  <span>{el}</span>
                </li>
              ))}
            </CheckBoxWrapper>
          </li>
          <li>
            <S.BodyBold>웹사이트</S.BodyBold>
            <InputText
              onChange={setBrandHomeInputText}
              name="webSite"
              value={brandHomeInfo.webSite}
              placeholder="예시) https://www.bizpost.kr"
              bgColor={false ? "white" : "#FAFAFA"}
            />
          </li>
          <li>
            <S.BodyBold>이메일</S.BodyBold>
            <FlexWrapper>
              <InputText
                width="124px"
                onChange={setBrandHomeEmailInfo}
                name="id"
                value={brandHomeInfo.email.id}
                placeholder=""
                bgColor={brandHomeInfo.email.id !== "" ? "white" : "#FAFAFA"}
              />
              <InputEmail
                value={brandHomeInfo.email.domain}
                onChangeText={handleDirectInputEmailChange}
                onSelect={setBrandHomeEmailDomain}
                text={brandHomeInfo.email.domain}
              />
            </FlexWrapper>
          </li>
          <li>
            <S.BodyBold>주소</S.BodyBold>
            <InputAddress onChange={setBrandHomeAddress} />
          </li>
        </LiStep>
      </StepLeftSideWrapper>
      <StepRightSideWrapper>
        <MockupBasic>
          <div>
            <ThumbnailLarge>
              <S.AutoCenterCoverSizeImage>
                <img
                  src={
                    brandHomeInfo.backgroundImage
                      ? brandStore.backgroundImageSrc
                      : MockupThumbnail
                  }
                  alt="배경화면 이미지 미리보기"
                />
              </S.AutoCenterCoverSizeImage>
            </ThumbnailLarge>
            <S.ScrollableTextContainer height="264px">
              <Padding height="40px" />
              <BrandProfile margin="0 0 16px 0">
                <ThumbnailMini>
                  <S.AutoCenterCoverSizeImage width="64px" height="64px">
                    <img
                      src={
                        brandHomeInfo.profileImage
                          ? brandStore.profileImageSrc
                          : MockupThumbnail
                      }
                      alt="프로필 이미지 미리보기"
                    />
                  </S.AutoCenterCoverSizeImage>
                </ThumbnailMini>
                <S.BoxStyleComponent
                  css={{
                    marginLeft: "16px",
                    marginBottom: "16px",
                  }}
                >
                  <S.BodyBold>
                    {brandHomeInfo.name !== ""
                      ? brandHomeInfo.name
                      : "브랜드 명"}
                  </S.BodyBold>
                  <S.Caption
                    css={{
                      color: "#666",
                      lineHeight: "normal",
                      whiteSpace: "pre-line",
                    }}
                  >
                    {brandHomeInfo.description !== ""
                      ? brandHomeInfo.description
                      : "브랜드 설명"}
                  </S.Caption>
                </S.BoxStyleComponent>
              </BrandProfile>
              <Line
                css={{
                  margin: "0 16px",
                  width: "calc(100% - 32px)",
                }}
              />
              <FlexWrapper
                css={{
                  padding: "16px 0 24px",
                  justifyContent: "center",
                }}
              >
                <S.ButtonRound
                  css={{
                    width: "113px",
                    height: "35px",
                    backgroundColor: "white",
                    color: "#999",
                  }}
                >
                  소식
                </S.ButtonRound>
                <S.ButtonRound
                  css={{
                    backgroundColor: "#EEE",
                    color: "#333",
                    width: "113px",
                    height: "35px",
                  }}
                >
                  정보
                </S.ButtonRound>
              </FlexWrapper>
              <S.Caption
                css={{
                  color: "#666",
                  paddingLeft: "16px",
                }}
              >
                전화번호 02-244-9200
              </S.Caption>
            </S.ScrollableTextContainer>
          </div>
        </MockupBasic>
      </StepRightSideWrapper>
    </StepSection>
  );
});

interface PropsStep2 {}
const Step2Section: React.FC<PropsStep2> = observer(({}) => {
  const brandStore = useContext(BrandStore);
  const brandHomeInfo = brandStore.brandHomeInfo;
  const brandChatRoomInfo = brandStore.brandChatRoomInfo;
  const setBrandChatRoomInputText = useCallback(
    (target: HTMLInputElement, name: string) => {
      brandStore.setBrandChatRoomInfo(name, target.value);
    },
    []
  );

  const setCommunicationProofImage = (imageFile) => {
    brandStore.setBrandChatRoomInfo("communicationProofImage", imageFile);

    console.dir(brandChatRoomInfo.communicationProofImage);
  };

  return (
    <StepSection>
      <StepLeftSideWrapper>
        <TitleByStep className="pb-16">
          STEP 2. 대화방 설정 (발신번호 정보 등록)
        </TitleByStep>
        <Padding height="16px" />
        <Body>
          사용자에게 안내할 브랜드 정보를 입력하세요. 입력한 정보는 메시지
          앱에서 제공하는 브랜드 홈에서 확인 할 수 있습니다.
        </Body>

        <Padding height="40px" />
        <LiStep>
          <li>
            <S.RequiredBodyHeader>대화방 명</S.RequiredBodyHeader>
            <InputText
              onChange={setBrandChatRoomInputText}
              value={brandChatRoomInfo.name}
              name="name"
              placeholder="대화방명을 입력해주세요"
              bgColor={brandChatRoomInfo.name !== "" ? "white" : "#FAFAFA"}
              maxByte={20}
            />
          </li>
          <li>
            <S.RequiredBodyHeader>대표 발신번호</S.RequiredBodyHeader>
            <InputText
              onChange={setBrandChatRoomInputText}
              name="senderNumber"
              value={brandChatRoomInfo.senderNumber}
              placeholder="휴대폰번호 등록 불가"
              onlyNumber
              bgColor={
                brandChatRoomInfo.senderNumber !== "" ? "white" : "#FAFAFA"
              }
            />
          </li>
          <li>
            <S.RequiredBodyHeader>통신서비스 가입증명원</S.RequiredBodyHeader>

            <InputFile
              text={
                brandChatRoomInfo.communicationProofImage
                  ? brandChatRoomInfo.communicationProofImage.name
                  : "파일 불러오기"
              }
              id="upload-communication-proof-image"
              setInputImage={setCommunicationProofImage}
              css={{
                height: "56px",
                margin: "0 0 16px 0",
              }}
            />

            <Descriptions>
              <Li>파일형식 : jpg, png, pdf, tiff, zip (최대 70MB)</Li>
              <Li>
                모든 발신번호에 해당하는 각각의 “통신서비스 가입증명원”은
                통신사에서 최근 1개월 이내 발행된 서류로 제출하세요.
              </Li>
              <Li>
                “발신번호 소유자"와 “가입 기업의 사업자”가 서로 다를 경우, 최근
                6개월 이내 발급된 위탁 관련 서류를 제출하세요.
              </Li>
            </Descriptions>
          </li>
        </LiStep>
        <Padding height="80px" />
      </StepLeftSideWrapper>
      <StepRightSideWrapper paddingTop="20px">
        <MockupBasic>
          <>
            <BrandProfile margin="40px 0 16px 0">
              <ThumbnailMini>
                <S.AutoCenterCoverSizeImage width="64px" height="64px">
                  <img
                    src={
                      brandHomeInfo.profileImage
                        ? brandStore.profileImageSrc
                        : MockupThumbnail
                    }
                    alt="프로필 이미지 미리보기"
                  />
                </S.AutoCenterCoverSizeImage>
              </ThumbnailMini>
              <S.BoxStyleComponent
                css={{
                  marginLeft: "16px",
                  marginBottom: "16px",
                }}
              >
                <S.BodyBold>
                  {brandChatRoomInfo.name === ""
                    ? "대화방 명"
                    : brandChatRoomInfo.name}
                </S.BodyBold>
                <S.Caption
                  css={{
                    color: "#666",
                    lineHeight: "normal",
                    whiteSpace: "pre-line",
                  }}
                >
                  {brandChatRoomInfo.senderNumber === ""
                    ? "대표 발신번호"
                    : formatToPhoneNumber(brandChatRoomInfo.senderNumber)}
                </S.Caption>
              </S.BoxStyleComponent>
            </BrandProfile>
            <FlexWrapper
              css={{
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0 16px 16px",
                boxSizing: "border-box",
              }}
            >
              <S.ButtonRound
                css={{
                  backgroundColor: "white",
                  border: "1px solid #D7DBE2",
                  color: "#333",
                  width: "105px",
                  height: "30px",
                  fontWeight: "normal",
                  fontSize: "14px",
                }}
              >
                브랜드홈
              </S.ButtonRound>
              <S.ButtonRound
                css={{
                  backgroundColor: "white",
                  border: "1px solid #D7DBE2",
                  color: "#333",
                  width: "105px",
                  height: "30px",
                  fontWeight: "normal",
                  fontSize: "14px",
                }}
              >
                수신차단
              </S.ButtonRound>
            </FlexWrapper>
            <ChatroomImageWrapper>
              <img src={MockupChatroom} alt="" />
            </ChatroomImageWrapper>
          </>
        </MockupBasic>
      </StepRightSideWrapper>
    </StepSection>
  );
});

interface PropsStep3 {}
const Step3Section: React.FC<PropsStep3> = observer(({}) => {
  const brandStore = useContext(BrandStore);
  const brandServiceManagementInfo = brandStore.brandServiceManagementInfo;

  const setCategoryOption = (target, name) => {
    brandStore.setBrandServiceManagementInfo(name, target.value);
  };
  const setServiceManagementInput = (target, name) => {
    brandStore.setBrandServiceManagementInfo(name, target.value);
  };
  return (
    <StepSection>
      <StepLeftSideWrapper>
        <TitleByStep className="pb-16">STEP 3. 서비스 관리 항목</TitleByStep>
        <Padding height="40px" />
        <LiStep>
          <li>
            <S.RequiredBodyHeader>카테고리 선택</S.RequiredBodyHeader>
            <FlexWrapper>
              <SelectBasic
                onChange={setCategoryOption}
                name="category1"
                value={brandServiceManagementInfo.category1}
                options={[
                  {
                    text: "카테고리 1",
                    value: "",
                  },
                ]}
              />
              <SelectBasic
                onChange={setCategoryOption}
                name="category2"
                value={brandServiceManagementInfo.category2}
                options={[
                  {
                    text: "카테고리 2",
                    value: "",
                  },
                ]}
                margin="0 0 0 8px"
              />
            </FlexWrapper>
            <Padding height="16px" />
            <InputText
              name="keyword"
              onChange={setServiceManagementInput}
              value={brandServiceManagementInfo.keyword}
              placeholder="검색용 키워드를 입력하세요"
            />
            <Padding height="40px" />
          </li>
        </LiStep>
      </StepLeftSideWrapper>
      <StepRightSideWrapper></StepRightSideWrapper>
    </StepSection>
  );
});

interface PropsStep4 {}
const Step4Section: React.FC<PropsStep4> = observer(({}) => {
  const brandStore = useContext(BrandStore);
  const brandHomeInfo = brandStore.brandHomeInfo;
  const brandHomeBasicTabInfo = brandStore.brandHomeBasicTabInfo;

  const onHandleClickPriorityTab = useCallback(async (value) => {
    await brandStore.initializeBasicTabSelectedValue();
    brandStore.setBrandHomeBasicTabInfo("tabPriority", value);
  }, []);
  const setBasicTabType = (value) => {
    brandStore.setBasicTabType(value);
  };
  const onHandleChangeBasicTabValue = (target) => {
    brandStore.setBasicTabValue(target.value);
  };
  return (
    <StepSection>
      <StepLeftSideWrapper>
        <TitleByStep className="pb-16">
          STEP 4. 브랜드 홈 기본 탭 설정
        </TitleByStep>
        <Padding height="16px" />
        <Body>
          브랜드 홈의 기본 탭을 설정합니다.
          <br />
          브랜드 홈 기본 탭은 브랜드 개설 시점에만 선택이 가능하며 이후 변경이
          불가합니다.
        </Body>
        <Padding height="40px" />
        <LiStep>
          <li>
            <S.BodyBold>기본 탭 설정</S.BodyBold>
            <CheckBoxWrapper>
              {PriorityTabsList.map((el, index) => (
                <li
                  key={`basic-tab-${index}`}
                  onClick={() => onHandleClickPriorityTab(el.value)}
                >
                  {el.value === brandHomeBasicTabInfo.tabPriority ? (
                    <Icons.RadioChecked />
                  ) : (
                    <Icons.RadioUnchecked />
                  )}
                  <span>{el.text}</span>
                </li>
              ))}
            </CheckBoxWrapper>
          </li>
          <li>
            <S.BodyBold>브랜드 소식 기본 화면 설정</S.BodyBold>
            <Descriptions>
              <Li>
                작성된 브랜드 소식이 없을 경우, 아래 설정한 화면이 표시됩니다.
              </Li>
              <Li>
                브랜드 소식 기본화면 설정에 따라 제공되는 버튼이 변경됩니다.
              </Li>
              <Li>
                홈페이지와 전화번호는 상단의 STEP 1. 브랜드 홈 정보 입력하기에
                입력한 값과 동일하게 반영됩니다.
              </Li>
              <Li>
                브랜드가 승인이 완료되면 브랜드 소식 메뉴를 통해 자유롭게 브랜드
                소식을 작성할 수 있습니다.
              </Li>
            </Descriptions>
            <CheckableInputList>
              {ContactMethodsList.map((m, index) => (
                <li
                  key={`contact-methods-${index}`}
                  onClick={() => setBasicTabType(m.value)}
                >
                  <FlexWrapper>
                    {m.value ===
                    brandHomeBasicTabInfo.brandNewsDefaultSetting
                      .selectedType ? (
                      <Icons.RadioChecked />
                    ) : (
                      <Icons.RadioUnchecked />
                    )}
                    <span>{m.text}</span>
                    <InputText
                      name={m.value}
                      onChange={onHandleChangeBasicTabValue}
                      value={
                        brandHomeBasicTabInfo.brandNewsDefaultSetting
                          .selectedType === m.value
                          ? brandHomeBasicTabInfo.brandNewsDefaultSetting
                              .selectedValue
                          : ""
                      }
                      onlyNumber={
                        brandHomeBasicTabInfo.brandNewsDefaultSetting
                          .selectedType === "phoneCall"
                          ? true
                          : false
                      }
                      placeholder={m.inputPlaceholder}
                    />
                  </FlexWrapper>
                </li>
              ))}
            </CheckableInputList>
          </li>
        </LiStep>
        <Padding height="40px" />
        <Line margin="0" />
      </StepLeftSideWrapper>
      <StepRightSideWrapper paddingTop="100px">
        <MockupBasic>
          <div>
            <ThumbnailLarge>
              <S.AutoCenterCoverSizeImage width="258px" height="258px">
                <img
                  src={
                    brandHomeInfo.backgroundImage
                      ? brandStore.backgroundImageSrc
                      : MockupThumbnail
                  }
                  alt="배경화면 이미지 미리보기"
                />
              </S.AutoCenterCoverSizeImage>
            </ThumbnailLarge>
            <Padding height="40px" />
            <BrandProfile margin="0 0 16px 0">
              <ThumbnailMini>
                <S.AutoCenterCoverSizeImage width="64px" height="64px">
                  <img
                    src={
                      brandHomeInfo.profileImage
                        ? brandStore.profileImageSrc
                        : MockupThumbnail
                    }
                    alt="프로필 이미지 미리보기"
                  />
                </S.AutoCenterCoverSizeImage>
              </ThumbnailMini>
              <S.BoxStyleComponent
                css={{
                  marginLeft: "16px",
                  marginBottom: "16px",
                }}
              >
                <S.BodyBold>브랜드 명</S.BodyBold>
                <S.Caption
                  css={{
                    color: "#666",
                    lineHeight: "normal",
                    whiteSpace: "pre-line",
                  }}
                >
                  브랜드 설명
                </S.Caption>
              </S.BoxStyleComponent>
            </BrandProfile>
            <Line
              margin="0 16px"
              css={{
                width: "calc(100% - 32px)",
              }}
            />
            <FlexWrapper
              css={{
                padding: "16px 0",
                justifyContent: "center",
              }}
            >
              <S.ButtonRound
                css={{
                  backgroundColor: "#EEE",
                  color: "#333",
                  width: "113px",
                  height: "35px",
                }}
              >
                소식
              </S.ButtonRound>
              <S.ButtonRound
                css={{
                  width: "113px",
                  height: "35px",
                  backgroundColor: "white",
                  color: "#999",
                }}
              >
                정보
              </S.ButtonRound>
            </FlexWrapper>
            <S.ButtonMockupSquare
              css={{
                width: "calc(100% - 32px)",
                margin: "0 16px",
              }}
            >
              {
                ContactMethodsList.filter(
                  (e) =>
                    e.value ===
                    brandHomeBasicTabInfo.brandNewsDefaultSetting.selectedType
                )[0].text
              }
            </S.ButtonMockupSquare>
          </div>
        </MockupBasic>
      </StepRightSideWrapper>
    </StepSection>
  );
});
