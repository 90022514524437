import { useContext, useEffect, useState } from "react";

import { useCustomNavigate } from "hooks/useCustomNavigate";
import * as session from "utils/session";

import { Loading } from "components/Icons/Loading";
import { Checkbox } from "components/Icons/Checkbox";
import { validateEmail } from "utils/email";

import { PATH } from "properties/menu";
import styled, { css } from "styled-components";
import { observer } from "mobx-react-lite";
import UserStore from "stores/UserStore";
import { AlertModal } from "components";

export const SignIn = observer(() => {
  const userStore = useContext(UserStore);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [isAutoLogin, setIsAutoLogin] = useState(false);

  // modals
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [validateErrorReason, setValidateErrorReason] = useState<string | null>(
    null
  );
  const [visibleAlertModal, setVisibleAlertModal] = useState(false);

  const navigate = useCustomNavigate();

  useEffect(() => {
    const visible = session.getVisibleNeedLoginAlert();
    setAlertMessage("로그인을 먼저 진행해주세요 ☺️");
    setVisibleAlertModal(!!visible);
  }, []);

  useEffect(() => {
    const validate = () => {
      setValidateErrorReason(null);

      if (!email) {
        setValidateErrorReason("이메일 주소를 입력해주세요");
      } else if (!validateEmail(email)) {
        setValidateErrorReason("이메일 형식을 확인해주세요");
      } else if (!password) {
        setValidateErrorReason("비밀번호를 입력해주세요");
      } else if (password.length < 6) {
        setValidateErrorReason("비밀번호를 6자 이상 입력해주세요");
      }
    };
    validate();
  }, [email, password, validateErrorReason]);

  /**
   * 로그인 유효성 검사
   */

  /**
   * 로그인 요청
   */
  const onSubmitSignIn = async () => {
    if (loading) {
      return;
    }
    if (validateErrorReason) {
      setAlertMessage(validateErrorReason);
      setVisibleAlertModal(true);
      return;
    }

    // 로그인
    setLoading(true);
    const loggined = await userStore.signIn(email, password, isAutoLogin);
    if (loggined) {
      navigate.moveTo(PATH.HOME);
      setLoading(false);
    } else {
      setAlertMessage("아이디와 비밀번호를 확인해주세요.");
      setVisibleAlertModal(true);
      setLoading(false);
    }
  };

  const onEnterKeyDown = (e) => {
    if (e.key === "Enter") {
      onSubmitSignIn();
    }
  };
  return (
    <Container>
      <Inner>
        <Title>로그인</Title>
        <div>
          <Label>이메일</Label>
          <Input
            written={email.length > 0}
            type="text"
            placeholder="이메일을 입력해주세요"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <Label>비밀번호</Label>
          <Input
            written={password.length > 0}
            type="password"
            placeholder="비밀번호를 입력해주세요"
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={onEnterKeyDown}
          />
        </div>
        <CheckboxContainer onClick={() => setIsAutoLogin(!isAutoLogin)}>
          <Checkbox active={isAutoLogin} />

          <div>로그인 상태유지</div>
        </CheckboxContainer>
        <Button active={!validateErrorReason} onClick={onSubmitSignIn}>
          <span>로그인</span>
          {loading && (
            <LoadingIcon>
              <Loading />
            </LoadingIcon>
          )}
        </Button>
        <AdditionalButtonContainer>
          <AdditionalButton
            onClick={() => {
              navigate.moveTo(PATH.SIGN_UP);
            }}
          >
            회원가입
          </AdditionalButton>
          <span>|</span>
          <AdditionalButton
            onClick={() => {
              navigate.moveTo(PATH.FIND_ID);
            }}
          >
            아이디찾기
          </AdditionalButton>
          <span>|</span>
          <AdditionalButton
            onClick={() => {
              navigate.moveTo(PATH.RESET_PASSWORD);
            }}
          >
            비밀번호 초기화
          </AdditionalButton>
        </AdditionalButtonContainer>
      </Inner>
      {visibleAlertModal && (
        <AlertModal
          title={"안내"}
          closeModal={() => {
            setVisibleAlertModal(false);
          }}
        >
          <>{alertMessage}</>
        </AlertModal>
      )}
    </Container>
  );
});

export const Container = styled.div``;

export const Inner = styled.div`
  max-width: 420px;
  background-color: white;
  margin: 0 auto 0px;
  padding: 48px 32px;
`;

export const Title = styled.h1`
  font-weight: 700;
  font-size: 24px;
  line-height: 39px;
  letter-spacing: -1px;
  padding-bottom: 40px;
`;

const Label = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -1px;
  color: #666666;
  padding-bottom: 8px;
`;
const Input = styled.input<{ written: boolean }>`
  border: 1px solid #999999;
  border-radius: 4px;
  padding: 12px 16px;
  height: 56px;
  width: 100%;
  margin-bottom: 24px;
  font-size: 16px;
  &::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -1px;
    color: #999999;
  }

  ${(props) =>
    props.written &&
    css`
      border: 1px solid #333333;
    `}
`;

const Button = styled.div<{ active: boolean }>`
  border-radius: 100px;
  width: 100%;
  margin: 0 auto;
  cursor: pointer;
  display: flex;

  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -1px;

  background: ${(props) => (props.active ? "#1b58f1" : "#e1e1e1")};

  color: #ffffff;
  height: 100%;
  width: 100%;
  padding: 16px;
  position: relative;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -1px;
  color: #666666;
  padding-bottom: 30px;
  cursor: pointer;
`;

const AdditionalButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px;
  gap: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -1px;
  color: #666666;
  span {
    font-size: 12px;
  }
`;

const AdditionalButton = styled.div`
  cursor: pointer;
`;

const LoadingIcon = styled.div`
  position: absolute;
  top: 10px;
  left: 56%;
`;
