import styled, { css } from "styled-components";
import { ButtonPrimary } from "styles/styled";

export const Container = styled.div`
  border-radius: 4px;
  min-width: 700px;
  min-height: 400px;
`;

export const TBody = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr 1fr 1fr 1fr 1fr 1fr;
  margin-bottom: 8px;
  background: #fafafa;
  padding-left: 4px;
`;

export const NoData = styled.div`
  text-align: center;
  padding-top: 160px;
  width: 100%;
  color: gray;
`;
export const TRow = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr 1fr 1fr 1fr 1fr 1fr;
  height: 34px;
  align-items: center;
`;

export const TD = styled.div<{
  hasLink?: boolean;
  isLeft?: boolean;
}>`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  padding: 4px;
  font-size: 16px;
  margin-left: -1px;
  margin-top: -1px;
  cursor: pointer;

  ${(props) =>
    props.isLeft &&
    css`
      text-align: left;
    `};

  ${(props) =>
    props.hasLink &&
    css`
      text-decoration-line: underline;
      color: #1b58f1;
    `}
`;

export const TR = styled(TD)`
  text-align: center;
  padding: 12px;
  color: #333333;
`;

export const LoadingContainer = styled.div`
  text-align: center;
  padding-top: 160px;
  width: 100%;
  display: flex;
  align-items: center;
`;

export const ButtonPrimaryStyled = styled(ButtonPrimary)``;
