import styled from "styled-components";

export const Container = styled.div`
  padding-bottom: 40px;
`;

export const Option = styled.div<{ isPlaceholder?: boolean }>`
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;
  letter-spacing: -1px;

  padding: 16px 10px;

  color: ${(props) => (props.isPlaceholder ? "#999999" : "#333333")};
`;

export const GroupSelection = styled.div`
  cursor: pointer;
  display: flex;
  border: 1px solid #d7dbe2;
  border-radius: 4px;
  flex: 1;
  align-items: center;
  padding: 0 8px;
  justify-content: space-between;
  position: relative;
`;

export const GroupOptions = styled.div`
  background: white;
  position: absolute;
  top: 56px;
  left: 0px;
  width: 100%;
  border-radius: 3px;
  overflow-y: auto;
  max-height: 380px;
`;

export const GroupOption = styled.div`
  padding: 16px;
  position: relative;
  background: white;
  z-index: 10;
  border: 0.5px solid #d7dbe2;
  margin-top: -1px;
  &:hover {
    background: #eef3ff;
  }
`;
