import { SVGProps } from "react";

export const ArrowRight = ({
  width = 22,
  height = 19,
  fill = "#29398A",
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="8.30005" width="20" height="2" rx="1" fill={fill}></rect>
      <rect
        x="12.4142"
        width="13.2045"
        height="2"
        rx="1"
        transform="rotate(45 12.4142 0)"
        fill={fill}
      />
      <rect
        x="11"
        y="17.2"
        width="13.2045"
        height="2"
        rx="1"
        transform="rotate(-45 11 17.2)"
        fill={fill}
      />
    </svg>
  );
};
