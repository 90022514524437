export const newCallerstepTexts = {
  WRITE_PHONE: {
    title: "발신번호 입력",
    content: "* 본인명의의 번호만 입력해주세요",
    subTitle: "발신번호 입력",
    inputPlaceholder: "숫자만 입력",
  },
  CHOOSE_NEXT_STEP: {
    title: "휴대폰으로 본인인증 하기",
    content: `* 본인 명의의 핸드폰 번호만 등록할 수 있습니다.
    * 본인 명의 핸드폰이 아닌 경우, 통신서비스 이용증명원 서류로 인증해주세요.`,
    subTitle: "발신번호 입력",
    inputPlaceholder: "숫자만 입력",
  },
  UPLOAD_DOCUMENT: {
    title: "서류인증",
    content: `등록하실 발신번호의 통신서비스 이용증명원을 첨부해주세요. (최근 1개월 이내)

    서류 인증 시에는 아래 서류를 *.zip 압축 파일로 업로드 해주세요.

    <div style="font-weight:700;font-size:18px;color:black; padding-bottom:8px;">(1) 기업회원 서류 안내</div><b>(1-1) 기업명의 발신번호 등록 필요 서류</b>
    - 사업자등록증, 통신서비스 가입증명원</p>
    <b>(1-2) 직원명의 발신번호 등록 필요 서류</b>
      - 사업자등록증, 통신서비스 가입증명원, 재직증명서

    <div style="font-weight:700;font-size:18px;color:black; padding-bottom:8px;">2) 일반회원 서류 안내</div><div>- 계정명의와 동일한 명의인 통신서비스 가입증명원 </div>`,
    subTitle: "파일 업로드",
    inputPlaceholder: "zip 파일만 가능 (최대 5MB)",
  },
};
