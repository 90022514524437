import axios, { AxiosError } from "axios";
import { getAccessToken } from "utils/session";
import { signOut } from "./userApi";
import * as storage from "utils/storage";
import * as session from "utils/session";
import { PROTECTED_PATHS } from "properties/menu";

export const serverApi = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
});

serverApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    const errorStatus: any = error?.response?.status;

    const path = window.location.pathname;
    if (errorStatus === 401 && PROTECTED_PATHS.includes(path)) {
      signOut();
      storage.removeTokens();
      session.removeTokens();
      window.location.href = "/";
    }

    return Promise.reject(error?.response?.data);
  }
);

/** access token이 포함된 header 반환 */
export const getHeaderWithToken = (contentType?: string) => {
  return {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };
};
