import { useContext, useEffect, useState } from "react";

import { InputWithLabel, ModalLayout } from "components";
import { Loading } from "components/Icons/Loading";

import { validatePhoneNumberOnly } from "utils/phone";
import { importCeriticatePhone } from "utils/import";

import { useCustomNavigate } from "hooks/useCustomNavigate";
import { PATH } from "properties/menu";
import styled, { css } from "styled-components";
import UserStore from "stores/UserStore";
import { observer } from "mobx-react-lite";
import {
  Button,
  ButtonContainer,
  Content,
} from "components/Modals/LayoutStyled";

export const FindEmail = observer(() => {
  const userStore = useContext(UserStore);
  const [username, setUsername] = useState("");
  const [phone, setPhone] = useState("");
  const [submitValidated, setSubmitValidate] = useState(false);
  const [validatedPhone, setValidatedPhone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userCertificated, setUserCeritificated] = useState(false);
  const [impUid, setImpUid] = useState("");
  const [visibleFoundEmail, setVisibleFoundEmail] = useState(false);
  const [foundEmail, setFoundEmail] = useState("");

  const navigate = useCustomNavigate();

  useEffect(() => {
    /** 회원가입 완료 유효성 검사 */
    const validateSubmit = () => {
      const isValidated =
        username.length > 0 && validatedPhone && userCertificated;
      setSubmitValidate(isValidated);
    };
    validateSubmit();
  }, [username, phone, userCertificated, validatedPhone]);

  useEffect(() => {
    setUserCeritificated(false);
  }, [phone, username]);

  const onHandleChangePhone = (e: any) => {
    const phone = e.target.value;
    const validated = validatePhoneNumberOnly(phone);
    setValidatedPhone(validated);
    setPhone(phone);
  };
  const onClickVerifyPhone = async () => {
    if (!validatedPhone) {
      alert("전화번호 형식을 확인해주세요.");
      return;
    }

    // 이미 등록된 번호인지 확인
    const result = await userStore.checkUniquePhone(phone);

    if (result.isUnique) {
      alert("회원 가입하지 않은 번호입니다.");
      return;
    }

    // 인증번호 요청
    importCeriticatePhone(
      phone,
      callbackCertificatePhoneSuccess,
      callbackCertificatePhoneFail
    );
  };

  const callbackCertificatePhoneSuccess = async (impUid: string) => {
    setImpUid(impUid);
    setUserCeritificated(true);
  };

  const callbackCertificatePhoneFail = (errorMesasge) => {
    alert(errorMesasge);
    setUserCeritificated(false);
  };

  /** 이메일 찾기 */
  const onClickFindEmail = async () => {
    if (loading) {
      return;
    } else if (!validatedPhone || username.length === 0) {
      alert("정보를 모두 입력해주세요.");
    } else if (!userCertificated) {
      alert("핸드폰 번호를 인증해주세요.");
      return;
    }

    setLoading(true);
    const response = await userStore.searchEmail(username, impUid, phone);
    setLoading(false);
    if (response.searched) {
      sessionStorage.setItem("searchedEmail", response.email);
      setFoundEmail(response.email);
      setVisibleFoundEmail(true);
    } else {
      setFoundEmail(response.email);
      alert(`이메일을 찾기에 문제가 발생했습니다. 고객센터에 문의해주세요.`);
    }
  };
  return (
    <div>
      <Container>
        <Inner>
          <Title>아이디(이메일) 찾기 </Title>

          <InputWithLabel
            label="이름"
            placeholder="이름을 입력해주세요"
            onInputChange={setUsername}
            value={username}
          />
          {/* 핸드폰 본인 인증 로직 */}
          <InputContainer>
            <Label>핸드폰 번호</Label>
            <InputButtonContainer>
              <Input
                type="phone"
                placeholder="-를 제외하고 입력"
                onChange={onHandleChangePhone}
              />

              <SideButton active={validatedPhone} onClick={onClickVerifyPhone}>
                {userCertificated ? "인증완료" : "본인인증"}
              </SideButton>
            </InputButtonContainer>
          </InputContainer>

          <SubmitButton active={submitValidated} onClick={onClickFindEmail}>
            <div>확인</div>
            {loading && <Loading />}
          </SubmitButton>
        </Inner>
        {visibleFoundEmail && (
          <ModalLayout
            title="아이디(이메일) 찾기"
            onClose={() => setVisibleFoundEmail(false)}
          >
            <div>
              <Content>{`등록하신 이메일은 [${foundEmail}]입니다.\n로그인 후 이용해주세요.`}</Content>
              <ButtonContainer>
                <Button
                  onClick={() => {
                    setVisibleFoundEmail(false);
                    navigate.moveTo(PATH.SIGN_IN);
                  }}
                >
                  로그인하기
                </Button>
              </ButtonContainer>
            </div>
          </ModalLayout>
        )}
      </Container>
    </div>
  );
});

const Container = styled.div`
  min-height: 100vh;
`;

const Inner = styled.div`
  max-width: 420px;
  background-color: white;
  margin-top: 32px;
  padding: 32px;
  margin: 16px auto 0px;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 500;

  letter-spacing: -1px;
  padding-bottom: 40px;
`;

const InputButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  button {
    width: 95px;
  }
  input {
    flex: 1;
  }
`;

const SubmitButton = styled.button<{ active: boolean }>`
  background: #e1e1e1;
  border-radius: 100px;
  padding: 16px;
  text-align: center;
  align-items: center;
  width: 100%;

  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -1px;
  display: flex;

  justify-content: center;
  gap: 4px;
  color: #ffffff;

  .icon {
    margin: 0px;
  }

  ${(props) =>
    props.active &&
    css`
      background: #1b58f1;
      cursor: pointer;
    `}
`;
const SideButton = styled.button<{ active: boolean }>`
  display: block;
  height: auto;
  border-radius: 4px;
  color: white;
  background: #e1e1e1;

  ${(props) =>
    props.active &&
    css`
      background: #1b58f1;
      cursor: pointer;
    `}
`;

const Label = styled.label`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -1px;
  color: #666666;
  padding-bottom: 8px;
  display: block;
`;

const InputContainer = styled.div`
  margin-bottom: 28px;
`;

const Input = styled.input<{ active?: boolean }>`
  background: #ffffff;
  border: 1px solid #d7dbe2;
  border-radius: 4px;
  padding: 16px;
  width: 100%;

  &::placeholder {
    color: #999999;
  }

  ${(props) =>
    props.active &&
    css`
      border-color: #333333;
    `}
`;
