import { SVGProps } from "react";

export const Search = ({
  width = 20,
  height = 20,
  fill = "#333333",
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.25631 15.7401C9.58491 15.6973 9.79176 15.3762 9.72286 15.052C9.65396 14.7279 9.33536 14.524 9.00615 14.5618C7.69162 14.7126 6.35716 14.446 5.19558 13.7915C3.85904 13.0383 2.84322 11.8234 2.3387 10.3747C1.83418 8.92589 1.87563 7.3428 2.45528 5.92241C3.03493 4.50202 4.11294 3.34194 5.48707 2.65982C6.86119 1.9777 8.43699 1.82041 9.91883 2.21747C11.4007 2.61453 12.6867 3.53864 13.5357 4.81644C14.3846 6.09423 14.7382 7.63789 14.53 9.15781C14.349 10.4788 13.7546 11.7029 12.8397 12.6588C12.8173 12.6822 12.7967 12.707 12.7782 12.7328C12.7762 12.7347 12.7743 12.7366 12.7724 12.7385C12.5284 12.9826 12.5284 13.3783 12.7724 13.6224L17.1918 18.0418C17.4359 18.2859 17.8317 18.2859 18.0757 18.0418C18.3198 17.7977 18.3198 17.402 18.0757 17.1579L14.0372 13.1194C14.9391 12.043 15.5261 10.728 15.7189 9.32067C15.9667 7.51124 15.5458 5.67355 14.5352 4.15236C13.5245 2.63118 11.9935 1.53105 10.2294 1.05836C8.46532 0.585673 6.58936 0.772918 4.9535 1.58497C3.31764 2.39701 2.0343 3.77806 1.34424 5.469C0.65418 7.15994 0.60483 9.04457 1.20545 10.7693C1.80606 12.494 3.01537 13.9404 4.60649 14.8369C6.02275 15.6349 7.65488 15.9486 9.25631 15.7401Z"
        fill={fill}
      />
    </svg>
  );
};
