import { getHeaderWithToken, serverApi } from "./serverApi";
import {
  CreatePlainTemplateType,
  TemplateType,
  UpdatePlainTemplateType,
} from "types/types";

/**
 * 템플릿 리스트 불러오기
 */
export const getPlainTemplateList = async (): Promise<TemplateType[]> => {
  return serverApi
    .get("/manage/template/", getHeaderWithToken())
    .then((response: any) => response.data);
};

/**
 * 템플릿 리스트 불러오기
 */
export const getPlainTemplateDetail = async (id): Promise<TemplateType> => {
  return serverApi
    .get(`/manage/template/${id}/detail`, getHeaderWithToken())
    .then((response: any) => response.data);
};

/**
 * 일반문자 템플릿 생성하기
 */
export const createPlainTemplate = async (body: CreatePlainTemplateType) => {
  var formData = new FormData();

  formData.append("template_name", body.template_name);
  formData.append("call_number", body.call_number);
  formData.append("title", body.title);
  formData.append("content", String(body.content));
  formData.append("msg_type", String(body.msg_type));
  formData.append("is_ad", String(body.is_ad));

  if (body.imageFiles) {
    Array.from(body.imageFiles).forEach((file) => {
      formData.append("image", file);
    });
  }
  return serverApi
    .post("/manage/template/", body, getHeaderWithToken())
    .then((response: any) => response.data);
};

/**
 * 일반문자 템플릿 수정하기
 */
export const updatePlainTemplate = async (
  templateId: number,
  body: UpdatePlainTemplateType
) => {
  var formData = new FormData();
  formData.append("template_name", body.template_name);
  formData.append("call_number", body.call_number);
  formData.append("title", body.title);
  formData.append("content", String(body.content));
  formData.append("msg_type", String(body.msg_type));
  formData.append("is_ad", String(body.is_ad));

  if (body.imageFiles) {
    Array.from(body.imageFiles).forEach((file) => {
      formData.append("image", file);
    });
  }
  return serverApi
    .put(`/manage/template/${templateId}/detail`, body, getHeaderWithToken())
    .then((response: any) => response.data);
};

/**
 * 일반문자 템플릿 수정하기
 */
export const removePlainTemplate = async (templateId: number) => {
  return serverApi
    .delete(`/manage/template/${templateId}/detail`, getHeaderWithToken())
    .then((response: any) => response.data);
};
