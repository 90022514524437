import styled, { css } from "styled-components";
import { MenuItemType } from "properties/menu";
import { useCustomNavigate } from "hooks/useCustomNavigate";

interface Props {
  menu: MenuItemType;
  active: boolean;
}
export const SubMenuItem = ({ menu, active }: Props) => {
  const navigate = useCustomNavigate();
  return (
    <SubListItem active={active}>
      <LinkItem
        onClick={() => navigate.moveTo(menu.path)}
        disabled={menu.disabled}
      >
        <div>{menu.label}</div>
      </LinkItem>
    </SubListItem>
  );
};

const SubListItem = styled.div<{ active: boolean }>`
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -1px;
  padding-bottom: 8px;
  display: flex;
  gap: 10px;
  cursor: pointer;
  align-items: center;
  &::before {
    padding-left: 36px;
    content: "•";
  }

  ${(props) =>
    props.active &&
    css`
      font-weight: 700;
      color: #1b58f1;
    `}
`;
const LinkItem = styled.a<{ disabled?: boolean }>`
  display: flex;
  gap: 10px;
  align-items: center;

  ${(props) =>
    props.disabled &&
    css`
      color: #e1e1e1;
      cursor: not-allowed;
    `}
`;
