import {
  ImgArrowRight,
  ImgHomeHeaderRolling1,
  ImgHomeHeaderRolling2,
  ImgHomeHeaderRolling3,
} from "assets";
import useResponsive from "hooks/useResponsive";
import { MEDIA_QUERY } from "properties/constant";
import styled from "styled-components";
import { RollingDown, RollingUp } from "styles/animation";

export const BannerMain = () => {
  const { isMobile } = useResponsive();
  return (
    <Container>
      <Inner>
        <BannerTextContainer>
          {/* 버벅임으로 인한 애니메이션 주석 처리 */}
          {/* <FadeIn speed={1} delay={0}> */}
          <SubTitle>비즈포스트 스마트메시지 💬</SubTitle>
          {/* </FadeIn> */}
          {/* <FadeIn speed={1.5} delay={0.5}> */}
          <Title>
            문자 하나 바꿨을 뿐인데
            <br />
            <b>클릭율 580% 증가!!</b>
          </Title>
          {/* </FadeIn> */}
          {/* <FadeIn speed={1.5} delay={1}> */}
          <WhiteButton>
            <div
              onClick={() => window.open("https://forms.gle/zbg8HVnbSfn2GZf78")}
            >
              무료 상담받기
            </div>
            <img
              width="20"
              height="18"
              src={ImgArrowRight}
              alt="오른쪽 화살표"
            />
          </WhiteButton>
          {/* </FadeIn> */}
        </BannerTextContainer>
        {!isMobile && (
          <ImageContainer>
            <RollingDown>
              <BannerImage bgImage={ImgHomeHeaderRolling1} />
            </RollingDown>
            <RollingUp>
              <BannerImage bgImage={ImgHomeHeaderRolling2} />
            </RollingUp>
            <RollingDown>
              <BannerImage bgImage={ImgHomeHeaderRolling3} />
            </RollingDown>
          </ImageContainer>
        )}
      </Inner>
    </Container>
  );
};

const Container = styled.div`
  background: #08173d;
  height: 600px;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    height: 480px;
    padding-top: 96px;
  }
`;

const Inner = styled.div`
  width: 940px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;

const BannerTextContainer = styled.div`
  padding: 20px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    text-align: center;
  }
`;

const SubTitle = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -1px;
  padding-bottom: 4px;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 20px;
    line-height: 24px;
    padding-bottom: 16px;
  }
`;

export const Title = styled.h1`
  background: -webkit-linear-gradient(
    0deg,
    #bcc6ff 0%,
    rgba(200, 236, 255, 1) 49%,
    rgba(255, 255, 255, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  white-space: pre-line;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 39px;
  letter-spacing: -1px;
  padding-bottom: 34px;

  b {
    font-weight: 700;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 34px;
    line-height: 40px;
  }
`;

const WhiteButton = styled.button`
  display: flex;
  background: #ffffff;
  border-radius: 40px;
  padding: 12px 20px 10px 20px;
  gap: 30px;
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -1px;
  color: #08173d;
  display: flex;
  align-items: center;
  width: fit-content;

  img {
    width: fit-content;
  }

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    text-align: center;
    margin: 0 auto;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const BannerImage = styled.div<{ bgImage: string }>`
  background-image: url(${(props) => props.bgImage});
  background-size: contain;
  background-position: top;
  width: 177px;
  height: 1200px;
`;
