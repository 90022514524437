import { ArrowLeftSimple } from "components/Icons";
import styled from "styled-components";
import { FlexBetween } from "styles/styled";
import { SendResultGroupType } from "types/types";

interface Props {
  group: SendResultGroupType;
}

export const MessageText: React.FC<Props> = ({ group }) => {
  return (
    <Container>
      <FlexBetween pb={16}>
        <ArrowLeftSimple />
        <Type>{group.msg_type}</Type>
      </FlexBetween>
      <MessageContainer>
        {group.msg_type !== "SMS" && <Title>{group.title}</Title>}
        <Text>
          <div>
            {group.content_link.map((imageLink, index) => (
              <img
                key={`img-${index}`}
                src={imageLink}
                alt="문자 서비스 소개"
              />
            ))}
          </div>
          <div>[Web발신]</div>
          {group.body}
        </Text>
      </MessageContainer>
    </Container>
  );
};

const Container = styled.div`
  border: 1px solid #3a404b;
  border-radius: 10px;
  width: 218px;
  height: 375px;
  padding: 15px;
`;

const Type = styled.div`
  background: #333333;
  border-radius: 8px;
  padding: 4px 11px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -1px;

  color: #ffffff;
`;

const MessageContainer = styled.div`
  white-space: pre-line;
  background: rgba(51, 51, 51, 0.08);
  border-radius: 10px;
  word-break: break-word;
  padding: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -1px;
  height: 280px;
  overflow: auto;

  color: #333333;
  img {
    border-radius: 4px;
  }
`;
const Title = styled.div`
  padding-bottom: 4px;
  font-weight: 700;
`;
const Text = styled.div`
  span {
    color: #999999;
  }
`;
