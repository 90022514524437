import { MEDIA_QUERY } from "properties/constant";
import styled from "styled-components";

export const Footer = () => {
  return (
    <Container>
      <LinkContainer>
        <Link href="#">회사소개</Link>ㅣ
        <Link
          target="_blank"
          href="https://married-oak-0f8.notion.site/cd408f1dd352498294bb2af1820220fc"
        >
          이용약관
        </Link>
        ㅣ
        <Link
          target="_blank"
          href="https://married-oak-0f8.notion.site/859f4ceb8e034731a0b359e650e31c09"
        >
          개인정보처리방침
        </Link>
        ㅣ
        <Link
          target="_blank"
          href="https://married-oak-0f8.notion.site/347cdae491444d509f6b28a84c85fdc3"
        >
          스팸방지정책
        </Link>
      </LinkContainer>

      <Info>
        고객센터 070-8287-1553
        <br />
        주식회사 플로셀 이태환, 박건ㅣ 세종특별자치시 가름로 232,
        비611호(어진동, 세종비즈니스센터)ㅣ사업자등록번호 266-88-01567
        <br />
        통신판매번호 2023-세종아름-0069
      </Info>
      <Copyright>copyrightⓒbizpost inc. All rights reserved.</Copyright>
    </Container>
  );
};

const Container = styled.footer`
  max-width: 760px;
  margin: 0 auto;
  padding: 34px 0px;
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    padding: 34px 20px;
    word-break: keep-all;
  }
`;

const LinkContainer = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -1px;
  color: #666666;
  padding-bottom: 24px;
`;
const Info = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -1px;
  color: #888888;
  padding-bottom: 30px;
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
  }
`;

const Copyright = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #888888;
`;

const Link = styled.a``;
