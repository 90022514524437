import { SVGProps } from "react";

export const RadioUnchecked = ({
  width = 24,
  height = 24,
  fill = "#999999",
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_671_2740)">
        <path
          d="M12 1C5.928 1 1 5.928 1 12C1 18.072 5.928 23 12 23C18.072 23 23 18.072 23 12C23 5.928 18.072 1 12 1ZM12 20.8C7.138 20.8 3.2 16.862 3.2 12C3.2 7.138 7.138 3.2 12 3.2C16.862 3.2 20.8 7.138 20.8 12C20.8 16.862 16.862 20.8 12 20.8Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_671_2740">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
