import styled, { CSSObject } from "styled-components";

interface IProps{
  children?: React.ReactNode;
  bgColor?: string;
  borderColor?: string;
  fontColor?: string;
  className?: string;
  margin?: string;
  onClick?: () => void;
  css?: CSSObject;
}

const ButtonSquare = ({
  children,
  bgColor,
  borderColor,
  fontColor,
  className,
  margin,
  onClick,
  css,
}: IProps) => {
  return (
    <Container
      bgColor={bgColor}
      borderColor={borderColor}
      fontColor={fontColor}
      className={className}
      margin={margin}
      onClick={onClick}
      css={css}
    >
      {children}
    </Container>
  );
};

export default ButtonSquare;

const Container = styled.div<{
  bgColor;
  borderColor;
  fontColor;
  margin;
  css;
}>`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: ${(props) => (props.margin ? props.margin : "0")};
  background: ${(props) => (props.bgColor ? props.bgColor : "white")};
  border-radius: 6px;
  border: 1px solid
    ${(props) => (props.borderColor ? props.borderColor : "#333")};
  color: ${(props) => (props.fontColor ? props.fontColor : "#333")};
  font-size: 16px;
  letter-spacing: -1px;
  cursor: pointer;

  ${(props) => (props.css ? props.css : "")};
`;
