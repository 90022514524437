import styled, { css, CSSObject } from "styled-components";

export const Line = styled.div<{ 
  margin?: string,
  css?: CSSObject
}>`
  width: 100%;
  height: 1px;
  background: #d7dbe2;
  margin: ${(props) => (props.margin ? props.margin : "16px 0px")};
  ${(props)=>
    props.css && css`${props.css}`
  };
`;
