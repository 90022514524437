import styled from "styled-components";
import { ModalLayout, ButtonNormal } from "components";
import { ButtonContainer, Input } from "components/Modals/LayoutStyled";
import { useContext, useState } from "react";
import AddressBookStore from "stores/AddressBookStore";
import { Loading } from "components/Icons";

interface Props {
  onClose: () => void;
}

export const UploadExcelModal = ({ onClose }: Props) => {
  const store = useContext(AddressBookStore);
  const [loading, setLoading] = useState(false);
  const [targetFile, setTargetFile] = useState<File | null>(null);
  const [percent] = useState(0);

  const onHandleChangeFile = (e) => {
    try {
      const file = e.target.files[0];
      setTargetFile(file);
    } catch (err) {
      console.error("파일 업로드 실패");
    }
  };

  const onSubmitFile = async () => {
    if (loading) {
      return;
    }
    if (targetFile) {
      setLoading(true);

      const result = await store.createAddressBookByExcel(targetFile);
      if (result.created) {
        store.fetchAddressBooks(true);
        store.fetchAddressBookGroups(true);
        alert(result.message);
        onClose();
      } else {
        alert("업로드에 문제가 발생하였습니다. 고객 센터로 문의해주세요.");
      }
      setLoading(false);
    }
  };
  return (
    <ModalLayout title={"엑셀로 주소록 등록하기"} onClose={onClose} width={700}>
      <Container>
        <Content>
          <div>주소록 형식에 맞는 엑셀을 업로드 해주세요</div>-
          <DownloadButton href="/비즈포스트 주소록 양식.xlsx" download>
            주소록 샘플 양식 다운로드
          </DownloadButton>
        </Content>

        <InputContainer>
          <input
            style={{ display: "none" }}
            id="fileUploadInput"
            type="file"
            accept=".xlsx"
            onChange={onHandleChangeFile}
          />
          <Input
            type="text"
            placeholder="*.xlsx 파일만 가능 (최대 5MB)"
            value={targetFile?.name}
            readOnly
          />

          <UploadButton htmlFor="fileUploadInput">
            <div>파일 올리기</div>
          </UploadButton>
          <ProgressBarContainer>
            <ProgressBar percent={percent} />
          </ProgressBarContainer>
        </InputContainer>

        <ButtonContainer>
          <ButtonNormal label="취소" onClick={onClose} width={154} />
          <ButtonNormal
            label={
              <ButtonLabel>
                등록하기
                {loading && (
                  <LoadingContainer>
                    <Loading width={24} height={24} />
                  </LoadingContainer>
                )}
              </ButtonLabel>
            }
            onClick={onSubmitFile}
            disabled={targetFile?.name === ""}
            isPrimary={true}
          />
        </ButtonContainer>
      </Container>
    </ModalLayout>
  );
};

const Container = styled.div`
  // border: solid 1px lightgray;
  border-radius: 4px;
`;

const ButtonLabel = styled.div`
  position: relative;
`;

const LoadingContainer = styled.div`
  position: absolute;
  top: 2px;
  left: 268px;
`;
const ProgressBarContainer = styled.div`
  margin-left: 30px;
  width: 70%;
  height: 4px;
  bottom: 6px;
  border: 10px;
  background-color: #ededed;
  position: relative;
`;
const ProgressBar = styled.div<{ percent: number }>`
  height: 100%;
  width: ${(props) => props.percent}%;
  height: 4px;
  position: absolute;
  left: 0px;
  background: #1b58f1;
`;
const Content = styled.div`
  padding-top: 24px;
  padding-bottom: 40px;
  color: #666666;
  white-space: pre-line;
  line-height: 28px;
  li {
    list-style: none;
    padding-left: 8px;
    &::before {
      content: "•";
      padding-right: 10px;
    }
  }
`;

const InputContainer = styled.div`
  position: relative;
  margin-bottom: 24px;
`;

const UploadButton = styled.label`
  background: #1b58f1;
  color: #ffffff;
  position: absolute;
  right: 0px;
  height: 56px;
  width: 166px;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  box-sizing: border-box;
  padding-bottom: 2px;

  cursor: pointer;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    cursor: pointer;
  }
`;

const DownloadButton = styled.a`
  padding-bottom: 24px;
  padding-left: 8px;
  text-decoration: underline;
  color: blue;
`;
