import dayjs from "dayjs";
import "dayjs/locale/ko";
dayjs.locale("ko");

export const DATE_FORMAT_1 = "YYYY.MM.DD";
export const DATE_FORMAT_2 = "YYYY-MM-DD";
export const DATE_FORMAT_3 = "YYYY년 MM월 DD일";
export const DATETIME_FORMAT_1 = "YYYY.MM.DD HH:mm";
export const DATETIME_FORMAT_2 = "YY.MM.DD HH:mm";

/** 날짜 형식 변경 */
export const changeDateFormat = (dateString: string, format: string) => {
  const date = dayjs(dateString);
  return date.format(format);
};

export const getCurrentTime = (format: string) => {
  const currentTime = dayjs();
  return currentTime.format(format);
};

export const addDays = (dateString, format, days) => {
  return dayjs(dateString).add(days, "day").format(format);
};
export const addMonth = (dateString, format, month) => {
  return dayjs(dateString).add(month, "month").format(format);
};

export const subtractDate = (dateString, format, days) => {
  return dayjs(dateString).subtract(days, "day").format(format);
};

export const getAfterTimeList = (targetDate: string) => {
  const currentTime = dayjs();
  const hours = Array.from({ length: 24 }, (_, i) => i);

  if (targetDate !== getCurrentTime(DATE_FORMAT_1)) {
    const currentHour = currentTime.hour();
    hours.splice(0, currentHour);
  }
  return hours;
};

export const getMinuteList = () => {
  const minutes = Array.from({ length: 12 }, (_, i) => i * 5);
  return minutes;
};

export const isDateToday = (dateString) => {
  const today = dayjs().format("YYYY-MM-DD");
  const date = dayjs(dateString).format("YYYY-MM-DD");
  return today === date;
};

// 두자리수 숫자로 반환
export const getTwoDigitNumber = (number) => {
  return number < 10 ? `0${number}` : number;
};
