import { SVGProps } from "react";

export const ArrowBottomFilled = ({
  width = 22,
  height = 22,
  fill = "#333333",
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11 17L4.0718 8L17.9282 8L11 17Z" fill={fill} />
    </svg>
  );
};
