import styled from "styled-components";
import { FlexBetween, IconButton, Pad } from "styles/styled";
import * as Icons from "components/Icons";
import { useCustomNavigate } from "hooks/useCustomNavigate";
import { PATH } from "properties/menu";
import { useContext } from "react";
import WalltetStore from "stores/WalltetStore";
import { observer } from "mobx-react-lite";
import UserStore from "stores/UserStore";
import * as session from "utils/session";

export const MenuBarUserPanel: React.FC = observer(() => {
  const userStore = useContext(UserStore);
  const walletStore = useContext(WalltetStore);
  const wallet = walletStore.wallet;
  const navigate = useCustomNavigate();

  const cashPoinrt = wallet ? wallet.amount.toLocaleString() : 0;
  const earnedPoint = wallet ? wallet.point.toLocaleString() : 0;
  const membershop = wallet ? wallet.membership.name : "";

  const user = userStore.getUser();
  if (!user) {
    window.location.href = PATH.HOME;
  }

  const onClickProfileSetting = async () => {
    // 기업 회원인 경우

    userStore.fetchUserInfo();
    const companyInfo = user.company_info;

    if (companyInfo && companyInfo.length > 0) {
      const company = companyInfo.find((company) => company.status === 1);

      if (company) {
        session.removeIsLoggined();
        navigate.moveTo(PATH.CHANGE_COMPANY_PROFILE);
        return;
      }
    }
    session.removeIsLoggined();
    navigate.moveTo(PATH.CHANGE_PROFILE);
    localStorage.setItem("clickedProfileSetting", "true");
  };
  const displayName =
    user?.company_info?.length > 0 && user.company_info[0].status === 1
      ? user.company_info[0].company_name
      : user.username;
  return (
    <Container>
      <Pad pb={16}>
        <FlexBetween>
          <UserAccount>{displayName}님</UserAccount>
          <Relative>
            <IconButton onClick={onClickProfileSetting}>
              <Icons.SettingFillRounded />
              {!localStorage.getItem("clickedProfileSetting") && (
                <Tooltip>
                  <div className="bg">
                    <Icons.TooltipBg />
                  </div>
                  <div className="text">
                    기업 회원 전환이 <br />
                    필요하다면?
                  </div>
                </Tooltip>
              )}
            </IconButton>
          </Relative>
        </FlexBetween>
      </Pad>
      <FlexBetween pb={12}>
        <Name>충전 포인트</Name>
        <Value>
          <b>{cashPoinrt}</b> p
        </Value>
      </FlexBetween>
      <FlexBetween pb={12}>
        <Name>적립 포인트</Name>
        <Value>
          <b>{earnedPoint}</b> p
        </Value>
      </FlexBetween>
      <FlexBetween pb={12}>
        <Name>요금제</Name>
        <Value>
          <b>{membershop}</b>
        </Value>
      </FlexBetween>
    </Container>
  );
});

const Container = styled.div`
  background-color: #1b58f1;
  padding: 24px 28px 30px 48px;
  margin-bottom: -8px;
  color: #ffffff;
`;

const UserAccount = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -1px;
`;

const Name = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -1px;
  opacity: 0.7;
`;

const Value = styled.div`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -1px;
  color: #ffffff;
`;
const Tooltip = styled.div`
  position: absolute;
  top: -31px;
  left: 10px;
  width: 140px;
  .bg {
    transform: scale(0.8);
  }
  .text {
    position: absolute;
    top: 10px;
    left: 36px;
    color: black;
    line-height: 1.2;
    padding: 8px;
    font-size: 12px;
  }
`;

const Relative = styled.div`
  position: relative;
`;
