import useResponsive from "hooks/useResponsive";
import { MEDIA_QUERY } from "properties/constant";
import styled from "styled-components";
import { Flex } from "styles/styled";
import { ActionButtonType } from "types/types";

interface Props {
  action: ActionButtonType;
  active: boolean;
}
/** */
export const ActionCard = ({ action, active }: Props) => {
  const { isMobile } = useResponsive();
  return (
    <Container active={active}>
      {isMobile ? (
        <Flex gap={14}>
          <Icon src={action.imageName} alt="card" />
          <Left>
            <Title active={active}>{action.title}</Title>
            <Text active={active}>{action.text}</Text>
          </Left>
        </Flex>
      ) : (
        <>
          <Icon src={action.imageName} alt="card" />
          <Title active={active}>{action.title}</Title>
          <Text active={active}>{action.text}</Text>
        </>
      )}
    </Container>
  );
};

const Container = styled.div<{ active: boolean }>`
  width: 242px;
  height: 242px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  transition: background-color 0.2s ease-in-out;

  background-color: ${(props) => (props.active ? "#2984FF" : "#ffffff")};

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 100%;
    height: fit-content;
    display: block;
  }
`;

const Icon = styled.img`
  width: 70px;
  height: 70px;
  margin: 8px 0 20px;
`;

const Title = styled.div<{ active: boolean }>`
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -1px;

  padding-bottom: 8px;
  color: ${(props) => (props.active ? "#ffffff" : "#333333")};
`;
const Text = styled.div<{ active: boolean }>`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -1px;

  color: ${(props) => (props.active ? "#ffffff" : "#666666")};
  flex: 1;
`;

const Left = styled.div`
  div {
    text-align: left;
    word-break: keep-all;
  }
`;
