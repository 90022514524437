import styled from "styled-components";
import { Flex } from "styles/styled";

export const Container = styled.div`
  width: 410px;
  padding-bottom: 56px;
`;

export const ButtonOutline = styled.button`
  border: 1px solid #333333;
  background: #ffffff;
  border-radius: 4px;
  padding: 16px 22px;
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -1px;
  color: #333333;
  cursor: pointer;
  &:hover {
    background-color: #eef3ff;
  }
`;

export const Input = styled.input`
  background: #ffffff;
  border: 1px solid #d7dbe2;
  border-radius: 6px;
  border: 1px solid #d7dbe2;
  flex: 1;
  padding: 16px;
`;

export const InputContainer = styled.div`
  display: flex;
  gap: 8px;
  padding-bottom: 8px;
`;

export const ContainerReceiver = styled.div`
  border: 1px solid #d7dbe2;
  border-radius: 6px;
  min-height: 150px;
  margin-bottom: 8px;
  padding: 16px 16px 48px 16px;
  position: relative;

  .inner {
    max-height: 200px;
    overflow-y: auto;
  }
`;

export const Count = styled.div``;

export const BottomContainer = styled.div`
  position: absolute;
  bottom: 16px;
  right: 16px;
  display: flex;
  gap: 8px;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -1px;
  color: #333333;
`;
export const RemoveAllButton = styled.div`
  color: #1b58f1;
  text-decoration: underline;
  cursor: pointer;
`;

export const ButtonContainer = styled(Flex)`
  button {
    flex: 1;
  }
`;

export const ListContainer = styled.div`
  display: inline-block;
  background: #f2f2f2;
  border-radius: 6px;
  padding: 5px 8px;
  margin: 0px 8px 8px 0px;
`;
export const ListFlex = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const PhoneNumber = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -1px;
  color: #333333;
`;
export const RemoveButton = styled.div`
  cursor: pointer;
`;
