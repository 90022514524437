import { SVGProps } from "react";

export const Send = ({
  width = 22,
  height = 22,
  fill = "#666666",
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.66669 3.66663C4.66669 3.11434 4.21897 2.66663 3.66669 2.66663C3.1144 2.66663 2.66669 3.11434 2.66669 3.66663V7.45829C2.66669 10.2197 4.90526 12.4583 7.66669 12.4583H16.2763L13.8164 16.4319C13.5257 16.9015 13.6707 17.5179 14.1403 17.8086C14.6099 18.0993 15.2263 17.9542 15.517 17.4846L18.8313 12.1307C19.2577 11.4419 19.2264 10.5638 18.7521 9.90705L15.4774 5.3728C15.154 4.92508 14.5289 4.82426 14.0812 5.14761C13.6335 5.47097 13.5326 6.09606 13.856 6.54378L16.6832 10.4583H7.66669C6.00983 10.4583 4.66669 9.11515 4.66669 7.45829V3.66663Z"
        fill={fill}
      />
    </svg>
  );
};
