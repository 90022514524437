import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import OutsideClickHandler from "react-outside-click-handler";

import * as messageApi from "apis/messageApi";
import { RejectorType, RejectorResponseType, CallerType } from "types/types";
import { ButtonPrimary, Flex, Pad } from "styles/styled";
import {
  InputButtonWithBottomArrow,
  InputDate,
  MessageLayout,
} from "components";
import { DATE_FORMAT_2, getCurrentTime, subtractDate } from "utils/datetime";
import { Pagination } from "components/Pagination";
import { Rejectors } from "./Rejectors";
import { useCustomNavigate } from "hooks/useCustomNavigate";
import { PATH } from "properties/menu";
import { addParameterToURL } from "utils/addParameterToURL";
import { Option } from "styles/styled";
import { observer } from "mobx-react-lite";
import CallerStore from "stores/CallerStore";

export const BlockedNumber = observer(() => {
  const callerStore = useContext(CallerStore);
  const callerPhoneNumber = callerStore.targetCaller?.phone_number;
  const navigate = useCustomNavigate();
  const { start, end, page } = navigate.query;

  const today = getCurrentTime(DATE_FORMAT_2);
  const MIN_DATE = subtractDate(today, DATE_FORMAT_2, 90);
  const MAX_DATE = today;

  const [rejectors, setRejectors] = useState<RejectorType[] | null>(null);
  const [startDate, setStartDate] = useState<string>(
    start ? String(start) : MIN_DATE
  );
  const [endDate, setEndDate] = useState<string>(start ? String(end) : today);
  const [resultCount, setResultCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(page ? Number(page) : 1);

  const [visibleCallerSelection, setVisibleCallerSelection] = useState(false);

  useEffect(() => {
    fetchMessageRejectors();
    fetchCallers();
    return () => {
      const SESSION_KEY = "MESSAGE_BLOCK_RESULT_FETCH_PARAMS";
      sessionStorage.removeItem(SESSION_KEY);
    };
  }, [currentPage]);

  const onClickSearchButton = () => {
    setCurrentPage(1);
    fetchMessageRejectors();
  };

  const fetchMessageRejectors = async () => {
    const SESSION_KEY = "MESSAGE_BLOCK_RESULT_FETCH_PARAMS";
    const currentAPIParam = `${startDate}-${endDate}-${currentPage}-${callerStore.targetCaller?.id}`;

    const lastAPIParam = sessionStorage.getItem(SESSION_KEY);
    if (lastAPIParam === currentAPIParam) {
      return;
    } else {
      const response: RejectorResponseType =
        await messageApi.fetchRejectPhoneNumbers({
          page: currentPage,
          startDate,
          endDate,
        });

      setRejectors(response.results);
      setResultCount(response.count);
      sessionStorage.setItem(SESSION_KEY, currentAPIParam);
      navigate.replace(generateUrlWithParams());
    }
  };

  const fetchCallers = () => {
    callerStore.fetchCallers();
  };
  const generateUrlWithParams = () => {
    let url = PATH.MESSAGE_BLOCK_RESULT;
    if (startDate)
      addParameterToURL(PATH.MESSAGE_BLOCK_RESULT, "start", startDate);
    if (endDate) url = addParameterToURL(url, "end", endDate);
    if (callerPhoneNumber)
      url = addParameterToURL(url, "sender_number", callerPhoneNumber);
    url = addParameterToURL(url, "page", currentPage);

    return url;
  };

  const onClickCaller = (caller: CallerType | null) => {
    callerStore.setTargetCaller(caller);
    setVisibleCallerSelection(!visibleCallerSelection);
  };

  const senderPhoneNumber = callerPhoneNumber || "발신번호 전체";

  return (
    <MessageLayout>
      <Container>
        <Inner>
          <Header>
            <SubTitle>차단된 수신 내역</SubTitle>
            <Descriptions>
              <Li>수신자가 차단요청한 전화번호를 확인할 수 있습니다.</Li>
              <Li>등록된 번호는 메세지 전송이 자동으로 차단됩니다.</Li>
            </Descriptions>
          </Header>
          <Pad px={24} py={16}>
            <Flex gap={8} justifyContent="flex-end">
              <InputDate
                date={startDate}
                minDate={MIN_DATE}
                maxDate={MAX_DATE}
                setDate={setStartDate}
                height="46px"
                width="140px"
              />
              <span>~</span>
              <InputDate
                date={endDate}
                minDate={MIN_DATE}
                maxDate={MAX_DATE}
                setDate={setEndDate}
                height="46px"
                width="140px"
              />

              <OutsideClickHandler
                onOutsideClick={() => {
                  setVisibleCallerSelection(false);
                }}
              >
                <MessageTypeContainer>
                  <InputButtonWithBottomArrow
                    onClick={() => setVisibleCallerSelection(true)}
                    text={senderPhoneNumber}
                    width="200px"
                    height="46px"
                  />

                  {visibleCallerSelection && (
                    <Selection>
                      <Option
                        width={200}
                        height={44}
                        onClick={() => onClickCaller(null)}
                      >
                        발신번호 전체
                      </Option>
                      {callerStore.callers &&
                        callerStore.callers.map((caller) => (
                          <Option
                            width={200}
                            height={44}
                            key={caller.id}
                            onClick={() => onClickCaller(caller)}
                          >
                            {caller.phone_number}
                          </Option>
                        ))}
                    </Selection>
                  )}
                </MessageTypeContainer>
              </OutsideClickHandler>
              <ButtonPrimaryStyled
                radius={4}
                padding="10px 16px"
                onClick={onClickSearchButton}
              >
                검색
              </ButtonPrimaryStyled>
            </Flex>
          </Pad>
          <section>
            <Rejectors rejectors={rejectors} />
          </section>
          <Pad pb={36} />
          <Pagination
            setCurrentPage={setCurrentPage}
            size={resultCount}
            defaultPage={currentPage}
          />
        </Inner>
      </Container>
    </MessageLayout>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 800px;
`;

const SubTitle = styled.h4`
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  letter-spacing: -1px;
  color: #333333;
  padding-bottom: 16px;
`;

const Inner = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding-bottom: 40px;
`;

const Header = styled.div`
  padding: 24px;
`;

const Descriptions = styled.ul``;

const Li = styled.li`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -1px;
  color: #666666;

  &::before {
    content: "• ";
    padding-right: 4px;
  }
`;

const MessageTypeContainer = styled.div`
  position: relative;
`;

const Selection = styled.div`
  position: absolute;
`;

const ButtonPrimaryStyled = styled(ButtonPrimary)``;
