import { useCallback, useContext, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import * as userApi from "apis/userApi";

import { CreateUserRequestType } from "types/types";

import { Pad } from "styles/styled";
import { ModalLayout } from "components";
import { CheckboxCircle, CheckboxCircleFilled } from "components/Icons";
import { PolicyItem } from "components/PolicyItem";
import { Loading } from "components/Icons/Loading";
import styled, { css } from "styled-components";
import UserStore from "stores/UserStore";
import { observer } from "mobx-react-lite";
import { Label } from "components/Label";
import { CompleteSection } from "components/CompleteSection";
import { InputListItem } from "components/InputListItem";

import { validateEmail } from "utils/email";
import { validatePassword } from "utils/password";
import { validatePhoneNumberOnly } from "utils/phone";
import { importCeriticatePhone } from "utils/import";

import { TERMS_OF_USE_URL, PRIVACY_USE_URL } from "properties/externalLink";

enum InvalidMessage {
  EMAIL = "이메일 형식이 올바르지 않습니다.",
  EMAIL_CHECK_DUPLICATE = "이메일 중복확인을 해주세요.",
  PASSWORD = "비밀번호를 대소문자, 특수문자 포함 6자리 이상 입력해주세요.",
  PASSWORD2 = "비밀번호가 일치하지 않습니다.",
  USER_NAME = "이름을 입력해주세요",
  PHONE = "핸드폰 번호를 입력해주세요.",
  USER_VERIFIED = "핸드폰 번호를 인증해주세요.",
  ADDRESS = "주소를 입력해주세요.",
  ADDRESS_DETAIL = "상세 주소를 입력해주세요.",
  AGREE_TERMS_OF_USE = "서비스 이용약관에 동의해주세요.",
  AGREE_PRIVACY_USE = "개인정보 수집 및 이용에 동의해주세요.",
}

export const SignUpRegular = observer(() => {
  const [isComplete, setIsComplete] = useState(false);
  const [loading, setLoading] = useState(false);

  const userStore = useContext(UserStore);
  const [email, setEmail] = useState("");
  const [isUniqueEmail, setIsUniqueEmail] = useState<boolean>(false);
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [username, setUsername] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [postcode, setPostcode] = useState("");
  const [addressDetail, setAddressDetail] = useState("");
  const [impUid, setImpUid] = useState("");

  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [userVerified, setUserCeritificated] = useState(false);
  const [invalidMessage, setInvalidMessage] = useState<InvalidMessage | null>(
    null
  );
  const [showInvalid, setShowInvalid] = useState(false);

  const [agreeAllTerm, setAgreeAllTerm] = useState(false);
  const [agreeTermsofUse, setAgreeTermsofUse] = useState(false);
  const [agreePrivacyUse, setAgreePrivacyUse] = useState(false);
  const [agreeReceiveMessage, setAgreeReceiveMessage] = useState(false);
  const [agreeReceiveEmail, setAgreeReceiveEmail] = useState(false);
  const [targetTerm, setTerm] = useState<{
    title: string;
    content: string;
  } | null>(null);

  /** 유효성 검사 */
  useEffect(() => {
    const validate = () => {
      setShowInvalid(false);
      setInvalidMessage(null);

      switch (true) {
        case email.length < 2:
          setInvalidMessage(InvalidMessage.EMAIL);
          return;
        case !isUniqueEmail:
          setInvalidMessage(InvalidMessage.EMAIL_CHECK_DUPLICATE);
          return;
        case password.length < 1 || !isValidPassword:
          setInvalidMessage(InvalidMessage.PASSWORD);
          return;
        case password2.length < 1 || password !== password2:
          setInvalidMessage(InvalidMessage.PASSWORD2);
          return;
        case username.length < 2:
          setInvalidMessage(InvalidMessage.USER_NAME);
          return;
        case !isValidPhone:
          setInvalidMessage(InvalidMessage.PHONE);
          return;
        case !userVerified:
          setInvalidMessage(InvalidMessage.USER_VERIFIED);
          return;
        case address.length < 1:
          setInvalidMessage(InvalidMessage.ADDRESS);
          return;
        case addressDetail.length < 1:
          setInvalidMessage(InvalidMessage.ADDRESS_DETAIL);
          return;
        case !agreeTermsofUse:
          setInvalidMessage(InvalidMessage.AGREE_TERMS_OF_USE);
          return;
        case !agreePrivacyUse:
          setInvalidMessage(InvalidMessage.AGREE_PRIVACY_USE);
          return;
      }
    };

    validate();
  }, [
    email,
    password,
    password2,
    username,
    phone,
    address,
    addressDetail,
    userVerified,
    isValidPhone,
    agreeTermsofUse,
    agreePrivacyUse,
    isUniqueEmail,
    isValidPassword,
  ]);

  /** 회원 가입 */
  const onSubmitSignUp = async () => {
    if (loading) {
      return;
    }

    if (invalidMessage) {
      setShowInvalid(true);
      return;
    }

    setShowInvalid(false);
    setLoading(true);

    const body: CreateUserRequestType = {
      email,
      password,
      password2,
      username,
      phone_number: phone,
      imp_uid: impUid,
      is_sms_agree: agreeReceiveMessage,
      is_email_agree: agreeReceiveEmail,
      address: address,
      address_detail: addressDetail,
      postcode: postcode,
    };

    try {
      const result = await userStore.signUp(body);
      if (result) {
        setLoading(false);
        setIsComplete(true);
      } else {
        throw Error();
      }
    } catch (e) {
      setLoading(false);
      alert("회원가입에 실패했습니다.");
    }
  };

  const onClickAreeTermAll = () => {
    if (agreeAllTerm) {
      setAgreeAllTerm(false);
      setAgreeTermsofUse(false);
      setAgreePrivacyUse(false);
      setAgreeReceiveMessage(false);
      setAgreeReceiveEmail(false);
    } else {
      setAgreeAllTerm(true);
      setAgreeTermsofUse(true);
      setAgreePrivacyUse(true);
      setAgreeReceiveMessage(true);
      setAgreeReceiveEmail(true);
    }
  };

  const onHandleChangeEmail = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const email = e.target.value;
      const validated = validateEmail(email);
      setIsValidEmail(validated);
      setEmail(email);
    },
    []
  );

  const onHandleChangePassword = useCallback((value: string) => {
    const isValid = validatePassword(value);
    setIsValidPassword(isValid);
    setPassword(value);
  }, []);

  const onHandleChangePhone = (e: any) => {
    const phone = e.target.value;
    const validated = validatePhoneNumberOnly(phone);
    setIsValidPhone(validated);
    setPhone(phone);
  };

  const onClickVerifyPhone = async () => {
    if (!isValidPhone) {
      alert("전화번호를 입력해주세요.");
      return;
    }

    // 이미 등록된 번호인지 확인
    const result = await userStore.checkUniquePhone(phone);
    if (!result.isUnique) {
      alert(result.message);
      return;
    }

    // 인증번호 요청
    importCeriticatePhone(
      phone,
      callbackCertificatePhoneSuccess,
      callbackCertificatePhoneFail
    );
  };

  const callbackCertificatePhoneSuccess = async (imp_uid: string) => {
    // 서버 인증
    const response = await userApi.userCerification({ imp_uid: imp_uid });
    if (!response || response instanceof Error) {
      alert("문제가 발생했습니다.");
      console.error(response);
    } else {
      setImpUid(imp_uid);
      setUserCeritificated(true);
    }
  };

  const callbackCertificatePhoneFail = (errorMesasge) => {
    // alert(errorMesasge);
    setUserCeritificated(false);
  };

  const onClickAddressInput = () => {
    let daum = window["daum"];
    new daum.Postcode({
      oncomplete: function (data) {
        setAddress(data.address);
        setPostcode(data.zonecode);
        // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분입니다.
        // 예제를 참고하여 다양한 활용법을 확인해 보세요.
      },
    }).open();
  };

  const onClickCheckDuplicateEmail = async () => {
    if (!isValidEmail) {
      alert("이메일을 입력해주세요.");
      return;
    }
    const result = await userStore.checkUniqueEmail(email);

    if (result.isUnique) {
      setIsUniqueEmail(true);
    } else {
      setIsUniqueEmail(false);
    }
    alert(result.message);
  };

  return (
    <>
      {!isComplete ? (
        /**
         * 회원가입
         */
        <Container>
          <Inner>
            <Title>일반 회원가입</Title>
            <InputContainer>
              <Label>이메일</Label>
              <InputButtonContainer>
                <Input
                  type="email"
                  placeholder="이메일을 입력해주세요"
                  onChange={onHandleChangeEmail}
                  value={email}
                  isInvalid={
                    showInvalid && invalidMessage === InvalidMessage.EMAIL
                  }
                />
                <SideButton
                  active={isValidEmail}
                  onClick={onClickCheckDuplicateEmail}
                >
                  중복 확인
                </SideButton>
              </InputButtonContainer>
            </InputContainer>
            <Pad pb={24}>
              <InputListItem
                label="비밀번호"
                type="password"
                value={password}
                placeholder="대소문자, 특수문자 포함 6자리 이상 입력해주세요"
                onChange={onHandleChangePassword}
                isInvalid={
                  showInvalid && invalidMessage === InvalidMessage.PASSWORD
                }
              />
            </Pad>
            <Pad pb={24}>
              <InputListItem
                label="비밀번호 재입력"
                type="password"
                value={password2}
                placeholder="비밀번호를 다시 입력해주세요"
                onChange={setPassword2}
                isInvalid={
                  showInvalid && invalidMessage === InvalidMessage.PASSWORD2
                }
              />
            </Pad>
            <Pad pb={24}>
              <InputListItem
                label="이름"
                placeholder="이름을 입력해주세요"
                onChange={setUsername}
                value={username}
                isInvalid={
                  showInvalid && invalidMessage === InvalidMessage.USER_NAME
                }
              />
            </Pad>
            {/* 핸드폰 본인 인증 로직 */}
            <InputContainer>
              <Label>핸드폰 번호</Label>
              <InputButtonContainer>
                <Input
                  type="phone"
                  placeholder="-를 제외하고 입력"
                  onChange={onHandleChangePhone}
                  isInvalid={
                    showInvalid && invalidMessage === InvalidMessage.PHONE
                  }
                />

                <SideButton active={isValidPhone} onClick={onClickVerifyPhone}>
                  {userVerified ? "인증완료" : "본인인증"}
                </SideButton>
              </InputButtonContainer>
            </InputContainer>
            {/* 주소지 */}
            <InputContainer>
              <Label>주소지</Label>
              <AddressInput
                type="text"
                readOnly
                placeholder="주소지 입력"
                value={address}
                onClick={onClickAddressInput}
                isInvalid={
                  showInvalid && invalidMessage === InvalidMessage.ADDRESS
                }
              />
              <AddressInput
                type="text"
                placeholder="상세 주소 입력"
                value={addressDetail}
                onChange={(e) => setAddressDetail(e.target.value)}
                isInvalid={
                  showInvalid &&
                  invalidMessage === InvalidMessage.ADDRESS_DETAIL
                }
              />
            </InputContainer>
            {/* 서비스 전체 약관 동의 */}
            <TermSection>
              <TermAll onClick={onClickAreeTermAll}>
                {agreeAllTerm ? (
                  <CheckboxCircleFilled
                    height={22}
                    width={22}
                    fill={"#1B58F1"}
                  />
                ) : (
                  <CheckboxCircle height={22} width={22} fill={"#C4C9D1"} />
                )}
                <div>서비스 전체 약관에 동의합니다</div>
              </TermAll>
              <div>
                <PolicyItem
                  text="서비스 이용약관"
                  isRequired
                  isChecked={agreeTermsofUse}
                  toggle={setAgreeTermsofUse}
                  // openModal={() => setTargetTerm("TermsOfUse")}
                  openModal={() => window.open(TERMS_OF_USE_URL)}
                />

                <PolicyItem
                  text="개인정보 수집 및 이용"
                  isRequired
                  isChecked={agreePrivacyUse}
                  toggle={setAgreePrivacyUse}
                  // openModal={() => setTargetTerm("PrivacyUse")}
                  openModal={() => window.open(PRIVACY_USE_URL)}
                />

                <PolicyItem
                  text="이벤트 및 혜택 문자 수신"
                  isChecked={agreeReceiveMessage}
                  toggle={setAgreeReceiveMessage}
                />

                <PolicyItem
                  text="이벤트 및 혜택 이메일 수신"
                  isChecked={agreeReceiveEmail}
                  toggle={setAgreeReceiveEmail}
                />
              </div>
            </TermSection>
            {targetTerm && (
              <ModalLayout
                title={targetTerm.title}
                onClose={() => setTerm(null)}
              >
                <TermContent>
                  <ReactMarkdown>{targetTerm.content}</ReactMarkdown>
                </TermContent>
              </ModalLayout>
            )}

            {showInvalid && (
              <InvalidText>
                <p>{invalidMessage}</p>
              </InvalidText>
            )}

            <SubmitButton active={!invalidMessage} onClick={onSubmitSignUp}>
              <div>가입 완료</div>
              {loading && <Loading />}
            </SubmitButton>
          </Inner>
        </Container>
      ) : (
        /**
         * 회원가입 완료
         */
        <CompleteSection />
      )}
    </>
  );
});

export const Container = styled.div``;

export const Inner = styled.div`
  max-width: 420px;
  background-color: white;
  margin: 0 auto 0px;
  padding: 48px 32px;
`;

export const Title = styled.h1`
  font-weight: 700;
  font-size: 24px;
  line-height: 39px;
  letter-spacing: -1px;
  padding-bottom: 40px;
`;

export const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  font-size: 16px;
  padding: 60px 0 24px;
  align-items: center;
`;

export const TabBtn = styled.button`
  background-color: white;
  padding: 20px 32px;
  border: solid 1px #f2f2f2;
  border-bottom-color: gray;
  margin: -1px;
  cursor: pointer;
`;

export const Section = styled.div`
  max-width: 980px;
  margin: 0 auto;
`;

export const Active = styled.div`
  color: #1b58f1;
  font-weight: bold;
  background-color: #f3f4f6;
`;

export const InputButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  button {
    width: 95px;
  }
  input {
    flex: 1;
  }
`;

export const SubmitButton = styled.button<{ active: boolean }>`
  background: #e1e1e1;
  border-radius: 100px;
  padding: 16px;
  text-align: center;
  width: 100%;
  height: 56px;

  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: -1px;
  display: flex;

  justify-content: center;
  align-items: center;
  gap: 4px;
  color: #ffffff;

  .icon {
    margin: 0px;
  }

  ${(props) =>
    props.active &&
    css`
      background: #1b58f1;
      cursor: pointer;
    `}
`;
export const SideButton = styled.button<{ active: boolean }>`
  display: block;
  height: auto;
  border-radius: 4px;
  color: white;
  font-size: 16px;
  font-weight: 700;
  background: #e1e1e1;

  ${(props) =>
    props.active &&
    css`
      background: #333;
      cursor: pointer;
    `}
`;

export const TermSection = styled.div`
  margin: 0 auto;
  padding: 16px 0px 40px;
`;

export const TermAll = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  padding-bottom: 8px;
  border-bottom: 0.5px solid #c4c9d1;
  cursor: pointer;
`;

export const InputContainer = styled.div`
  margin-bottom: 28px;
`;

export const Input = styled.input<{ active?: boolean; isInvalid?: boolean }>`
  background: #ffffff;
  border: 1px solid #d7dbe2;
  border-radius: 4px;
  width: 100%;
  height: 56px;
  padding: 17px 16px 16px;
  transition: border-color 0.3s ease;

  &::placeholder {
    color: #999999;
  }

  ${(props) =>
    props.active &&
    css`
      border-color: #333333;
    `}

  ${(props) =>
    props.isInvalid &&
    css`
      border-color: #ec1c24;
    `}
`;

export const AddressInput = styled(Input)`
  margin-bottom: 4px;
  cursor: pointer;
`;

export const TermContent = styled.div`
  margin-top: 24px;
  overflow-y: scroll;
  height: 220px;
  white-space: pre-wrap;
`;

export const InvalidText = styled.div`
  position: relative;
  height: 1px;
  margin-top: -1px;

  p {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    color: #ec1c24;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -1px;
  }
`;
