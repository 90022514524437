import { useCallback, useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { MessageLayout } from "components";
import { Flex, Pad, PageSubTitle } from "styles/styled";
import KakaoStore from "stores/KakaoStore";
import SelectBasic from "components/SelectBasic";
import { Label } from "components/Label";
import InputText from "components/InputText";
import styled from "styled-components";
import { Li } from "components/layout/MessageLayout";
import {
  InputButtonContainer,
  InvalidText,
  SideButton,
  SubmitButton,
} from "pages/user/SignUp/Regular";
import { validatePhoneNumberOnly } from "utils/phone";
import { Loading } from "components/Icons";
import { VerifyProfileSenderType } from "types/types";

enum InvalidMessage {
  PROFILE_NAME = "발신프로필을 입력해주세요",
  PHONE = "담당자 핸드폰 번호를 입력해주세요.",
  VALID_USER = "담당자 핸드폰 번호를 인증 요청해주세요.",
  CATEGORY_CODE = "카테고리를 선택해주세요.",
  TOKEN = "인증번호를 입력해주세요.",
}

export const KakaoProfile = observer(() => {
  const store = useContext(KakaoStore);

  const [profileName, setProfileName] = useState<string>("");
  const [categories, setCategories] = useState<Array<{
    code: string;
    main: string;
    sub: string;
    detail: string;
  }> | null>(null);
  const [selectedMain, setSelectedMain] = useState<string | null>(null);
  const [selectedSub, setSelectedSub] = useState<string | null>(null);
  const [selectedDetail, setSelectedDetail] = useState<string | null>(null);
  const [categoryCode, setCategoryCode] = useState<string | null>(null);
  const [phone, setPhone] = useState("");
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [isValidUser, setIsValidUser] = useState(false);
  const [token, setToken] = useState<string | null>("");
  const [invalidMessage, setInvalidMessage] = useState<InvalidMessage | null>(
    null
  );
  const [showInvalid, setShowInvalid] = useState(false);
  const [loading, setLoading] = useState(false);

  /** 유효성 검사 */
  useEffect(() => {
    const validate = () => {
      setShowInvalid(false);
      setInvalidMessage(null);

      switch (true) {
        case profileName.length < 2:
          setInvalidMessage(InvalidMessage.PROFILE_NAME);
          return;
        case !categoryCode:
          setInvalidMessage(InvalidMessage.CATEGORY_CODE);
          return;
        case !isValidPhone:
          setInvalidMessage(InvalidMessage.PHONE);
          return;
        case !isValidUser:
          setInvalidMessage(InvalidMessage.VALID_USER);
          return;
        case !token:
          setInvalidMessage(InvalidMessage.TOKEN);
          return;
        default:
          return;
      }
    };

    validate();
  }, [categoryCode, isValidPhone, profileName.length, token, isValidUser]);

  const handleSubmit = async () => {
    if (loading) {
      return;
    }

    if (invalidMessage) {
      setShowInvalid(true);
      return;
    }

    setShowInvalid(false);
    setLoading(true);

    // 발신프로필 토큰 인증/생성
    const body: VerifyProfileSenderType = {
      yellowId: profileName,
      token,
      etc: "",
    };

    const result = await store.verifyProfileSender(body);

    setLoading(false);

    if (!result.isSuccess) {
      setInvalidMessage(result.message);
      setShowInvalid(true);
      return;
    }

    alert("발신프로필 등록을 성공했습니다!");
  };

  /** 전체 카테고리 조회 */
  useEffect(() => {
    const loadCategories = async () => {
      const result = await store.fetchCategories();
      setCategories(result);
    };

    loadCategories();
  }, [store]);

  const mainCategories = Array.from(
    new Set(categories?.map((item) => item.main))
  );

  const extractSubCategories = (selectedMainCategory: string): string[] => {
    const subCategories: Set<string> = new Set();
    categories?.forEach((item) => {
      const { main, sub } = item;
      if (main === selectedMainCategory) {
        subCategories.add(sub);
      }
    });
    return Array.from(subCategories);
  };

  const extractDetailCategory = (
    selectedMainCategory: string,
    selectedSubCategory: string
  ): string[] => {
    const thirdCategories: Set<string> = new Set();
    categories?.forEach((item) => {
      const { main, sub, detail } = item;
      if (main === selectedMainCategory && sub === selectedSubCategory) {
        thirdCategories.add(detail);
      }
    });
    return Array.from(thirdCategories);
  };

  const handleMainCategoryChange = (target: HTMLSelectElement) => {
    setSelectedMain(target.value);
    setSelectedSub(null);
    setSelectedDetail(null);
    setCategoryCode(null);
    setShowInvalid(false);
  };

  const handleSubCategoryChange = (target: HTMLSelectElement) => {
    setSelectedSub(target.value);
    setSelectedDetail(null);
    setCategoryCode(null);
    setShowInvalid(false);
  };

  const handleDetailCategoryChange = (target: HTMLSelectElement) => {
    setSelectedDetail(target.value);
    const selectedItem = categories.find((item) => {
      const { main, sub, detail } = item;
      return (
        main === selectedMain && sub === selectedSub && detail === target.value
      );
    });
    if (selectedItem) {
      setCategoryCode(selectedItem.code);
    }
  };

  /** 핸드폰번호 인증 요청 */
  const handleChangePhone = useCallback((target: HTMLInputElement) => {
    const phone = target.value;
    const validated = validatePhoneNumberOnly(phone);
    setIsValidPhone(validated);
    setPhone(phone);
  }, []);

  const handleClickVerifyPhone = async () => {
    if (!profileName || !isValidPhone || !categoryCode) {
      setShowInvalid(true);
      return;
    }

    // 발신프로필 토큰 발송
    const result = await store.createProfileSender({
      yellowId: profileName,
      phoneNumber: phone,
      categoryCode,
    });

    if (!result.isSuccess) {
      setInvalidMessage(result.message);
      setShowInvalid(true);
      return;
    }

    setIsValidUser(true);
  };

  return (
    <MessageLayout title="프로필 관리">
      <Container>
        <PageSubTitle>발신프로필 등록</PageSubTitle>
        <ul>
          <Li>
            카카오톡 채널(https://center-pf.kakao.com)에서 비즈니스 채널로
            등록된 카카오톡 채널(발신프로필)만 등록 가능합니다.
          </Li>
          <Li>
            카카오톡 채널 관리자센터 - [채널] - 원하는 채널 클릭 - [프로필] -
            [프로필 설정] - 옵션 설정 - 채널 공개, 검색 허용 모두 ON 상태여야
            등록 가능합니다.
          </Li>
        </ul>
        <InnerContainer>
          <InputContainer>
            <Pad pb={40}>
              <Label htmlFor="senderProfile" isBold={true}>
                발신프로필
              </Label>
              <InputText
                onChange={(target) => setProfileName(target.value)}
                name="senderProfile"
                value={profileName}
                placeholder="카카오 채널명(검색용)을 입력해주세요 ex) @비즈포스트"
                isInvalid={
                  showInvalid && invalidMessage === InvalidMessage.PROFILE_NAME
                }
              />
            </Pad>

            <Pad pb={40}>
              <Label isBold={true}>카테고리</Label>
              <Flex>
                {/* 메인 카테고리 */}
                <SelectBasic
                  value={selectedMain}
                  options={[{ value: "", text: "카테고리 선택" }].concat(
                    mainCategories.map((item) => ({
                      value: item,
                      text: item,
                    }))
                  )}
                  name="mainCategory"
                  onChange={handleMainCategoryChange}
                  css={{
                    flex: "1",
                    height: "56px",
                  }}
                />
                {/* 서브 카테고리 */}
                <SelectBasic
                  value={selectedSub}
                  options={[{ value: "", text: "카테고리 선택" }].concat(
                    extractSubCategories(selectedMain).map((item) => ({
                      value: item,
                      text: item,
                    }))
                  )}
                  name="subCategory"
                  onChange={handleSubCategoryChange}
                  css={{
                    flex: "1",
                    height: "56px",
                  }}
                />
                {/* 디테일 카테고리 */}
                <SelectBasic
                  value={selectedDetail}
                  options={[{ value: "", text: "카테고리 선택" }].concat(
                    extractDetailCategory(selectedMain, selectedSub).map(
                      (item) => ({
                        value: item,
                        text: item,
                      })
                    )
                  )}
                  name="thirdCategory"
                  onChange={handleDetailCategoryChange}
                  css={{
                    flex: "1",
                    height: "56px",
                  }}
                />
              </Flex>
            </Pad>

            <Pad pb={40}>
              <Label htmlFor="phone" isBold={true}>
                담당자 핸드폰 번호
              </Label>
              <Flex direction="column">
                <InputButtonContainer>
                  <InputText
                    type="phone"
                    onChange={handleChangePhone}
                    name="phone"
                    value={phone}
                    placeholder="-를 제외하고 입력"
                    isInvalid={
                      showInvalid && invalidMessage === InvalidMessage.PHONE
                    }
                  />
                  <SideButton
                    active={isValidPhone}
                    onClick={handleClickVerifyPhone}
                  >
                    인증요청
                  </SideButton>
                </InputButtonContainer>

                <InputText
                  onChange={(target) => setToken(target.value)}
                  name="token"
                  value={token}
                  placeholder="인증번호를 입력해주세요."
                  disabled={!isValidUser}
                />
              </Flex>
            </Pad>

            {showInvalid && (
              <InvalidText>
                <p>{invalidMessage}</p>
              </InvalidText>
            )}

            <SubmitButton active={!invalidMessage} onClick={handleSubmit}>
              <div>등록하기</div>
              {loading && <Loading />}
            </SubmitButton>
          </InputContainer>
        </InnerContainer>
        <PageSubTitle>발신프로필 이력</PageSubTitle>
        TBD
      </Container>
    </MessageLayout>
  );
});

export const Container = styled.div`
  padding: 32px 24px;
`;

export const InnerContainer = styled.div`
  margin-top: 40px;
  padding-top: 40px;
  padding-bottom: 100px;
  border-top: 0.5px solid #999;
`;

export const InputContainer = styled.div`
  max-width: 600px;
`;
