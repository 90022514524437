import { observable, makeObservable, runInAction, action } from "mobx";
import { createContext } from "react";
import {
  CallerType,
  CallersResponseType,
  CreateCallerRequestType,
} from "types/types";
import * as callerApi from "apis/callerApi";

class CallerStore {
  @observable callers: CallerType[] | null = null;
  @observable targetCaller: CallerType | null = null;
  @observable callerTotalCount: number = 0;
  @observable currentPage: number = 1;
  @observable approvedCallers: CallerType[] = [];

  constructor() {
    makeObservable(this);
  }

  fetchCallers = async (isReload?: boolean) => {
    if (isReload || !this.callers) {
      const body = { size: 10, page: this.currentPage };
      const response: CallersResponseType = await callerApi.fetchCallers(body);

      runInAction(() => {
        this.callers = response.results;
        this.callerTotalCount = response.count;
        this.updateFilteredApprovedCallers();
      });
    }
  };

  @action updateFilteredApprovedCallers = async () => {
    this.approvedCallers = this.callers.filter((caller) => caller.status === 1);
    this.targetCaller =
      this.approvedCallers.length > 0 ? this.approvedCallers[0] : null;
  };

  @action removeCaller = async (caller: CallerType, callback: any) => {
    try {
      await callerApi.removeCaller(caller.id);
      alert("발신번호가 삭제 되었습니다.");
      runInAction(() => {
        this.targetCaller = null;
        this.fetchCallers(true);
      });
      callback();
    } catch (err) {
      console.error(err);
    }
  };

  @action setTargetCaller = (caller: CallerType) => {
    this.targetCaller = caller;
  };

  @action setCurrentPage = (page: number) => {
    this.currentPage = page;
  };

  @action createCallerByPhone = async (body: CreateCallerRequestType) => {
    try {
      const response = await callerApi.createCaller(body);

      runInAction(() => {
        this.fetchCallers(true);
      });
      return {
        created: true,
        message:
          response.status === 1
            ? "발신번호 등록이 완료되었습니다"
            : "등록이 완료되었습니다. 승인까지 1-2일이 소요됩니다.",
      };
    } catch (err) {
      return { created: false, message: err.detail };
    }
  };

  @action createCallerByDocuments = async (
    body: CreateCallerRequestType,
    updateProgressBar: (progressEvent) => void
  ) => {
    try {
      const options = {
        onUploadProgress: updateProgressBar,
      };
      await callerApi.createCaller(body, options);
      runInAction(() => {
        this.fetchCallers(true);
      });
      return {
        created: true,
        message: "등록이 완료되었습니다. 승인까지 1-2일이 소요됩니다.",
      };
    } catch (err: any) {
      console.error(err);

      return { created: false, message: err?.detail };
    }
  };
}

export default createContext(new CallerStore());
