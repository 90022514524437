import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import styled, { CSSObject } from 'styled-components';
import fonts from 'styles/fonts';
import { getBytes } from 'utils/bytes';

interface IProps{
  value: string;
  height?: string;
  maxByte?: number;
  onChange: (
    target:HTMLTextAreaElement, 
    name:string
  )=>void;
  name: string;
  placeholder?: string;
  css?: CSSObject;
  byteTag?: string;
}
const TextareaBasic = observer(({
  value,
  height,
  maxByte,
  onChange,
  name,
  placeholder,
  css,
  byteTag
}:IProps) => {
  const onHandleValueChange = useCallback((
    target, 
    name
  )=>{
    if(getBytes(target.value) <= maxByte){
      onChange(target, name);
    }else{
      alert(`${maxByte}바이트까지 전송가능합니다.`);
    }
  },[maxByte, onChange]);
  return (
    <Container 
      height={height} 
      value={value} 
      maxByte={maxByte}
      css={css}
    >
      <textarea 
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={(e)=>
          maxByte ? 
            onHandleValueChange(e.target, name) 
            : onChange(e.target, name)
        }
      >
      </textarea>
      {
        maxByte ? (
          <ByteCalculationArea>
            {getBytes(value)} / {maxByte}byte 
            {byteTag? " " +byteTag : ""}
          </ByteCalculationArea>
        ) : null
      }
    </Container>
  );
});

export default TextareaBasic;

const Container = styled.div<{
  height,
  value, 
  maxByte, 
  css
}>`
  position: relative;
  textarea{
    width: 100%; 
    height: ${props=>
      props.height ? 
        props.height 
        : "192px"
    };
    padding: ${props=>
      props.maxByte ? 
        "16px 16px 52px" 
        : "16px"
    };
    border-radius: 6px;
    border: 1px solid #D7DBE2;
    background: ${props=>
      props.value ? 
        "white" 
        : "#FAFAFA" 
    };
    color: ${props=>
      props.value ? 
        "black" 
        : "#999" 
    };
    box-sizing: border-box;
    resize: none;
    ${fonts.Body1};
    letter-spacing: -1px;

    ${props=>
      props.css ? 
        props.css 
        : ""
    }
  }
`;
const ByteCalculationArea = styled.div`
  position: absolute;
  right: 16px;
  bottom: 16px;
  width: fit-content;
  height: 20px;
  font-size: 14px;
`;
