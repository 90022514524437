// import { useRouter } from "next/router";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import queryString from "query-string";

import { PATH, PROTECTED_PATHS } from "properties/menu";
import * as session from "../utils/session";
import { useContext } from "react";
import UserStore from "stores/UserStore";

export const useCustomNavigate = () => {
  const userStore = useContext(UserStore);
  const location = useLocation();
  const navigate = useNavigate();
  const parsed = queryString.parse(location.search);
  let query = useParams();

  const pathname = location.pathname;

  const isProtectedAndIsLogin = (path) => {
    const isLogin = userStore.getUser();
    return !isLogin && PROTECTED_PATHS.includes(path);
  };

  const moveTo = (path: string, targetBlank?: boolean) => {
    if (isProtectedAndIsLogin(path)) {
      session.setVisibleNeedLoginAlert();
      navigate(PATH.SIGN_IN);
      return;
    } else {
      session.removeVisibleNeedLoginAlert();
    }

    if (targetBlank) {
      window.open(path, "_blank");
    } else {
      navigate(path);
      window.scrollTo(0, 0);
    }
    session.setPrevPath(pathname + location.search);
  };
  const replace = (path: string) => {
    if (isProtectedAndIsLogin(path)) {
      session.setVisibleNeedLoginAlert();

      navigate(PATH.SIGN_IN);
      return;
    }
    return navigate(path, { replace: true });
  };

  const reload = () => {
    window.location.reload();
  };
  const back = () => {
    navigate(-1);
  };

  return {
    pathname,
    location,
    moveTo,
    replace,
    reload,
    back,
    query,
    parsed,
  };
};
