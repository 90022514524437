import { PaymentTypeLabel } from "types/types";

export const PAYMENT_TYPE: PaymentTypeLabel[] = [
  { type: "card", label: "신용카드" },
  { type: "samsung", label: "삼성페이" },
  { type: "trans", label: "계좌이체" },
  { type: "vbank", label: "가상계좌" },
  { type: "phone", label: "휴대폰" },
  { type: "ssgpay", label: "SSGPAY (쓱페이)" },
  { type: "lpay", label: "LPay (엘페이)" },
  { type: "payco", label: "페이코" },
  { type: "kakaopay", label: "카카오페이" },
  { type: "tosspay", label: "토스" },
  { type: "naverpay", label: "네이버페이" },
  { type: "deposit", label: "무통장입금" },
];
