import { UserType } from "types/types";

const ACCESS_TOKEN = "ACCESS_TOKEN";
const REFRESH_TOKEN = "REFRESH_TOKEN";
const USER = "USER";
const PREV_PATH = "PREV_PATH";
const TEST_PHONE = "TEST_PHONE";
const MOBILE_VIEW_WARNING = "MOBILE_VIEW_WARNING";
const IS_LOGGINED = "IS_LOGGINED";
const VISIBLE_NEED_LOGIN_ALERT = "VISIBLE_NEED_LOGIN_ALERT";

/** local storage에 access token 저장 */
export const saveAccessToken = (accessToken: string) => {
  sessionStorage.setItem(ACCESS_TOKEN, accessToken);
};

/** local storage에서 access token 불러오기*/
export const getAccessToken = () => {
  return sessionStorage.getItem(ACCESS_TOKEN);
};

export const saveRefreshToken = (refreshToken: string) => {
  sessionStorage.setItem(REFRESH_TOKEN, refreshToken);
};

export const getRefreshToken = () => {
  return sessionStorage.getItem(REFRESH_TOKEN);
};

/** sessionStorage에서 토큰 정보 지우기 */
export const removeTokens = () => {
  sessionStorage.removeItem(USER);
  sessionStorage.removeItem(ACCESS_TOKEN);
  sessionStorage.removeItem(REFRESH_TOKEN);
};

export const saveUser = (user: UserType) => {
  try {
    sessionStorage.setItem(USER, JSON.stringify(user));
  } catch (err) {
    console.error(err);
  }
};

export const getUser = (): UserType => {
  const user =
    typeof window !== "undefined" ? sessionStorage.getItem(USER) : null;
  return user ? JSON.parse(user) : null;
};

export const getPrevPath = () => {
  return sessionStorage.getItem(PREV_PATH);
};

export const setPrevPath = (path: string) => {
  sessionStorage.setItem(PREV_PATH, path);
};

export const setVisibleNeedLoginAlert = () => {
  sessionStorage.setItem(VISIBLE_NEED_LOGIN_ALERT, "true");
};
export const getVisibleNeedLoginAlert = () => {
  return !!sessionStorage.getItem(VISIBLE_NEED_LOGIN_ALERT);
};

export const removeVisibleNeedLoginAlert = () => {
  sessionStorage.removeItem(VISIBLE_NEED_LOGIN_ALERT);
};

export const removePrevPath = () => {
  sessionStorage.removeItem(PREV_PATH);
};

export const getTestPhone = () => {
  return sessionStorage.getItem(TEST_PHONE);
};

export const setTestPhone = (path: string) => {
  sessionStorage.setItem(TEST_PHONE, path);
};

export const getMobileViewWarning = () => {
  return !!sessionStorage.getItem(MOBILE_VIEW_WARNING);
};

export const setMobileViewWarning = (isOpened: boolean) => {
  sessionStorage.setItem(MOBILE_VIEW_WARNING, String(isOpened));
};

export const setIsLoggined = (loggined: boolean) => {
  sessionStorage.setItem(IS_LOGGINED, String(loggined));
};

export const getIsLoggined = () => {
  return !!sessionStorage.getItem(IS_LOGGINED);
};

export const removeIsLoggined = () => {
  sessionStorage.removeItem(IS_LOGGINED);
};
