import styled, { css } from "styled-components";

export const Container = styled.div`
  // border: solid 1px lightgray;
  border-radius: 4px;
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 39px;
  letter-spacing: -1px;
  color: #333333;
`;

export const Content = styled.div`
  color: #666666;
  padding-top: 8px;
  padding-bottom: 36px;
  white-space: pre-line;
  line-height: 24px;
  li {
    list-style: none;
    padding-left: 8px;
    &::before {
      content: "•";
      padding-right: 10px;
    }
  }
`;

export const Description = styled.div`
  padding-top: 8px;
  padding-bottom: 32px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Close = styled.div`
  cursor: pointer;
`;

export const Button = styled.div<{ disabled?: boolean }>`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -1px;
  color: #333333;
  border: 1px solid #333333;
  border-radius: 100px;
  padding: 14px;
  text-align: center;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;

  ${(props) =>
    props.disabled &&
    css`
      background: #e1e1e1;
      border-radius: 100px;
      border-color: #e1e1e1;
      color: white;
    `};
`;

export const PrimaryButton = styled(Button)`
  background: #1b58f1;
  border-color: #1b58f1;
  color: white;
`;
export const OutlineButton = styled(Button)`
  border: 1px solid #1b58f1;
  color: #1b58f1;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 24px;
  button {
    flex: 1;
  }
`;

export const FlexInherit = styled.div`
  flex: inherit !important;
`;

export const SubTitle = styled.div`
  font-weight: 700;
  font-size: 18px;
  padding-bottom: 4px;
  line-height: 26px;
  letter-spacing: -1px;

  color: #333333;
  padding-bottom: 8px;
`;

export const Input = styled.input`
  border: 1px solid #999999;
  border-radius: 100px;
  padding: 16px;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  height: 56px;
  letter-spacing: -1px;
  color: #333333;

  &::placeholder {
    color: #999999;
  }
`;
export const InputContainer = styled.div`
  position: relative;
`;

export const UploadButton = styled.div`
  background: #1b58f1;
  color: #ffffff;
  position: absolute;
  right: 0px;
  height: 56px;
  width: 166px;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    cursor: pointer;
  }
`;
