import * as Image from "assets";
import { UseCaseType } from "types/types";
export const useCaseList: UseCaseType[] = [
  {
    id: 0,
    type: "주문/배달",
    title: "배달 상품\n프로모션 캠페인",
    text: "SMS 캠페인 대비 <br/><b>300%</b> 이상의 클릭 반응률",
    mockupImage: Image.ImgMockup1,
    chartImage: Image.ImgHomeUsecaseChart1,
  },
  {
    id: 1,
    type: "리테일",
    title: "리테일 스토어\n회원 혜택 프로모션",
    text: "SMS 캠페인 대비<br/>메시지 반응률 <b>10배</b> 증가",
    mockupImage: Image.ImgMockup2,
    chartImage: Image.ImgHomeUsecaseChart2,
  },
  {
    id: 2,
    type: "자동차",
    title: "사외 캠페인 진행",
    text: "SMS 캠페인 대비<br/>캠페인 응답률 <b>200%</b> 상승",
    mockupImage: Image.ImgMockup3,
    chartImage: Image.ImgHomeUsecaseChart3,
  },
  {
    id: 3,
    type: "뷰티/패션",
    title: "드로그스토어 세일\n프로모션",
    text: "MMS 캠페인 대비<br/>구매연결 <b>13.5</b>배 증가",
    mockupImage: Image.ImgMockup4,
    chartImage: Image.ImgHomeUsecaseChart4,
  },
  {
    id: 4,
    type: "금융/보험",
    title: "보험료 납입 안내",
    text: "SMS 캠페인 대비<br/><b>280%</b> 이상의 클릭 반응률",
    mockupImage: Image.ImgMockup5,
    chartImage: Image.ImgHomeUsecaseChart5,
  },
];
