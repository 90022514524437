import * as messageApi from "apis/messageApi";
import { observable, makeObservable, runInAction, action } from "mobx";
import { TITLE_BYTE_LIMIT } from "properties/constant";
import { createContext } from "react";
import {
  MessageUppercaseType,
  ResultGroupType,
  SendMessageRequestType,
  SendResultGroupResponseType,
  StatisticsType,
  WalletType,
  SendMessageParamsType,
  CallerType,
  SendReceiverAddressType,
  AddressGroupType,
} from "types/types";
import { addParameterToURL } from "utils/addParameterToURL";
import { getBytes } from "utils/bytes";
import { DATETIME_FORMAT_1, changeDateFormat } from "utils/datetime";

class MessageStore {
  @observable wallet: WalletType | null = null;
  @observable stats: StatisticsType | null = null;

  @observable startDate: string | null = null;
  @observable currentPage: number = 1;
  @observable resultGroups: ResultGroupType[] | null = null;
  @observable resultTotalCount: number = 0;
  @observable targetMessageType: MessageUppercaseType = "SMS";
  // 메시지 발송

  @observable targetGroup: ResultGroupType | null = null;
  @observable targetTitle: string = "";
  @observable targetContent: string = "";
  @observable completedTitle: string = "";
  @observable completedContent: string = "";

  @observable totalContentByte: number = 0;
  @observable totalTitleByte: number = 0;
  @observable isAd: boolean = false;
  @observable isReservation: boolean = false;
  @observable reservedDatetime: string = "";
  @observable targetCaller: CallerType | null = null;
  @observable hasImages: boolean | null = null;

  // 수신 거부 안내
  @observable rejectPhoneNumber: string = "080-***-****";
  @observable rejectVerifyCode: string = "******";
  @observable rejectText: string;

  // 수신자 리스트
  @observable receivers: SendReceiverAddressType[] = [];
  @observable receiverGroups: AddressGroupType[] = [];
  @observable receiverGroupPhone: AddressGroupType[] = [];

  constructor() {
    makeObservable(this);
    this.rejectText = `\n\n무료거부 {rejectPhoneNumber}\n인증코드 {rejectVerifyCode}`;
  }

  @action setCurrentPage = (page: number) => {
    this.currentPage = page;
  };
  @action setIsReservation = (isReservation: boolean) => {
    this.isReservation = isReservation;
  };

  @action setReservedDatetime = (reservedDatetime: string) => {
    this.reservedDatetime = reservedDatetime;
  };

  @action setTargetCaller = (caller: CallerType) => {
    this.targetCaller = caller;
    this.rejectPhoneNumber = caller.reject_number;
    this.rejectVerifyCode = String(caller.verify_code);
  };

  getRejectText = () => {
    return this.rejectText
      .replace("{rejectPhoneNumber}", this.rejectPhoneNumber)
      .replace("{rejectVerifyCode}", this.rejectVerifyCode);
  };
  @action setTargetGroup = (group: ResultGroupType) => {
    this.targetGroup = group;
  };
  @action setTargetMessageType = (messageType: MessageUppercaseType) => {
    this.targetMessageType = messageType;
  };

  @action toggleIsAd = () => {
    this.isAd = !this.isAd;
    let _content = this.isAd
      ? `(광고)${this.targetContent}` + this.getRejectText()
      : this.targetContent;
    this.completedContent = _content;
    this.totalContentByte = getBytes(_content);
  };

  @action setIsAd = (isAd: boolean) => {
    this.isAd = isAd;
  };

  @action setReceiverGroups = (groups: AddressGroupType[]) => {
    this.receiverGroups = groups;
  };

  @action addReceiverGroup = (group: AddressGroupType) => {
    if (this.receiverGroups.find((g) => g.id === group.id)) return;
    this.receiverGroups = [...this.receiverGroups, group];
  };
  @action updateMessageType() {
    if (this.hasImages) {
      this.targetMessageType = "MMS";
    } else if (this.totalContentByte > 90) {
      this.targetMessageType = "LMS";
    } else {
      this.targetMessageType = "SMS";
    }
  }

  @action setReceivers = (receivers: SendReceiverAddressType[]) => {
    this.receivers = receivers;
  };

  @action fetchMessageResult = async (
    startDate: string,
    endDate: string,
    isResevation: boolean
  ) => {
    try {
      const response: SendResultGroupResponseType =
        await messageApi.fetchMessageSendResults({
          page: this.currentPage,
          startDate,
          endDate,
          messageType: this.targetMessageType,
          isReserved: isResevation,
        });

      runInAction(() => {
        this.resultGroups = response.results;
        this.resultTotalCount = response.count;
      });
    } catch (err) {
      console.error(err);
      alert("결과 내역을 불러오는데 문제가 발생했습니다.");
    }
  };

  generateUrlWithParams = (
    baseUrl: string,
    startDate: string,
    endDate: string
  ) => {
    let url = addParameterToURL(baseUrl, "start", startDate);
    if (endDate) url = addParameterToURL(url, "end", endDate);
    if (this.targetMessageType)
      url = addParameterToURL(url, "msgType", this.targetMessageType);
    url = addParameterToURL(url, "page", this.currentPage);

    return url;
  };

  @action updateMessageContent = (content: string) => {
    this.targetContent = content;
    this.completedContent = this.isAd
      ? `(광고)${content}` + this.getRejectText()
      : content;
    this.totalContentByte = getBytes(this.completedContent);

    this.updateMessageType();
  };

  @action setHasImages = (hasImages: boolean) => {
    this.hasImages = hasImages;
    this.updateMessageType();
  };
  /** 제한된 byte로 보낼 수 있는 텍스트까지만 자르기 */
  getTextSplitUnderByte = (text: string, byteSize: number) => {
    let compeletedText = "";

    for (let i = 0; i < text.length; i++) {
      // 한 글자씩 더하기 위해 문자를 아스키 코드로 변환 후 더해줍니다.
      const character = text.charAt(i);

      if (getBytes(`${compeletedText}${character}`) < byteSize) {
        compeletedText += text.charAt(i);
      }
    }

    return compeletedText;
  };

  @action updateMessageTitle = (title: string) => {
    let isNeedSlice =
      this.targetMessageType !== "SMS" && getBytes(title) > TITLE_BYTE_LIMIT;

    this.targetTitle = title;
    // SMS가 아닌 경우, 64Byte 까지만 잘리도록
    this.completedTitle = isNeedSlice
      ? this.getTextSplitUnderByte(title, TITLE_BYTE_LIMIT)
      : title;

    this.totalTitleByte = getBytes(this.completedTitle);
  };

  sendMessage = async ({
    receivers,
    caller,
    group_ids,
    image_link,
  }: SendMessageParamsType) => {
    // 광고인 경우, SMS면 내용에 (광고) 표시
    const body: SendMessageRequestType = {
      title: this.completedTitle,
      content: this.completedContent,
      receiver: receivers,
      call_number: caller.phone_number,
      is_ad: this.isAd,
      group_ids: group_ids,
      image_link: image_link,
    };

    if (this.isReservation && this.reservedDatetime) {
      body.reserve_time = this.reservedDatetime;
    }
    const response = await messageApi.sendMessage(body);
    return response;
  };

  getReservedDatetime = () => {
    const reservedDatetimeText = this.reservedDatetime
      ? changeDateFormat(this.reservedDatetime, DATETIME_FORMAT_1)
      : null;
    return reservedDatetimeText;
  };

  /** 예약된 메시지 취소 */
  cancelReservedMessage = async (groupId: string) => {
    try {
      await messageApi.cancelReservedMessage(groupId);
      return { canceled: true, message: "" };
    } catch (err) {
      console.error(err);
      return { canceled: false, message: "" };
    }
  };

  getRemovedDuplicateReceivers = (receivers: SendReceiverAddressType[]) => {
    return receivers.filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (receiver) => receiver.phone_number === value.phone_number
        )
    );
  };

  getAllReceiversFromGroup = (receivers: SendReceiverAddressType[]) => {
    return receivers.filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (receiver) => receiver.phone_number === value.phone_number
        )
    );
  };

  resetMessage = () => {
    this.targetContent = "";
    this.targetTitle = "";
    this.isAd = false;
  };
}

export default createContext(new MessageStore());
