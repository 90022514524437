import styled, { CSSObject, css } from "styled-components";

import IconsArrowRight from "assets/images/icons/ico-arrow-right-black-1.svg";

export const BoxStyleComponent = styled.div<{
  css?: CSSObject;
}>`
  ${(props) => (props.css ? props.css : "")};
`;
export const FlexBetween = styled.div<{ pb?: number; gap?: number }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(props) =>
    props.pb &&
    css`
      padding-bottom: ${props.pb}px;
    `};

  ${(props) =>
    props.gap &&
    css`
      gap: ${props.gap}px;
    `};
`;
export const InnerContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 24px 24px 40px;
`;
export const FlexEnd = styled.div<{ pb?: number; gap?: number }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  ${(props) =>
    props.pb &&
    css`
      padding-bottom: ${props.pb}px;
    `}
  ${(props) =>
    props.gap &&
    css`
      gap: ${props.gap}px;
    `}
`;
export const IconButton = styled.div`
  cursor: pointer;
`;

export const Flex = styled.div<{
  gap?: number;
  direction?: string;
  justifyContent?: string;
  alignItems?: string;
}>`
  display: flex;

  ${(props) =>
    css`
      flex-direction: ${props.direction || "row"};
      justify-content: ${props.justifyContent || "flex-start"};
      align-items: ${props.alignItems || "stretch"};
      gap: ${props.gap || 10}px;
    `}
`;

export const Button = styled.div<{ radius?: number; padding?: string }>`
  border-radius: ${(props) => (props.radius ? props.radius : 4)}px;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.2;
  letter-spacing: -1px;
  cursor: pointer;
  padding: ${(props) => (props.padding ? props.padding : "13px")};
  text-align: center;
  width: fit-content;
`;

export const ButtonOutline = styled(Button)`
  border: 0.5px solid #333333;
  color: #333333;
`;

export const ButtonPrimary = styled(Button)<{ bgColor?: string }>`
  background: ${(props) => (props.bgColor ? props.bgColor : "#1b58f1")};
  color: #ffffff;
`;

export const Input = styled.input<{ width: number }>`
  background: #ffffff;
  border: 1px solid #d7dbe2;
  border-radius: 6px;
  padding: 10px;
  height: 46px;
  ${(props) =>
    props.width &&
    css`
      width: ${props.width}px;
    `}
  &:focus-visible {
    outline: none;
  }
  &::placeholder {
    color: #999999;
  }
  &:focus {
    border-color: #1b58f1;
  }
`;

export const Option = styled.div<{ width?: number; height: number }>`
  padding: 8px;
  width: ${(props) => (props.width ? `${props.width}px` : `100%`)};
  height: ${(props) => props.height}px;
  border: 1px solid #d7dbe2;
  background-color: white;
  margin-bottom: -1px;
  display: flex;
  align-items: center;
  color: #333333;

  cursor: pointer;

  &:hover {
    background: #eef3ff;
  }
`;

export const Pad = styled.div<{
  p?: number;
  px?: number;
  py?: number;
  pt?: number;
  pb?: number;
  pr?: number;
  pl?: number;
}>`
  ${(props) => props.p && css`padding:${props.p}px;}`};
  ${(props) =>
    props.px &&
    css`
      padding-left: ${props.px}px;
      padding-right: ${props.px}px;
    `};
  ${(props) =>
    props.py &&
    css`
      padding-top: ${props.py}px;
      padding-bottom: ${props.py}px;
    `};
  ${(props) =>
    props.pt &&
    css`
      padding-top: ${props.pt}px;
    `};

  ${(props) =>
    props.pb &&
    css`
      padding-bottom: ${props.pb}px;
    `};
  ${(props) =>
    props.pr &&
    css`
      padding-right: ${props.pr}px;
    `}
  ${(props) =>
    props.pl &&
    css`
      padding-left: ${props.pl}px;
    `};
`;
export const Center = styled.div`
  display: flex;
  justify-content: center;
`;
export const Div = styled.div<{
  css?: CSSObject;
}>`
  ${(props) => (props.css ? props.css : "")};
`;

/****  BUTTON  ****/

export const ButtonRound = styled.div<{
  width?: number;
  padding?: string;
  css?: CSSObject;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  text-align: center;
  padding: ${(props) => (props.padding ? props.padding : "10px 40px")};
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -1px;
  cursor: pointer;
  white-space: nowrap;
  ${(props) => props.width && `width: ${props.width}px; text-align: center;`}
  ${(props) => (props.css ? props.css : "")};
`;

export const ButtonRoundPrimary = styled(ButtonRound)<{
  disabled?: boolean;
}>`
  background: #1b58f1;
  color: #ffffff;
  ${(props) => props.disabled && `background: #e1e1e1; cursor: not-allowed;`}
`;

export const ButtonRoundOutline = styled(ButtonRound)`
  border: 0.5px solid #333333;
`;

export const ButtonMockupSquare = styled.div<{
  css?: CSSObject;
}>`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 56px;
  padding: 16px;
  border: 1px solid #d7dbe2;
  background: #fff;
  border-radius: 6px;
  box-sizing: border-box;

  ${(props) => (props.css ? props.css : "")};

  :after {
    content: "";
    position: absolute;
    top: 16px;
    right: 16px;
    display: block;
    width: 24px;
    height: 24px;
    background-image: url(${IconsArrowRight});
  }
`;

/****  TEXT  ****/

export const TextGray9 = styled.div`
  color: #999999;
`;

export const TextGray6 = styled.div`
  color: #666666;
`;

export const PageSubTitle = styled.h4`
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  letter-spacing: -1px;
  color: #333333;
  padding-bottom: 16px;
`;

export const PageTitle = styled.h4`
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  letter-spacing: -1px;
  color: #333333;
  padding-bottom: 16px;
`;

export const RequiredBodyHeader = styled.div`
  font-weight: bold;
  letter-spacing: -1px;
  margin-bottom: 16px;
  :after {
    content: "*";
    display: inline-block;
    padding-left: 5px;
    color: #ec1c24;
    font-size: 16px;
    font-css: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -1px;
  }
`;

export const SubHeading = styled.div<{
  css?: CSSObject;
}>`
  font-weight: normal;
  letter-spacing: -1px;
  margin-bottom: 18px;
  ${(props) =>
    props.css &&
    css`
      ${props.css}
    `};
`;

export const BodyBold = styled.div<{
  css?: CSSObject;
}>`
  font-weight: bold;
  letter-spacing: -1px;
  margin-bottom: 16px;
  ${(props) =>
    props.css &&
    css`
      ${props.css}
    `};
`;

export const Body = styled.div<{
  css?: CSSObject;
}>`
  letter-spacing: -1px;
  font-size: 16px;
  ${(props) =>
    props.css &&
    css`
      ${props.css}
    `};
`;

export const Caption = styled.div<{
  css?: CSSObject;
}>`
  letter-spacing: -1px;
  font-size: 14px;
  ${(props) =>
    css`
      ${props.css}
    `};
`;

export const Small = styled.div<{
  css?: CSSObject;
}>`
  letter-spacing: -1px;
  font-size: 12px;
  ${(props) =>
    css`
      ${props.css}
    `};
`;

export const EllipsisControl = styled.div<{
  css?: CSSObject;
  line: number;
}>`
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${(props) => props.line};
  ${(props) =>
    css`
      ${props.css}
    `};
`;

export const AutoCenterCoverSizeImage = styled.div<{
  width?: string;
  height?: string;
  css?: CSSObject;
}>`
  position: relative;
  ${(props) =>
    props.width
      ? `
        width: ${props.width};
        min-width: ${props.width};
      `
      : `
        width: 258px;
        min-width: 258px;
      `};
  ${(props) =>
    props.height
      ? `
        height: ${props.height};
        min-height: ${props.height};
      `
      : `
        height: 258px;
        min-height: 258px;
      `};
  overflow: hidden;

  ${(props) => (props.css ? props.css : "")};

  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

export const ScrollableTextContainer = styled.div<{
  css?: CSSObject;
  height: string;
}>`
  height: ${(props) => props.height};
  overflow-y: auto;

  ${(props) =>
    css`
      ${props.css}
    `};
`;
/** Table */
export const TBody = styled.div`
  display: flex;
  margin-bottom: 8px;
  background: #fafafa;
  padding-left: 4px;
`;

export const TR = styled.div`
  flex: 1;
  text-align: center;
  padding: 12px;
  margin-left: -1px;

  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -1px;
  color: #333333;

  &:nth-child(1) {
    width: 200px;
  }
  &:nth-child(3) {
    flex: 2;
  }
`;

export const NoData = styled.div`
  text-align: center;
  padding: 40px;
  width: 100%;
  color: gray;
`;

export const TRow = styled.div`
  display: flex;
  height: 34px;
  align-items: center;
`;

export const TD = styled.div<{ hasLink?: boolean; left?: boolean }>`
  flex: 1;
  text-align: center;
  padding: 4px;
  font-size: 16px;

  margin-left: -1px;
  margin-top: -1px;
  cursor: pointer;

  &:nth-child(1) {
    width: 200px;
  }
  &:nth-child(3) {
    flex: 2;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &:last-child {
    border-width: 0px;
  }

  ${(props) =>
    props.hasLink &&
    css`
      text-decoration-line: underline;
      color: #1b58f1;
    `}
  ${(props) =>
    props.left &&
    css`
      text-align: left;
    `}
`;
