import { useContext, useEffect, useState } from "react";

import styled from "styled-components";
import { observer } from "mobx-react-lite";
import UserStore from "stores/UserStore";
import { MessageLayout } from "components";
import { Flex, FlexBetween, InnerContainer, Pad } from "styles/styled";
import { InputFile } from "./InputFile";
import { CreateChangeToCompanyUser } from "types/types";
import { useCustomNavigate } from "hooks/useCustomNavigate";
import { PATH } from "properties/menu";
import {
  CheckboxCircle,
  CheckboxCircleFilled,
  Loading,
} from "components/Icons";
import { uploadS3File } from "utils/awsS3";
import { v4 as uuidv4 } from "uuid";
import { InputListItem } from "components/InputListItem";

export const ChangeCompanyProfile = observer(() => {
  const navigate = useCustomNavigate();
  const userStore = useContext(UserStore);
  const [loading, setLoading] = useState(false);

  const [companyName, setCompanyName] = useState("");
  const [ceoName, setCeoName] = useState("");
  const [businessNumber, setBusinessNumber] = useState("");
  const [industryCategory1, setIndustryCategory1] = useState("");
  const [industryCategory2, setIndustryCategory2] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [managerName, setManagerName] = useState("");
  const [isCeo, setIsCeo] = useState(false);
  const [validateErrorReason, setValidateErrorReason] = useState<string | null>(
    null
  );
  const [businessFile, setBusinessFile] = useState<File | null>(null);
  const [employeeFile, setEmployeeFile] = useState<File | null>(null);
  const [businessFileKey, setBusinessFileKey] = useState<string | null>(null);
  const [employeeFileKey, setEmployeeFileKey] = useState<string | null>(null);

  const [uuid, setUuid] = useState<string>(sessionStorage.getItem("UUID"));

  useEffect(() => {
    let company = null;
    if (userStore.activeCompany) {
      company = userStore.activeCompany;
    } else if (userStore.user.company_info.length > 0) {
      company = userStore.user.company_info[0];
    }
    if (company) {
      setCompanyName(company.company_name);
      setCeoName(company.ceo_name);
      setBusinessNumber(company.registry_number);
      setIndustryCategory1(company.industry_category1);
      setIndustryCategory2(company.industry_category2);
      setCompanyAddress(company.company_address);
      setManagerName(company.manager_name);
    }

    if (!uuid) {
      const currentUuid = uuidv4();
      setUuid(currentUuid);
      sessionStorage.setItem("UUID", currentUuid);
    }
  }, [userStore.activeCompany, userStore.user.company_info, uuid]);

  useEffect(() => {
    /**
     * 로그인 유효성 검사
     */
    const validate = () => {
      setValidateErrorReason(null);

      if (companyName.length < 2) {
        setValidateErrorReason("기업명을 입력해주세요");
      } else if (ceoName.length < 2) {
        setValidateErrorReason("대표자 명을 입력해주세요");
      } else if (businessNumber.length !== 10) {
        setValidateErrorReason("사업자 등록번호 10자리를 입력해주세요");
      } else if (!businessFile) {
        setValidateErrorReason("사업자 등록증을 첨부해주세요");
      } else if (companyAddress.length < 2) {
        setValidateErrorReason("사업장 주소지를 입력해주세요");
      } else if (industryCategory1.length < 2) {
        setValidateErrorReason("업종을 입력해주세요");
      } else if (managerName.length < 2) {
        setValidateErrorReason("기업 담당자 이름을 확인해주세요");
      } else if (!isCeo && !employeeFile) {
        setValidateErrorReason("재직 증명서를 첨부해주세요");
      }
    };

    validate();
  }, [
    companyName,
    ceoName,
    businessNumber,
    industryCategory1,
    industryCategory2,
    companyAddress,
    managerName,
    businessFile,
    employeeFile,
    isCeo,
  ]);

  const onHandleChangeBusinessFile = async (e) => {
    try {
      const file = e.target.files[0];
      setBusinessFile(file);
      const response = await uploadS3File("bf", file, uuid);
      setBusinessFileKey(response.Key);
    } catch (err) {
      console.error("파일 업로드 실패");
    }
  };

  const onHandleChangeEmployeeFile = async (e) => {
    try {
      const file = e.target.files[0];
      setEmployeeFile(file);
      const response = await uploadS3File("ef", file, uuid);
      setEmployeeFileKey(response.Key);
    } catch (err) {
      console.error("파일 업로드 실패");
    }
  };

  const onSubmit = async () => {
    if (loading) {
      return;
    }
    if (validateErrorReason) {
      alert(validateErrorReason);
      return;
    }
    setLoading(true);

    const body: CreateChangeToCompanyUser = {
      company_name: companyName,
      ceo_name: ceoName,
      registry_number: businessNumber,
      industry_category1: industryCategory1,
      industry_category2: industryCategory2,
      company_address: companyAddress,
      manager_name: managerName,
      employment_certificate: employeeFileKey,
      file_paper: businessFileKey,
      is_employment_ceo: isCeo,
    };

    const result = await userStore.changeToCompanyUser(body);
    setLoading(false);
    if (result) {
      alert(
        "기업회원 전환을 신청하였습니다.\n관리자 승인까지 1-2일 소요될 수 있습니다."
      );
      await userStore.fetchUserInfo();
      navigate.moveTo(PATH.CHANGE_PROFILE);
    } else {
      alert("기업회원 전환 신청에 실패했습니다. 고객센터로 문의해주세요.");
    }
  };

  const onClickCeoCheckbox = () => {
    if (isCeo) {
      setIsCeo(false);
      if (managerName === ceoName) {
        setManagerName("");
      }
    } else {
      setIsCeo(true);
      setManagerName(ceoName);
    }
  };

  return (
    <MessageLayout title="회원 정보변경">
      <InnerContainer>
        <Title>
          {userStore.activeCompany ? "기업 회원 정보" : "기업 회원전환"}
        </Title>
        {userStore.activeCompany ? (
          <Pad pb={48} />
        ) : (
          <Descriptions>
            <Li>
              {`기업회원 전환 시 기존에 등록된 발신번호가 모두 삭제됩니다. 재등록 시 다시 사용하실 수 있습니다.`}
            </Li>
            <Li>{`관리자 승인까지 영업일 기준 1~2일 소요될 수 있습니다.`}</Li>
          </Descriptions>
        )}
        <InputContainer>
          <Pad pb={24}>
            <InputListItem
              label={"기업 이름"}
              value={companyName}
              placeholder={"기업/업체/단체명 입력"}
              onChange={setCompanyName}
            />
          </Pad>
          <Pad pb={24}>
            <InputListItem
              label={"기업 대표자 이름"}
              value={ceoName}
              placeholder={"대표자명 입력 (1명만)"}
              onChange={setCeoName}
            />
          </Pad>
          <Pad pb={10}>
            <InputListItem
              label={"사업자 등록번호"}
              value={businessNumber}
              placeholder={"사업자 번호 입력 (-제외)"}
              onChange={setBusinessNumber}
            />
          </Pad>
          <Pad pb={24}>
            <InputFile
              inputId="businessFileInput"
              labelText={"사업자 등록증 첨부"}
              placeholder={"jpg, png, pdf (최대5M)"}
              fileName={businessFile?.name}
              onHandleChangeFile={onHandleChangeBusinessFile}
            />
          </Pad>
          <Pad pb={24}>
            <InputListItem
              label={"사업장 주소지"}
              value={companyAddress}
              placeholder={"사업장 주소지 입력"}
              onChange={setCompanyAddress}
            />
          </Pad>
          <Pad pb={24}>
            <FlexBetween gap={10}>
              <InputListItem
                label={"업종"}
                value={industryCategory1}
                placeholder={"업종 입력"}
                onChange={setIndustryCategory1}
              />

              <InputListItem
                label={"업태"}
                value={industryCategory2}
                placeholder={"업태 입력"}
                onChange={setIndustryCategory2}
              />
            </FlexBetween>
          </Pad>
          <Pad pb={10}>
            <InputListItem
              label={"기업 담당자 정보"}
              value={managerName}
              placeholder={"기업 담당자 이름 입력"}
              onChange={setManagerName}
              disabled={isCeo}
            />
          </Pad>
          <Pad pb={54}>
            <InputFile
              inputId="employeeFileInput"
              labelText={"재직 증명서 첨부"}
              placeholder={"jpg, png, pdf (최대5M)"}
              fileName={employeeFile?.name}
              disabled={isCeo}
              onHandleChangeFile={onHandleChangeEmployeeFile}
            />
            <Pad pt={9}>
              <Flex gap={8} onClick={onClickCeoCheckbox} className="pointer">
                {isCeo ? (
                  <CheckboxCircleFilled
                    height={22}
                    width={22}
                    fill={"#1B58F1"}
                  />
                ) : (
                  <CheckboxCircle height={22} width={22} fill={"#C4C9D1"} />
                )}
                <div>대표자 본인이 직접 신청합니다.</div>
              </Flex>
            </Pad>
          </Pad>
          <Button active={!validateErrorReason} onClick={onSubmit}>
            <span>
              {userStore.user?.company_info?.length > 0
                ? "기업정보 변경하기"
                : "기업전환 신청하기"}

              {loading && (
                <LoadingContainer>
                  <Loading />
                </LoadingContainer>
              )}
            </span>
          </Button>
        </InputContainer>
      </InnerContainer>
    </MessageLayout>
  );
});

const Container = styled.div`
  min-height: 100vh;
  min-width: 600px;
  padding: 64px 40px 24px;
`;
const InputContainer = styled.div`
  max-width: 380px;
  margin: 0 auto;
`;

const PageTitle = styled.h1`
  font-weight: 700;
  font-size: 32px;
  line-height: 47px;
  letter-spacing: -1px;
  padding-bottom: 30px;
  color: #333333;
`;

const Descriptions = styled.div`
  padding-bottom: 24px;
`;

const Li = styled.div`
  padding-bottom: 8px;
  color: rgb(85, 85, 85);
  &::before {
    content: "•";
    padding: 0 8px;
  }
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;

  letter-spacing: -1px;

  color: #333333;
  padding-bottom: 16px;
`;

const Button = styled.div<{ active: boolean }>`
  border-radius: 100px;
  position: relative;
  width: 100%;
  margin: 0 auto;
  cursor: pointer;
  display: flex;

  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -1px;

  background: ${(props) => (props.active ? "#1b58f1" : "#e1e1e1")};

  color: #ffffff;
  height: 100%;
  width: 100%;
  padding: 16px;
  position: relative;
`;

const LoadingContainer = styled.div`
  position: absolute;
  right: 100px;
  top: 12px;
`;
