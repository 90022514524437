import { UserType } from "types/types";

const ACCESS_TOKEN = "ACCESS_TOKEN";
const REFRESH_TOKEN = "REFRESH_TOKEN";
const USER = "USER";
/** local storage에 access token 저장 */
export const saveAccessToken = (accessToken: string) => {
  localStorage.setItem(ACCESS_TOKEN, accessToken);
};

/** local storage에서 access token 불러오기*/
export const getAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN);
};

export const saveRefreshToken = (refreshToken: string) => {
  localStorage.setItem(REFRESH_TOKEN, refreshToken);
};

export const getRefreshToken = () => {
  return localStorage.getItem(REFRESH_TOKEN);
};

/** localStorage에서 토큰 정보 지우기 */
export const removeTokens = () => {
  localStorage.removeItem(USER);
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
};

export const saveUser = (user: UserType) => {
  localStorage.setItem(USER, JSON.stringify(user));
};

export const getUser = () => {
  const user =
    typeof window !== "undefined" ? localStorage.getItem(USER) : null;
  return user ? JSON.parse(user) : null;
};
