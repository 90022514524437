import { useState } from "react";
import styled, { css } from "styled-components";
import { successfulCases } from "properties/content";
import { SuccessfulCaseType } from "types/types";
import { SuccessfulCaseCard } from "components/SuccessfulCaseCard";
import { MEDIA_QUERY } from "properties/constant";
import useResponsive from "hooks/useResponsive";

export const SuccessfulCase = () => {
  const [activeSuccessfulCase, setActiveSuccessfulCase] =
    useState<SuccessfulCaseType>(successfulCases[0]);
  const { isDesktop } = useResponsive();

  return (
    <Container>
      <div>
        <Title>
          {isDesktop ? (
            <>
              비즈포스트만의 <b>성과 문자 컨설팅</b>
            </>
          ) : (
            <>
              비즈포스트만의 <br />
              <b>성과 문자 컨설팅</b>
            </>
          )}
        </Title>
        <Text>
          <b>평균 4배 매출</b> 상승시킨 <b>{'"설득 및 매력적인" 문자광고'}</b>로
          성과를 높이세요.
        </Text>
      </div>

      <CaseButtonList>
        {successfulCases.map((successfulCase) => (
          <CaseButton
            key={successfulCase.id}
            active={activeSuccessfulCase.id === successfulCase.id}
            onClick={() => setActiveSuccessfulCase(successfulCase)}
          >
            {successfulCase.type}
          </CaseButton>
        ))}
      </CaseButtonList>
      <SuccessfulCaseCard successfulCase={activeSuccessfulCase} />

      <Notice>
        <b>매출에 직접 기여합니다. </b>
        <br />
        단순 홍보문자가 아닌 광고의 핵심가치를 매력적으로 전달하는{" "}
        <b>{'"비즈포스트"'}</b>
        입니다.
      </Notice>
    </Container>
  );
};

const Container = styled.div`
  background: #f6f8ff;
  padding: 105px 20px 55px;
  text-align: center;
`;

const Title = styled.div`
  font-weight: 400;
  font-size: 34px;
  line-height: 39px;
  padding-bottom: 16px;

  letter-spacing: -1px;

  color: #333333;
  b {
    font-weight: 700;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 28px;
    line-height: 34px;
  }
`;
const Text = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -1px;
  padding-bottom: 40px;
  word-break: keep-all;

  color: #333333;
  b {
    font-weight: 700;
  }
`;

const CaseButtonList = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    max-width: 420px;
    margin: 0 auto;
  }
`;

const CaseButton = styled.button<{ active: boolean }>`
  border: 0.5px solid #666666;
  border-radius: 100px;
  width: 220px;
  color: #666666;
  background-color: #f6f8ff;
  box-sizing: border-box;
  padding-top: 4px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  padding: 8px 8px 6px;
  height: 34px;

  ${(props) =>
    props.active &&
    css`
      color: #ffffff;
      font-weight: 700px;
      background-color: #2984ff;
      filter: drop-shadow(0px 4px 4px rgba(8, 23, 61, 0.25));
      border-width: 0px;
    `}
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 14px;
    width: 46%;
  }
`;

const Notice = styled.div`
  width: 782px;
  height: 102px;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -1px;

  color: #333333;
  background: #edf1ff;
  border-radius: 9.98438px;
  margin: 24px auto 0;
  text-align: left;
  padding: 24px;
  box-sizing: border-box;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 100%;
    font-size: 16px;
    height: auto;
  }
`;
