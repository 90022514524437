import styled, { css } from "styled-components";
import { MessageUppercaseType } from "types/types";

export const Container = styled.div`
  position: relative;
  margin-top: 36px;
`;

export const ContentContainer = styled.div`
  position: absolute;
  top: 28px;
  left: 56px;
`;
export const BodyContainer = styled.div`
  max-height: 476px;
  overflow-y: auto;
`;
export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 22px;
  padding-left: 10px;
`;
export const PhoneContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const Icon = styled.div``;
export const PhoneNumber = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -1px;

  color: #333333;
`;

export const SmsType = styled.div<{ messageType: MessageUppercaseType }>`
  border-radius: 100px;
  padding: 4px 24px;
  background: #ffffff;
  border: 1px solid #d7dbe2;

  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -1px;

  ${(props) =>
    props.messageType === "LMS" &&
    css`
      border: 1px solid #1b58f1;
      color: #1b58f1;
    `};

  ${(props) =>
    props.messageType === "MMS" &&
    css`
      border: 1px solid #652d90;
      color: #652d90;
    `};
`;

export const MessageContainer = styled.div`
  width: 238px;
  background: #f2f2f2;
  border-radius: 10px;
  padding: 16px;
  margin-bottom: 8px;
  word-break: break-word;
`;
export const Message = styled.div<{ isPlaceholder?: boolean }>`
  white-space: pre-line;
  word-break: break-all;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: -1px;

  color: ${(props) => (props.isPlaceholder ? "#999999" : "#333333")};
`;

export const PreviewImage = styled.img`
  border: solid 1px #efeeee;
  width: 235px;
  margin: 8px 0px;
  border-radius: 8px;
`;
