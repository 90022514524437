import { Icons } from 'components';
import InputFile from 'components/InputFile';
import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { Body, BodyBold } from 'styles/styled';

const MainImageRatio = ({
  imageRatio,
  setImageRatio,
  setMainImage,
  setMainImagePreview
}:{
  imageRatio: string;
  setImageRatio: Dispatch<SetStateAction<string>>;
  setMainImage: (fileImage: File) => void;
  setMainImagePreview: (readerResult: string | ArrayBuffer) => void;
}) => {
  const onClickRatioButton = (value : string) => {
    setImageRatio(value);
  }
  return (
    <div>
      <BodyBold>
        메인 이미지 선택
      </BodyBold>
      <ImageRatioWrapper>
        <Body>이미지 비율</Body>
        <ul>
          <li onClick={()=> onClickRatioButton("1:1")}>
            {
              imageRatio === "1:1" 
                ? <Icons.RadioChecked/>
                : <Icons.RadioUnchecked/> 
            }
            <div className="label">1:1</div>
          </li>
          <li onClick={()=> onClickRatioButton("3:4")}>
            {
              imageRatio === "3:4" 
                ? <Icons.RadioChecked/>
                : <Icons.RadioUnchecked/> 
            }
            <div className="label">3:4</div>
          </li>
        </ul>
      </ImageRatioWrapper>
      <InputFile
        id="btn-main-image-file"
        setInputImage={setMainImage}
        setPreviewImage={setMainImagePreview}
        text="이미지 불러오기"
        css={{
          height: "56px",
          marginTop: "20px",
          marginBottom: "40px",
          fontWeight: "bold"
        }}
      />
    </div>
  );
};

export default MainImageRatio;

const ImageRatioWrapper = styled.div`
  display: flex;
  align-items: center;
  ul{
    display: flex;
    align-items: center;
    margin-left: 24px;
    li{
      display: flex;
      align-items: center;
      margin-right: 24px;
      cursor: pointer;
      >.label{
        display: block;
        margin-top: 4px;
        margin-left: 8px;
      }
    }
  }
`