import { ModalLayout } from "components/Modals/ModalLayout";
import { useCallback, useContext, useEffect, useState } from "react";
import { getBytes } from "utils/bytes";
import { SubTitle } from "../../styled";
import { CheckboxChecked, CheckboxUnchecked } from "components/Icons/Checkbox";
import { observer } from "mobx-react-lite";
import MessageStore from "stores/MessageStore";
import * as S from "./styled";
import { TITLE_BYTE_LIMIT } from "properties/constant";

export const MessageEditor = observer(() => {
  const messageStore = useContext(MessageStore);
  const [visibleAdPolicy, setVisibleAdPolicy] = useState(false);
  const [alertText, setAlertText] = useState("");

  const updateMessageTitle = useCallback((title: string) => {
    if (getBytes(title) > TITLE_BYTE_LIMIT) {
      setAlertText(`제목은 ${TITLE_BYTE_LIMIT}바이트 이내로 입력해주세요.`);
      return;
    }
    setAlertText(null);
    messageStore.updateMessageTitle(title);
  }, []);

  const onClickAgreementPoliyLink = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setVisibleAdPolicy(!visibleAdPolicy);
    },
    [visibleAdPolicy]
  );

  const messageType = messageStore.targetMessageType;
  const messageContent = messageStore.targetContent;
  const messageTitle = messageStore.targetTitle;

  return (
    <S.Container>
      <S.Header>
        <SubTitle>내용</SubTitle>
        <S.Agreement onClick={messageStore.toggleIsAd}>
          <S.Icon>
            {messageStore.isAd ? <CheckboxChecked /> : <CheckboxUnchecked />}
          </S.Icon>
          <S.Link onClick={onClickAgreementPoliyLink}>광고성 정보</S.Link>가
          포함되어 있습니다.
        </S.Agreement>
      </S.Header>
      <S.MessageTitleInput
        messageType={messageType}
        placeholder={`제목을 입력해주세요. (최대 ${TITLE_BYTE_LIMIT} byte, SMS는 제목 전송 안됨)`}
        onChange={(e: any) => updateMessageTitle(e.target.value)}
        value={messageStore.targetTitle}
      ></S.MessageTitleInput>

      <S.Caption>
        {/* TODO: 하나의 함수로 통합하기 */}
        {messageType === "SMS" &&
          messageTitle &&
          messageTitle.length > 0 &&
          "* SMS 문자인 경우 제목은 발송되지 않습니다."}
        {alertText}
      </S.Caption>

      <S.MessageInputContainer
        messageType={messageType}
        isAd={messageStore.isAd}
      >
        <S.MessageContentInput
          messageType={messageType}
          placeholder={`내용을 입력해주세요.\n(90byte초과 시 LMS로 자동 전환, 파일 첨부시 MMS로 자동 전환)`}
          onChange={(e) => messageStore.updateMessageContent(e.target.value)}
          value={messageStore.targetContent}
        />
        <S.MessageByteContainer>
          <span className="messageByte">
            <span className="targetByte">{messageStore.totalContentByte}</span>
            <span className="messageButtons">
              <span>/</span>
              {messageType === "SMS" && (
                <S.MessageByteSize> 90 Byte ({messageType})</S.MessageByteSize>
              )}
              {messageType === "MMS" && (
                <S.MessageByteSize color="#652D90">
                  2000 Byte ({messageType})
                </S.MessageByteSize>
              )}
              {messageType === "LMS" && (
                <S.MessageByteSize color="#1B58F1">
                  2000 Byte ({messageType})
                </S.MessageByteSize>
              )}
            </span>
          </span>
        </S.MessageByteContainer>
        {messageStore.isAd && (
          <S.BottomMessage hasMessage={messageContent.length > 0}>
            {messageStore.getRejectText()}
          </S.BottomMessage>
        )}
      </S.MessageInputContainer>
      {/* 글자수 계산 */}

      {visibleAdPolicy && (
        <ModalLayout
          title="광고성 정보 전송 시 유의사항"
          onClose={() => setVisibleAdPolicy(false)}
        >
          <S.PolicyContainer>
            <br />
            한국인터넷진흥원(KISA)에서 배포한
            <br />
            <S.Link
              onClick={() => {
                window.open(
                  "https://stage-admin-static.s3.ap-northeast-2.amazonaws.com/media/files/kisa_spam_guide.pdf"
                );
              }}
            >
              불법 스팸 방지를 위한 정보통신망법 안내서
            </S.Link>
            에 따라 광고성 정보를 전송하실 경우아래 규칙을 준수하여 메시지를
            작성하셔야 합니다.
            <br /> 광고표시 미준수 시 메시지 발송이 중단될 수 있으며 KISA로 부터
            과태료 등의 처벌을 받을 수 있습니다.
            <br />
            <br />
            <br />
            <S.ConfirmButton onClick={() => setVisibleAdPolicy(false)}>
              확인
            </S.ConfirmButton>
          </S.PolicyContainer>
        </ModalLayout>
      )}
    </S.Container>
  );
});
