import { css } from "styled-components";

export const maxWidth = css`
  max-width: 960px;
  margin: 0 auto;
`;

export const ellipsis = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
`;
