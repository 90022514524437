export function getBytes(text: string) {
  let encodedText = encodeURIComponent(text);
  let byteCount = 0;

  for (let i = 0; i < encodedText.length; i++) {
    if (encodedText.charAt(i) === "%") {
      if (encodedText.charAt(i + 1) === "u") {
        byteCount += 2;
        i += 5;
      } else {
        byteCount += 1;
        i += 2;
      }
    } else {
      byteCount += 1;
    }
  }

  return byteCount;
}