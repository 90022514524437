import { UseCaseType } from "types/types";
import styled from "styled-components";
import { MEDIA_QUERY } from "properties/constant";

export const UseCaseCard = ({ useCase }: { useCase: UseCaseType }) => {
  return (
    <Container>
      <div className="imageContainer">
        <img src={useCase.mockupImage} alt="mockup" />
      </div>
      <TextContainer>
        <div>
          <Title>{useCase.title}</Title>
          <Text dangerouslySetInnerHTML={{ __html: useCase.text }}></Text>
        </div>

        <ChartContainer>
          <img src={useCase.chartImage} alt="mockup" />
        </ChartContainer>
      </TextContainer>
    </Container>
  );
};

const Container = styled.div`
  background: #f6f8ff;
  padding-top: 62px;
  display: flex;
  justify-content: center;
  gap: 120px;
  max-width: 800px;
  margin: 0 auto;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    padding: 40px 20px;
    gap: 12px;

    .imageContainer {
      flex: 1;
      max-width: 300px;
      margin: 0 auto;
      img {
        height: auto;
      }
    }
  }
`;

const TextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 34px;
  line-height: 45px;

  letter-spacing: -1px;
  white-space: pre-line;

  color: #333333;
  padding-bottom: 24px;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    padding-bottom: 12px;
  }
`;

const Text = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -1px;
  word-break: keep-all;

  color: #333333;
  white-space: pre-line;
  padding-bottom: 20px;
  b {
    font-size: 24px;
    line-height: 36px;
    font-weight: 700;
    color: rgb(27, 88, 241);
  }

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 14px;
    line-height: 16px;
    align-items: center;
    text-align: center;
    b {
      font-size: 20px;
      line-height: 36px;
      padding-top: 3px;
    }
  }
`;
const ChartContainer = styled.div`
  margin-bottom: 40px;

  img {
    width: 70%;
    margin: 0 auto;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    text-align: center;
    img {
      width: 100%;
      margin-left: 10px;
    }
  }
`;
