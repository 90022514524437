import { RejectorType } from "types/types";
import { changeDateFormat, DATETIME_FORMAT_1 } from "utils/datetime";
import styled, { css } from "styled-components";
import { Loading } from "components/Icons";

interface Props {
  rejectors: RejectorType[] | null;
}

export const Rejectors = ({ rejectors }: Props) => {
  return (
    <Container>
      <TBody>
        <TR>발신번호</TR>
        <TR>차단된 수신번호</TR>
        <TR>차단일자</TR>
        <TR>차단상태</TR>
      </TBody>

      {rejectors && rejectors.length > 0 ? (
        rejectors.map((rejector) => (
          <TRow key={`m-rejector-${rejector.id}`}>
            <TD>{rejector.sender_number.phone_number}</TD>
            <TD>{rejector.receive_number}</TD>
            <TD> {changeDateFormat(rejector.created, DATETIME_FORMAT_1)}</TD>
            <TD color="#1b58f1">차단됨</TD>
          </TRow>
        ))
      ) : (
        <>
          {rejectors ? (
            <NoData>차단된 수신 내역이 없습니다.</NoData>
          ) : (
            <LoadingContainer>
              <Loading />
            </LoadingContainer>
          )}
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  border-radius: 4px;
  min-width: 700px;
`;

const TBody = styled.div`
  display: flex;
`;

const TR = styled.div`
  flex: 1;
  text-align: center;
  background: #fafafa;
  padding: 12px;
  margin-left: -1px;

  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -1px;
  color: #333333;
`;

const NoData = styled.div`
  text-align: center;
  padding: 40px;
  width: 100%;
  color: gray;
`;

const TRow = styled.div`
  display: flex;
`;

const TD = styled.div<{ hasLink?: boolean; fontSize?: number; color?: string }>`
  flex: 1;
  padding: 6px;
  text-align: center;
  font-size: 14px;
  line-height: 23px;
  font-size: ${(props) => (props.fontSize ? props.fontSize : 16)}px;

  margin-left: -1px;
  margin-top: -1px;

  &:last-child {
    border-width: 0px;
  }

  ${(props) =>
    props.color &&
    css`
      color: ${props.color};
    `}
  ${(props) =>
    props.hasLink &&
    css`
      text-decoration-line: underline;
      color: #1b58f1;
    `}
`;

const LoadingContainer = styled.div`
  text-align: center;
  padding: 40px;
  width: 100%;
`;
