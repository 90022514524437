import { getHeaderWithToken, serverApi } from "./serverApi";
import {
  CreateChangeToCompanyUser,
  CreateCompanySignUp,
  CreateUserRequestType,
} from "types/types";

/** 회원가입 */
export const signUp = async (
  body: CreateUserRequestType | CreateCompanySignUp
) => {
  return serverApi
    .post("/user/signup", body)
    .then((response: any) => response.data);
};

/** 본인인증 */
export const userCerification = async (body: { imp_uid: string }) => {
  return serverApi
    .post("/user/certification/", body)
    .then((response: any) => response.data);
};

/** 로그인 */
export const signIn = async (body: { email: string; password: string }) => {
  var bodyFormData = new FormData();
  bodyFormData.append("email", body.email);
  bodyFormData.append("password", body.password);
  return serverApi
    .post("/user/signin", bodyFormData)
    .then((response: any) => response.data);
};

/** 로그아웃 */
export const signOut = async () => {
  return serverApi
    .post("/user/signout", getHeaderWithToken())
    .then((response: any) => response.data);
};

/** 비밀번호 변경 */
export const changePassword = async (body: {
  current_password: string;
  new_password: string;
}) => {
  return serverApi
    .post("/user/change-password", body, getHeaderWithToken())
    .then((response: any) => response.data);
};

/** 토큰 갱신 */
export const refreshToken = async () => {
  return serverApi
    .post("/user/token-refresh", getHeaderWithToken())
    .then((response: any) => response.data);
};
/** 기업유저 전환요청 */
export const changeToCompanyUser = async (data: CreateChangeToCompanyUser) => {
  var bodyFormData = new FormData();
  bodyFormData.append("company_name", data.company_name);
  bodyFormData.append("ceo_name", data.ceo_name);
  bodyFormData.append("registry_number", data.registry_number);
  bodyFormData.append("industry_category1", data.industry_category1);
  bodyFormData.append("industry_category2", data.industry_category2);
  bodyFormData.append("company_address", data.company_address);
  bodyFormData.append("manager_name", data.manager_name);
  bodyFormData.append("file_paper", data.file_paper);
  bodyFormData.append("employment_certificate", data.employment_certificate);

  bodyFormData.append(
    "is_employment_ceo",
    data.is_employment_ceo ? "True" : "False"
  );

  return serverApi
    .post("/user/company/signup", bodyFormData, getHeaderWithToken())
    .then((response: any) => response.data);
};

/** 이메일 중복체크 */
export const checkDuplicateEmail = async (email: string) => {
  const body = { email: email };
  return serverApi
    .post("/user/validate_email/", body)
    .then((response: any) => response.data);
};

/** 휴대폰번호 중복체크 */
export const checkDuplicatePhone = async (phoneNumber: string) => {
  const body = {
    phone_number: phoneNumber,
  };
  return serverApi
    .post("/user/validate_phone_number/", body)
    .then((response: any) => response.data);
};

/** 이메일 찾기 */
export const searchEmail = async (
  username: string,
  impUid: string,
  phoneNumber: string
) => {
  const body = {
    req_type: "email",
    name: username,
    phone_number: phoneNumber,
    imp_uid: impUid,
  };
  return serverApi
    .post("/user/search/", body)
    .then((response: any) => response.data);
};

/** 비밀번호 초기화 */
export const resetPassword = async (
  username: string,
  impUid: string,
  phoneNumber: string,
  email: string,
  newPassword: string
) => {
  const body = {
    req_type: "password",
    name: username,
    phone_number: phoneNumber,
    imp_uid: impUid,
    email: email,
    new_password: newPassword,
  };
  return serverApi
    .post("/user/change-password", body)
    .then((response: any) => response.data);
};

/** 유저 정보 불러오기 */
export const fetchUserInfo = async () => {
  return serverApi
    .get("/user/info/", getHeaderWithToken())
    .then((response: any) => response.data);
};

/** 회원탈퇴 */
export const withdrawal = async () => {
  return serverApi
    .delete("/user/withdrawal", getHeaderWithToken())
    .then((response: any) => response.data);
};
