import { MEDIA_QUERY } from "properties/constant";
import styled from "styled-components";
import { WalletType } from "types/types";

interface Props {
  amount: number;
  wallet: WalletType | null;
  onChangeAmount: (amount: number) => void;
}

export const ChargeAmount: React.FC<Props> = ({
  amount,
  wallet,
  onChangeAmount,
}) => {
  const getCount = (price: number | undefined) => {
    return (price ? Math.ceil(amount / price) : 0).toLocaleString();
  };
  return (
    <Container>
      <h3>포인트 충전하기</h3>

      <AmountInputContainer>
        <input
          placeholder="최소 충전 금액은 10000원입니다"
          type="number"
          min={10000}
          onChange={(e) => onChangeAmount(Number(e.target.value))}
          value={amount === 0 ? "" : amount}
        />
        <div className="amountUnit">원</div>
      </AmountInputContainer>
      <AmountSelections>
        <li onClick={() => onChangeAmount(amount + 50000)}>+ 5만</li>
        <li onClick={() => onChangeAmount(amount + 100000)}>+ 10만</li>
        <li onClick={() => onChangeAmount(amount + 500000)}>+ 50만</li>
        <li onClick={() => onChangeAmount(amount + 1000000)}>+ 100만</li>
        <li onClick={() => onChangeAmount(0)}>정정</li>
      </AmountSelections>
      <FinalPaymnetAountContainer>
        <h5>최종 결제 금액</h5>
        <AmountContainer>
          <FinalPaymentAmount>{amount.toLocaleString()}</FinalPaymentAmount>
          <AmountUnit>원</AmountUnit>
        </AmountContainer>
      </FinalPaymnetAountContainer>
      <div className="priceContainer">
        <div className="priceSection">
          <PriceType>
            적용단가 <span>(부가세별도)</span>
          </PriceType>
          <ul>
            <li>
              <div>단문 (SMS)</div>
              <div>{wallet?.membership.sms}원</div>
            </li>
            <li>
              <div>장문 (LMS)</div>
              <div>{wallet?.membership.lms}원</div>
            </li>
            <li>
              <div>포토 (MMS)</div>
              <div>{wallet?.membership.mms}원</div>
            </li>
            <li>
              <div>비즈문자 (SMS)</div>
              <div>{wallet?.membership.sms_biz}원</div>
            </li>
            <li>
              <div>비즈문자 (LMS)</div>
              <div>{wallet?.membership.lms_biz}원</div>
            </li>
            <li>
              <div>비즈문자 (MMS)</div>
              <div>{wallet?.membership.mms_biz}원</div>
            </li>
            <li>
              <div>
                비즈문자 (MMS) <br />
                <span>다중 카드 슬라이드</span>
              </div>
              <div>{wallet?.membership.mms_multi}원</div>
            </li>
          </ul>
        </div>
        <div className="priceSection">
          <PriceType>발송가능건</PriceType>
          <ul>
            <li>
              <div>단문 (SMS)</div>
              <div>
                <b>{getCount(wallet?.membership.sms)}건</b>
              </div>
            </li>
            <li>
              <div>장문 (LMS)</div>
              <div>
                <b>{getCount(wallet?.membership.lms)}건</b>
              </div>
            </li>
            <li>
              <div>포토 (MMS)</div>
              <div>
                <b>{getCount(wallet?.membership.mms)}건</b>
              </div>
            </li>
            <li>
              <div>비즈문자 (SMS)</div>
              <div>
                <b>{getCount(wallet?.membership.sms_biz)}건</b>
              </div>
            </li>
            <li>
              <div>비즈문자 (LMS)</div>
              <div>
                <b>{getCount(wallet?.membership.lms_biz)}건</b>
              </div>
            </li>
            <li>
              <div>비즈문자 (MMS)</div>
              <div>
                <b>{getCount(wallet?.membership.mms_biz)}건</b>
              </div>
            </li>
            <li>
              <div>
                비즈문자 (MMS) <br />
                <span>다중 카드 슬라이드</span>
              </div>
              <div>
                <b>{getCount(wallet?.membership.mms_multi)}건</b>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </Container>
  );
};
const Container = styled.div`
  .priceContainer {
    background: #fafafa;
    padding: 24px;
    display: flex;

    @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
      flex-direction: column;
      gap: 60px;
    }
    li {
      display: flex;
      gap: 42px;
      padding-bottom: 12px;

      div {
        flex: 1;

        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        letter-spacing: -1px;
      }
      div:nth-child(1) {
        text-align: right;
        color: #666666;
        span {
          font-size: 14px;
        }
      }
      div:nth-child(2) {
        color: #333333;
      }
    }
  }

  .priceSection {
    flex: 1;
  }
`;

const AmountInputContainer = styled.div`
  border: 1px solid #d7dbe2;
  border-radius: 6px;
  display: flex;
  padding: 14px 24px;
  gap: 8px;
  justify-content: flex-end;
  align-items: center;
  color: #333333;

  input {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -1px;
    text-align: right;
    outline: none;

    width: 100%;
    &::placeholder {
      content: attr(placeholder);
      color: #acacaccf;

      /* padding-right: 50px; */
      /* margin-right: 10px; */
    }
  }
`;

const AmountSelections = styled.ul`
  display: flex;
  padding: 10px 0px 24px;
  gap: 5px;

  li {
    flex: 1;
    text-align: center;
    padding: 10px 24px;
    background: #f4f4f4;
    border: 0.5px solid #d7dbe2;
    border-radius: 6px;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -1px;
    color: #333333;
    cursor: pointer;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-bottom: 40px;

    li {
      padding: 10px;
    }
  }
`;

const AmountContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

const FinalPaymentAmount = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 47px;
  letter-spacing: -1px;
  color: #1b58f1;
`;

const FinalPaymnetAountContainer = styled.div`
  background: rgba(27, 88, 241, 0.08);
  padding: 34px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AmountUnit = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -1px;
  color: #333333;
`;

const PriceType = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -1px;
  color: #333333;
  padding-bottom: 16px;
  text-align: center;
  width: 219px;
  border-bottom: 0.5px solid #999999;
  margin: 0 auto 16px;

  span {
    color: #999999;
  }
`;
