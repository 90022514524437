import "react-calendar/dist/Calendar.css";
import { useContext, useState } from "react";
import styled from "styled-components";
import Calendar from "react-calendar";
import dayjs from "dayjs";
import { ModalLayout } from "components";
import { DATE_FORMAT_1, DATE_FORMAT_2, changeDateFormat } from "utils/datetime";
import { observer } from "mobx-react-lite";
import MessageStore from "stores/MessageStore";

interface Props {
  setVisibleModal: (visible: boolean) => void;
}

export const ModalReservation: React.FC<Props> = observer(
  ({ setVisibleModal }) => {
    const messageStore = useContext(MessageStore);
    const now = dayjs();
    const defaultDatetime = messageStore.reservedDatetime
      ? dayjs(messageStore.reservedDatetime)
      : dayjs();
    const [targetDate, setTargetDate] = useState<Date>(
      defaultDatetime.toDate()
    );
    const [targetTime, setTargetTime] = useState<string>(
      defaultDatetime.format("HH:mm")
    );

    const onClickDate = (date) => {
      setTargetDate(date);
    };

    const onClickSaveReservation = () => {
      const tenMinuteAfter = now.add(10, "minute");
      const formattedDate = dayjs(targetDate).format(DATE_FORMAT_2);
      const isBefore = dayjs(`${formattedDate} ${targetTime}`).isAfter(
        tenMinuteAfter
      );

      if (isBefore) {
        messageStore.setReservedDatetime(`${formattedDate} ${targetTime}`);
        messageStore.setIsReservation(true);
        setVisibleModal(false);
      } else {
        alert("현재 시간에서 10분 후부터 예약 가능합니다.");
      }
    };

    const onClickCancellation = () => {
      setVisibleModal(false);
    };

    return (
      <ModalLayout title="예약발송 설정" onClose={() => setVisibleModal(false)}>
        <Container>
          <Calendar
            onChange={onClickDate}
            value={targetDate}
            minDate={now.toDate()}
            formatDay={(locale, date) => dayjs(date).format("D")}
            locale="KR-ko"
          />
          <DateContainer>
            <Label>발송 날짜</Label>
            <BorderRadius>
              {changeDateFormat(targetDate.toDateString(), DATE_FORMAT_1)}
            </BorderRadius>
          </DateContainer>
          <Label>발송 시간</Label>

          <TimeInputContainer>
            <BorderRadius>
              <InputTime
                type="time"
                onChange={(e) => setTargetTime(e.target.value)}
                value={targetTime}
              />
            </BorderRadius>
          </TimeInputContainer>

          <Flex>
            <ButtonOutline onClick={onClickCancellation}>취소</ButtonOutline>
            <ButtonPrimary onClick={onClickSaveReservation}>
              예약하기
            </ButtonPrimary>
          </Flex>
        </Container>
      </ModalLayout>
    );
  }
);

const Container = styled.div`
  padding: 40px 0px 0px;
  overflow-y: auto;
  max-height: 90vh;

  .react-calendar {
    width: 100%;
    border: none;
    font-family: "Noto Sans KR";
    background: #fafafa;
  }
  .react-calendar__tile:disabled {
    color: #999999;
    background: #fafafa;
  }
  .react-calendar__navigation__label__labelText {
    font-weight: 700;
    font-size: 20px;
    line-height: 29px;
    letter-spacing: -1px;

    color: #333333;
  }
  .react-calendar__navigation button:disabled {
    visibility: hidden;
  }
  .react-calendar__navigation button {
    &:hover {
      background-color: inherit;
    }
  }

  .react-calendar__navigation__arrow {
    font-size: 28px;
    margin-bottom: 10px;
  }
  .react-calendar__navigation__next2-button,
  .react-calendar__navigation__prev2-button {
    display: none;
  }
  .react-calendar__month-view__weekdays__weekday {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: -1px;
    color: #666666;
    abbr[title] {
      text-decoration: none;
    }
  }
  .react-calendar__month-view__days__day {
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: -1px;
    color: #333333;
  }

  .react-calendar__tile--active {
    background: #1b58f1 !important;
    color: white !important;
    border-radius: 100px;
  }

  .react-calendar__tile--active:enabled:hover
    .react-calendar__tile--active:enabled:focus {
    background: #1b58f1;
  }

  .react-calendar__tile--now,
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    color: #999999;
    background: #fafafa;
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    visibility: hidden;
  }
`;

const DateContainer = styled.div`
  padding: 28px 0px 16px;
`;
const Label = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;
  letter-spacing: -1px;
  color: #333333;
  padding-bottom: 8px;
`;

const BorderRadius = styled.div`
  border: 1px solid #333333;
  border-radius: 6px;
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
`;

const TimeInputContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  gap: 10px;
`;

const Button = styled.div`
  border-radius: 100px;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -1px;
  cursor: pointer;
  flex: 1;
  padding: 14px;
  text-align: center;
  margin-top: 40px;
`;

const ButtonOutline = styled(Button)`
  border: 0.5px solid #333333;
  color: #333333;
`;

const ButtonPrimary = styled(Button)`
  background: #1b58f1;
  color: #ffffff;
`;

const InputTime = styled.input`
  outline: none;
  width: 150px;
`;
