import { ResultGroupType } from "types/types";
import { changeDateFormat, DATETIME_FORMAT_2 } from "utils/datetime";
import * as S from "./styled";
import { useCustomNavigate } from "hooks/useCustomNavigate";
import { PATH } from "properties/menu";
import { Loading } from "components/Icons";

interface Props {
  groups: ResultGroupType[] | null;
}

export const MessageResultGroup = ({ groups }: Props) => {
  const navigate = useCustomNavigate();

  const onClickResultGroup = (group: ResultGroupType) => {
    navigate.moveTo(
      PATH.MESSAGE_SEND_RESULT_DETAIL.replace(
        ":groupId",
        group.group_id.toString()
      )
    );
  };
  const getCreatedDate = (group: ResultGroupType) => {
    return changeDateFormat(group.created, DATETIME_FORMAT_2);
  };

  return (
    <S.Container>
      <S.TBody>
        <S.TR>발송일자</S.TR>
        <S.TR isLeft={true}>제목</S.TR>
        <S.TR>타입</S.TR>
        <S.TR>전송건수</S.TR>
        <S.TR>성공</S.TR>
        <S.TR>실패</S.TR>
        <S.TR>대기</S.TR>
      </S.TBody>

      {groups && groups.length > 0 ? (
        groups.map((group) => {
          return (
            <S.TRow key={`m-result-${group.id}`}>
              <S.TD>{getCreatedDate(group)}</S.TD>
              <S.TD
                isLeft={true}
                hasLink={true}
                onClick={() => onClickResultGroup(group)}
              >
                {group.title}
              </S.TD>
              <S.TD>{group.msg_type}</S.TD>
              <S.TD>{group.status_info.total_count}</S.TD>
              <S.TD>{group.status_info.success_count}</S.TD>
              <S.TD>{group.status_info.failure_count}</S.TD>
              <S.TD>{group.status_info.wait_count}</S.TD>
            </S.TRow>
          );
        })
      ) : (
        <>
          {groups ? (
            <S.NoData>발송 내역이 없습니다.</S.NoData>
          ) : (
            <S.LoadingContainer>
              <Loading width={50} height={50} />
            </S.LoadingContainer>
          )}
        </>
      )}
    </S.Container>
  );
};
