import { Label } from "components/Label";
import styled from "styled-components";

interface Props {
  label: string;
  type?: string;
  placeholder: string;
  isFocused?: boolean;
  value: string;
  isLabelBold?: boolean;
  isGreyBg?: boolean;
  onInputChange: (element: any) => void;
  onEnter?: () => void;
}
export const InputWithLabel = ({
  label,
  type = "text",
  placeholder,
  value,
  isLabelBold,
  isFocused = false,
  isGreyBg = false,
  onInputChange,
  onEnter,
}: Props) => {
  return (
    <InputContainer>
      <Label isBold={isLabelBold}>{label}</Label>
      <Input
        greyBg={isGreyBg}
        active={value.length > 0}
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={(e) => onInputChange(e.target.value)}
        autoFocus={isFocused}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onEnter && onEnter();
          }
        }}
      />
    </InputContainer>
  );
};

const InputContainer = styled.div`
  margin-bottom: 28px;
`;

const Input = styled.input<{ active: boolean; greyBg: boolean }>`
  background: ${(props) => (props.greyBg ? "#fafafa" : "#ffffff")};
  border: 1px solid #333333;
  border-radius: 4px;
  padding: 16px;
  width: 100%;
  height: 56px;

  &::placeholder {
    color: #999999;
  }
`;
