import { useContext, useEffect, useState } from "react";

import * as S from "./styled";
import { ManageAddressList } from "./ManageAddressList/index";
import { InputWithSearch, ConfirmModal, MessageLayout } from "components";
import { GroupList } from "./components/GroupList";
import { AddressGroupType } from "types/types";

import { Input } from "styles/styled";
import AddressBookStore from "stores/AddressBookStore";
import { observer } from "mobx-react-lite";

export const AddressBook = observer(() => {
  const store = useContext(AddressBookStore);
  const [loading, setLoading] = useState(false);
  const [fiteredGroups, setFilteredGroups] = useState<
    AddressGroupType[] | null
  >(null);
  const [searchedGroupName, setSearchedGroupName] = useState<string>("");
  const [newGroupName, setNewGroupName] = useState<string>("");
  const [visibleRemomveGroupModal, setVisibleRemoveGroupModal] =
    useState<boolean>();

  useEffect(() => {
    setFilteredGroups(store.addressBookGroups);
  }, [store.addressBookGroups]);

  useEffect(() => {
    if (store.addressBookGroups && store.addressBooks) {
      store.initAddressBookPage();
    } else {
      loadAddressBooks();
    }
  }, []);

  const loadAddressBooks = async () => {
    setLoading(true);
    await store.fetchAddressBookGroups();
    await store.fetchAddressBooks();
    setLoading(false);
  };
  /**
   * 그룹 검색
   */
  const onClickSearchButton = () => {
    const filteredGroups =
      store.addressBookGroups?.filter((group) =>
        group.group_name.includes(searchedGroupName)
      ) || [];
    setFilteredGroups(filteredGroups);
  };

  const onChangeNewGroupName = (e) => {
    setNewGroupName(e.target.value);
  };

  const onHandleremoveGroup = (groupId: number) => {
    store.removeGroup(groupId);
    setVisibleRemoveGroupModal(false);
  };

  return (
    <MessageLayout title="">
      <>
        <S.Inner>
          {/* -------- Header --------- */}
          <S.Header>
            <S.SubTitle>주소록 관리</S.SubTitle>
            <S.Descriptions>
              <S.Li>수신자 번호를 등록하고 관리할 수 있는 기능입니다.</S.Li>
              <S.Li>
                메세지 발송 시, 등록한 주소록을 불러와 발송할 수 있습니다.
              </S.Li>
            </S.Descriptions>
          </S.Header>
          {/* -------- 그룹 관리 섹션 --------- */}

          <S.Flex>
            <section id="manageAddressGroup">
              <S.GroupManageContainer>
                <h4>그룹 관리</h4>

                <InputWithSearch
                  placeholder={"그룹명 검색"}
                  value={searchedGroupName}
                  onInputChange={setSearchedGroupName}
                  onClickSearchButton={onClickSearchButton}
                />

                <S.Line />
                <S.GroupSearchContainer>
                  <Input
                    width={120}
                    placeholder="그룹명 입력"
                    onChange={onChangeNewGroupName}
                    value={newGroupName}
                    onKeyDown={(e) => {
                      if (
                        e.key === "Enter" &&
                        e.nativeEvent.isComposing === false
                      ) {
                        e.preventDefault();
                        e.stopPropagation();
                        store.onCreateNewGroup(newGroupName, () =>
                          setNewGroupName("")
                        );
                      }
                    }}
                  />

                  <S.PrimaryButton
                    onClick={() =>
                      store.onCreateNewGroup(newGroupName, () =>
                        setNewGroupName("")
                      )
                    }
                  >
                    추가
                  </S.PrimaryButton>
                </S.GroupSearchContainer>
                {/* -------- 그룹 리스트 --------- */}
                <GroupList
                  groups={fiteredGroups}
                  activeGroup={store.activeGroup}
                  setActiveGroup={store.setActiveGroup}
                  setVisibleRemoveGroupModal={setVisibleRemoveGroupModal}
                />
              </S.GroupManageContainer>
            </section>

            {/* -------- 그룹 리스트 --------- */}
            <section id="manageAddressBook" style={{ flex: 1 }}>
              <ManageAddressList />
            </section>
          </S.Flex>
        </S.Inner>

        {/* -------- 모달  --------- */}
        {visibleRemomveGroupModal && store.activeGroup && (
          <ConfirmModal
            title="그룹 삭제"
            confirmButtonText="그룹 삭제"
            confirmButtonColor="#EC1C24"
            closeModal={() => setVisibleRemoveGroupModal(false)}
            onConfirm={() => onHandleremoveGroup(store.activeGroup!.id)}
          >
            <div>
              <b>{store.activeGroup.group_name}</b>을 삭제하시겠습니까? <br />
              그룹 내 {store.activeGroup.total_count}개의 번호가 삭제되며
              <br />
              삭제된 번호는 복구할 수 없습니다.
            </div>
          </ConfirmModal>
        )}
      </>
    </MessageLayout>
  );
});
