import ButtonSquare from "components/ButtonSquare";
import { FlexWrapper } from "components/Flex";
import InputText from "components/InputText";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { StoreAddressType } from "types/types";

const Container = styled.div``;

interface IProps {
  onChange: (addressInfo: StoreAddressType) => void;
}

const InputAddress = observer(({ onChange }: IProps) => {
  const [address, setAddress] = useState("");
  const [zonecode, setZonecode] = useState("");
  const [detailAddress, setDetailAddress] = useState("");

  const onClickAddressInput = useCallback(() => {
    let daum = window["daum"];
    new daum.Postcode({
      oncomplete: function (data) {
        setAddress(data.address);
        setZonecode(data.zonecode);
      },
    }).open();
  }, []);

  const onHandleChangeAddressDetail = useCallback(
    (target: HTMLInputElement) => {
      setDetailAddress(target.value);
    },
    []
  );

  useEffect(() => {
    onChange({
      address,
      zonecode,
      detail: detailAddress,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, zonecode, detailAddress]);

  return (
    <Container>
      <FlexWrapper
        css={{
          height: "56px",
        }}
      >
        <InputText
          width="173px"
          onChange={() => {}}
          value={zonecode}
          placeholder=""
          bgColor={zonecode !== "" ? "white" : "#FAFAFA"}
        />
        <ButtonSquare
          bgColor="#333"
          fontColor="white"
          margin="0 8px 0 8px"
          onClick={onClickAddressInput}
        >
          주소검색
        </ButtonSquare>
        <ButtonSquare>삭제</ButtonSquare>
      </FlexWrapper>
      <InputText
        width="100%"
        onChange={() => {}}
        value={address}
        margin="16px 0 0 0"
      />
      <InputText
        width="100%"
        onChange={onHandleChangeAddressDetail}
        value={detailAddress}
        name="address-detail"
        margin="16px 0 80px 0"
        placeholder="상세주소를 입력해주세요"
      />
    </Container>
  );
});

export default InputAddress;
