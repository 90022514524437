import styled from "styled-components";
import { SmsType, StatisticsType } from "types/types";
import { getStatisticsInit } from "utils/statistics";

interface Props {
  smsTypeOptions: SmsType[];
  sendStatistics: StatisticsType | null;
}
export const UsageStat: React.FC<Props> = ({
  smsTypeOptions,
  sendStatistics,
}) => {
  const statistics = getStatisticsInit(sendStatistics);

  return (
    <InnerContainer>
      <SubTitle>이번달 사용통계</SubTitle>
      <EmContainer>
        <div>
          <b>총 발송건수</b>
        </div>
        <div>
          <b>{statistics.total_count}</b> 건
        </div>
      </EmContainer>
      {/*SMS 타입별 총 발송건수 */}
      <ul>
        {smsTypeOptions.map((smsType, index) => {
          const totalCount = statistics[smsType.uppercaseKey].total_count;
          return (
            <Li key={index} active={totalCount > 0}>
              <div>{smsType.label}</div>
              <div>
                <b>{totalCount}</b> 건
              </div>
            </Li>
          );
        })}
      </ul>
    </InnerContainer>
  );
};

const SubTitle = styled.h4`
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  letter-spacing: -1px;
  color: #333333;
  padding-bottom: 16px;
`;

const InnerContainer = styled.section`
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 24px;
  flex: 1;
`;
const EmContainer = styled.div`
  background: rgba(27, 88, 241, 0.08);
  border-radius: 10px;
  padding: 20px 16px;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  line-height: 26px;
  align-items: center;
  letter-spacing: -1px;
  color: #1b58f1;
  margin-bottom: 24px;

  b {
    font-weight: 700;
  }
`;

const Li = styled.li<{ active: boolean }>`
  padding: 0 16px;
  letter-spacing: -1px;
  color: ${(props) => (props.active ? "#333333" : "#999999")};
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  padding-bottom: 12px;
  display: flex;
  justify-content: space-between;
`;
