import { useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import WalltetStore from "stores/WalltetStore";

import { UsageStat } from "./UsageStat";
import { smsTypeOptions } from "properties/content";
import { MyPricingPlan } from "./MyPricingPlan";

import { SendResult } from "./SendResult";
import { DashboardLayout } from "components";

export const Dashaboard = observer(() => {
  const walletStore = useContext(WalltetStore);

  useEffect(() => {
    walletStore.fetchStatistics();
  }, [walletStore]);

  return (
    <DashboardLayout title="대시보드">
      <Container>
        <TwoColumn>
          {/* 이번달 사용통계 */}
          <UsageStat
            smsTypeOptions={smsTypeOptions}
            sendStatistics={walletStore.stats}
          />
          {/* 나의 요금제 */}
          <MyPricingPlan
            smsTypeOptions={smsTypeOptions}
            wallet={walletStore.wallet}
          />
        </TwoColumn>
        {/* 이번달 발송결과 */}
        <SendResult sendStatistics={walletStore.stats} />
      </Container>
    </DashboardLayout>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 800px;
`;

const TwoColumn = styled.div`
  display: flex;
  gap: 24px;
  margin-bottom: 24px;
`;
