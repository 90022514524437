import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { convertPhoneOnlyNumber, validatePhoneNumberOnly } from "utils/phone";
import { SubTitle } from "../../styled";
import * as S from "./styled";
import { ReceiverListItem } from "./ReceiverListItem";
import { AddressBookModal } from "../modals/AddressBookModal";
import {
  AddressBookType,
  AddressGroupType,
  SendReceiverAddressType,
} from "types/types";
import MessageStore from "stores/MessageStore";
import { Flex } from "styles/styled";
import { BulkAddressModal } from "../modals/BulkAddressModal";
import { AlertModal } from "components";

interface Props {
  receivers: SendReceiverAddressType[];
  receiverGroups: AddressGroupType[];
}
export const ManageReceiver = ({ receivers, receiverGroups }: Props) => {
  const messageStore = useContext(MessageStore);
  const phoneInputRef = useRef<HTMLInputElement>(null);

  const [totalReceiverCount, setReceiverTotalCount] = useState<number>(0);
  const [visibleAddressModal, setVisibleAddressBookModal] =
    useState<boolean>(false);
  const [visibleBulkAddresskModal, setVisibleModal] = useState<boolean>(false);
  const [selectedAddressBooks, setSelectedAddressBooks] = useState<
    AddressBookType[]
  >([]);
  const [visibleAlertModal, setVisibleAlertModal] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");

  useEffect(() => {
    const groupReceiverCount = receiverGroups.reduce((acc, cur) => {
      return acc + cur.total_count;
    }, 0);

    setReceiverTotalCount(receivers.length + groupReceiverCount);
  }, [receivers, receiverGroups]);

  const onCloseAddressBookModal = useCallback(() => {
    setVisibleAddressBookModal(false);
  }, []);

  const onClickAddReceiverPhone = useCallback(() => {
    let phoneNumber = phoneInputRef.current!.value;
    if (phoneNumber.includes("-")) {
      phoneNumber = convertPhoneOnlyNumber(phoneNumber);
    }

    if (receivers.find((receiver) => receiver.phone_number === phoneNumber)) {
      setAlertMessage("이미 추가된 번호입니다.");
      setVisibleAlertModal(true);
      return;
    } else if (!validatePhoneNumberOnly(phoneNumber)) {
      setAlertMessage("번호 형식을 확인해주세요.");
      setVisibleAlertModal(true);
      return;
    }
    updateReceivers([...receivers, { name: "", phone_number: phoneNumber }]);
    phoneInputRef.current!.value = "";
  }, []);

  const updateReceivers = useCallback(
    (receivers: SendReceiverAddressType[]) => {
      messageStore.setReceivers(receivers);
    },
    []
  );

  const updateReceiverGroups = useCallback((groups: AddressGroupType[]) => {
    messageStore.setReceiverGroups(groups);
  }, []);

  const onRemoveReceiver = useCallback((phoneNumber: string) => {
    updateReceivers(
      receivers.filter((receiver) => receiver.phone_number !== phoneNumber)
    );
  }, []);

  const onRemoveReceiverGroup = useCallback((groupId: number) => {
    updateReceiverGroups(
      receiverGroups.filter((group) => group.id !== groupId)
    );
  }, []);

  const removeAllReceiver = useCallback(() => {
    updateReceivers([]);
    updateReceiverGroups([]);
  }, []);

  const onClickAddressBookName = useCallback((addressBook: AddressBookType) => {
    if (selectedAddressBooks.includes(addressBook)) {
      const newSelectedAddressBooks = selectedAddressBooks.filter(
        (book) => book.id !== addressBook.id
      );
      setSelectedAddressBooks(newSelectedAddressBooks);
    } else {
      setSelectedAddressBooks([...selectedAddressBooks, addressBook]);
    }
  }, []);

  const addGroupToReceivers = useCallback((group: AddressGroupType) => {
    updateReceiverGroups([...receiverGroups, group]);
    setVisibleAddressBookModal(false);
    setSelectedAddressBooks([]);
  }, []);

  const addAddressesToReceiver = useCallback(() => {
    if (selectedAddressBooks) {
      updateReceivers([
        ...receivers,
        ...selectedAddressBooks.map((book) => {
          return { name: book.name, phone_number: book.phone_number };
        }),
      ]);
      setVisibleAddressBookModal(false);
      setSelectedAddressBooks([]);
    }
  }, []);

  /** 주소록 일괄 등록으로 전화번호 업데이트 */
  const addBulkAddressesToReceiver = useCallback(
    (bulkReceivers: SendReceiverAddressType[]) => {
      updateReceivers([...receivers, ...bulkReceivers]);
    },
    []
  );

  return (
    <S.Container>
      <SubTitle>수신번호</SubTitle>
      <S.InputContainer>
        <S.Input
          ref={phoneInputRef}
          placeholder={`휴대폰 번호를 입력해주세요. (숫자만)`}
          onKeyUp={(e) => {
            e.key === "Enter" && onClickAddReceiverPhone();
          }}
        />
        <S.ButtonOutline onClick={onClickAddReceiverPhone}>
          번호 추가
        </S.ButtonOutline>
      </S.InputContainer>
      {/* 주소록  */}
      <S.ContainerReceiver>
        <div className="inner">
          {receiverGroups.map((group, index) => (
            <ReceiverListItem
              key={`phone-${index}`}
              phoneNumber={`${group.group_name} (${group.total_count})`}
              onRemove={() => onRemoveReceiverGroup(group.id)}
            />
          ))}

          {receivers.map((receiver, index) => (
            <ReceiverListItem
              key={`phone-${index}`}
              phoneNumber={receiver.phone_number}
              onRemove={() => onRemoveReceiver(receiver.phone_number)}
            />
          ))}
          <S.BottomContainer>
            <S.Count>총 {totalReceiverCount}명</S.Count>
            <span>|</span>
            <S.RemoveAllButton onClick={removeAllReceiver}>
              전체삭제
            </S.RemoveAllButton>
          </S.BottomContainer>
        </div>
      </S.ContainerReceiver>

      <S.ButtonContainer gap={4}>
        <S.ButtonOutline onClick={() => setVisibleModal(true)}>
          일괄 등록하기
        </S.ButtonOutline>
        <S.ButtonOutline onClick={() => setVisibleAddressBookModal(true)}>
          주소록 불러오기
        </S.ButtonOutline>
      </S.ButtonContainer>

      {visibleAddressModal && (
        <AddressBookModal
          selectedAddressBooks={selectedAddressBooks}
          addGroupToReceivers={addGroupToReceivers}
          addAddressesToReceiver={addAddressesToReceiver}
          onClickAddressBookName={onClickAddressBookName}
          onClose={onCloseAddressBookModal}
        />
      )}
      {visibleBulkAddresskModal && (
        <BulkAddressModal
          receivers={receivers}
          addBulkAddressesToReceiver={addBulkAddressesToReceiver}
          openAlertModal={() => setVisibleAlertModal(true)}
          setAlertMessage={setAlertMessage}
          onClose={() => setVisibleModal(false)}
        />
      )}
      {visibleAlertModal && (
        <AlertModal
          title={"알림"}
          confirmButtonText={"확인"}
          closeModal={() => setVisibleAlertModal(false)}
        >
          <div>{alertMessage}</div>
        </AlertModal>
      )}
    </S.Container>
  );
};
