import { SVGProps } from "react";

export const Close = ({
  width = 30,
  height = 30,
  fill = "#333333",
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="4.6875"
        y="3.75"
        width="30"
        height="1.875"
        rx="0.9375"
        transform="rotate(45 4.6875 3.75)"
        fill={fill}
      />
      <rect
        x="26.25"
        y="4.6875"
        width="30"
        height="1.875"
        rx="0.9375"
        transform="rotate(135 26.25 4.6875)"
        fill={fill}
      />
    </svg>
  );
};
