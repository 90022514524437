import styled from "styled-components";

export const Container = styled.div``;

export const GroupSelection = styled.div`
  cursor: pointer;
  display: flex;
  border: 1px solid #d7dbe2;
  border-radius: 4px;
  flex: 1;
  align-items: center;
  padding: 0 8px;
  justify-content: space-between;
  position: relative;
`;

export const Option = styled.div<{ isPlaceholder?: boolean }>`
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;
  letter-spacing: -1px;

  padding: 16px 10px;

  color: ${(props) => (props.isPlaceholder ? "#999999" : "#333333")};
`;
