import styled from "styled-components";
import {
  ImgPricingHeader1,
  ImgPricingHeader2,
  ImgPricingHeader3,
  ImgPlusPrimary,
} from "assets";
import { MEDIA_QUERY } from "properties/constant";
import { SectionTitle } from "./styled";
import { Pad } from "styles/styled";

export const Benefit = () => {
  return (
    <Container>
      <Pad pb={60}>
        <SectionTitle>
          <div>비즈포스트 고객은</div>
          <b>추가 혜택 더 가져가세요!</b>
        </SectionTitle>
      </Pad>

      <HeaderCardContainer>
        <HeaderCard>
          <img src={ImgPricingHeader1} alt="pricing" />
          <div>클릭률이 올라가는</div>
          <div>메시지 문구 컨설팅 제공</div>
        </HeaderCard>
        <IconPlus src={ImgPlusPrimary} alt="plus" />
        <HeaderCard>
          <img src={ImgPricingHeader2} alt="일반문자 LMS,SMS" />
          <div>일반문자 SMS, LMS, MMS</div>
          <div>500포인트 무료 지급</div>
        </HeaderCard>
        <IconPlus src={ImgPlusPrimary} alt="plus" />
        <HeaderCard>
          <img src={ImgPricingHeader3} alt="차세대 스마트 비즈메세지" />
          <div>차세대 스마트 비즈메세지</div>
          <div>템플릿 무료 제공</div>
        </HeaderCard>
      </HeaderCardContainer>
    </Container>
  );
};

const Container = styled.div`
  padding: 120px 20px 100px;
  text-align: center;
`;

const HeaderCard = styled.div`
  img {
    width: 164px;
    height: 164px;
    margin-bottom: 16px;
  }
  div:nth-child(2) {
    margin-top: -10px;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;

    letter-spacing: -1px;
    color: #999999;
  }
  div:nth-child(3) {
    font-weight: 700;
    font-size: 20px;
    line-height: 29px;
    letter-spacing: -1px;
  }
`;

const HeaderCardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    flex-direction: column;
    height: auto;
  }
`;
const IconPlus = styled.img`
  width: 60px;
  height: 60px;
  margin-bottom: 50px;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    margin-bottom: 0px;
    width: 40px;
    height: 40px;
  }
`;
