import * as templateApi from "apis/templateApi";
import { observable, makeObservable, runInAction, action } from "mobx";
import { createContext } from "react";
import {
  CreatePlainTemplateType,
  TemplateType,
  UpdatePlainTemplateType,
} from "types/types";

class TemplateStore {
  @observable templates: TemplateType[] | null = null;
  @observable targetTemplate: TemplateType;
  @observable currentPage: number = 1;

  constructor() {
    makeObservable(this);
  }

  /** 템플릿 리스트 불러오기 */
  @action resetTemplate = () => {
    this.templates = null;
  };

  @action setCurrentPage = (page: number) => {
    this.currentPage = page;
  };

  @action setTargetTemplate = (template: TemplateType) => {
    this.targetTemplate = template;
  };

  @action fetchTemplates = async (isReload?: boolean) => {
    if (!this.templates || isReload) {
      runInAction(async () => {
        this.templates = null;
        const templates = await templateApi.getPlainTemplateList();
        this.templates = templates;
      });
    }
  };

  @observable templateConfiguration = {
    brandName: "",
    templateFormat: "",
    templateName: ""
  }
  @action setTemplateConfiguration = (
    target: HTMLSelectElement | HTMLInputElement,
    name?: string
  ) => {
    this.templateConfiguration = {
      ...this.templateConfiguration,
      [name]: target.value
    }
  }
  
  /** 템플릿 리스트 불러오기 */
  @action fetchTemplateDetail = async (id: number) => {
    const template = await templateApi.getPlainTemplateDetail(id);

    runInAction(() => {
      this.targetTemplate = template;
    });
  };

  @action createPlainTemplate = async (params: CreatePlainTemplateType) => {
    try {
      const response = await templateApi.createPlainTemplate(params);
      if (response) {
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  @action updatePlainTemplate = async (
    templateId: number,
    params: UpdatePlainTemplateType
  ) => {
    try {
      await templateApi.updatePlainTemplate(templateId, params);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  @action removePlainTemplate = async (templateId: number) => {
    try {
      await templateApi.removePlainTemplate(templateId);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };
}

export default createContext(new TemplateStore());
