import styled from "styled-components";
import IconsStepCheck from "assets/images/icons/ico-step-check.svg";
import { MEDIA_QUERY } from "properties/constant";

interface Props {
  steps: string[];
  activeStep?: number;
}
export const Stepper = ({ steps, activeStep = 0 }: Props) => {
  const activeIndex =
    activeStep >= 0 && activeStep < steps.length ? activeStep : 0;

  return (
    <StepBox>
      {steps.map((label, index) => (
        <Step key={`${label}_${index}`} isActive={index === activeIndex}>
          {label}
        </Step>
      ))}
    </StepBox>
  );
};

const StepBox = styled.ol`
  display: flex;
  conter-reset: item;
  margin-bottom: 40px;
  padding-bottom: 24px;
  border-bottom: 0.5px solid #999;
`;

const Step = styled.li<{ isActive?: boolean }>`
  counter-increment: item;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
  color: #333;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -1px;
  text-align: center;
  line-height: normal;

  ::before {
    content: "";
    display: block;
    position: relative;
    z-index: 1;
    width: 24px;
    height: 24px;
    margin: 0 auto 11px;
    background: url(${IconsStepCheck});
    border-radius: 100%;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    line-height: 24px;
  }

  ::after {
    content: "";
    position: absolute;
    top: 12px;
    left: 0;
    width: 100%;
    height: 1px;
    border: 1px;
    background: #1b58f1;
  }

  :first-child {
    flex-grow: 0;

    ::after {
      background: #1b58f1;
      transform: translateX(50%);
    }
  }

  :last-child {
    flex-grow: 0;

    ::after {
      background: linear-gradient(
        to right,
        ${(props) => (props.isActive ? "#1b58f1" : "#ebeef2")} 50%,
        transparent 50%
      );
    }
  }

  ${(props) =>
    props.isActive &&
    `
      color: #1B58F1;
      font-weight: 700;

      ::before {
        content: counter(item);
        background: #1B58F1;
      }

      ::after {
        background: linear-gradient(to right, #1b58f1 50%, #ebeef2 50%);
      }
      
      :first-child::after {
        background: #ebeef2;
      }

      /* active 다음 스텝 스타일 */
      ~ li {
        color: #666;

        ::before {
          content: counter(item);
          background: #EBEEF2;
          color: #C4C9D1;
        }

        ::after {
          background: #EBEEF2;
        }
      }
    `}

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 16px;
  }
`;
