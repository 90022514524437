import { Icons } from 'components';
import styled from 'styled-components';
import { ButtonRound, Caption } from 'styles/styled';


interface IProps{
  messageType?: string;
}

const NavTopBack = ({
  messageType
}:IProps) => {
  return (
    <Container>
      <LeftSection>
        <Icons.ArrowLeftSimple/>
        <Caption
          css={{
            marginLeft: "9px"
          }}
        >
          발신번호
        </Caption>
      </LeftSection>
      {
        messageType ? (
          <ButtonRound
            css={{
              width: "89px",
              height: "31px",
              border: "1px solid #D7DBE2",
              fontSize: "14px"
            }}
          >
            {messageType}
          </ButtonRound>
        ) : null
      }
    </Container>
  );
};

export default NavTopBack;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 12px 9px 7px 15px;
  box-sizing: border-box;
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;
`;