import styled, { CSSObject, css } from 'styled-components';
import { MessageLayout, ModalLayout, Padding, Pagination } from 'components';
import { PageTitle, PageTitleSmall } from '../BrandCreation';
import { MenuBar } from 'components/MenuBar';
import BrandLayout from '../BrandLayout';
import { FlexWrapper } from 'components/Flex';
import ButtonSquare from 'components/ButtonSquare';
import TBodyList, { TableLayout, THeadList } from 'components/TableLayout';
import { useCallback, useState } from 'react';
import { Router } from 'react-router';
import { PATH } from 'properties/menu';
import { useCustomNavigate } from 'hooks/useCustomNavigate';
import * as S from "styles/styled";


interface IProps{}

export const BrandHome = ({
}:IProps) => {
  const navigate = useCustomNavigate();
  const [isEnterpriseMemberModalOpen, setIsEnterpriseMemberModalOpen] = useState(false);

  const moveBrandCreationPage = useCallback(()=>{
    navigate.moveTo(PATH.BRAND_CREATION);
  },[]);

  const moveToUpgradenterprieseMember = useCallback(()=>{
    navigate.moveTo(PATH.CHANGE_COMPANY_PROFILE);
  }, []);
  return (
    <BrandLayout>
      <Container>
        <PageTitle>브랜드 관리</PageTitle>
        <Inner>
          <PaddingHorizontal>
            <PageTitleSmall>브랜드 리스트</PageTitleSmall>
            <Descriptions>
              <Li>고객님의 브랜드 목록입니다.</Li>
              <Li>브랜드를 추가하시려면 ‘브랜드 개설’ 버튼을 통해 추가 개설해주세요.</Li>
              <Li>RCS Biz Center 승인 후에 정상적으로 이용이 가능합니다.</Li>
            </Descriptions>
            <Padding height="16px"/>
            <FlexWrapper
              css={{
                justifyContent:"flex-end",
                paddingBottom:"16px"
              }}
            >
              <ButtonSquare
                bgColor="#1B58F1"
                css={{
                  height: "46px",
                  width: "106px",
                  boxSizing:"border-box",
                  flex:"none",
                  color: "white",
                  fontWeight: "bold"
                }}
                onClick={moveBrandCreationPage}
              >
                브랜드 개설
              </ButtonSquare>
            </FlexWrapper>
          </PaddingHorizontal>

          <TableLayout
            css={{
              minHeight:"416px",
              marginBottom: "16px"
            }}
          >
            <THeadList
              headList={[
                {
                  text:"번호",
                  css:{
                    minWidth:"13%",
                    maxWidth:"13%",
                  }
                },
                {
                  text:"브랜드명",
                  css:{
                    minWidth:"40%",
                    maxWidth:"40%",
                  }
                },
                {
                  text:"상태",
                  css:{
                    minWidth:"18%",
                    maxWidth:"18%",
                  }
                },
                {
                  text:"개설일"
                },
              ]}
            />
            <TBodyList>
              <tr>
                <Td
                  css={{
                    minWidth:"13%",
                    maxWidth:"13%",
                  }}
                >
                  1
                </Td>
                <Td
                  css={{
                    minWidth:"40%",
                    maxWidth:"40%",
                  }}
                >
                  비즈포스트00
                </Td>
                <Td
                  css={{
                    minWidth:"18%",
                    maxWidth:"18%",
                  }}
                >
                  검수중
                </Td>
                <Td>
                  2023.01.01
                </Td>
              </tr>
            </TBodyList>
          </TableLayout>
          <Pagination
            size={1}
            defaultPage={1}
            setCurrentPage={()=>{}}
            arrowColor="#999999"
          />
        </Inner>

        {/* 기업회원 전환 모달 */}
        {
          isEnterpriseMemberModalOpen ? (
            <ModalLayout
              title="기업회원 전환"
              onClose={()=>
                setIsEnterpriseMemberModalOpen(false)
              }
            >
              <S.SubHeading
                css={{
                  lienHeight:"26px",
                  color: "#666",
                  margin: "20px 0 42px"
                }}
              >
                기업회원 승인 후 브랜드 개설이 가능합니다.<br/>
                기업회원 승인상태를 확인하세요.
              </S.SubHeading>
              <FlexWrapper>
                <S.ButtonRound
                  css={{
                    border: "1px solid #333",
                    fontWeight: "normal",
                    flex:"1",
                    marginRight:"10px"
                  }}
                  onClick={()=>
                    setIsEnterpriseMemberModalOpen(false)
                  }
                >
                  나중에
                </S.ButtonRound>
                <S.ButtonRound
                  css={{
                    backgroundColor:"#1B58F1",
                    color:"white",
                    flex:"1"
                  }}
                  onClick={moveToUpgradenterprieseMember}
                >
                  기업회원 전환
                </S.ButtonRound>
              </FlexWrapper>
            </ModalLayout>
          ) : null
        }
        
      </Container>
    </BrandLayout>
  );
};


const Container = styled.div`
  padding: 48px 40px;
  width: fit-content;
  min-width: 820px;
`;
const PaddingHorizontal = styled.div`
  padding: 0 24px;
`;

export const Inner = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 24px 0 0;
`;

const Descriptions = styled.div`
  padding-bottom: 24px;
`;

const Li = styled.div`
  display: flex;
  padding-bottom: 8px;
  color: #666;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -1px;
  &::before {
    content: "•";
    display: inline-block;
    height: 100%;
    padding: 0 8px;
  }
`;

const Td = styled.td<{
  css?: CSSObject
}>`
  ${props=>
    props.css?
      props.css
      :""
  };
`;