import styled, { css } from "styled-components";
import colors from "styles/colors";
import { ellipsis } from "styles/mixin";

export const Content = styled.div`
  position: absolute;
  background: #eef6ff;
  border: solid 1px #c2c2c2;
  border-radius: 4px;
  padding: 12px;
  margin-left: 60px;
  margin-top: 4px;
  width: 250px !important;
  text-align: left !important;

  max-height: 200px;
  overflow-y: auto;
  white-space: pre-line;
`;

export const TR = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  div {
    text-align: center;
  }
  div:nth-child(1) {
    width: 60px;
  }
  div:nth-child(2) {
    width: 100px;
  }
  div:nth-child(3) {
    flex: 1;
    text-align: left;
    ${ellipsis};
  }
  div:nth-child(4) {
    width: 100;
  }
  div:nth-child(5) {
    width: 100px;
  }
`;

export const TD = styled.div<{ active?: boolean; isEditable?: boolean }>`
  padding: 4px 10px;
  height: 32px;
  color: #333333;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -1px;

  color: #333333;

  svg {
    display: inline-block;
  }

  ${(props) =>
    props.active &&
    css`
      color: #ec1c24;
      font-weight: 700;
    `}

  ${(props) =>
    props.isEditable &&
    css`
      border: 1px solid #d7dbe2;
      border-radius: 6px;
    `}
`;

export const TH = styled(TD)``;

export const TBody = styled(TR)`
  background: #fafafa;
`;

export const ButtonSmall = styled.button`
  background-color: rgb(41, 132, 255);
  color: rgb(255, 255, 255);
  padding: 4px 6px;
  font-weight: 700;
  border-radius: 8px;
  font-size: 12px;
  cursor: pointer;
`;
