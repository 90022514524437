import styled from "styled-components";
import SlideToggle from "react-slide-toggle";
import { ModalLayout, ButtonNormal } from "components";
import { ButtonContainer, Input } from "../LayoutStyled";
import { serviceCertificate } from "properties/content";

interface Props {
  stepText: any;
  fileName: string;
  percent: number;
  onHandleChangeFile: (file: any) => void;
  onClose: () => void;
  onClickPrev: () => void;
  onSubmitFile: () => void;
}

export const UploadDocumentModal = ({
  stepText,
  fileName,
  onHandleChangeFile,
  onClickPrev,
  onClose,
  percent,
  onSubmitFile,
}: Props) => {
  return (
    <ModalLayout title={stepText.title} onClose={onClose} width={700}>
      <Container>
        <Content
          dangerouslySetInnerHTML={{ __html: stepText.content }}
        ></Content>

        <SlideToggle
          collapsed
          duration={300}
          render={({ toggle, setCollapsibleElement, toggleState }) => {
            return (
              <CollapseContainer>
                <CollapseButton onClick={toggle}>
                  [ 통신서비스 가입증명원 발급 방법 ]
                </CollapseButton>
                <CollapseContent ref={setCollapsibleElement}>
                  <div
                    dangerouslySetInnerHTML={{ __html: serviceCertificate }}
                  ></div>
                </CollapseContent>
              </CollapseContainer>
            );
          }}
        />

        <SubTitle>{stepText.subTitle} </SubTitle>
        <InputContainer>
          <input
            style={{ display: "none" }}
            id="fileUploadInput"
            type="file"
            accept=".zip, .gzip"
            placeholder={stepText.inputPlaceholder}
            onChange={onHandleChangeFile}
          />
          <Input
            type="text"
            placeholder={stepText.inputPlaceholder}
            value={fileName}
            readOnly
          />
          <UploadButton htmlFor="fileUploadInput">
            <div>파일 올리기</div>
          </UploadButton>
          <ProgressBarContainer>
            <ProgressBar percent={percent} />
          </ProgressBarContainer>
        </InputContainer>

        <ButtonContainer>
          <ButtonNormal label="이전" onClick={onClickPrev} width={154} />
          <ButtonNormal
            label="등록하기"
            onClick={onSubmitFile}
            disabled={fileName === ""}
            isPrimary={true}
          />
        </ButtonContainer>
      </Container>
    </ModalLayout>
  );
};

const Container = styled.div`
  // border: solid 1px lightgray;
  border-radius: 4px;
`;

const ProgressBarContainer = styled.div`
  margin-left: 30px;
  width: 70%;
  height: 4px;
  bottom: 6px;
  border: 10px;
  background-color: #ededed;
  position: relative;
`;
const ProgressBar = styled.div<{ percent: number }>`
  height: 100%;
  width: ${(props) => props.percent}%;
  height: 4px;
  position: absolute;
  left: 0px;
  background: #1b58f1;
`;
const Content = styled.div`
  padding-top: 24px;
  padding-bottom: 36px;
  color: #666666;
  white-space: pre-line;
  line-height: 24px;
  li {
    list-style: none;
    padding-left: 8px;
    &::before {
      content: "•";
      padding-right: 10px;
    }
  }
`;

const SubTitle = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -1px;

  color: #333333;
  padding-bottom: 8px;
`;

const InputContainer = styled.div`
  position: relative;
  margin-bottom: 24px;
`;

const UploadButton = styled.label`
  background: #1b58f1;
  color: #ffffff;
  position: absolute;
  right: 0px;
  height: 56px;
  width: 166px;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  box-sizing: border-box;
  padding-bottom: 2px;

  cursor: pointer;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    cursor: pointer;
  }
`;

const CollapseContainer = styled.div`
  padding-bottom: 40px;
`;

const CollapseButton = styled.div`
  padding-bottom: 16px;
  text-decoration: underline;
  color: #1b58f1;
  cursor: pointer;
`;

const CollapseContent = styled.div`
  div {
    color: #333333;
    white-space: pre-line;
    line-height: 24px;
    height: 200px;
    overflow-y: auto;
    background-color: #e4e9f8;
    border-radius: 8px;
    padding: 16px 16px;
  }
`;
