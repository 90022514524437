export const BULK_SEND_SIZE = process.env.NODE_ENV === "development" ? 3 : 100;
export const TITLE_BYTE_LIMIT = 64;

// STYLE
export const APP_MAX_WIDTH = 1200;
export const HEADER_Z_INDEX = 1000;
export const SIDE_NAV_Z_INDEX = 2000;
export const GOTOTOP_Z_INDEX = 900;
export const HEADER_HEIGHT = 108;
export const BASE_X_PADDING = 30;
export const FOOTER_HEIGHT = 300;

export const MEDIA_QUERY = {
  TABLET: {
    MAX_WIDTH: 1199,
    HEADER_HEIGHT: 72,
    BASE_X_PADDING: 16,
  },
  MOBILE: {
    MAX_WIDTH: 743,
    HEADER_HEIGHT: 62,
    BASE_X_PADDING: 22,
  },
  MOBILE_S: {
    MAX_WIDTH: 479,
    HEADER_HEIGHT: 62,
    BASE_X_PADDING: 22,
  },
};
