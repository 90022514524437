import { SVGProps } from "react";

export const SettingFillRounded = ({
  width = 24,
  height = 24,
  fill = "#1B58F1",
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill="white" />
      <path
        d="M10.671 5.88338C10.991 4.46704 13.009 4.46704 13.329 5.88338C13.5363 6.80131 14.5897 7.23764 15.3854 6.73518C16.6131 5.9599 18.0401 7.38689 17.2648 8.61462C16.7624 9.41031 17.1987 10.4637 18.1166 10.671C19.533 10.991 19.533 13.009 18.1166 13.329C17.1987 13.5363 16.7624 14.5897 17.2648 15.3854C18.0401 16.6131 16.6131 18.0401 15.3854 17.2648C14.5897 16.7624 13.5363 17.1987 13.329 18.1166C13.009 19.533 10.991 19.533 10.671 18.1166C10.4637 17.1987 9.41031 16.7624 8.61462 17.2648C7.38689 18.0401 5.9599 16.6131 6.73518 15.3854C7.23764 14.5897 6.80131 13.5363 5.88338 13.329C4.46704 13.009 4.46704 10.991 5.88338 10.671C6.80131 10.4637 7.23764 9.41031 6.73518 8.61462C5.9599 7.38689 7.38689 5.9599 8.61462 6.73518C9.41031 7.23764 10.4637 6.80131 10.671 5.88338Z"
        fill={fill}
      />
      <circle cx="12" cy="12" r="3" fill="white" />
    </svg>
  );
};
