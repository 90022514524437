import styled from "styled-components";
import { useContext, useEffect, useState } from "react";
import { MessageLayout } from "components/layout/MessageLayout";
import { CallerType } from "types/types";

import { AlertModal, ButtonPrimary } from "components";
import { CallerList } from "pages/sender/Senders/SenderList";
import { UnableNewCallerModal } from "components/Modals/UnableNewCallerModal";
import { AddNewCallerModal } from "components/Modals/AddNewCallerModal";
import { RemoveCallerModal } from "components/Modals/RemoveCallerModal";

import CallerStore from "stores/CallerStore";
import { observer } from "mobx-react-lite";
import { useCustomNavigate } from "hooks/useCustomNavigate";

export const Senders = observer(() => {
  const navigate = useCustomNavigate();
  const callerStore = useContext(CallerStore);
  const [visibleNewCallerModal, setVisibleNewCallerModal] =
    useState<boolean>(false);
  const [visibleUnableNeweCallerModal, setVisibleUnableNewCallerModal] =
    useState<boolean>(false);

  const [visibleRemoveCallerModal, setVisibleRemoveCallerModal] =
    useState<boolean>(false);
  const [visibleCancelReasonModal, setVisibleCancelReasonModal] =
    useState<boolean>(false);
  const [removeLoading, setRemoveLoading] = useState<boolean>(false);

  useEffect(() => {
    callerStore.fetchCallers();
  }, [callerStore]);

  const onClickAddNewCaller = () => {
    if (callerStore.callers && callerStore.callers.length >= 3) {
      setVisibleUnableNewCallerModal(true);
    } else {
      setVisibleNewCallerModal(true);
    }
  };

  const onRemoveCaller = async (caller: CallerType) => {
    if (removeLoading) {
      return;
    }
    setRemoveLoading(true);
    await callerStore.removeCaller(caller, () => {
      setVisibleRemoveCallerModal(false);
      navigate.reload();
    });
    setRemoveLoading(false);
  };

  const openRemoveCallerModal = (caller: CallerType) => {
    callerStore.setTargetCaller(caller);
    setVisibleRemoveCallerModal(true);
  };
  const openFailReasonModal = (caller: CallerType) => {
    callerStore.setTargetCaller(caller);
    setVisibleCancelReasonModal(true);
  };

  return (
    <MessageLayout>
      <Container>
        <Inner>
          <Header>
            <SubTitle>발신번호 등록/편집</SubTitle>
            <Descriptions>
              <ListItem>
                메시지 발송 시 사용하실 발신번호 리스트입니다.
              </ListItem>
              <ListItem>발신번호는 최대 3개까지 등록 가능합니다.</ListItem>
            </Descriptions>
            <ButtonPrimary
              label="발신번호 등록"
              onClick={onClickAddNewCaller}
            />
          </Header>
          <CallerList
            callers={callerStore.callers}
            openRemoveCallerModal={openRemoveCallerModal}
            callerTotalCount={callerStore.callerTotalCount}
            currentPage={callerStore.currentPage}
            setCurrentPage={callerStore.setCurrentPage}
            openFailReasonModal={openFailReasonModal}
          />

          {/* Modal */}
          {visibleUnableNeweCallerModal && (
            <UnableNewCallerModal
              onClose={() => {
                setVisibleUnableNewCallerModal(false);
              }}
            />
          )}

          {visibleNewCallerModal && (
            <AddNewCallerModal
              onClose={() => {
                setVisibleNewCallerModal(false);
              }}
              reloadCallers={callerStore.fetchCallers}
            />
          )}
        </Inner>

        {visibleRemoveCallerModal && (
          <RemoveCallerModal
            caller={callerStore.targetCaller!}
            onClose={() => setVisibleRemoveCallerModal(false)}
            onRemoveCaller={onRemoveCaller}
            loading={removeLoading}
          />
        )}

        {visibleCancelReasonModal && (
          <AlertModal
            title={"등록 거절 이유"}
            confirmButtonText={"확인"}
            closeModal={() => setVisibleCancelReasonModal(false)}
          >
            <div>{callerStore.targetCaller?.staff_memo}</div>
          </AlertModal>
        )}
      </Container>
    </MessageLayout>
  );
});

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PageTitle = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 46px;
  letter-spacing: -1px;
  color: #333333;
  padding-bottom: 32px;
`;

export const Inner = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
`;

export const Header = styled.div`
  padding: 24px;
`;

export const SubTitle = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  letter-spacing: -1px;
  color: #333333;
  padding-bottom: 16px;
`;

export const Descriptions = styled.div`
  padding-bottom: 36px;
`;

export const ListItem = styled.li`
  padding-bottom: 8px;
  color: rgb(85, 85, 85);
  list-style: none;
  &::before {
    content: "• ";
    padding-right: 4px;
  }
`;

export const EmphasizedText = styled.span`
  font-weight: 700;
  text-decoration: underline;
`;

export const Editors = styled.div`
  display: flex;
  gap: 24px;
`;

export const PrimaryButton = styled.button`
  background: #1b58f1;
  border-radius: 100px;
  align-items: center;
  text-align: center;
  letter-spacing: -1px;
  color: #ffffff;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 16px;
  width: fit-content;
  margin-bottom: 26px;
  border: none;
  cursor: pointer;
`;

export const OutlineButton = styled.button`
  border: 1px solid #1b58f1;
  color: #1b58f1;
  background-color: transparent;
  border-radius: 100px;
  padding: 8px 16px;
  width: fit-content;
  cursor: pointer;
`;

export const DisabledButton = styled.button`
  background: #e1e1e1;
  border-radius: 100px;
  border-color: #e1e1e1;
  color: white;
  border: none;
  cursor: not-allowed;
`;

export const FlexInherit = styled.div`
  flex: inherit !important;
`;
