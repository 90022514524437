import { MEDIA_QUERY } from "properties/constant";
import useResponsive from "hooks/useResponsive";
import styled from "styled-components";

interface Props {
  type: {
    id: number;
    text: string;
    title: string;
    imageName: string;
  };
  hoveredTypeId: number | null;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}
export const MessageType = ({
  type,
  hoveredTypeId,
  onMouseEnter,
  onMouseLeave,
}: Props) => {
  const { isDesktop } = useResponsive();
  return (
    <Container
      active={hoveredTypeId === type.id || hoveredTypeId === null}
      onMouseEnter={isDesktop ? onMouseEnter : null}
      onMouseLeave={isDesktop ? onMouseLeave : null}
    >
      <div className="card">
        <CardImage src={type.imageName} alt="card" />
      </div>
      <div className="text">
        <Title>{type.title}</Title>
        <TextContainer>
          <Text>{type.text}</Text>
        </TextContainer>
      </div>
    </Container>
  );
};

const Container = styled.div<{ active: boolean }>`
  width: 285px;
  transition: all 0.1s ease-in-out;
  opacity: ${(props) => (props.active ? "1" : "0.5")};

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
    display: flex;
    padding-bottom: 24px;
    .card {
      flex: 1;
    }
    .text {
      flex: 1;
    }
  }
`;

const CardImage = styled.img`
  width: 90%;
  transition: all 0.1s ease-in-out;
  &:hover {
    transform: scale(1.1);
    opacity: 1;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    transition: none;
    width: 110%;
    margin-left: -16px;
    margin-top: -20px;

    &:hover {
      transform: none;
      opacity: none;
    }
  }
`;
const TextContainer = styled.div`
  width: 173px;
  margin: 0 auto;
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 100%;
    word-break: keep-all;
    padding: 0;
  }
`;
const Title = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -1px;

  color: #333333;
  padding-bottom: 12px;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 20px;
    text-align: left;
  }
`;
const Text = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -1px;

  color: #666666;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 16px;
    text-align: left;
  }
`;
