import styled from "styled-components";

interface Props {
  children: React.ReactNode;
  isBold?: boolean;
  htmlFor?: string;
}
export const Label = ({ children, isBold, htmlFor }: Props) => {
  return (
    <StyledLabel htmlFor={htmlFor} isBold={isBold}>
      {children}
    </StyledLabel>
  );
};

const StyledLabel = styled.label<{ isBold: boolean }>`
  font-weight: ${(props) => (props.isBold ? 700 : 400)};
  color: ${(props) => (props.isBold ? "#333" : "#666666")};

  font-size: 16px;
  line-height: 24px;
  letter-spacing: -1px;
  padding-bottom: 8px;
  display: block;
`;
