import { useEffect, useState } from "react";
import styled from "styled-components";
import { actionButtons } from "properties/content";
import { ActionCard } from "components/ActionCard";
import { MEDIA_QUERY } from "properties/constant";

export const ActionButton = () => {
  const [activeActionId, setActiveActionId] = useState<number>(0);

  useEffect(() => {
    let actionId = -1;
    let timer = setInterval(() => {
      let activeActionId = ++actionId % 6;
      setActiveActionId(activeActionId);
    }, 3000);

    return () => clearInterval(timer);
  }, []);

  return (
    <Container>
      <Title>
        문자메시지에는 없는
        <br />
        <b>다양한 액션 버튼</b>을 지원합니다
      </Title>

      <List>
        {actionButtons.map((action) => (
          <ActionCard
            key={action.id}
            action={action}
            active={activeActionId === action.id}
          />
        ))}
      </List>
    </Container>
  );
};

const Container = styled.div`
  padding: 100px 20px;
  text-align: center;
`;
const Title = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  line-height: 39px;
  letter-spacing: -1px;
  color: #333333;
  padding-bottom: 60px;
  b {
    font-weight: 700;
  }

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -2px;
  }
`;
const List = styled.div`
  display: flex;
  gap: 0px;
  justify-content: center;
  flex-wrap: wrap;
  gap: 24px;
  max-width: 860px;
  margin: 0 auto;
`;
