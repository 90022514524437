import colors from "styles/colors";
import {
  CheckboxCircle,
  Dashboard,
  List,
  Point,
  Send,
  Person,
  Template,
} from "../components/Icons";

export type MenuItemType = {
  id: string;
  isSubMenu?: boolean;
  label: string;
  path: string;
  icon?: JSX.Element;
  iconActive?: JSX.Element;
  disabled?: boolean;
  subMenus?: MenuItemType[];
  activePath?: string;
};

export const PATH = {
  HOME: "/",
  SIGN_UP: "/signUp",
  SIGN_UP_REGULAR: "/signUp/regular",
  SIGN_UP_COMPANY: "/signUp/company",
  SIGN_IN: "/signIn",
  CHANGE_PROFILE: "/profile",
  CHANGE_COMPANY_PROFILE: "/profile/company",
  FIND_ID: "/findEmail",
  RESET_PASSWORD: "/resetPassword",
  PRICING: "/pricing",
  POINT_CHARGE: "/point/charge",

  DASHBOARD: "/message/dashboard",
  MESSAGE_SEND: "/message/send",
  MESSAGE_SEND_SMART: "/message/send/smart",
  MESSAGE_BLOCK_RESULT: "/message/block",

  MESSAGE_SEND_RESULT: "/message/result",
  MESSAGE_SEND_RESULT_DETAIL: "/message/result/:groupId",
  MESSAGE_RESERVED_RESULT: "/message/reserved",
  MESSAGE_RESERVED_RESULT_DETAIL: "/message/reserved/:groupId",

  TEMPLATE_LIST_PLAIN: "/template/plain",
  TEMPLATE_LIST_SMART: "/template/smart",
  TEMPLATE_DETAIL_PLAIN: "/template/plain/:templateId",
  TEMPLATE_NEW_PLAIN: "/template/plain/new",
  TEMPLATE_SMART_SELECT_DESCRIPTION: "/template/smart/selectDescription",
  TEMPLATE_SMART_IMAGE_TITLE: "/template/smart/imageTitle",
  TEMPLATE_SMART_SELECT_CELL: "/template/smart/selectCell",
  CALLERS: "/message/caller",
  ADDRESS_BOOK: "/addressbook",

  BRAND: "/brand",
  BRAND_CREATION: "/brand/creation",

  CHARGE_HISTORY: "/point/history",

  KAKAO_PROFILE: "/kakao/profile",
};

export const PROTECTED_PATHS = [
  PATH.DASHBOARD,
  PATH.MESSAGE_SEND,
  PATH.MESSAGE_SEND_SMART,
  PATH.MESSAGE_SEND_RESULT,
  PATH.MESSAGE_BLOCK_RESULT,
  PATH.MESSAGE_RESERVED_RESULT,
  PATH.MESSAGE_SEND_RESULT_DETAIL,
  PATH.MESSAGE_RESERVED_RESULT_DETAIL,
  PATH.CALLERS,
  PATH.POINT_CHARGE,
  PATH.ADDRESS_BOOK,
  PATH.CHARGE_HISTORY,
  PATH.BRAND_CREATION,
];

export const MenuItems: MenuItemType[] = [
  {
    id: "1",
    path: PATH.DASHBOARD,
    label: "대시보드",
    icon: <Dashboard />,
    iconActive: <Dashboard fill={colors.primary1} />,
  },
  {
    id: "2",
    path: PATH.MESSAGE_SEND,
    label: "메시지 발송",
    icon: <Send />,
    iconActive: <Send fill={colors.primary1} />,
    subMenus: [
      {
        id: "21",
        isSubMenu: true,
        path: PATH.MESSAGE_SEND,
        label: "일반 문자",
      },
      // {
      //   id: "22",
      //   isSubMenu: true,
      //   path: PATH.MESSAGE_SEND_SMART,
      //   label: "스마트 메시지",
      // },
    ],
  },

  {
    id: "4",
    path: PATH.MESSAGE_SEND_RESULT,
    label: "발송 결과",
    icon: <CheckboxCircle />,
    iconActive: <CheckboxCircle fill={colors.primary1} />,
    subMenus: [
      {
        id: "41",
        isSubMenu: true,
        path: PATH.MESSAGE_SEND_RESULT,
        label: "발송 내역",
      },
      {
        id: "42",
        isSubMenu: true,
        path: PATH.MESSAGE_RESERVED_RESULT,
        label: "예약 내역",
      },
      {
        id: "43",
        isSubMenu: true,
        path: PATH.MESSAGE_BLOCK_RESULT,

        label: "차단된 수신 내역",
      },
    ],
  },
  {
    id: "6",
    path: PATH.TEMPLATE_LIST_PLAIN,
    label: "템플릿 관리",
    icon: <Template />,
    iconActive: <Template fill={colors.primary1} />,
    subMenus: [
      {
        id: "61",
        isSubMenu: true,
        path: PATH.TEMPLATE_LIST_PLAIN,
        label: "일반 문자",
      },
      // {
      //   id: "62",
      //   isSubMenu: true,
      //   path: PATH.TEMPLATE_LIST_SMART,
      //   label: "스마트 메시지",
      // },
    ],
  },
  {
    id: "3",
    path: "/addressbook",
    label: "주소록",
    icon: <Person />,
    iconActive: <Person fill={colors.primary1} />,
  },
  {
    id: "5",
    path: PATH.CALLERS,
    label: "발신번호 관리",
    icon: <List />,
    iconActive: <List fill="#1B58F1" />,
  },

  // {
  //   id: "7",
  //   path: PATH.BRAND,
  //   label: "브랜드 관리",
  //   icon: <CheckboxMedal />,
  //   iconActive: <CheckboxMedal fill="#1B58F1" />
  // },
  {
    id: "67",
    path: PATH.CHARGE_HISTORY,
    label: "충전내역",

    icon: <Point />,
    iconActive: <Point fill="#1B58F1" />,
  },
];
