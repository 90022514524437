import { CheckboxCircle, CheckboxCircleFilled } from "components/Icons";
import { useCallback, useState } from "react";

import { TemplateType } from "types/types";
import { changeDateFormat, DATE_FORMAT_1 } from "utils/datetime";
import * as S from "./styled";
import { ButtonRound } from "styles/styled";

interface Props {
  template: TemplateType;
  onClickTemplate: () => void;
  isActiveCheckbox: () => boolean;
  moveTemplateDetail: () => void;
}
export const TemplateRow: React.FC<Props> = ({
  template,
  onClickTemplate,
  isActiveCheckbox,
  moveTemplateDetail,
}) => {
  const getMessageTypeLabel = useCallback((typeNumber: number) => {
    switch (typeNumber) {
      case 1:
        return "SMS";
      case 2:
        return "LMS";
      case 3:
        return "MMS";
      default:
        return "-";
    }
  }, []);

  const active = isActiveCheckbox();

  return (
    <S.TR key={template.id} onClick={onClickTemplate}>
      <S.TD>
        {active ? (
          <CheckboxCircleFilled width="20" height="20" />
        ) : (
          <CheckboxCircle fill="#C4C9D1" width="20" height="20" />
        )}
      </S.TD>

      <S.TD>{getMessageTypeLabel(template.msg_type)}</S.TD>
      <S.TD>
        <span className="underline">{template.template_name}</span>

        {active && (
          <span>
            <S.Content>
              <b>{template.title}</b>
              <br />
              <br />
              <span>{template.content}</span>
            </S.Content>
          </span>
        )}
      </S.TD>

      <S.TD>{changeDateFormat(template.modified, DATE_FORMAT_1)}</S.TD>
      <S.TD>
        <S.ButtonSmall onClick={moveTemplateDetail}>수정</S.ButtonSmall>
      </S.TD>
    </S.TR>
  );
};

export const TableTbody = () => {
  return (
    <S.TBody>
      <S.TH>선택</S.TH>
      <S.TH>문자 종류</S.TH>
      <S.TH>템플릿 이름</S.TH>

      <S.TH>최종수정일</S.TH>
      <S.TH>수정/삭제</S.TH>
    </S.TBody>
  );
};
