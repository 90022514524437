// 이메일 정규표현식 체크
export const validateEmail = (inputEmail: string) => {
  var email = inputEmail;
  var exptext = /^[A-Za-z0-9_.-]+@[A-Za-z0-9-]+\.[A-Za-z0-9-]+/;
  if (exptext.test(email) === false) {
    //이메일 형식이 알파벳+숫자@알파벳+숫자.알파벳+숫자 형식이 아닐경우
    return false;
  }
  return true;
};
