import styled from "styled-components";

import { useState } from "react";
import { UseCaseType } from "types/types";

import { useCaseList } from "properties/useCase";
import { UseCaseCard } from "./UseCaseCard";
import { MEDIA_QUERY } from "properties/constant";

export const UseCase = () => {
  const [activeUseCase, setActiveUseCase] = useState<UseCaseType>(
    useCaseList[0]
  );

  return (
    <Container>
      <Inner>
        <header>
          <TextContainer>
            <Title>
              <b> 스마트메시지</b> 활용 사례
            </Title>
            <Desc>
              <b>스마트메시지 활용</b>으로 개선 된 <b>마케팅 성과</b>를
              확인해보세요
            </Desc>
          </TextContainer>
        </header>
        <UseCaseTypeContainer>
          {useCaseList.map((useCase) => (
            <UseCaseButton
              key={useCase.id}
              className={activeUseCase.type === useCase.type ? "active" : ""}
              onClick={() => setActiveUseCase(useCase)}
            >
              {useCase.type}
            </UseCaseButton>
          ))}
        </UseCaseTypeContainer>
        <UseCaseCard useCase={activeUseCase} />
      </Inner>
    </Container>
  );
};

export const Container = styled.div`
  background: #f6f8ff;
  padding: 100px 0px;
`;

const Inner = styled.div`
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    max-width: 480px;
    margin: 0 auto;
  }
`;
export const TextContainer = styled.div`
  text-align: center;
`;

export const Title = styled.div`
  font-weight: 400;
  font-size: 34px;
  line-height: 39px;
  letter-spacing: -1px;
  color: #333333;
  padding-bottom: 16px;

  b {
    font-weight: 700;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 28px;
    line-height: 24px;
  }
`;

export const Desc = styled.div`
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -1px;
  color: #333333;
  padding-bottom: 40px;

  b {
    font-weight: 700;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 14px;
    padding-bottom: 24px;
    line-height: 16px;
  }
`;

export const UseCaseTypeContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  flex-wrap: wrap;
`;

export const UseCaseButton = styled.button`
  border: 0.5px solid #666666;
  border-radius: 100px;
  text-align: center;
  width: 150px;
  padding: 8px;
  cursor: pointer;
  background-color: #f6f8ff;

  &.active {
    filter: drop-shadow(0px 4px 4px rgba(12, 77, 239, 0.25));
    background: #2984ff;
    color: white;
    border-color: #2984ff;
    transition: all 0.2s ease-in-out;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 14px;
    padding-bottom: 24px;
    line-height: 16px;
    height: 20px;
    width: 100px;
  }
`;
