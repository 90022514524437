import styled from "styled-components";
import { Header } from "./Header";
import { Service } from "./Service";
import { SMSPricing } from "./SMSPricing";
import { SmartMessage } from "./SmartMessage";
import { SmartMessagePricing } from "./SmartMessagePricing";
import { Banner, Footer } from "components";
import { Benefit } from "./Benefit";
import { MEDIA_QUERY } from "properties/constant";

export const Pricing = () => {
  return (
    <div>
      <Container>
        <Header />
        <Service />
        <SMSPricing />
        <Benefit />
        <SmartMessage />
        <SmartMessagePricing />
        <Banner />
        <Footer />
      </Container>
    </div>
  );
};

const Container = styled.div`
  position: relative;
  padding-bottom: 60px;
`;
