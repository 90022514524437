import styled from "styled-components";
import { ImgFirecraker } from "assets";
import { PATH } from "properties/menu";
import { useCustomNavigate } from "hooks/useCustomNavigate";

interface Props {
  memberType?: "regular" | "company";
}

export const CompleteSection = ({ memberType = "regular" }: Props) => {
  const navigate = useCustomNavigate();

  const handleLogin = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate.replace(PATH.SIGN_IN);
  };

  return (
    <Container>
      <Title>{contents[memberType].title}</Title>
      <Desc>{contents[memberType].desc}</Desc>
      <Image src={ImgFirecraker} alt="폭죽" width="372" />
      <Button onClick={handleLogin}>로그인하기</Button>
    </Container>
  );
};

const contents = {
  regular: {
    title: "회원가입을 축하합니다",
    desc: "비즈포스트 회원에게만 \n드리는 혜택을 누릴 수 있게 되었어요!",
  },
  company: {
    title: "기업 회원가입 신청을 완료하였습니다",
    desc: "기업 회원가입 승인까지는 평균 3~5일 정도의 기간이 소요됩니다. \n원활한 가입 승인을 위해 1-2일 사이 담당자가 연락 드릴 예정입니다.",
  },
};

const Container = styled.div`
  padding: 124px 24px 24px;
  max-width: 480px;
  margin: 0 auto;
  text-align: center;
`;

const Title = styled.h1`
  margin-bottom: 24px;
  color: #333;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 39px;
  letter-spacing: -1px;
`;

const Image = styled.img`
  display: block;
  width: 100%;
  max-width: 372px;
  margin: 0 auto;
`;

const Desc = styled.div`
  white-space: pre-line;
  word-break: keep-all;
  color: #333;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -1px;
  margin-bottom: 12px;
`;

const Button = styled.a`
  display: block;
  width: 100%;
  background-color: #1b58f1;
  color: white;
  border-radius: 36px;
  padding: 16px 0;
  margin: 0 auto;
  cursor: pointer;
`;
