import { useContext, useState } from "react";
import { observer } from "mobx-react-lite";
import { ChooseNextStepModal } from "./ChooseNextStepModal";
import { UploadDocumentModal } from "./UploadDocumentModal";
import { newCallerstepTexts } from "properties/newCallerStep";
import { importCeriticatePhone } from "utils/import";
import { WritePhoneModal } from "./WritePhoneModal";
import { validateNumberOnly } from "utils/phone";
import CallerStore from "stores/CallerStore";
import { CreateCallerRequestType } from "types/types";
import { uploadS3File } from "utils/awsS3";
import { v4 as uuidv4 } from "uuid";

interface Props {
  onClose: () => void;
  reloadCallers: () => void;
}
type ADD_NEW_CALLER_STEP =
  | "WRITE_PHONE"
  | "CHOOSE_NEXT_STEP"
  | "UPLOAD_DOCUMENT";
export const AddNewCallerModal = observer(
  ({ onClose, reloadCallers }: Props) => {
    const callerStore = useContext(CallerStore);
    const [phone, setPhone] = useState("");
    const [phoneValid, setPhoneValid] = useState(false);
    const [phoneVerified, setPhoneVerified] = useState(false);
    const [documentUploaded, setDocumentUploaded] = useState(false);
    const [currentStep, setCurrentStep] =
      useState<ADD_NEW_CALLER_STEP>("WRITE_PHONE");
    const [targetFile, setTargetFile] = useState<File | null>(null);
    const [targetFileKey, setTargetFileKey] = useState<string | null>(null);
    const [uploadPercent, setUploadPercent] = useState(0);
    const [uuid] = useState<string>(uuidv4());

    const onHandleChangeFile = async (e) => {
      try {
        const file = e.target.files[0];
        setTargetFile(file);
        const response = await uploadS3File("cc", file, uuid);
        setTargetFileKey(response.Key);
      } catch (err) {
        console.error("파일 업로드 실패");
      }
    };

    const onHandleClose = () => {
      initStateList();
      onClose();
    };

    const initStateList = () => {
      setCurrentStep("WRITE_PHONE");
      setPhone("");
      setPhoneValid(false);
      setPhoneVerified(false);
      setDocumentUploaded(false);
    };

    const onClickNext = () => {
      if (currentStep === "WRITE_PHONE" && phoneValid) {
        setCurrentStep("CHOOSE_NEXT_STEP");
      } else if (currentStep === "CHOOSE_NEXT_STEP" && phoneValid) {
        setCurrentStep("UPLOAD_DOCUMENT");
      }
    };

    const onChangePhone = (currentPhone) => {
      // 기업 번호를 위해 숫자만 입력받도록 수정
      if (
        validateNumberOnly(currentPhone) &&
        currentPhone.length > 4 &&
        currentPhone.length < 15
      ) {
        setPhoneValid(true);
        setPhone(currentPhone);
      } else {
        setPhoneValid(false);
      }
    };

    const onClickPrev = () => {
      if (currentStep === "CHOOSE_NEXT_STEP") {
        setCurrentStep("WRITE_PHONE");
      } else if (currentStep === "UPLOAD_DOCUMENT") {
        setCurrentStep("CHOOSE_NEXT_STEP");
      }
    };

    const createCallerByPhone = async (impUid: string) => {
      const body: CreateCallerRequestType = {
        phone_number: phone,
        imp_uid: impUid,
      };
      let result = await callerStore.createCallerByPhone(body);
      if (result.created) {
        alert(result.message);
      } else {
        alert(
          `발신번호 등록에 문제가 발생하였습니다.고객센터로 문의해주세요\n[에러 메시지] ${result.message}`
        );
      }
    };

    const updateProgressBar = (progressEvent) => {
      const { loaded, total } = progressEvent;
      let percent = Math.floor((loaded * 100) / total);
      setUploadPercent(percent);
      if (percent === 100) {
        onHandleClose();
      }
    };

    const createCallerByDocuments = async () => {
      const body: CreateCallerRequestType = {
        phone_number: phone,
        file_paper: targetFileKey,
      };
      const result = await callerStore.createCallerByDocuments(
        body,
        updateProgressBar
      );

      if (result.created) {
        alert(result.message);
      } else {
        alert(
          `발신번호 등록에 문제가 발생하였습니다. 파일 크기와 형식을 확인해주세요.\n${
            result.message ? `[에러 메시지] ${result.message}` : ""
          }`
        );
        setUploadPercent(0);
      }
    };

    const cetificatePhone = () => {
      importCeriticatePhone(
        phone,
        callbackCertificatePhoneSuccess,
        callbackCertificatePhoneFail
      );
    };

    /**
     * 인증 성공시 실행되는 콜백함수
     */
    const callbackCertificatePhoneFail = async (e) => {
      console.error(e);
    };
    const callbackCertificatePhoneSuccess = async (impUid: string) => {
      setPhoneVerified(true);
      createCallerByPhone(impUid);
      onHandleClose();
    };

    const onSubmitFile = () => {
      setDocumentUploaded(true);
      createCallerByDocuments();
    };
    const stepText = newCallerstepTexts[currentStep];
    if (currentStep === "WRITE_PHONE") {
      return (
        <WritePhoneModal
          stepText={stepText}
          phone={phone}
          phoneValid={phoneValid}
          onChangePhone={onChangePhone}
          onClose={onHandleClose}
          onClickNext={onClickNext}
        />
      );
    } else if (currentStep === "CHOOSE_NEXT_STEP") {
      return (
        <ChooseNextStepModal
          phoneVerified={phoneVerified}
          documentUploaded={documentUploaded}
          cetificatePhone={cetificatePhone}
          stepText={stepText}
          phone={phone}
          onClose={onHandleClose}
          onClickNext={onClickNext}
          onClickPrev={onClickPrev}
        />
      );
    } else {
      return (
        <UploadDocumentModal
          stepText={stepText}
          fileName={targetFile?.name}
          onClose={onHandleClose}
          onClickPrev={onClickPrev}
          onHandleChangeFile={onHandleChangeFile}
          onSubmitFile={onSubmitFile}
          percent={uploadPercent}
        />
      );
    }
  }
);
