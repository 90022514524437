import { observer } from "mobx-react-lite";
import { useCallback } from "react";
import styled, { css } from "styled-components";
import { getBytes } from "utils/bytes";

const Container = styled.div<{
  bgColor;
  borderColor;
  width;
  margin;
  disabled?: boolean;
  isInvalid?: boolean;
}>`
  position: relative;
  ${(props) => (props.width ? "" : "flex:1;")};
  height: 100%;
  margin: ${(props) => (props.margin ? props.margin : "0")};

  input {
    display: flex;
    width: ${(props) => (props.width ? props.width : "100%")};
    height: 56px;
    padding: 17px 16px 16px;
    border-radius: 6px;
    border: 1px solid #d7dbe2;
    background: ${(props) => (props.bgColor ? props.bgColor : "#FFF")};
    box-sizing: border-box;
    letter-spacing: -1px;

    ::placeholder {
      color: #999;
      letter-spacing: -1px;
    }

    ${(props) =>
      props.isInvalid &&
      css`
        &:not(:focus) {
          border-color: #ec1c24;
        }
      `}

    ${(props) =>
      props.disabled &&
      css`
        background-color: #ededed;
      `}
  }
`;

const Caption = styled.div`
  position: absolute;
  top: 19px;
  right: 16px;
  font-size: 14px;
`;

interface IProps {
  type?: string;
  bgColor?: string;
  borderColor?: string;
  value: string;
  onChange: (target: HTMLInputElement, name?: string) => void;
  maxByte?: number;
  placeholder?: string;
  width?: string;
  margin?: string;
  name?: string;
  onlyNumber?: boolean;
  disabled?: boolean;
  isInvalid?: boolean;
}

const InputText = observer(
  ({
    type = "text",
    bgColor,
    borderColor,
    value,
    onChange,
    maxByte,
    placeholder,
    width,
    margin,
    name,
    onlyNumber,
    disabled = false,
    isInvalid,
  }: IProps) => {
    const validateAndLimitByte = useCallback((target, name) => {
      // byte 검사
      if (getBytes(target.value) <= maxByte) {
        onChange(target, name);
      } else {
        alert(`${maxByte}바이트까지 전송가능합니다.`);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkAndEnforceNumbersOnly = useCallback((target, name) => {
      target.value = target.value
        .replace(/[^0-9.]/g, "")
        .replace(/(\..*)\./g, "$1");
      onChange(target, name);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Container
        bgColor={bgColor}
        borderColor={borderColor}
        width={width}
        margin={margin}
        isInvalid={isInvalid}
        disabled={disabled}
      >
        <input
          type={type}
          value={value}
          onChange={(e) =>
            maxByte
              ? validateAndLimitByte(e.target, name)
              : onlyNumber
              ? checkAndEnforceNumbersOnly(e.target, name)
              : onChange(e.target, name)
          }
          placeholder={placeholder}
          name={name}
          disabled={disabled}
        />
        {maxByte ? (
          <Caption>
            {getBytes(value)}
            &nbsp;/ {maxByte}byte
          </Caption>
        ) : null}
      </Container>
    );
  }
);

export default InputText;
