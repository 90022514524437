import { ModalLayout, ButtonNormal } from "components";
import {
  Container,
  Content,
  SubTitle,
  InputContainer,
  ButtonContainer,
  Input,
} from "../LayoutStyled";

interface Props {
  stepText: any;
  phone: string;
  phoneValid: boolean;
  onChangePhone: (phone: string) => void;
  onClose: () => void;
  onClickNext: () => void;
}
export const WritePhoneModal = ({
  phone,
  phoneValid,
  stepText,
  onChangePhone,
  onClose,
  onClickNext,
}: Props) => {
  const onHandleClickNext = () => {
    if (phoneValid) {
      onClickNext();
    } else {
      alert(
        "핸드폰 번호가 형식에 맞지 않습니다.\n 010으로 시작하는 11자리 숫자만 입력해주세요."
      );
    }
  };
  return (
    <ModalLayout title={stepText.title} onClose={onClose} width={680}>
      <Container>
        <Content>{stepText.content}</Content>

        <SubTitle>{stepText.subTitle} </SubTitle>

        {/* input box */}
        <InputContainer>
          <Input
            type="text"
            placeholder={stepText.inputPlaceholder}
            onChange={(e) => onChangePhone(e.target.value)}
            onKeyDown={(e) => {
              e.key === "Enter" && onClickNext();
            }}
            defaultValue={phone}
          />
        </InputContainer>

        <ButtonContainer>
          <ButtonNormal
            label="다음"
            onClick={onHandleClickNext}
            disabled={!phoneValid}
            isPrimary={true}
          />
        </ButtonContainer>
      </Container>
    </ModalLayout>
  );
};
