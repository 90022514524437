import {
  ImgHomeAction1,
  ImgHomeAction2,
  ImgHomeAction3,
  ImgHomeAction4,
  ImgHomeAction5,
  ImgHomeAction6,
  ImgHomeMessageType1,
  ImgHomeMessageType2,
  ImgHomeMessageType3,
  ImgHomeMessageType4,
  ImgHomePricingChar1,
  ImgHomePricingChar1Active,
  ImgHomePricingChar2,
  ImgHomePricingChar2Active,
  ImgHomePricingChar3,
  ImgHomePricingChar3Active,
  ImgHomeQuestionImg,
  ImgHomeSuccessfulMockup1,
  ImgHomeSuccessfulMockup2,
  ImgHomeSuccessfulMockup3,
  ImgHomeSuccessfulMockup4,
} from "assets";
import {
  ActionButtonType,
  PeriodOptionType,
  PricingType,
  QnaType,
  SmsType,
  SuccessfulCaseType,
} from "types/types";

export const messageTypes = [
  {
    id: 0,
    title: "이미지 & 타이틀 강조형",
    text: "세로형, 가로형의 미디어를 사용할 수 있습니다.\n제목과 함께 간단한 텍스트로 풍부한 메시지를 구성할 수 있어 이벤트 알림에 적합합니다.",
    imageName: ImgHomeMessageType1,
  },
  {
    id: 1,
    title: "썸네일형",
    text: "썸네일 이미지와 설명을 입력하여 상품이나 서비스를 효과적으로 전달할 수 있습니다.",
    imageName: ImgHomeMessageType2,
  },
  {
    id: 2,
    title: "이미지 강조형",
    text: "세로형, 가로형의 미디어를 사용할 수 있습니다. 제목과 함께 줄글로 풍부한 메시지를 구성할 수 있습니다.",
    imageName: ImgHomeMessageType3,
  },
  {
    id: 3,
    title: "SNS형",
    text: "제목과 줄글 텍스트를 입력할 수 있습니다. 버튼과 이미지를 활용하여, SNS 느낌의 메시지를 작성해 보세요.",
    imageName: ImgHomeMessageType4,
  },
];

export const actionButtons: ActionButtonType[] = [
  {
    id: 0,
    title: "URL 연결",
    text: "Web page 또는 App으로 이동할 수 있습니다.",
    imageName: ImgHomeAction1,
  },
  {
    id: 1,
    title: "지도/위치",
    text: "미리 지정된 지도의 위치를 보여주거나 사용자의 현재 위치를 서버로 전송할 수 있습니다.",
    imageName: ImgHomeAction2,
  },
  {
    id: 2,
    title: "일정 등록",
    text: "사용자의 캘린더에 특정 일정을 등록할 수 있습니다.",
    imageName: ImgHomeAction3,
  },
  {
    id: 3,
    title: "복사하기",
    text: "특정 문구를 사용자 단말이 자동 복사할 수 있게 합니다.",
    imageName: ImgHomeAction4,
  },
  {
    id: 4,
    title: "대화방 열기",
    text: "다른 번호로 메시지를 보낼 수 있도록 대화방을 엽니다.",
    imageName: ImgHomeAction5,
  },
  {
    id: 5,
    title: "전화 연결",
    text: "특정 전화번호로 전화를 걸 수 있습니다.",
    imageName: ImgHomeAction6,
  },
];

export const successfulCases: SuccessfulCaseType[] = [
  {
    id: 0,
    type: "뜸한 고객 재방문 유도",
    title: "서면 R레스토랑 성공사례",
    text: "한동안 방문하지 않은 고객에게 쿠폰을 보내\n재방문을 유도해보세요.\n매장을 잊을 틈을 주지 마세요.",
    imageName: ImgHomeSuccessfulMockup1,
    totalSales: 37520000,
    costPerDay: 4860,
    roi: 4.3,
  },
  {
    id: 1,
    type: "적립/구매량 별 이벤트",
    title: "목동 S반찬가게 성공사례",
    text: "적립이나 구매를 많이 한 고객에게\n감사의 마음을 전해보세요.\n단골을 충성 고객으로 만들어 보세요.",
    imageName: ImgHomeSuccessfulMockup2,
    totalSales: 15232000,
    costPerDay: 2560, //76800
    roi: 6.23,
  },
  {
    id: 2,
    type: "첫 적립 고객 환영하기",
    title: "광화문 C카페 성공사례",
    text: "처음 적립한 고객에게 환영 문자를 보내보세요.\n처음 온 고객이 두 번째 방문하면\n재방문률이 50% 이상 상승합니다. ",
    imageName: ImgHomeSuccessfulMockup3,
    totalSales: 22405000,
    costPerDay: 13000,
    roi: 5.1,
  },
  {
    id: 3,
    type: "생일 고객 축하하기",
    title: "강남 M피부과 성공사례",
    text: "생일 고객에게 깜짝 생일 쿠폰을 보내보세요.\n고객의 생일을 축하해 \n감동을 선사하세요.",
    imageName: ImgHomeSuccessfulMockup4,
    totalSales: 51000000,
    costPerDay: 1300,
    roi: 7.3,
  },
];

export const pricings: PricingType[] = [
  {
    id: 0,

    title: "저렴한 비용으로\n즉시 전송이 필요한 고객",
    imageName: ImgHomePricingChar1,
    iamgeNameActive: ImgHomePricingChar1Active,
    activeIds: [0, 1, 2],
  },
  {
    id: 1,
    title: "클릭UP 문자 컨설팅을\n진행한 고객",
    imageName: ImgHomePricingChar2,
    iamgeNameActive: ImgHomePricingChar2Active,
    activeIds: [1, 4, 5],
  },
  {
    id: 2,
    title: "비즈포스트\n스마트 디자인메시지 고객",
    imageName: ImgHomePricingChar3,
    iamgeNameActive: ImgHomePricingChar3Active,
    activeIds: [4, 5, 6],
  },
];

export const messagePricing = [
  { id: 0, name: "단문", subname: "SMS", price: 10.5 },
  { id: 1, name: "장문", subname: "LMS", price: 32 },
  { id: 2, name: "포토", subname: "MMS", price: 65 },
  { id: 3, name: "비즈문자", subname: "SMS", price: 10.5 },
  { id: 4, name: "비즈문자", subname: "LMS", price: 32 },
  { id: 5, name: "비즈문자", subname: "MMS", price: 65 },
  { id: 6, name: "비즈문자", subname: "템플릿", price: 65 },
];

export const periodOptions: PeriodOptionType[] = [
  { id: 0, text: "오늘", days: 0 },
  { id: 1, text: "1주일", days: 7 },
  { id: 2, text: "1개월", days: 30 },
  { id: 3, text: "3개월", days: 89 },
];

export const smsTypeOptions: SmsType[] = [
  {
    key: "sms",
    uppercaseKey: "SMS",
    label: "단문 (SMS)",
  },
  {
    key: "lms",
    uppercaseKey: "LMS",
    label: "장문 (LMS)",
  },
  {
    key: "mms",
    uppercaseKey: "MMS",
    label: "포토 (MMS)",
  },
  // {
  //   key: "sms_biz",
  //   uppercaseKey: "RCS_SMS",
  //   label: "비즈스마트 (SMS)",
  // },
  // {
  //   key: "lms_biz",
  //   uppercaseKey: "RCS_LMS",
  //   label: "비즈스마트 (LMS)",
  // },
  // {
  //   key: "mms_biz",
  //   uppercaseKey: "RCS_MMS",
  //   label: "비즈스마트 (MMS)",
  // },
];

export const QnaList: QnaType[] = [
  {
    id: "1",
    question: "인프라 이중화 시스템은 어떤 건가요?",
    answer: (
      <>
        인프라 이중화 시스템(DR)이란 물리적으로 떨어진 2개의 장소에 인프라 및
        시스템이 구축되어 있음을 의미합니다. KT는 분당-대전 간 100km 이상의
        이격거리를 갖추어, 어떤 상황이 벌어지더라도 즉각 절체하여 무중단 운영이
        가능합니다.
      </>
    ),
  },
  {
    id: "2",
    question:
      "발신번호는 어떻게 사전등록 해야 하나요? 왜 미리 등록해야만 하나요? ",
    answer: (
      <>
        PC나 시스템을 통해 발송하는 국내 모든 A2P 문자메시지는 전화번호를
        사전등록하도록 정부기관에서 감독하고 있습니다. 서비스 이용회원은 반드시
        본인 명의로 된 번호만 등록하여 문자를 전송할 수 있습니다. 기업회원은
        스마트메시지 고객센터를 통해, 개인회원은 본인 명의의 휴대전화번호를
        휴대폰 본인인증을 통해 등록 할 수 있습니다.
      </>
    ),
  },
  {
    id: "3",
    question:
      "기업회원인데 매월 고정적으로 문자를 다량 발송하고 있습니다. 별도 요금조정은 어려운가요?",
    answer: (
      <>
        스마트메시지 고객센터로 문의주시면 협정요금 조정 가능여부를 내부적으로
        확인하여 안내 드리겠습니다.
      </>
    ),
  },
  {
    id: "4",
    question: "기업회원과 개인회원은 어떤 차이가 있나요?",
    answer: (
      <>
        <br />
        <img
          src={ImgHomeQuestionImg}
          alt="기업회원 개인회원 차이"
          height="127"
          width="804"
        />
      </>
    ),
  },
];

export const serviceCertificate = `통신서비스 이용증명원은 각 통신사 홈페이지에서 다운받으실 수 있습니다.
통신사별 발급 방법은 아래와 같습니다.
(최근 1개월 이내 통신사에서 발급된 이용증명원으로 유첨해주세요)
 
<a href="https://www.tworld.co.kr/poc/html/main/MA.html" target="_blank" style="text-decoration: underline;"><b>SK텔레콤 바로가기</b></a>
ㆍ 발급방법 : 로그인 > 나의 가입정보 > 이용계약증명서 조회
ㆍ 모바일서비스 고객센터 : 114, 080-011-6000, 1599-0011
ㆍ 유선서비스 고객센터 : 080-816-2000, 1600-2000


<a href="https://cfm.kt.com/images/v2/layout/gnb-ktlogo.png" target="_blank" style="text-decoration: underline;"><b>KT 바로가기</b></a>
ㆍ 발급방법 : 로그인 > 가입정보 > 조회/변경 > 가입증명원 인쇄
ㆍ 모바일서비스 고객센터 : 114, 1588-0010
ㆍ 유선서비스 고객센터 : 100


<a href="http://www.uplus.co.kr" target="_blank" style="text-decoration: underline;"><b>LG U+ 바로가기</b></a>
ㆍ 발급방법 : 로그인 > 상품가입안내 > 가입사실확인 조회
ㆍ 모바일서비스 고객센터 : 114, 1544-0010
ㆍ 유선서비스 고객센터 : 101


<a href="https://www.uplussave.com" target="_blank" style="text-decoration: underline;"><b>U+ 알뜰모바일 바로가기</b></a>
ㆍ 고객센터 : 1644-5353


<a href="https://www.skbroadband.com" target="_blank" style="text-decoration: underline;"><b>SK 브로드밴드 바로가기</b></a>
ㆍ 고객센터 : 106


<a href="https://www.sejongtelecom.net" target="_blank" style="text-decoration: underline;"><b>세종텔레콤 바로가기</b></a>
ㆍ 고객센터 : 1688-1000, 080-889-1000

<a href="https://www.ktmmobile.com" target="_blank" style="text-decoration: underline;"><b>KT 알뜰폰 M모바일 바로가기</b></a>
ㆍ 고객센터 : 1899-5000`;
