import styled from "styled-components";

interface Props {
  name: string;
  value: number;
  unit?: string;
}
export const StatListItem = ({ name, value, unit = "%" }: Props) => {
  return (
    <StatTitle>
      <label>{name}</label>
      <span>
        <b>{value}</b>
        <span>{unit}</span>
      </span>
    </StatTitle>
  );
};

const StatTitle = styled.div`
  display: flex;
  justify-content: space-between;
  background: rgba(51, 51, 51, 0.08);
  border-radius: 10px;
  padding: 16px;
`;
