import styled, { css } from "styled-components";
import { Flex } from "styles/styled";

interface Props {
  fileName: string;
  placeholder: string;
  labelText: string;
  inputId: string;
  disabled?: boolean;
  onHandleChangeFile: (file: any) => void;
}
export const InputFile = ({
  disabled = false,
  fileName,
  placeholder,
  labelText,
  inputId,
  onHandleChangeFile,
}: Props) => {
  return (
    <Container>
      <input
        style={{ display: "none" }}
        id={inputId}
        type="file"
        accept=".jpg, .png, pdf (최대 5MB)"
        onChange={onHandleChangeFile}
        disabled={disabled}
      />
      <Flex gap={10}>
        <Input
          type="text"
          placeholder={placeholder}
          value={fileName}
          disabled={disabled}
          readOnly
        />
        <Button active={!disabled} htmlFor={inputId}>
          <div>{labelText}</div>
        </Button>
      </Flex>
    </Container>
  );
};

const Container = styled.div`
  margin: 0 auto;
`;
const Input = styled.input<{ disabled: boolean }>`
  flex: 1;
  border: 1px solid #d7dbe2;
  border-radius: 4px;
  padding: 12px 0 12px 16px;
  height: 56px;
  font-size: 16px;

  &::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -1px;
    color: #999999;
  }

  ${(props) =>
    props.disabled &&
    css`
      background-color: #ededed;
    `}
`;

const Button = styled.label<{ active: boolean }>`
  flex-shrink: 0;
  border-radius: 4px;
  background: #e1e1e1;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  line-height: 36px;
  padding: 10px 16px;
  height: 56px;
  cursor: pointer;
  box-sizing: border-box;

  ${(props) =>
    props.active &&
    css`
      background: #333;
      cursor: pointer;
    `}
`;
