import styled from "styled-components";
import { StatisticsCountType } from "types/types";

interface Props {
  name: string;
  statistic: StatisticsCountType;
}
export const StatisticListItem: React.FC<Props> = ({ name, statistic }) => {
  if (statistic.total_count > 0) {
    return (
      <StatResultItem>
        <StatResultTotal>
          <span>{name}</span>
          <span>
            <b>{statistic.total_count}</b>건
          </span>
        </StatResultTotal>
        {/* 성공 건수 */}
        <StatResult>
          <b>{statistic.total_success}</b>건
        </StatResult>
        {/* 실패 건수 */}
        <StatResult>
          <b>{statistic.total_failed}</b>건
        </StatResult>
        {/* 대기 건수 */}
        <StatResult>
          <b>{statistic.total_waiting}</b>건
        </StatResult>
      </StatResultItem>
    );
  } else {
    return null;
  }
};

const StatResultItem = styled.div`
  display: flex;
  gap: 8px;
`;
const StatResult = styled.div`
  padding: 0px 16px 12px;
  text-align: right;
  width: 112px;
  b {
    padding-right: 2px;
  }
`;

const StatResultTotal = styled(StatResult)`
  display: flex;
  justify-content: space-between;
  flex: 1;
`;
